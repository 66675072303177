import {
  LOAD_FIELD,
  LOAD_RESULT,
  LOAD_ONE_FIELD,
  LOAD_TEST,
  LOAD_CONGRATULATIONS,
  LOAD_TEXT,
} from '../actionTypes';

const field = (
  state = { field: [], result: [], fieldOne: null, test: [] },
  action
) => {
  switch (action.type) {
    case LOAD_FIELD:
      return { field: [...action.fields] };
    case LOAD_RESULT:
      return { result: [...action.results] };
    case LOAD_ONE_FIELD:
      return { fieldOne: action.field };
    case LOAD_TEST:
      return { test: [...action.tests] };
    case LOAD_CONGRATULATIONS:
      return { congrats: action.congrats };
    case LOAD_TEXT:
      return { text: action.text };
    default:
      return state;
  }
};

export default field;
