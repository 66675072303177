import React, { Component } from 'react';
import { scrollToY } from '../services/scroll';
import { connect } from 'react-redux';
import { AddEmail } from '../store/actions/survey';
import Footer from './Footer';
import WaitNavbar from '../containers/WaitNavbar';
import WaitSuccess from './WaitSuccess';

class Waitlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      more: '',
      email: '',
      error: '',
      share: false,
      cancel: true,
      view: true,
    };
  }

  componentDidMount() {}

  handleMore = (e) => {
    this.setState({
      more: e,
    });
  };

  handleClick = (e) => {
    const el = document.getElementById(e);
    var pos = el.scrollLeft;
    scrollToY(pos + 355, 1000, el);
  };

  handleMove = (e) => {
    const el = document.getElementById('nav');
    if (e) {
      el.classList.remove('whit');
    } else {
      el.classList.add('whit');
    }
  };

  handleScroll(e) {
    e.preventDefault();
    const schedule = document.querySelector('.fifthland .service-cards');
    schedule.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
  }

  handleCopy = (e) => {
    const text = 'https://www.trailng.com/pathway';
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  };

  handleSubmit = (e) => {
    const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var { email } = this.state;
    email = email.replace(/\s/g, '');
    if (re.test(email)) {
      this.setState({
        view: false,
      });
      this.props
        .AddEmail({ email })
        .then(() => {
          if (this.props.errors.message === null) {
            this.setState({
              email: '',
              cancel: false,
              share: false,
              view: true,
            });
          } else {
            this.setState({
              error: this.props.errors.message,
              share: false,
              view: true,
            });
          }
        })
        .catch(() => {
          this.setState({
            view: true,
          });
        });
      this.setState({
        error: '',
      });
    } else {
      this.setState({
        error: 'Please Enter a valid email address',
      });
    }
  };

  handleChange = (e) => {
    this.setState({
      email: e.target.value,
      error: '',
    });
  };

  handleClick = (e) => {
    if (e.target.className.indexOf('ignore') === -1) {
      this.setState({
        error: '',
      });
      return;
    }
  };

  handleShare = (e) => {
    this.setState({
      share: true,
    });
  };

  handleCancel = (e) => {
    this.setState({
      cancel: true,
      share: false,
    });
  };

  render() {
    const { email, view, error, cancel, share } = this.state;

    return (
      <>
        <WaitNavbar />
        <div className="webinar" id="webinar">
          <h1>Build a successful career in Marketing</h1>
          <p>
            Trail is building a personalized learning pathway platform that can
            help you build an excellent career in marketing in the fastest,
            easiest, and safest way possible.
          </p>
          <div className="form">
            <div className="err">{error}</div>
            <div className={`search ${error !== '' ? 'error' : ''} `}>
              <label
                htmlFor="email"
                onClick={this.handleSubmit}
                className="ignore"
              >
                <div className="inner">
                  <div className={`ignore loader ${view ? 'hide' : ''}`}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                  <span className={`ignore ${view ? '' : 'hide'}`}>
                    Register
                  </span>
                </div>
              </label>
              <input
                type="text"
                id="email"
                placeholder="Johndoe@example.com"
                onChange={this.handleChange}
                value={email}
                name="email"
                className="ignore"
              />
            </div>
            <WaitSuccess
              share={share}
              cancel={cancel}
              shareBtn={this.handleShare}
              cancelBtn={this.handleCancel}
              title={'Thanks for Registering!'}
              desc={'We hope you have a swell time.'}
              copy={this.handleCopy}
            />
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    errors: state.errors,
  };
}

export default connect(mapStateToProps, { AddEmail })(Waitlist);
