import React, { Component } from 'react';
import Footer from './Footer';
import WaitNavbar from '../containers/WaitNavbar';
import { fetchField } from '../store/actions/field';
import { pathwayFullUrl } from '../store/actions/payments';
import { getUser } from '../store/actions/users';
import { connect } from 'react-redux';
import Error from './Error';
import {} from './Copy/Copy';

class Pathway extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      load: false,
      name: '',
      view: false,
      id: '',
    };
  }

  componentDidMount() {
    var loc = this.props.location.pathname.split('/');
    if (this.props.currentUser.isAuthenticated) {
      this.props
        .getUser(this.props.currentUser.user.id, 'user')
        .then((user) => {
          if (user.fieldPayment[0]) {
            this.props.history.push(`/assess/${loc[2]}`);
          }
        });
    }
    this.props
      .fetchField(loc[2])
      .then(() => {
        if (this.props.fields.length === 0 || !this.props.fields) {
          this.setState({
            error: true,
          });
        } else {
          const { name, _id } = this.props.fields[0];
          this.setState({
            load: true,
            name: `Start building a successful ${name} career`,
            id: _id,
          });
        }
      })
      .catch(() => {
        this.setState({
          error: true,
        });
      });
  }

  handleClick = (e) => {
    var loc = this.props.location.pathname.split('/');
    this.setState({
      view: true,
    });
    if (!this.props.currentUser.isAuthenticated) {
      this.setState({
        view: false,
      });
      this.props.history.push('/signin', {
        from: this.props.location.pathname,
        err: 'You need to be logged in to get a pathway',
      });
      return;
    }
    this.props.getUser(this.props.currentUser.user.id, 'user').then((user) => {
      if (user.fieldpayment[0].fullPayment) {
        this.setState({
          view: false,
        });
        this.props.history.push(`/assess/${loc[2]}`);
      }
    });
    this.props
      .pathwayFullUrl(this.state.id)
      .then(() => {
        this.setState({
          view: false,
        });
        window.location.href = this.props.pathwayFull;
      })
      .catch(() => {
        this.setState({
          view: false,
        });
        return;
      });
  };

  render() {
    const { error, load, name, view } = this.state;

    return error ? (
      <Error />
    ) : (
      <div className="home-hero">
        <WaitNavbar />
        {load ? (
          <div className="pathpage">
            <div className="content">
              <div className="title">{name}</div>
              <p>
                The good thing about learning product design is that there are
                tons of free resources out there. But then, the bad thing about
                learning product design is that{' '}
                <span>there are tons of resources out there.</span>
              </p>
              <img
                src="https://dqe80zoqbuyqe.cloudfront.net/root/New+Iteration-15.png"
                alt="pathway"
              />
              <p>
                Trail has handpicked the most suitable resources for you and
                will arrange them in the order in which you can best learn them.
                Start learning the right things the right way.{' '}
              </p>
              <div className="link" onClick={this.handleClick}>
                <span className={view === false ? '' : 'hide'}>
                  Get Started
                </span>{' '}
                <div className={`loader ${view === false ? 'hide' : ''}`}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
            <div className="img">
              <img
                src="https://dqe80zoqbuyqe.cloudfront.net/root/New+Iteration-13.png"
                alt="pathway"
              />
            </div>
          </div>
        ) : (
          <div className="pathpage">
            <div className="content">
              <div className="title load flicker"></div>
              <p className="load flicker"></p>
              <p className="load flicker"></p>
              <p className="load flicker"></p>
              <div className="link load flicker"></div>
            </div>
            <div className="img"></div>
          </div>
        )}
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    fields: state.field.fieldOne,
    pathwayFull: state.payment.pathwayFullUrl,
  };
}

export default connect(mapStateToProps, {
  fetchField,
  pathwayFullUrl,
  getUser,
})(Pathway);
