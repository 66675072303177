import { apiCall } from '../../services/api';
import { addError, removeError } from './errors';
import { LOAD_RESOURCES, LOAD_SECTION_RESOURCES } from '../actionTypes';

export const loadResources = (resources) => ({
  type: LOAD_RESOURCES,
  resources,
});

export const loadSectionResources = (resources) => ({
  type: LOAD_SECTION_RESOURCES,
  sectionResources: resources,
});

export const fetchResources = (id, subscriptionNo, type) => {
  return (dispatch) => {
    return apiCall('get', `/api/v1/pathways/${id}/resources`)
      .then(({ data }) => {
        const newData = data.filter((e) => {
          const foundNo = e.section.find((el) => el.payment <= subscriptionNo);
          const foundType = e.section.find((el) => el.type === type);
          if (!foundNo || !foundType) {
            return false;
          }
          return true;
        });
        dispatch(loadResources(newData));
        dispatch(removeError());
      })
      .catch((err) => {
        dispatch(addError(err));
      });
  };
};

export const sectionResources = (id, data) => {
  return (dispatch) => {
    return apiCall('post', `/api/v1/fields/${id}/resources`, data)
      .then(({ data }) => {
        dispatch(loadSectionResources(data));
        dispatch(removeError());
      })
      .catch((err) => {
        dispatch(addError(err));
      });
  };
};
