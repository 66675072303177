import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Ipad from "./PictureOne.png";
import Laptop from "./PictureTwo.png";
import Pointer from "./pointer.png";
import Highlight from "./highlight.png";
import TrailFooter from "./trailFooter.png";
import Instagram from "./instagram.png";
import Linkedin from "./linkedin.png";
import Twitter from "./twitter.png";
import TikTok from "./tiktok.png";
import Logo from "./logo.png";
import Revenue from "./revenue.png";
import Leadway from "./leadWay.png";
import Efficient from "./efficient.png";
import EmployeeDevelopment from "./employeeDevelopment.png";
import OptimizeBusiness from "./optimizeBusiness.png";
import KeepAnEye from "./keepAnEye.png";
import BusinessInsights from "./businessInsights.png";
import Visualize from "./visualize.png";
import VisualizeLaptop from "./visualizeLaptopOne.png";
import VisualizePhone from "./visualizeLaptop2.png";
import UltimateToolForTracking from "./ultimateToolForTracking.png";

import "./EnterpriseLanding.css";

import Carousel from "react-material-ui-carousel";

class EnterpriseLanding extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: false,
    };
  }

  componentDidMount() {
    const query = window.location.search;
    if (query.includes("portfolio=mubarak")) {
      localStorage.setItem("wake", true);
      localStorage.setItem("portfolio", Date.now().toString());
      this.props.wake();
    }
  }

  handleClick = (e) => {
    this.setState({ menu: !this.state.menu });
  };

  handleDemo = () => {
    window.open("https://calendly.com/onetrail");
  };

  render() {
    var items = [
      {
        description: "Exponentially Increase Your Business Revenue",
        subtopic:
          " By investing in employee development, your business can improve its team's skills and productivity, potentially leading to increased revenue.",
        image: Revenue,
      },
      {
        description: "Lead the Way and Stay Ahead of Your Competition ",
        subtopic:
          "With One Trail, your business can ensure that its employees have the skills they need to stay competitive in the industry.",
        image: Leadway,
      },
      {
        description: "Maximize Efficiency and Save Time ",
        subtopic:
          "One Trail's tracking tools can help businesses stay organized and save time, leading to more efficient processes and better use of resources.",
        image: Efficient,
      },
    ];

    function Item(props) {
      return (
        <div className="carouselContent">
          <img
            src={props.item.image}
            alt={props.item.description}
            width="44px"
            height="44px"
          />
          <p className="contentHeader">{props.item.description}</p>

          <p className="contentBody">{props.item.subtopic}</p>
        </div>
      );
    }

    return (
      <div className="enterpriseContainer">
        <Helmet>
          <title>One Trail</title>
          <meta name="description" content="My app description" />
        </Helmet>

        <nav className="navbar">
          <div>
            <img src={Logo} alt="Logo" className="brandTitle" />
          </div>

          <button
            class={`hamburger hamburger--squeeze ${
              this.state.menu ? "active" : ""
            }`}
            type="button"
            onClick={this.handleClick}
          >
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button>

          <div className={`navbarLinks ${this.state.menu ? "active" : ""}`}>
            <ul>
              <li>
                <a href="/"> Home </a>
              </li>

              <li>
                <a href="/about"> About Us</a>
              </li>

              <li>
                <a href="/individuals">For Individuals</a>
              </li>

              <li>
                <a href="/signin">log in</a>
              </li>

              <button className="demo-button" onClick={this.handleDemo}>
                Request Demo
              </button>
            </ul>
          </div>
        </nav>

        <div className="bodyContainer">
          <div className="heroDocs">
            <h2>
              Take your business to the{" "}
              <span className="nextLevel">next level </span> by transforming
              your workforce!
              <img src={Highlight} alt="highlight" className="highlight" />
            </h2>

            <div>
              <p>
                With our easy-to-use platform, your company can quickly and
                easily identify skill gaps, create personalized learning plans,
                and track employee progress.
              </p>

              <img src={Pointer} alt="pointer" className="pointer" />
            </div>

            <button className="requestDemo bookDemo" onClick={this.handleDemo}>
              Book a free demo
            </button>
          </div>

          <div className="heroImages">
            <img src={Ipad} alt="Trail Ipad View" />
            <img
              src={Laptop}
              alt="Trail Laptop View"
              className="laptopActive"
            />
          </div>

          <Carousel
            className="carousel"
            indicatorIconButtonProps={{
              style: {
                color: "#D9D9D9",
                width: "9px",
                height: "9px",
                marginRight: "10px",
              },
            }}
            activeIndicatorIconButtonProps={{
              style: {
                color: "#18A0FB",
                width: "9px",
                height: "9px",
              },
            }}
            indicatorContainerProps={{
              style: {
                marginRight: "10px",
              },
            }}
          >
            {items.map((item, i) => (
              <Item key={i} item={item} />
            ))}
          </Carousel>

          <div className="webCarousel">
            <div className="wcContent">
              <img src={Revenue} alt="revenue" width="59px" height="65px" />
              <p className="contentHeader">
                {" "}
                Exponentially Increase Your Business Revenue{" "}
              </p>
              <p className="contentBody">
                By investing in employee development, your business can improve
                its team's skills and productivity, potentially leading to
                increased revenue.
              </p>
            </div>

            <div className="wcContent">
              <img src={Leadway} alt="Leadway" width="59px" height="65px" />
              <p className="contentHeader">
                {" "}
                Lead the Way and Stay Ahead of Your Competition{" "}
              </p>
              <p className="contentBody">
                By investing in employee development, your business can improve
                its team's skills and productivity, potentially leading to
                increased revenue.
              </p>
            </div>

            <div className="wcContent">
              <img src={Efficient} alt="Save time" width="59px" height="65px" />
              <p className="contentHeader">
                Maximize Efficiency and Save Time{" "}
              </p>
              <p className="contentBody">
                One Trail's tracking tools can help businesses stay organized
                and save time, leading to more efficient processes and better
                use of resources.
              </p>
            </div>
          </div>

          <div className="companyPotential">
            <h3>
              <span className="cpSpan">Unlock</span> your company's full
              potential by filling the skill gaps{" "}
              <span className="cpSpan">in your teams</span>
              <img src={Highlight} alt="highlight" className="highlight" />
            </h3>
          </div>

          <div className="cpCards">
            <div className="grid">
              <div className="gridContent">
                <p className="gridTitle">Upskill your workforce</p>
                <p className="gridSubtitle">Improved employee development</p>

                <p className="gridDescription">
                  One Trail can help businesses identify skill gaps and create
                  personalized learning plans, leading to more effective and
                  efficient employee development.
                </p>
              </div>

              <img
                src={EmployeeDevelopment}
                alt="Employee Development"
                className="gridImage"
              />
            </div>

            <div className="grid">
              <div className="gridContent">
                <p className="gridTitle">Accelerated Growth</p>
                <p className="gridSubtitle">Optimize your business</p>
                <p className="gridDescription">
                  Streamline your processes and optimize your resources with our
                  dashboard and tracking tools. Invest in One Trail and improve
                  your business's efficiency and effectiveness.
                </p>
              </div>

              <img
                src={OptimizeBusiness}
                alt="Optimize your business"
                className="gridImage"
              />
            </div>

            <div className="grid">
              <div className="gridContent">
                <p className="gridTitle">Stay in Charge</p>
                <p className="gridSubtitle">Keep an eye on your team</p>
                <p className="gridDescription">
                  Our dashboard provides a clear view of employee growth,
                  helping you make informed decisions about employee development
                  and career advancement.
                </p>
              </div>

              <img
                src={KeepAnEye}
                className="gridImage"
                alt="Keep an eye on your team"
              />
            </div>

            <div className="grid">
              <div className="gridContent">
                <p className="gridTitle">Get Key Insights</p>
                <p className="gridSubtitle">Greater business insights</p>
                <p className="gridDescription">
                  By using One Trail's dashboard and tracking tools, your
                  business can better understand its team's skills and areas for
                  improvement, allowing you to make informed decisions.
                </p>
              </div>

              <img
                src={BusinessInsights}
                className="gridImage"
                alt="Business Insights"
              />
            </div>
          </div>

          <div className="lastGrid">
            <div className="gridContent">
              <p className="gridTitle">See the Change</p>
              <p className="gridSubtitle">Visualize your team's growth</p>
              <p className="gridDescription">
                Visualize your team's skills and progress to make informed
                decisions about employee development and allocate resources
                effectively.
              </p>

              <img
                src={VisualizeLaptop}
                className="gridFullView laptop"
                alt="Visualize team growth"
              />
            </div>

            <div className="lastGridElement2">
              <img
                src={VisualizePhone}
                className="gridFullView phone"
                alt="Visualize team growth"
              />
            </div>

            <img
              src={Visualize}
              className="gridImage"
              alt="Visualize team growth"
            />
          </div>

          <div className="transformWorkforceAndBusiness">
            <div>
              <p className="readyToTransform">
                Ready to transform your workforce and business?
              </p>

              <h4>
                The ultimate tool for tracking employee learning and
                development.
              </h4>

              <p className="unlockBusiness">
                Unlock your business's full potential with One Trail - see how
                it can make a difference!
              </p>

              <button
                className="requestDemo bookDemo"
                onClick={this.handleDemo}
              >
                Book a free demo
              </button>
            </div>

            <div>
              <img
                src={UltimateToolForTracking}
                alt="Unlock full potential"
                className="ultimateTool"
              />
            </div>
          </div>
        </div>

        <div className="enterpriseFooter">
          <div className="bottomNav">
            <img src={TrailFooter} alt="logo" className="footerImg" />

            <p className="boostBusiness">
              Boost your business with One Trail: enhance employee development
              and increase revenue
            </p>

            <div className="contactUs">
              <p className="contactHeader">
                <strong>CONTACT</strong>
              </p>

              <a
                href="mailto:hello@trailng.com?subject=We%20are%20excited%20to%hear%20from%20you"
                target="_blank"
              >
                <p className="email">Email: hello@trailng.com</p>
              </a>

              <a href="tel:+2348146770822">
                <p className="number">Phone: +234 814 677 0822</p>
              </a>
            </div>
          </div>

          <div className="divider"></div>

          <div className="socialsBox">
            <p className="trademark">© 2022 Trail Ltd. All Rights Reserved</p>

            <div className="socialMediaIcons">
              <a href="https://instagram.com/trail.ng" target="_blank">
                <img src={Instagram} alt="Instagram logo" />
              </a>

              <a
                href="https://www.linkedin.com/company/trailng"
                target="_blank"
              >
                <img src={Linkedin} alt="Linkedin logo" />
              </a>

              <a href="https://www.tiktok.com/@trail.africa" target="_blank">
                <img src={TikTok} alt="TikTok logo" />
              </a>

              <a href="https://twitter.com/trail_ng?s=20" target="_blank">
                <img src={Twitter} alt="Twitter logo" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EnterpriseLanding;
