import {
  GET_MENTOR_HOUR_URL,
  GET_PATHWAY_FULL_URL,
  GET_PATHWAY_SUB_URL,
  GET_BANK,
} from '../actionTypes';

const payment = (
  state = { mentorHourUrl: null, pathwayFullUrl: null, banks: [] },
  action
) => {
  switch (action.type) {
    case GET_MENTOR_HOUR_URL:
      return { mentorHourUrl: action.url };
    case GET_PATHWAY_FULL_URL:
      return { pathwayFullUrl: action.url };
    case GET_PATHWAY_SUB_URL:
      return { pathwaySubUrl: action.url };
    case GET_BANK:
      return { banks: action.data };
    default:
      return state;
  }
};

export default payment;
