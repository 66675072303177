import React, { Component } from 'react';
import Footer from './Footer';
import WaitNavbar from '../containers/WaitNavbar';
import Firstpage from './Firstpage';
import { land56 } from './Copy/Copy';

class Pathwaypage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { title, dub } = this.props;
    return (
      <div className="home-hero">
        <WaitNavbar />
        <Firstpage
          alt
          alt1
          img={land56}
          simg={land56}
          text={[
            `${title ? title : 'Error 404 You got the wrong page'}`,
            `${
              dub ? dub : 'sorry about the confusion, kindly go back or go home'
            }`,
          ]}
          btn="Home"
          url="/"
          err
        />
        <Footer />
      </div>
    );
  }
}

export default Pathwaypage;
