import React, { Component } from 'react';

class Obj extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    var { e, change, check } = this.props;

    return (
      <div className="container">
        <p>{e.question}</p>
        <div className="input">
          <div className="radio test">
            {e.options.map((el, i) => (
              <div key={i}>
                <input
                  type={check ? 'checkbox' : 'radio'}
                  onChange={change}
                  className="state"
                  id={el}
                  value={el}
                  name={e.question}
                />
                <label htmlFor={el}>
                  <span className="fit">
                    <span>
                      <div className="circle"></div>
                    </span>
                    {el}
                  </span>
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Obj;
