import { LOAD_PATHWAY } from '../actionTypes';

const pathway = (state = [], action) => {
  switch (action.type) {
    case LOAD_PATHWAY:
      return [...action.pathways];
    default:
      return state;
  }
};

export default pathway;
