import { ADD_LEARNING, GET_MICROSKILLS, UPDATE_LEARNING } from '../actionTypes'

const learning = (
  state = { new: {}, microSkills: {}, updatedLearning: {} },
  action
) => {
  switch (action.type) {
    case ADD_LEARNING:
      return { new: action.learning }
    case GET_MICROSKILLS:
      return { microSkills: action.microSkills }
    case UPDATE_LEARNING:
      return { updatedLearning: action.learning }
    default:
      return state
  }
}

export default learning
