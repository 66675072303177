import React, { Component } from 'react';

class Mcq extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    var { e, change, id, check } = this.props;

    return (
      <div className="container mcq" key={id}>
        <div className="intro">
          <span>{id + 1}.</span>
          <p>{e.question}</p>
        </div>
        <div className="input">
          {e.options.map((el, i) => (
            <label className="form-control mcq" key={i}>
              <input
                type={check ? 'checkbox' : 'radio'}
                onChange={change}
                value={el}
                name={e.question}
              />
              <span>{el}</span>
            </label>
          ))}
        </div>
      </div>
    );
  }
}

export default Mcq;
