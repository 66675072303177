import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addTime, generateCode } from '../store/actions/users';
import { withdraw } from '../store/actions/payments';
import {
  icon07,
  icon08,
  icon23,
  icon10,
  icon11,
  icon12,
  icon17,
  icon26,
  icon25,
  wicon40,
} from './Copy/Copy';

class Referral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      draw: false,
      amount: '',
      success: '',
      view: false,
    };
  }

  handleCopy = (e) => {
    const { currentUser } = this.props;
    if (e.target.className.indexOf('link') !== -1) {
      const text = `https://www.trailng.com/signup?refCode=${currentUser.user.code}`;
      if ('clipboard' in navigator) {
        return navigator.clipboard.writeText(text);
      } else {
        return document.execCommand('copy', true, text);
      }
    } else if (e.target.className.indexOf('code') !== -1) {
      const text = currentUser.user.code;
      if ('clipboard' in navigator) {
        return navigator.clipboard.writeText(text);
      } else {
        return document.execCommand('copy', true, text);
      }
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleCode = (e) => {
    this.props.generateCode();
  };

  handleDraw = (e) => {
    if (e.target.className.indexOf('ignore') !== -1) {
      return;
    }
    this.setState({
      draw: !this.state.draw,
    });
  };

  handleCash = (e) => {
    this.setState({
      view: true,
    });
    if (!this.state.amount) {
      this.setState({
        error: 'Please type in an amount',
        view: false,
      });
      return;
    }

    if (this.state.amount < 1) {
      this.setState({
        error: 'Please type in a valid amount',
        view: false,
      });
      return;
    }

    if (
      parseInt(this.state.amount * 100) >
      this.props.currentUser.user.mentorBalance
    ) {
      this.setState({
        error: `Cannot withdraw more than N ${(
          this.props.currentUser.user.mentorBalance / 100
        ).toLocaleString('en-us')}`,
        view: false,
      });
      return;
    }

    this.props
      .withdraw({ amount: this.state.amount })
      .then(() => {
        this.setState({
          error: '',
          amount: '',
          success: 'Withdrawal Successful',
          view: false,
        });
      })
      .catch(() => {
        this.setState({
          error: this.props.errors.message,
          amount: '',
          view: false,
        });
      });
  };

  render() {
    const { currentUser, display, page } = this.props;
    const { error, draw, amount, success, view } = this.state;
    var referred = 0;
    var pathwayReferral = 0;
    var mentorReferral = 0;
    var total = 0;
    var date = new Date();
    date = date.toLocaleDateString('en-us', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    if (currentUser.user.referred) {
      referred = currentUser.user.referred;
      mentorReferral = currentUser.user.mentorReferral;
      pathwayReferral = currentUser.user.pathwayReferral;
      total = pathwayReferral + mentorReferral;
    }
    let setValue = (val) => (val > 9 ? '' : '0') + val;

    return (
      <div className={`referral og ${display === 'ref' ? '' : 'hide'}`}>
        <div className="info">
          <h1>My Earnings</h1>
          <div className="time">{date}</div>
        </div>
        <div className="upcoming">
          <div className="session">
            <div className="cover sm">
              <img src={icon08} alt="Refer Trail" />
              <div className="amount">
                N{' '}
                {currentUser.user.mentorBalance
                  ? (currentUser.user.mentorBalance / 100).toLocaleString(
                      'en-us'
                    )
                  : '0'}
              </div>
            </div>
            <div className="btn sm" onClick={this.handleDraw}>
              Withdraw earning <i className="fas fa-chevron-right"></i>
            </div>
            <div className="earn-cover">
              {currentUser.user.bankName ? (
                <div className="gen info left account" onClick={page}>
                  <div className="title account">Bank Details</div>
                  <div className="img account">
                    <img className="account" src={icon07} alt="Refer Trail" />
                  </div>
                  <div className="down account">
                    {currentUser.user.accountNumber}
                  </div>
                  <div className="down account">
                    {currentUser.user.accountName}
                  </div>
                  <div className="down  account">
                    {currentUser.user.bankName}
                  </div>
                  <div className="btn account" onClick={page}>
                    upload details <i className="fas fa-chevron-right"></i>
                  </div>
                </div>
              ) : (
                <div className="gen info left account" onClick={page}>
                  <div className="title account">Upload Bank Details</div>
                  <div className="img account">
                    <img className="account" src={icon07} alt="Refer Trail" />
                  </div>
                  <div className="down account">Upload your back details</div>
                  <div className="btn account" onClick={page}>
                    upload details <i className="fas fa-chevron-right"></i>
                  </div>
                </div>
              )}
              <div className="gen info bg" onClick={this.handleDraw}>
                <div className="title">Earnings</div>
                <div className="img">
                  <img src={icon08} alt="Refer Trail" />
                </div>
                <div className="amount">
                  N{' '}
                  {currentUser.user.mentorBalance
                    ? (currentUser.user.mentorBalance / 100).toLocaleString(
                        'en-us'
                      )
                    : '0'}
                </div>
                <div className="btn" onClick={this.handleDraw}>
                  Withdraw earning <i className="fas fa-chevron-right"></i>
                </div>
                {draw && (
                  <div className="form ignore draw" onClick={this.handleDraw}>
                    <div className="cancel">
                      <span></span>
                      <span></span>
                    </div>
                    <div className="info ignore">
                      <p className="ignore">Amount you want to withdraw</p>
                    </div>
                    {error && <div className="error ignore">{error}</div>}
                    {success && <div className="success ignore">{success}</div>}
                    <input
                      className={`ignore ${error ? 'err' : ''}`}
                      type="number"
                      name="amount"
                      value={amount}
                      onChange={this.handleChange}
                      placeholder="Ex: 100,000"
                      min="0"
                    />
                    <div className="links ignore">
                      <div
                        className={`btn ignore ${view ? 'hide' : ''}`}
                        onClick={this.handleCash}
                      >
                        Withdraw
                      </div>
                      <div className={`loader ${view ? '' : 'hide'}`}>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                      <div className="back">Cancel</div>
                    </div>
                  </div>
                )}
              </div>
              <div className="gen info" onClick={this.handleCode}>
                <div className="title">
                  {currentUser.user.code ? '' : 'Generate your'} Unique Link
                </div>
                <div className="img">
                  <img src={icon23} alt="Refer Trail" />
                </div>
                {currentUser.user.code && (
                  <p className="link" onClick={this.handleCopy}>
                    {`https://www.trailng.com/signup?refCode=${currentUser.user.code}`}{' '}
                    <i className="far fa-copy"></i>
                  </p>
                )}
                <p className="code" onClick={this.handleCopy}>
                  {currentUser.user.code ? (
                    <>
                      {currentUser.user.code}{' '}
                      <i className="far fa-copy cod"></i>
                    </>
                  ) : (
                    'Generate the code you would share with users to redeem your referral bonus and begin earning'
                  )}
                </p>
                {!currentUser.user.code && (
                  <div className="btn" onClick={this.handleCode}>
                    Generate your Code <i className="fas fa-chevron-right"></i>
                  </div>
                )}
              </div>
            </div>
            <p className="sm">Progress</p>
            <div className="progre sm">
              <div className="card">
                <p>signups</p>
                <img src={icon17} alt="Refer sign up" />
                <div className="no">{setValue(referred)}</div>
              </div>
              <div className="card">
                <p>Paid for Pathway</p>
                <img src={icon26} alt="Refer pathway" />
                <div className="no">{setValue(pathwayReferral)}</div>
              </div>
              <div className="card">
                <p>Paid for Mentorship</p>
                <img src={icon25} alt="Refer mentorship" />
                <div className="no">{setValue(mentorReferral)}</div>
              </div>
            </div>
            <p>Top 3 things to do</p>
            <div className="deets">
              <div className="badg">Badge</div>
              <div className="targ">Target</div>
              <div className="achi">Achieved</div>
              <div className="rema">Remaining</div>
              <div className="stat">Status</div>
            </div>
            <div className="deets icon">
              <div className="badg">
                <img src={icon12} alt="Refer badge" />
              </div>
              <div className="targ">25</div>
              <div className="achi">{total > 25 ? '25' : total}</div>
              <div className="rema">{total > 25 ? '0' : 25 - total}</div>
              <div
                className={`stat ${
                  total < 8 ? 'low' : total < 16 ? 'pend' : 'done'
                }`}
              >
                {total < 8
                  ? 'low'
                  : total < 16
                  ? 'medium'
                  : total === 25
                  ? 'done'
                  : 'almost'}
              </div>
            </div>
            <div className="deets icon">
              <div className="badg">
                <img src={icon10} alt="Refer badge" />
              </div>
              <div className="targ">50</div>
              <div className="achi">{total > 50 ? '50' : total}</div>
              <div className="rema">{total > 50 ? '0' : 50 - total}</div>
              <div
                className={`stat ${
                  total < 16 ? 'low' : total < 32 ? 'pend' : 'done'
                }`}
              >
                {total < 16
                  ? 'low'
                  : total < 32
                  ? 'medium'
                  : total === 50
                  ? 'done'
                  : 'almost'}
              </div>
            </div>
            <div className="deets icon">
              <div className="badg">
                <img src={icon11} alt="Refer badge" />
              </div>
              <div className="targ">75</div>
              <div className="achi">{total > 75 ? '75' : total}</div>
              <div className="rema">{total > 75 ? '0' : 75 - total}</div>
              <div
                className={`stat ${
                  total < 25 ? 'low' : total < 50 ? 'pend' : 'done'
                }`}
              >
                {total < 25
                  ? 'low'
                  : total < 50
                  ? 'medium'
                  : total === 75
                  ? 'done'
                  : 'almost'}
              </div>
            </div>
          </div>
          <div className="progress">
            <div className="title">Progress</div>
            <div className="stat">
              <div className="icon">
                <img src={icon17} alt="Refer sign up" />
              </div>
              <div className="desc">Sign Ups</div>
              <div className="no">{setValue(referred)}</div>
            </div>
            <div className="stat">
              <div className="icon">
                <img src={icon26} alt="Refer pathway" />
              </div>
              <div className="desc">Paid for Pathway</div>
              <div className="no">{setValue(pathwayReferral)}</div>
            </div>
            <div className="stat">
              <div className="icon">
                <img src={icon25} alt="Refer mentorship" />
              </div>
              <div className="desc">Paid for Mentorship</div>
              <div className="no">{setValue(mentorReferral)}</div>
            </div>
            <div className="query">
              <div className="bulb">
                <div className="inner">
                  <img src={wicon40} alt="Help Trail" />
                </div>
              </div>
              <div className="content">
                <div className="title">
                  Have any creative idea that might help boost our marketing?
                  Even if it's just by a tiny bit? Share away.
                </div>
                <a
                  href="mailto:marketing@trailng.com?subject=We%20want%20to%20hear%20from%20you!"
                  className="message"
                >
                  Send us a message
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { errors: state.errors, authUrl: state.authUrl };
}

export default connect(mapStateToProps, { addTime, generateCode, withdraw })(
  Referral
);
