import React from 'react';

const Byte = ({
  title,
  photo,
  desc,
  duration,
  alt,
  link,
  read,
  marked,
  pending,
  article,
}) => (
  <li>
    <div className="byte">
      <div className="card">
        {alt ? (
          <>
            <div className="img">
              <img src={photo} alt="trail blog" />
            </div>
            <div className="content">
              <div className="title">{title}</div>
              <div className="btn">
                <p>Duration: {duration}</p>
              </div>
            </div>
          </>
        ) : (
          <a href={link} target="_blank" rel="noreferrer" onClick={pending}>
            {!article && (
              <div className="cover">
                <i className="far fa-play-circle"></i>
              </div>
            )}
            <div className="img">
              <img src={photo} alt="trail blog" />
            </div>
            <div className="content">
              <div className="title">{title}</div>
              <div className="desc">{desc}</div>
              <div className="btn">
                <div
                  className={`mark ignore ${marked ? 'active' : ''}`}
                  onClick={read}
                >
                  <i className="fas fa-check"></i>{' '}
                  {marked ? 'Completed' : 'Mark as Read'}
                </div>
                <p>
                  {duration} {duration > 1 ? 'mins' : 'min'} Read
                </p>
              </div>
            </div>
          </a>
        )}
      </div>
    </div>
  </li>
);

export default Byte;
