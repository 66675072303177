import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateUser, getUser } from '../store/actions/users';
import {
  mentorHourUrl,
  pathwayFullUrl,
  pathwaySubUrl,
} from '../store/actions/payments';
import { getAllIndexes } from '../services/helperFunctions';
import Dashnav from './Dashboard/Dashnav';
import { icon33, icon42, icon44, land30, land31, land32 } from './Copy/Copy';

class onboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: '1',
      plan: '',
      clarity: '',
      tag: '',
      bio: '',
      one: [],
      four: '4',
      view: false,
      payment: '',
      close: false,
      settings: false,
      referral: '',
      nage: false,
    };
  }
  componentDidMount() {
    if (!this.props.location.state) {
      this.props.pathway
        ? this.props.history.push('/pathway')
        : this.props.history.push('/mentor');
    }
    this.props.getUser(this.props.currentUser.user.id, 'user').then((user) => {
      this.setState({
        bio: `${user.bio ? user.bio : ''}`,
        tag: `${user.tag ? user.tag : ''}`,
        referral: `${user.refCode ? user.refCode : ''}`,
        nage: user.refCode ? true : false,
      });
    });
  }
  handleClick = (e) => {
    const clicked = e.target.id;
    if (this.state.active === clicked && this.state.active !== '1') {
      this.setState({ active: '' });
    } else {
      this.setState({ active: clicked });
    }
  };
  handleForward = (e) => {
    if (e.target.id.split('.')[0] === 'pay') {
      this.setState({
        payment: e.target.id.split('.')[1],
      });
    }
    this.setState({ active: `${parseInt(this.state.active) + 1}` });
  };
  handleBack = () => {
    this.setState({ active: `${parseInt(this.state.active) - 1}` });
  };
  handleCard = (e) => {
    const clicked = e.target.id;
    this.setState({ four: clicked });
    if (this.state.plan === clicked) {
      this.setState({ plan: '' });
    } else {
      this.setState({ plan: clicked });
    }
  };
  handleSubmit = (e) => {
    if (this.state.view === false) {
      this.setState({ view: true });
    }
    let one = [this.state.tag, this.state.bio];
    let oneCopy = getAllIndexes(one, '');
    this.setState({
      one: oneCopy,
    });
    const { bio, tag } = this.state;
    if (this.props.pathway) {
      this.setState({
        four: this.state.plan,
      });
      if (oneCopy.length !== 0 || this.state.plan === '') {
        this.setState({ view: false });
        return;
      }
      if (this.state.payment === 'full') {
        this.props
          .updateUser({
            bio,
            tag,
          })
          .then(() => {
            this.props
              .pathwayFullUrl(this.props.location.state.id, {
                type: parseInt(this.state.plan),
              })
              .then(() => {
                window.location.href = this.props.pathwayFull;
              })
              .catch(() => {
                this.setState({ view: false });
                return;
              });
          })
          .catch(() => {
            this.setState({ view: false });
            return;
          });
      } else {
        this.props
          .updateUser({
            bio,
            tag,
          })
          .then(() => {
            this.props
              .pathwaySubUrl(this.props.location.state.id, {
                type: parseInt(this.state.plan),
              })
              .then(() => {
                window.location.href = this.props.pathwaySub;
              })
              .catch(() => {
                this.setState({ view: false });
                return;
              });
          })
          .catch(() => {
            this.setState({ view: false });
            return;
          });
      }
    } else {
      this.setState({
        four: this.state.clarity,
      });
      if (oneCopy.length !== 0 || this.state.clarity === '') {
        this.setState({ view: false });
        return;
      }
      const { clarity, referral } = this.state;
      this.props
        .updateUser({
          clarity,
          bio,
          tag,
        })
        .then(() => {
          this.props
            .mentorHourUrl({ time: this.props.location.state.info, referral })
            .then(() => {
              window.location.href = this.props.mentorHour;
            })
            .catch(() => {
              this.setState({ view: false });
              return;
            });
        })
        .catch(() => {
          this.setState({ view: false });
          return;
        });
    }
  };
  handleChange = (e) => {
    this.props.removeError();
    if (e.target.id === 'textarea') {
      this.setState({
        four: '4',
      });
    }
    const tags = e.target.name.split('.');
    if (tags.length > 1 && e.target.type !== 'checkbox') {
      let pageCopy = JSON.parse(
        JSON.stringify(this.state[tags[tags.length - 2]])
      );
      pageCopy = pageCopy.filter((n) => {
        return n !== parseInt(tags[tags.length - 1]);
      });
      this.setState({
        [tags[tags.length - 2]]: pageCopy,
      });
    }
    if (e.target.type === 'checkbox') {
      e.target.value === 'false'
        ? (e.target.value = 'true')
        : (e.target.value = 'false');
    }
    if (e.target.className.includes('state')) {
      let stateCopy = JSON.parse(JSON.stringify(this.state[tags[0]]));
      if (e.target.value === 'true' && e.target.type === 'checkbox') {
        stateCopy[tags[1]] = true;
      } else if (e.target.value === 'false' && e.target.type === 'checkbox') {
        stateCopy[tags[1]] = false;
      } else if (e.target.type !== 'checkbox') {
        stateCopy[tags[1]] = e.target.value;
      }
      this.setState({
        [tags[0]]: stateCopy,
      });
    } else {
      if (tags[0] === 'referral' && this.state.nage) {
        return;
      }
      this.setState({
        [tags[0]]: e.target.value,
      });
    }
  };

  handleNav = (e) => {
    if (this.state.close === false) {
      this.setState({ close: true });
    } else {
      this.setState({ close: false });
    }
    this.setState({
      right: false,
    });
  };

  handlePages = (e) => {
    const { location } = this.props;
    if (e.target.className.indexOf(`hom`) !== -1) {
      if (location.pathname !== '/') {
        this.props.history.push('/');
      } else {
        this.setState({
          display: 'hom',
          close: false,
        });
      }
    }
    if (e.target.className.indexOf(`set`) !== -1) {
      if (location.pathname !== '/') {
        this.props.history.push('/');
      } else {
        this.setState({
          close: true,
          settings: !this.state.settings,
        });
      }
    }
    if (e.target.className.indexOf(`proff`) !== -1) {
      if (location.pathname !== '/') {
        this.props.history.push('/');
      } else {
        this.setState({
          display: 'proff',
          close: false,
        });
      }
    }
    if (e.target.className.indexOf(`account`) !== -1) {
      if (location.pathname !== '/') {
        this.props.history.push('/');
      } else {
        this.setState({
          display: 'account',
          close: false,
        });
      }
    }
    if (e.target.className.indexOf(`avail`) !== -1) {
      if (location.pathname !== '/') {
        this.props.history.push('/');
      } else {
        this.setState({
          display: 'avail',
          close: false,
        });
      }
    }
  };

  logout = (e) => {
    e.preventDefault();
    this.props.logout();
  };

  render() {
    const { pathway, errors, history, removeError } = this.props;
    const {
      tag,
      bio,
      clarity,
      one,
      four,
      active,
      view,
      // plan,
      referral,
      nage,
    } = this.state;
    console.log(this.props.location.state.info);

    history.listen(() => {
      removeError();
    });
    return (
      <div className="onboard">
        <div className="content">
          <div className="onboard-nav">
            <Dashnav
              click={this.handleNav}
              page={this.handlePages}
              logout={this.logout}
              settings={this.state.settings}
              close={this.state.close}
            />
            <div
              className={`nav-info ${active === '1' ? 'show' : ''} ${
                parseInt(active) > 1 ? 'col' : ''
              } ${one.length === 0 ? '' : 'error'}`}
              id="1"
              onClick={this.handleClick}
            >
              <div className="icon">
                <i className="fas fa-check"></i>
              </div>
              Experience{' '}
              <div className="line">
                <div className="fill"></div>
              </div>
            </div>
            {pathway ? (
              <div
                className={`nav-info ${active === '2' ? 'show' : ''} ${
                  parseInt(active) > 2 ? 'col' : ''
                } ${four === '' ? 'error' : ''}`}
                id="2"
                onClick={this.handleClick}
              >
                <div className="icon">
                  <i className="fas fa-check"></i>
                </div>
                Plan{' '}
                <div className="line">
                  <div className="fill"></div>
                </div>
              </div>
            ) : (
              <div
                className={`nav-info ${active === '2' ? 'show' : ''} ${
                  parseInt(active) > 2 ? 'col' : ''
                } ${four === '' ? 'error' : ''}`}
                id="2"
                onClick={this.handleClick}
              >
                <div className="icon">
                  <i className="fas fa-check"></i>
                </div>
                Note{' '}
                <div className="line">
                  <div className="fill"></div>
                </div>
              </div>
            )}
            <div
              className={`nav-info ${active === '3' ? 'show' : ''}  ${
                view === false ? '' : 'col'
              }`}
              id="3"
              onClick={this.handleClick}
            >
              <div className="icon">
                <i className="fas fa-check"></i>
              </div>
              Payment
            </div>
          </div>
          {errors.message && (
            <div className="alert-danger">{errors.message}</div>
          )}
          <form className="onboard-form">
            <div
              className={`content ${
                active === '1' ? '' : parseInt(active) > 1 ? 'hide' : 'show'
              }`}
            >
              <div className="title">You are almost set!</div>
              <div className="sub-title">
                Your answers help our system describe you clearly to the expert
                assigned to you
              </div>
              <div className="input">
                <div className="label">Title</div>
                <input
                  type="text"
                  placeholder="Ex: Product design enthusiast"
                  onChange={this.handleChange}
                  value={tag}
                  name="tag.one.0"
                  className={`${one.includes(0) ? 'error' : ''}`}
                  tabIndex={active === '1' ? '0' : '-1'}
                />
              </div>
              <div className="input">
                <div className="label">Bio</div>
                <textarea
                  id="textarea"
                  cols="30"
                  rows="10"
                  placeholder="Ex: I am passionate about ..."
                  onChange={this.handleChange}
                  value={bio}
                  name="bio.one.1"
                  tabIndex={active === '1' ? '0' : '-1'}
                  className={`${four === '' ? 'error' : ''}`}
                ></textarea>
              </div>
              <div className="links">
                <div className="continue" onClick={this.handleForward}>
                  Continue
                </div>
                <Link
                  to="/"
                  className="home"
                  tabIndex={active === '1' ? '0' : '-1'}
                >
                  Back to Home
                </Link>
              </div>
            </div>
            {pathway ? (
              <div
                className={`back content ${
                  active === '2' ? '' : parseInt(active) > 2 ? 'hide' : 'show'
                }`}
              >
                <div className="arrow">
                  <div className="bach" onClick={this.handleBack}>
                    <i className="fas fa-arrow-left"></i>
                  </div>
                </div>
                <div className="n-arrow">
                  <div className="options">
                    <div
                      className={`card-inner ${
                        this.state.plan === '1' ? 'show' : ''
                      }`}
                    >
                      <div className="card" onClick={this.handleCard} id="1">
                        <div className="title">Basic</div>
                        <div className="icon">
                          <i className="fas fa-cog"></i>
                        </div>
                        <div className="price">~ 10</div>
                        <div className="pricetag">per course</div>
                        <div className="features">Core Features</div>
                        <div className="feature-list">lasjd jashd jas</div>
                        <div className="feature-list">
                          lasjd jashd jashd ja h
                        </div>
                        <div className="feature-list">lasjd jashd jas</div>
                        <div className="feature-list">
                          lasjd jashd jashd ja h
                        </div>
                      </div>
                    </div>
                    <div
                      className={`card-inner ${
                        this.state.plan === '2' ? 'show' : ''
                      }`}
                    >
                      <div className="card" id="2" onClick={this.handleCard}>
                        <div className="title">Premium</div>
                        <div className="icon">
                          <i className="far fa-gem"></i>
                        </div>
                        <div className="price">~ 50</div>
                        <div className="pricetag">per course</div>
                        <div className="features">Core Features</div>
                        <div className="feature-list">
                          lasjd jashd jashd ja h
                        </div>
                        <div className="feature-list">lasjd jashd jas</div>
                        <div className="feature-list">
                          lasjd jashd jashd ja h
                        </div>
                        <div className="feature-list">lasjd jashd jas</div>
                      </div>
                    </div>
                    <div
                      className={`card-inner ${
                        this.state.plan === '3' ? 'show' : ''
                      }`}
                    >
                      <div className="card" onClick={this.handleCard} id="3">
                        <div className="title">Best Value</div>
                        <div className="icon">
                          <i className="fab fa-xbox"></i>
                        </div>
                        <div className="price">~ 399</div>
                        <div className="pricetag">per course</div>
                        <div className="features">Core Features</div>
                        <div className="feature-list">
                          lasjd jashd jashd ja h
                        </div>
                        <div className="feature-list">lasjd jashd jas</div>
                        <div className="feature-list">
                          lasjd jashd jashd ja h
                        </div>
                        <div className="feature-list">lasjd jashd jas</div>
                      </div>
                    </div>
                  </div>
                  <div className="links plan">
                    <div
                      className="continue"
                      id="pay.full"
                      onClick={this.handleForward}
                    >
                      Pay in Full
                    </div>
                    <div
                      className="home"
                      id="pay.sub"
                      tabIndex={active === '2' ? '0' : '-1'}
                      onClick={this.handleForward}
                    >
                      Pay Monthly
                    </div>
                  </div>
                  <p>
                    Having troubles choosing the best plan for you?{' '}
                    <Link to="/pathway" tabIndex={active === '2' ? '0' : '-1'}>
                      Learn more here
                    </Link>
                  </p>
                </div>
              </div>
            ) : (
              <div
                className={`back content ${
                  active === '2' ? '' : parseInt(active) > 2 ? 'hide' : 'show'
                }`}
              >
                <div className="arrow">
                  <div className="bach" onClick={this.handleBack}>
                    <i className="fas fa-arrow-left"></i>
                  </div>
                </div>
                <div className="n-arrow">
                  <div className="title">Just this one question</div>
                  <div className="sub-title">
                    Your answers help our system describe you clearly to the
                    expert assigned to you
                  </div>
                  <div className="input">
                    <div className="label">Clarity Needed</div>
                    <textarea
                      id="textarea"
                      cols="30"
                      rows="10"
                      placeholder="Ex: I am interested in finding out if I ..."
                      onChange={this.handleChange}
                      value={clarity}
                      name="clarity"
                      tabIndex={active === '2' ? '0' : '-1'}
                      className={`bio ${four === '' ? 'error' : ''}`}
                    ></textarea>
                  </div>
                  <div className="input">
                    <div className="label">
                      Referral Code {nage && '[Do not edit]'}
                    </div>
                    <input
                      type=""
                      placeholder="Ex: Nes-tex-43b"
                      onChange={this.handleChange}
                      value={referral}
                      name="referral"
                      tabIndex={active === '2' ? '0' : '-1'}
                    ></input>
                  </div>
                  <div className="links">
                    <div className="continue" onClick={this.handleForward}>
                      Continue
                    </div>
                    <Link
                      to="/"
                      className="home"
                      tabIndex={active === '2' ? '0' : '-1'}
                    >
                      Back to Home
                    </Link>
                  </div>
                </div>
              </div>
            )}
            <div
              className={`back content ${
                active === '3' ? '' : parseInt(active) > 3 ? 'hide' : 'show'
              }`}
            >
              <div className="arrow">
                <div className="bach" onClick={this.handleBack}>
                  <i className="fas fa-arrow-left"></i>
                </div>
              </div>
              <div className="n-arrow">
                <div className="title">Ready, set, go!</div>
                <div className="feats">
                  <div className="feat-link">
                    <div className="icon">
                      <img src={icon42} alt="Trail Icon" />
                    </div>
                    <div className="title">Mentorship</div>
                    <div className="subtitle">
                      Fast-Track your growth in personal and career development.
                      Become a better person all-round.
                    </div>
                  </div>
                  <div className="feat-link">
                    <div className="icon">
                      <img src={icon33} alt="Trail Icon" />
                    </div>
                    <div className="title">Career Advice</div>
                    <div className="subtitle">
                      Learn the ropes from the ones who reached to the top
                      climbing with it.
                    </div>
                  </div>
                  <div className="feat-link">
                    <div className="icon">
                      <img src={icon44} alt="Trail Icon" />
                    </div>
                    <div className="title">Upskilling</div>
                    <div className="subtitle">
                      Learn the right things the right way by getting
                      recommendations from top experts in the industry.
                    </div>
                  </div>
                </div>

                <div className="links submit">
                  <div
                    className={`continue ${view === false ? '' : 'hide'}`}
                    onClick={this.handleSubmit}
                  >
                    Proceed to pay
                  </div>
                  <div className={`loader ${view === false ? 'hide' : ''}`}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                  <Link
                    to="/"
                    className="home"
                    tabIndex={active === '3' ? '0' : '-1'}
                  >
                    Back to Home
                  </Link>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="img">
          <img
            src={active === '1' ? land30 : active === '2' ? land31 : land32}
            alt="Trail Onboarding"
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    errors: state.errors,
    mentorHour: state.payment.mentorHourUrl,
    pathwayFull: state.payment.pathwayFullUrl,
    pathwaySub: state.payment.pathwaySubUrl,
  };
}

export default connect(mapStateToProps, {
  updateUser,
  mentorHourUrl,
  pathwayFullUrl,
  pathwaySubUrl,
  getUser,
})(onboard);
