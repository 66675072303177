import React, { Component } from 'react';
import { getUser } from '../store/actions/users';
import { getMenteeMeetings, getMentorMeetings } from '../store/actions/meeting';
import { connect } from 'react-redux';
import { icon41 } from './Copy/Copy';

class RightNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      split: false,
    };
  }

  componentDidMount() {
    if (!this.props.role) {
      this.props
        .getUser(this.props.currentUser.user.id, 'user')
        .then((user) => {
          if (user.role === 'mentor') {
            this.props.getMentorMeetings(user.id).then(() => {
              this.setState({
                load: true,
              });
            });
          } else if (user.role === 'user') {
            this.props.getMenteeMeetings(user.id).then(() => {
              this.setState({
                load: true,
              });
            });
          }
        });
    }
  }

  handleSplit = (e) => {
    if (this.props.role === 'user') {
      this.props.page(e);
      return;
    }
    this.setState({
      split: !this.state.split,
    });
  };

  render() {
    const {
      right,
      click,
      currentUser,
      meeting,
      role,
      logout,
      mobile,
      display,
      page,
    } = this.props;

    const now = new Date();
    var meets = [];
    var arr = [];
    if (meeting.mentorMeetings || meeting.menteeMeetings) {
      if (role === 'mentor') {
        meets = meeting.mentorMeetings.filter((e) => {
          const meetTime = new Date(e.time);
          if (now < meetTime && now.getUTCMonth() === meetTime.getUTCMonth()) {
            return true;
          }
          return false;
        });
      } else if (role === 'user') {
        meets = meeting.menteeMeetings.filter((e) => {
          const meetTime = new Date(e.time);
          if (now < meetTime && now.getUTCMonth() === meetTime.getUTCMonth()) {
            return true;
          }
          return false;
        });
      }

      meets.sort((a, b) => {
        const at = new Date(a.time);
        const bt = new Date(b.time);
        return at - bt;
      });

      meets.forEach((e) => {
        var newTime = new Date(e.time);
        var tib = newTime.toLocaleString('en-us', {
          weekday: 'short',
          day: 'numeric',
        });
        arr.push({
          date: tib.split(' ')[0],
          day: tib.split(' ')[1],
          name: `${
            role === 'mentor'
              ? `${e.mentee.firstName} ${e.mentee.lastName}`
              : `${e.mentor.firstName} ${e.mentor.lastName}`
          }`,
          purpose:
            e.purpose.length > 120
              ? `${e.purpose.substring(0, 120)}...`
              : e.purpose,
        });
      });
    }

    if (role) {
      if (currentUser.user.picture.indexOf('google') !== -1) {
        var imgStr = currentUser.user.picture;
      } else if (currentUser.user.picture.indexOf('trailbucket12345') !== -1) {
        imgStr =
          'https://dqe80zoqbuyqe.cloudfront.net' +
          currentUser.user.picture.substring(51);
      } else {
        imgStr = '';
      }
    }

    return (
      <div
        className={`side-nav ${!right ? 'open' : ''} ${mobile ? 'mob' : ''} ${
          display === 'edit' ? 'active' : ''
        }`}
      >
        {!mobile && (
          <div
            className={`cancel-nav acti ${!right ? 'open' : ''}`}
            onClick={click}
          ></div>
        )}
        <div
          className={`hamburger dash acti ${!right ? '' : 'open'}`}
          onClick={click}
        >
          <span className="acti"></span>
          <span className="acti"></span>
          <span className="acti"></span>
          <span className="acti"></span>
        </div>
        {mobile && (
          <div className="mot">
            <div className="bac hom" onClick={page}>
              <i className="fas fa-arrow-left"></i>
            </div>
            <p>Profile</p>
            <div
              className={`edy ${this.state.split ? '' : 'show'} proff`}
              onClick={this.handleSplit}
            >
              Edit
            </div>
            <div className={`icons ${this.state.split ? 'show' : ''}`}>
              <i className="fas fa-user proff" onClick={page}></i>
              <i
                className={`fas fa-money-check account ${
                  role === 'user' ? 'hide' : ''
                }`}
                onClick={page}
              ></i>
            </div>
          </div>
        )}
        <div className="img">
          {imgStr ? (
            <img src={imgStr} alt="trail user" />
          ) : (
            <div className="icon">
              <i className="fas fa-user"></i>
            </div>
          )}
        </div>
        <h1>
          {currentUser.user.firstName} {currentUser.user.lastName}
        </h1>
        <p>{currentUser.user.tag}</p>
        <div className="intro">About</div>
        <p className="abo">
          {currentUser.user.bio
            ? `${currentUser.user.bio}`
            : 'Your bio would appear here as soon as you update it'}
        </p>
        {role === 'mentor' && (
          <div className="account">
            <div className="intro">Account Number</div>
            <p className="abo">
              {currentUser.user.accountName
                ? `${currentUser.user.accountName}`
                : 'Your account number would appear here as soon as you update it'}
              <br />
              {currentUser.user.accountNumber}
              <br />
              {currentUser.user.bankName}
            </p>
          </div>
        )}

        <div className="intro">Monthly Schedule</div>
        {arr.length > 0 ? (
          arr.map((e, i) => (
            <div className="item" key={i}>
              <div className="date bg">
                <div className="no">{e.date}</div>
                <div className="day">{e.day}</div>
              </div>
              <img src={icon41} alt="Trail Clarity" />
              <div className="info">
                <div className="title">Clarity session with {e.name}</div>
              </div>
              <div className="date sm">
                <div className="no">{e.date}</div>
                <div className="day">{e.day}</div>
              </div>
            </div>
          ))
        ) : (
          <div>No Upcoming Event</div>
        )}
        <div className="settings proff" onClick={page}>
          <i className="fas fa-cog"></i> <span>Change Settings</span>
        </div>
        <div className="logout ref" onClick={page}>
          <i className="fas fa-hand-holding-usd"></i> <span>Referrals</span>
        </div>
        <div className="logout" onClick={logout}>
          <i className="fas fa-sign-out-alt"></i> <span>Log out</span>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { meeting: state.meeting };
}

export default connect(mapStateToProps, {
  getMenteeMeetings,
  getMentorMeetings,
  getUser,
})(RightNav);
