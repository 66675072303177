import React from 'react';
import { Link } from 'react-router-dom';

const WaitSuccess = ({
  share,
  shareBtn,
  cancelBtn,
  cancel,
  title,
  desc,
  copy,
  sharble,
}) => {
  return (
    <div className={`waitsuccess ${cancel ? 'hide' : ''}`}>
      <div className="inner">
        <div className="container">
          <div className="cancelBtn" onClick={cancelBtn}>
            <span></span>
            <span></span>
          </div>
          <div className="title">{title}</div>
          <p>{desc}</p>
          {sharble && (
            <>
              <div className="cta">Share this event with your friends</div>
              <div className="links">
                <div
                  className={`join ${share ? 'hide' : ''}`}
                  onClick={shareBtn}
                >
                  Share
                </div>
                <div className={`social-links ${share ? '' : 'hide'}`}>
                  <Link to="/" onClick={copy}>
                    <i className="fas fa-link"></i>
                  </Link>
                  <a href="whatsapp://send?text=https%3A%2F%2Fwww.trailng.com">
                    <i className="fab fa-whatsapp"></i>
                  </a>
                  <a href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fwww.trailng.com&text=Check+out+Trail.">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="https://www.linkedin.com/sharing/share-offsite/?url={https%3A%2F%2Fwww.trailng.com}">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div>
                <div className="join cancel" onClick={cancelBtn}>
                  Cancel
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default WaitSuccess;
