import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUser, markAsRead, pending } from '../store/actions/users';
import { sectionResources } from '../store/actions/resources';
import Byte from './Byte/Byte';
import { scrollToY } from '../services/scroll';

class Section extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pos: 0,
      total: 0,
      title: '',
      sort: '',
      read: [],
      sub: [],
      videoNext: '',
      videoPrev: '',
      videoprevView: '',
      load: false,
      refresh: 0,
    };
  }

  componentDidMount() {
    if (!this.props.currentUser.user.role) {
      this.props
        .getUser(this.props.currentUser.user.id, 'user')
        .then((user) => {
          var section = user.section.find((e) => e.done === 'pending');
          if (section) {
            this.props.sectionResources(section.field, {
              pathway: section.title,
            });
            this.setState({
              pos: section.no,
              total: user.section.length,
              title: section.title,
              sub: section.sub,
              load: true,
            });
          }
        });
    } else {
      var section = this.props.currentUser.user.section.find(
        (e) => e.done === 'pending'
      );
      if (section) {
        this.props.sectionResources(section.field, { pathway: section.title });
        this.setState({
          pos: section.no,
          total: this.props.currentUser.user.section.length,
          title: section.title,
          sub: section.sub,
          load: true,
        });
      }
    }
  }

  handleClick = (e) => {
    var pos = e.target.parentNode.scrollLeft;
    if (e.target.childNodes[0].classList[1] === 'fa-chevron-left') {
      scrollToY(pos - 1145, 1000, e.target.parentNode);
    } else if (e.target.childNodes[0].classList[1] === 'fa-chevron-right') {
      scrollToY(pos + 1145, 1000, e.target.parentNode);
    }
  };

  handleScroll = (e) => {
    var scrollVal = e.target.scrollLeft;
    var idVal = e.target.id;

    if (scrollVal + e.target.clientWidth >= e.target.scrollWidth) {
      this.setState({ videoNext: idVal });
    } else {
      this.setState({ videoNext: '' });
    }

    if (scrollVal === 0) {
      this.setState({ videoPrev: `${idVal}prev` });
    } else {
      this.setState({ videoPrev: '' });
      this.setState({ videoprevView: idVal });
    }
  };

  handleRead = (id, e) => {
    e.preventDefault();
    var { read } = this.state;
    read.push(id);
    this.setState({
      read,
    });
    this.props.markAsRead(id);
  };

  handlePending = (id, e) => {
    if (e.target.className.indexOf('ignore') === -1) {
      this.props.pending(id);
    }
  };

  handleSort = (e) => {
    if (this.state.sort === e) {
      this.setState({
        sort: '',
      });
    } else {
      this.setState({
        sort: e,
      });
    }
  };

  componentDidUpdate() {
    if (this.state.refresh + 1 === this.props.refScore) {
      this.setState({
        load: false,
        refresh: this.state.refresh + 1,
      });
      this.props
        .getUser(this.props.currentUser.user.id, 'user')
        .then((user) => {
          var section = user.section.find((e) => e.done === 'pending');
          if (section) {
            this.props.sectionResources(section.field, {
              pathway: section.title,
            });
            this.setState({
              pos: section.no,
              total: user.section.length,
              title: section.title,
              sub: section.sub,
              load: true,
            });
          }
        });
    }
  }

  render() {
    const { currentUser, display, page } = this.props;
    const { pos, title, total, read, sort, sub, load } = this.state;
    var { resource } = this.props;
    var art = 0;
    var vid = 0;
    if (resource) {
      if (sort) {
        if (sort === 'video') {
          resource = resource.filter((e) => !e.article);
        } else {
          resource = resource.filter((e) => e.article);
        }
      }

      var courseList = resource.map((m, i) => {
        if (!sub.includes(m.sub)) {
          return null;
        }
        if (m.article) {
          art++;
        } else {
          vid++;
        }
        if (
          currentUser.user.completedResource.includes(m._id) ||
          read.includes(m._id)
        ) {
          return (
            <Byte
              key={i}
              title={m.title}
              photo={m.photo}
              desc={
                m.description.length > 100
                  ? `${m.description.substring(0, 100)}...`
                  : m.description
              }
              duration={m.duration}
              link={m.link}
              read={this.handleRead.bind(this, m._id)}
              pending={this.handlePending.bind(this, m._id)}
              marked
              article={m.article}
            />
          );
        } else {
          return (
            <Byte
              key={i}
              title={m.title}
              photo={m.photo}
              desc={
                m.description.length > 100
                  ? `${m.description.substring(0, 100)}...`
                  : m.description
              }
              duration={m.duration}
              link={m.link}
              pending={this.handlePending.bind(this, m._id)}
              read={this.handleRead.bind(this, m._id)}
              article={m.article}
            />
          );
        }
      });
    }
    return (
      <div className={`section ${display === 'sect' ? '' : 'hide'}`}>
        <div className="up"></div>
        <div className="title">
          Stage {pos} of {total} - {title}
        </div>
        <div className="structure">
          <div
            className={`item ${sort === 'article' ? 'active' : ''}`}
            onClick={this.handleSort.bind(this, 'article')}
          >
            {art} readings
          </div>
          <div
            className={`item ${sort === 'video' ? 'active' : ''}`}
            onClick={this.handleSort.bind(this, 'video')}
          >
            {vid} videos
          </div>
          <div className="item quiz" onClick={page}>
            1 assessment
          </div>
        </div>
        <div className="sixthpage">
          <div className="tag">Resources</div>
          <div className="service-cards">
            <div
              className="cata-sub-nav"
              onScroll={this.handleScroll}
              id="video"
            >
              <div
                className={`nav-prev arrow ${
                  this.state.videoPrev === 'videoprev' ? 'hide' : ''
                } ${this.state.videoprevView === '' ? 'hide' : ''}`}
                onClick={this.handleClick}
              >
                <i className="fas fa-chevron-left"></i>
              </div>
              <ul>
                {!load ? (
                  <>
                    <li>
                      <div className="byte">
                        <div className="card load flicker"></div>
                      </div>
                    </li>
                    <li>
                      <div className="byte">
                        <div className="card load flicker"></div>
                      </div>
                    </li>
                    <li>
                      <div className="byte">
                        <div className="card load flicker"></div>
                      </div>
                    </li>
                  </>
                ) : (
                  courseList
                )}
              </ul>
              <div
                className={`nav-next arrow ${
                  this.state.videoNext === 'video' ? 'hide' : ''
                }`}
                onClick={this.handleClick}
              >
                <i className="fas fa-chevron-right"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="link quiz" onClick={page}>
          Take Assessment
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resource: state.resource.sectionResources,
  };
}

export default connect(mapStateToProps, {
  getUser,
  sectionResources,
  markAsRead,
  pending,
})(Section);
