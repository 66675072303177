import React, { Component } from 'react'
import Footer from './Footer'
import WaitNavbar from '../containers/WaitNavbar'
import { HashLink } from 'react-router-hash-link'

class Privacy extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div>
        <WaitNavbar />
        <div className="privacy-body">
          <h1>Privacy Policy</h1>
          <p>Last Updated: September 2022</p>
          <p>Version number: 1.0</p>
          <p>
            One Trail, Inc. ("Trail") is committed to respecting the privacy of
            its users. We strive to provide a safe, secure online experience.
            This Privacy Policy sets forth the online data collection, usage,
            maintenance, disclosure and protection policies and practices that
            apply when Personal Data is disclosed in connection with the
            trailng.com website and applications (together: "Site") and the
            services provided through it, such as our Trail learning platform
            ("Services").{' '}
            <span>
              By using Trail services, you are consenting to the practices
              described in this privacy policy.
            </span>{' '}
            We are not responsible for the privacy policies or practices of
            other websites to which links are included on this Site.
          </p>
          <h2>Data subjects</h2>
          <p>
            A Trail user is an <span>individual consumer</span> is someone who
            creates a personal account via this Site using a personal email
            address.
          </p>
          <h2>Quick links</h2>
          <HashLink className="link" to="#data">
            Data collected by Trail
          </HashLink>
          <HashLink className="link" to="#how">
            How Trail uses your data
          </HashLink>
          <HashLink className="link" to="#non">
            Non-personally identifiable information
          </HashLink>
          <HashLink className="link" to="#sharing">
            Sharing information with third parties {'&'} international transfer
          </HashLink>
          <HashLink className="link" to="#rights">
            Rights of access, deletion and other rights
          </HashLink>
          <HashLink className="link" to="#long">
            How long we retain your data
          </HashLink>
          <HashLink className="link" to="#children">
            Children under 13 years of age
          </HashLink>
          <HashLink className="link" to="#trail's">
            Trail’s use of cookies and similar technologies Information{' '}
          </HashLink>
          <HashLink className="link" to="#protection">
            protection and security
          </HashLink>
          <HashLink className="link" to="#consent">
            Consent and revisions to this Privacy Policy
          </HashLink>
          <h3 id="data">Data collected by Trail</h3>
          <p>
            We collect and process Personal Data about you when you interact
            with us and our Services. Such information may include:
          </p>
          <ul>
            <li>Your name, email address, username and password;</li>
            <li>
              Your academic history and other information you entered into your
              User profile ("Trail Profile"), and how you use our Services;
            </li>
            <li>
              Your marketing preferences, including any consent you have given
              us; and
            </li>
            <li>
              Information related to the browser or device you use to access our
              Site, as also further detailed in our{' '}
              <HashLink to="#trail's">Cookie Notice;</HashLink>
            </li>
          </ul>
          <p>
            Most Personal Data you provide to us is voluntary, unless indicated
            otherwise. Examples of areas on the Site where we collect data are
            pages where you can open a Trail account and create a Trail Profile.
            Only you can modify your own personal Trail Profile once it has been
            created. You may remove your Trail Profile from our database at any
            time.
          </p>
          <p>
            To the extent you wish to retain an independent record of such your
            Trail Profile, you must include a personal email address at the time
            of creating your Trail Profile.
          </p>
          <p>
            We may combine the information we collect with publicly available
            information and information we receive from our parent, affiliate,
            or subsidiary companies, business partners, and other third parties.
            We may use this combined information to enhance and personalize your
            experience with us, to communicate with you about products,
            services, and events that may be of interest to you, for promotional
            purposes, and for other purposes described in this Privacy Policy.
          </p>
          <p>
            We may enable you to log into the Services using a social media
            account, and if you do so you may be asked to grant us access to
            certain information from your social media profile, such as your
            public profile, birthday, current city, and email address. Social
            media networks operate independently from Trail, and we are not
            responsible for social media networks' interfaces or privacy or
            security practices. Please refer to the privacy settings of the
            applicable social media platform or application to determine how you
            may manage the interactivity between us and your social media
            accounts.
          </p>
          <h3 id="how">How Trail uses your data</h3>
          <p>We process your Personal Data for the following purposes:</p>
          <ul>
            <li>
              As required to provide you with our Services. This may include
              verifying your identity, communicating with you and providing
              customer services and support;
            </li>
            <li>
              As required by us to enable our business and pursue our legitimate
              interests, in particular:
              <ul>
                <li>
                  we monitor the use of our Site and Services, and may use your
                  information to help us monitor, improve and protect our
                  products, content, services and websites, both online and
                  offline;
                </li>
                <li>
                  we may use information you provide and information from
                  external sources to personalize our Site and Services for you;
                  For example - making content recommendations, making
                  inferences about skills you may have acquired, and/or matching
                  your profile with career opportunities.
                </li>
                <li>
                  we may monitor our Services to prevent, investigate and/or
                  report fraud, misrepresentation, security incidents or crime,
                  in accordance with applicable laws;
                </li>
                <li>
                  we may use information you provide to investigate any
                  complaints received from you or from others;
                </li>
                <li>
                  we may use your information to invite you to take part in
                  market research; and
                </li>
              </ul>
            </li>
            <li>
              As required for compliance with applicable laws and protection of
              Trail's legitimate business interests and legal rights, including,
              but not limited to, use in connection with legal claims,
              compliance, regulatory, investigative purposes (including
              disclosure of such information in connection with legal process or
              litigation).
            </li>
          </ul>
          <p>
            In addition, and where needed with your consent, we may send you
            messages in relation to our products and services, for example to
            send notifications about new features. You can opt-out of such
            messages by following the instructions in the communication.
          </p>
          <p>Trail does not sell your Personal Data.</p>
          <h3 id="non">Technical and Connection Data</h3>
          <p>
            Where possible, the Trail servers automatically recognize each
            User's Internet Protocol address. Based on your IP, Trail might be
            able to infer your Geo-location data: for example, your locale, zip
            code, and general geolocation information (based on your IP
            address). Also, aggregate information about Trail Users is routinely
            gathered for purposes of identifying the use of{' '}
            <HashLink to="/">trailng.com</HashLink>, including information such
            as what pages are being visited most, what domains our visitors are
            coming from (e.g. google.com), when our servers are busiest, and
            what websites are referring users to us and aggregated data for
            purposes of analysis, gathering employment statistical information
            and for learning and training. This information helps us to improve
            our services and to evaluate our marketing efforts. We only use such
            data anonymously and aggregately. This information may be shared
            with third parties*, but only in the aggregate. Trail owns all
            rights in and to this anonymous and aggregated data.
          </p>
          <h3 id="sharing">Sharing Information with Third Parties</h3>
          <p>
            If necessary for the purposes set out in this Privacy Policy we can
            share your information with other Trail entities or third-party
            service providers, for example email and hosting providers, payment
            processing providers, customer management providers and marketing
            providers. Such third-party service providers will process your
            information on behalf of Trail and for the purposes listed above.
            When we share your data with these parties, we make sure appropriate
            safeguards are taken to protect your information.
          </p>
          <p>
            We reserve the right to transfer your Personal Data to a buyer or
            other transferee in the event of a merger, divestiture,
            restructuring, reorganization, dissolution, sale, or other transfer
            of some or all of our assets. Should such a sale, merger, or
            transfer occur, we will use reasonable efforts to direct the
            transferee to use your Personal Data in a manner that is consistent
            with our Privacy Policy.
          </p>
          <p>
            We may also share your data with other third parties, such as
            governmental authorities or law enforcement officials, if we are
            required by law to do so or if this is for the protection of our
            legitimate interests and/or legal rights.
          </p>
          <h3 id="rights">Rights of Access, Deletion and Other Rights</h3>
          <p>
            We provide you with access to your information through your Trail
            Profile where you can access, update, amend or correct inaccuracies
            on information provided in your Trail Profile. From each Trail
            account you may also opt out of receiving newsletters and certain
            other correspondence from Trail. If you wish to delete your Trail
            Profile you can do so in the Personal section of their Profile
            Settings. Once you have arrived at the Profile Settings page you can
            select "Delete Profile" and select "yes" to confirm that you would
            like to delete your account.
          </p>
          <p>
            In addition to the above, you are entitled to reach out to us via
            the contact details below and ask us for an overview or copy of your
            Personal Data, to correct or delete certain data or restrict
            processing of your data. In some cases you may object to the
            processing of your information, and where we have asked you for your
            consent to process Personal Data, you can withdraw your consent at
            any time.
          </p>
          <p>
            To make such a request, please send an email to{' '}
            <HashLink to="mailto:support@trailng.com?subject=We%20want%20to%20hear%20from%20you!">
              support@trailng.com
            </HashLink>{' '}
            or write us at: 3 - 4 Adegboyega street, Apata Abgowo, Ibadan
            Nigeria 101401
          </p>
          <p>
            Please note that the rights described above are not absolute, and
            that your request cannot always be met entirely. For example,
            sometimes we cannot delete or restrict the processing of your
            information as we may have legal obligations or contractual
            obligations to keep certain information.
          </p>
          <h3 id="long">How Long We Retain Your Data</h3>
          <p>
            Where you have a Trail Profile, we will keep your information for
            the duration of your use of our Services and, to the extent
            permitted, after the end of that relationship for as long as
            necessary to perform the purposes set out in this notice.{' '}
          </p>
          <p>
            Laws may require us to hold certain information for specific
            periods. In other cases, we may retain data for an appropriate
            period after any relationship with you ends to protect ourselves
            from legal claims or to conduct our business.
          </p>
          <h3 id="children">Children Under 13 Years of Age</h3>
          <p>
            Trail does not intend to collect information from children under the
            age of 13 or knowingly allow those individuals to create profiles.
            All Trail Profiles stored are for Users ages 13 or older and those
            seeking higher education who state their age to be 13 years or
            older. If we learn we have collected or received Personal Data from
            a child under the age of 13, we will delete that information. If you
            believe we might have any information from or about a child under
            the age of 13, please contact us at{' '}
            <HashLink to="mailto:support@trailng.com?subject=We%20want%20to%20hear%20from%20you!">
              support@trailng.com
            </HashLink>
            .
          </p>
          <h3 id="trail's">Trail's use of Cookies and Similar Technologies</h3>
          <p>
            Like most websites, Trail uses cookies and other similar
            technologies, such as local storage, to provide a better user
            experience. These cookies are safe and secure and do not contain
            sensitive information.
          </p>
          <h3 id="protection">Information Protection and Security</h3>
          <p>
            When you submit information to Trail we do our utmost to ensure that
            information is protected against loss, misuse, alteration,
            destruction or unauthorized access. Trail uses standard encryption
            tools such as SSL (Secure Socket Layer) to help protect Personal
            Data and to keep information collected secure. The privacy policies
            of linked sites may differ from those of Trail. Trail is not
            responsible for any loss or misuse of your username and password.
          </p>
          <h3 id="consent">Consent and Revisions to this Privacy Policy</h3>
          <p>
            We may revise this Privacy Policy from time to time as we add new
            features or as laws change that may affect our Site or Services. If
            we make material changes to this Privacy Policy, we will let you
            know in an appropriate manner and we will post a new version number
            at the beginning of this Privacy Policy, consisting of the date
            (year and month) the Privacy Policy was last revised, so that you
            will be able to tell when the Privacy Policy was last changed.
          </p>
          <p>Version: 1.0</p>
          <p>Date: 21/09/22</p>
        </div>
        <Footer />
      </div>
    )
  }
}

export default Privacy
