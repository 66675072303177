import React, { Component } from 'react';
import Footer from '../Footer';
import WaitNavbar from '../../containers/WaitNavbar';
import { fetchField, fetchTest, scoreTest } from '../../store/actions/field';
import { connect } from 'react-redux';
import { scrollToY } from '../../services/scroll';
import Error from '../Error';
import Obj from '../Obj';
import Text from '../Text';
import {} from '../Copy/Copy';

class Assessment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      load: false,
      name: '',
      view: false,
      id: '',
      ball: 0,
      total: 0,
      assessment: [],
      result: false,
      text: 'Next',
      pop: false,
    };
  }

  componentDidMount() {
    var loc = this.props.location.pathname.split('/');
    this.props
      .fetchField(loc[2])
      .then(() => {
        if (this.props.fields.length === 0 || !this.props.fields) {
          this.setState({
            error: true,
          });
        } else {
          const { name, _id } = this.props.fields[0];
          this.setState({
            load: true,
            name: `Get Started on ${name}`,
            id: _id,
          });
        }
      })
      .catch(() => {
        this.setState({
          error: true,
        });
      });
  }

  handleScroll = (e) => {
    var pos = e.target.scrollLeft;
    const wid = e.target.childNodes[0].offsetWidth;
    const ball = Math.round(pos / wid);
    this.setState({
      ball,
    });
    if (this.state.ball !== this.state.total) {
      this.setState({
        text: 'Next',
      });
    } else {
      this.setState({
        text: 'Submit',
      });
    }
  };

  handleNext = (e) => {
    if (this.state.text === 'Proceed') {
      this.props.history.push('/');
    }
    if (this.state.ball === this.state.total) {
      this.setState({
        view: true,
      });
      this.props
        .scoreTest(this.state.id, {
          assessment: this.state.assessment,
        })
        .then(() => {
          this.setState({
            view: false,
            result: true,
            text: 'Proceed',
          });
        });
    } else {
      const el = e.target.parentNode.parentNode.childNodes[2].childNodes[0];
      const wid =
        e.target.parentNode.parentNode.childNodes[2].childNodes[0].childNodes[0]
          .offsetWidth;
      const pos =
        e.target.parentNode.parentNode.childNodes[2].childNodes[0].scrollLeft +
        wid;
      e.target.parentNode.parentNode.childNodes[2].childNodes[0].classList.remove(
        'snap'
      );
      scrollToY(pos, 1000, el);
      setTimeout(() => {
        e.target.parentNode.parentNode.childNodes[2].childNodes[0].classList.add(
          'snap'
        );
      }, 1000);
    }
  };

  handleClick = (e) => {
    this.setState({
      view: true,
    });
    if (e.target.className.indexOf('back') !== -1) {
      this.props.history.goBack();
    } else {
      this.props.fetchTest(this.state.id).then(() => {
        if (this.props.test) {
          this.setState({
            total: this.props.test.length - 1,
          });
        }
        this.setState({
          view: false,
        });
      });
    }
  };

  handleChange = (e) => {
    var { assessment } = this.state;
    var i = assessment.findIndex((el) => el.question === e.target.name);

    if (e.target.type === 'checkbox') {
      if (i === -1) {
        var arr = [];
      } else {
        arr = assessment[i].answers;
        assessment.splice(i, 1);
      }

      var id = arr.findIndex((el) => el === e.target.value);
      if (id !== -1) {
        arr.splice(id, 1);
      } else {
        arr.push(e.target.value);
      }
      assessment.push({
        question: e.target.name,
        answers: arr,
      });
    } else {
      if (i !== -1) {
        assessment.splice(i, 1);
      }
      assessment.push({
        question: e.target.name,
        answer: e.target.value,
      });
    }

    this.setState({
      assessment,
    });
  };

  handleSkip = (e) => {
    this.setState({
      pop: !this.state.pop,
    });
  };

  handleNull = (e) => {
    this.props
      .scoreTest(this.state.id, {
        assessment: this.state.assessment,
      })
      .then(() => {
        this.props.history.push('/');
      });
  };

  render() {
    const { error, load, view, text, result, pop } = this.state;
    const { test, currentUser, errors } = this.props;
    const ratio = (this.state.ball + 1) / (this.state.total + 1);
    if (test) {
      var TestList = test.map((e, i) => {
        if (e.type === 'obj') {
          return <Obj key={i} e={e} id={e._id} change={this.handleChange} />;
        } else if (e.type === 'text') {
          return <Text key={i} e={e} id={e._id} change={this.handleChange} />;
        } else if (e.type === 'check') {
          return (
            <Obj key={i} e={e} id={e._id} change={this.handleChange} check />
          );
        }
        return undefined;
      });
    }

    return error ? (
      <Error />
    ) : (
      <div className="home-hero">
        <WaitNavbar />
        {load ? (
          test ? (
            <div className="exam-outer">
              <h1>You are doing a great Job, Keep Going.</h1>
              <div className="progress">
                <div className={`point ${ratio > 0.249 ? 'full' : ''} semi`}>
                  <div className="box">
                    <i className="fas fa-check"></i>
                  </div>
                  <div className="line">
                    <div className="inner"></div>
                  </div>
                  <div className="text">25%</div>
                </div>
                <div
                  className={`point ${
                    ratio > 0.49 ? 'full' : ratio > 0.249 ? 'semi' : ''
                  }`}
                >
                  <div className="box">
                    <i className="fas fa-check"></i>
                  </div>
                  <div className="line">
                    <div className="inner"></div>
                  </div>
                  <div className="text">50%</div>
                </div>
                <div
                  className={`point ${
                    ratio > 0.749 ? 'full' : ratio > 0.49 ? 'semi' : ''
                  }`}
                >
                  <div className="box">
                    <i className="fas fa-check"></i>
                  </div>
                  <div className="line">
                    <div className="inner"></div>
                  </div>
                  <div className="text">75%</div>
                </div>
                <div
                  className={`point ${
                    ratio > 0.99 ? 'full' : ratio > 0.749 ? 'semi' : ''
                  }`}
                >
                  <div className="box">
                    <i className="fas fa-check"></i>
                  </div>
                  <div className="text">100%</div>
                </div>
              </div>
              <div className="exam">
                {result ? (
                  <div className="content">
                    <h1>Congratulations, {currentUser.user.firstName}</h1>
                    <p>
                      Our system has created a pathway that fits your background
                      and goal.
                    </p>
                  </div>
                ) : (
                  <div className={`carousel snap`} onScroll={this.handleScroll}>
                    {TestList}
                  </div>
                )}
              </div>
              <div className={`popup ${pop ? '' : 'hide'}`}>
                <div className="inner">
                  <div className="header">Confirm Skip</div>
                  <p>
                    If you skip this test our system would assume you have no
                    knowledge of Product Design. Do you still wish to proceed?
                  </p>
                  <div className="btn">
                    <div className="link back" onClick={this.handleSkip}>
                      No
                    </div>
                    <div className="link" onClick={this.handleNull}>
                      Yes
                    </div>
                  </div>
                </div>
              </div>
              <div className={`btn ${result ? 'pro' : ''}`}>
                {!result && (
                  <div className="link back" onClick={this.handleSkip}>
                    Skip Test
                  </div>
                )}
                <div className="link" onClick={this.handleNext}>
                  <span className={view === false ? '' : 'hide'}>{text}</span>{' '}
                  <div className={`loader ${view === false ? 'hide' : ''}`}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="assess">
              <div className="pathpage">
                <div className="content">
                  <div className="title">Let’s get to know you better</div>
                  <p className="down">
                    Trail’s intelligence system would love to know where you are
                    at with Product Design. It’s okay if you aren’t anywhere
                    yet. Just sit back, relax and take your test calmly.
                  </p>
                  <div className="cover">
                    <div className="info">
                      <img
                        src="https://dqe80zoqbuyqe.cloudfront.net/root/White+icons-51.png"
                        alt="assess"
                      />
                      <div className="text">20 sections</div>
                    </div>
                    <div className="info">
                      <img
                        src="https://dqe80zoqbuyqe.cloudfront.net/root/White+Icons-38.png"
                        alt="assess"
                      />
                      <div className="text">40 minutes</div>
                    </div>
                    <div className="info">
                      <img
                        src="https://dqe80zoqbuyqe.cloudfront.net/root/White+Icons-30.png"
                        alt="assess"
                      />
                      <div className="text">Multiple Choice and Open ended</div>
                    </div>
                  </div>
                </div>
                <div className="img">
                  <img
                    src="https://dqe80zoqbuyqe.cloudfront.net/root/New+Iteration-13.png"
                    alt="assess"
                  />
                </div>
              </div>
              {errors.message && <div className="error">{errors.message}</div>}
              <div className="btn">
                <div className="link back" onClick={this.handleClick}>
                  Go Back
                </div>
                <div className="link" onClick={this.handleClick}>
                  <span className={view === false ? '' : 'hide'}>
                    Get Started
                  </span>{' '}
                  <div className={`loader ${view === false ? 'hide' : ''}`}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          <div className="assess">
            <div className="pathpage">
              <div className="content">
                <div className="title load flicker"></div>
                <p className="load flicker"></p>
                <p className="load flicker"></p>
                <p className="load flicker"></p>
                <div className="link load flicker"></div>
              </div>
              <div className="img"></div>
            </div>
            <div className="btn">
              <div className="link load flicker">
                <span className={view === false ? '' : 'hide'}></span>{' '}
              </div>
              <div className="link load flicker">
                <span className={view === false ? '' : 'hide'}></span>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    errors: state.errors,
    fields: state.field.fieldOne,
    test: state.field.test,
  };
}

export default connect(mapStateToProps, { fetchField, fetchTest, scoreTest })(
  Assessment
);
