import React, { Component } from 'react';
import Footer from '../Footer';
import WaitNavbar from '../../containers/WaitNavbar';
import Firstpage from '../Firstpage';
import Reviews from '../Reviews';
// import News from './News';
import { Link } from 'react-router-dom';
import {
  icon28,
  icon40,
  icon43,
  icon46,
  land14,
  land15,
  land16,
  land17,
  land24,
  land26,
  land27,
  land34,
  text104,
  text105,
  text106,
  text107,
} from '../Copy/Copy';

class Aboutpage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="home-hero">
        <WaitNavbar />
        <Firstpage
          alt
          alt1
          img={land24}
          abo
          simg={land34}
          text={[text104, text105]}
          btn="Join for Free"
        />
        <div className="about">
          <div className="img">
            <div className="title">The Trail Story</div>
            <img alt="Trail Founders" src={land14} />
          </div>
          <div className="content">
            <div className="title">The Trail Story</div>
            <p>
              The Trail story began when co-founders, Habibllah Ayodele, Mubarak
              Lawal, and Isaac Enobun could not find clarity on how to upskill
              due to information overload on the internet and lack of access to
              industry experts for guidance.
            </p>
            <p>
              They realized the possibility of solving this problem for every
              young person across Africa when they discovered their friends
              faced similar issues. Trail has gone through a series of
              iterations since then.
            </p>
            <p>
              Now, we recognize it as a digital platform that helps young people
              with career-growth-related resources, created by the experts they
              look up to and vetted by the companies they would love to work
              with.
            </p>
            <p>
              We have come to understand that what separates the winners from
              the average is not necessarily the difference in abilities, but
              the difference in opportunities. We believe that every young
              person in Africa, irrespective of where they live, or who they
              are, should have access to the resources that would help them get
              satisfactory jobs.
            </p>
          </div>
        </div>
        <div className="break">
          <div className="title">Our Vision</div>
          <h1>
            To create an Africa where people enjoy learning, feel confident
            while learning, and use what they learn to live a better life.
          </h1>
        </div>
        <Reviews
          alt
          img={[land26, land27]}
          text={[text106, undefined, text107]}
          simg={[land26, land27]}
        />
        <div className="values">
          <div className="clicks">
            <div className="title">Our Values</div>
            <p>
              Here are the reasons we wake up every morning to go to work on
              career solutions for young Africans.
            </p>
            <Link>See Job openings</Link>
          </div>
          <div className="items">
            <div className="val">
              <div className="icon">
                <img alt="Trail values" src={icon46} />
              </div>
              <div className="title">Providing accessibility</div>
              <p>
                We believe that every young person in Africa, irrespective of
                where they live, or who they are, should have access to the
                resources that would help them get satisfactory jobs.
              </p>
            </div>
            <div className="val">
              <div className="icon">
                <img alt="Trail values" src={icon40} />
              </div>
              <div className="title">Growth</div>
              <p>
                We value the little, step-by-step, consistent growth of
                individuals. We strive to always help our customers and
                employees grow in all areas of their lives.
              </p>
            </div>
            <div className="val">
              <div className="icon">
                <img alt="Trail values" src={icon28} />
              </div>
              <div className="title">People</div>
              <p>
                Trail is a people-oriented start-up. The interest of our
                customers and employees comes first and always. This is a value
                we never compromise.
              </p>
            </div>
            <div className="val">
              <div className="icon">
                <img alt="Trail values" src={icon43} />
              </div>
              <div className="title">Fun</div>
              <p>We believe in the fun processes that get the work done.</p>
            </div>
          </div>
        </div>
        <div className="profile">
          <div className="title">Our Team</div>
          <div className="container">
            <div className="item">
              <div className="img">
                <img alt="Mubarak Lawal" src={land15} />
              </div>
              <a href="https://www.linkedin.com/in/mubarak-lawal-568854156/">
                <div className="title">Mubarak Lawal O.</div>
              </a>
              <div className="role">
                Chief Executive Officer <div>and</div> Chief Technology Officer
              </div>
            </div>
            <div className="item">
              <div className="img">
                <img alt="Sultan Habibllah A." src={land17} />
              </div>
              <a href="https://www.linkedin.com/in/habibllah-ayodele-778b9b14a/">
                <div className="title">Sultan Habibllah A.</div>
              </a>
              <div className="role">
                Chief Marketing Officer <div>and</div> Chief Product Officer
              </div>
            </div>
            <div className="item">
              <div className="img">
                <img alt="Isaac Enobun O." src={land16} />
              </div>
              <a href="https://www.linkedin.com/in/isaacenobun">
                <div className="title">Isaac Enobun O.</div>
              </a>
              <div className="role">
                Chief Operations Officer <div>and</div> Head of Partnerships
              </div>
            </div>
          </div>
        </div>
        {/* <News /> */}
        <Footer />
      </div>
    );
  }
}

export default Aboutpage;
