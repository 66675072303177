import React, { Component } from 'react';
import { getUser } from '../store/actions/users';
import { getMenteeMeetings, getMentorMeetings } from '../store/actions/meeting';
import { connect } from 'react-redux';
import { sectionResources } from '../store/actions/resources';

class Monthly extends Component {
  constructor(props) {
    super(props);
    this.state = {
      split: false,
    };
  }

  componentDidMount() {
    if (!this.props.role) {
      this.props
        .getUser(this.props.currentUser.user.id, 'user')
        .then((user) => {
          var section = user.section.find((e) => e.done === 'pending');
          if (section) {
            this.props.sectionResources(section.field, {
              pathway: section.title,
            });
          }
          if (user.role === 'mentor') {
            this.props.getMentorMeetings(user.id).then(() => {
              this.setState({
                load: true,
              });
            });
          } else if (user.role === 'user') {
            this.props.getMenteeMeetings(user.id).then(() => {
              this.setState({
                load: true,
              });
            });
          }
        });
    }
  }

  handleSplit = (e) => {
    if (this.props.role === 'user') {
      this.props.page(e);
      return;
    }
    this.setState({
      split: !this.state.split,
    });
  };

  render() {
    const { meeting, role, resource, currentUser } = this.props;
    const now = new Date();
    var meets = [];
    var arr = [];
    if (meeting.mentorMeetings || meeting.menteeMeetings) {
      if (role === 'mentor') {
        meets = meeting.mentorMeetings.filter((e) => {
          const meetTime = new Date(e.time);
          if (now < meetTime && now.getUTCMonth() === meetTime.getUTCMonth()) {
            return true;
          }
          return false;
        });
      } else if (role === 'user') {
        meets = meeting.menteeMeetings.filter((e) => {
          const meetTime = new Date(e.time);
          if (now < meetTime && now.getUTCMonth() === meetTime.getUTCMonth()) {
            return true;
          }
          return false;
        });
      }

      meets.sort((a, b) => {
        const at = new Date(a.time);
        const bt = new Date(b.time);
        return at - bt;
      });

      meets.forEach((e) => {
        var newTime = new Date(e.time);
        var tib = newTime.toLocaleString('en-us', {
          weekday: 'short',
          day: 'numeric',
        });
        arr.push({
          date: tib.split(' ')[0],
          day: tib.split(' ')[1],
          name: `${
            role === 'mentor'
              ? `${e.mentee.firstName} ${e.mentee.lastName}`
              : `${e.mentor.firstName} ${e.mentor.lastName}`
          }`,
        });
      });

      if (resource && role) {
        var section = currentUser.user.section.find(
          (e) => e.done === 'pending'
        );
        if (section) {
          var newDate = new Date(section.due);
        }
        if (now < newDate && now.getUTCMonth() === newDate.getUTCMonth()) {
          resource.forEach((e) => {
            var tia = newDate.toLocaleString('en-us', {
              weekday: 'short',
              day: 'numeric',
            });
            if (!currentUser.user.completedResource.includes(e._id)) {
              arr.push({
                date: tia.split(' ')[0],
                day: tia.split(' ')[1],
                name: e.title,
                resource: true,
              });
            }
          });
        }
      }
      arr.sort((a, b) => {
        return a.date - b.date;
      });
      if (arr.length > 3) {
        arr.length = 3;
      }
    }

    return (
      <>
        {arr.length > 0 ? (
          arr.map((e, i) => (
            <div className="card" key={i}>
              <div className="content">
                <div className="title">
                  {e.resource ? 'Complete' : 'Mentorship'}
                </div>
                <div className="desc">
                  {e.resource ? e.name : `Session with ${e.name}`}
                </div>
              </div>
              <div className="date">
                <div className="day">{e.date}</div>
                <div className="no">{e.day}</div>
              </div>
            </div>
          ))
        ) : (
          <div>No Upcoming Event</div>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return { meeting: state.meeting, resource: state.resource.sectionResources };
}

export default connect(mapStateToProps, {
  getMenteeMeetings,
  getMentorMeetings,
  getUser,
  sectionResources,
})(Monthly);
