import { apiCall } from '../../services/api';
import { addError, removeError } from './errors';
import {} from '../actionTypes';

export const AddEmail = (email) => {
  return (dispatch) => {
    return apiCall('post', `/api/v1/mailinglist`, email)
      .then(() => {
        dispatch(removeError());
      })
      .catch((err) => {
        dispatch(addError(err));
      });
  };
};

export const AddResponse = (response) => {
  return (dispatch) => {
    return apiCall('post', `/api/v1/survey`, response)
      .then(() => {
        dispatch(removeError());
      })
      .catch((err) => {
        dispatch(addError(err));
      });
  };
};
