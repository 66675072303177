import React from 'react';
import { Link } from 'react-router-dom';
import { icon13, icon14, icon18 } from './Copy/Copy';

const MeetDetail = ({ role, time, purpose, e, link, load }) => {
  var newTime = new Date(time);
  var tag;
  var username;
  if (!load) {
    newTime.toDateString();
    var dateTime = newTime.toLocaleDateString('en-us', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    dateTime = `${dateTime}`.split(',');
    var day = dateTime[0];
    var date = dateTime[1];
    newTime = `${newTime}`.split(' ');
    var meetTime = `${newTime[4].split(':')[0]}:${newTime[4].split(':')[1]}`;
    var zone = newTime[5];
    if (purpose.length > 120) {
      purpose = `${purpose.substring(0, 120)}...`;
    }
    if (role === 'mentor') {
      if (e.mentee.picture.indexOf('google') !== -1) {
        var imgStr = e.mentee.picture;
      } else if (e.mentee.picture.indexOf('trailbucket12345') !== -1) {
        imgStr =
          'https://dqe80zoqbuyqe.cloudfront.net' +
          e.mentee.picture.substring(51);
      } else {
        imgStr = '';
      }
      if (e.mentee.tag.length > 120) {
        tag = `${e.mentee.tag.substring(0, 120)}...`;
      } else {
        tag = e.mentee.tag;
      }
      username = e.mentee.username;
    } else {
      if (e.mentor.picture.indexOf('google') !== -1) {
        imgStr = e.mentor.picture;
      } else if (e.mentor.picture.indexOf('trailbucket12345') !== -1) {
        imgStr =
          'https://dqe80zoqbuyqe.cloudfront.net' +
          e.mentor.picture.substring(51);
      } else {
        imgStr = '';
      }
      var url = `/${e.mentor.username}`;
      if (e.mentor.tag.length > 120) {
        tag = `${e.mentor.tag.substring(0, 120)}...`;
      } else {
        tag = e.mentor.tag;
      }
      username = e.mentor.username;
    }
  }
  return (
    <div className="container">
      <div className="content">
        <div className={`title ${load ? 'load flicker' : ''}`}>
          {!load && 'Upcoming session'}
        </div>
        <div className={`desc ${load ? 'load flicker' : ''}`}>{purpose}</div>
        {!load && (
          <a href="/" className="btn">
            Update Schedule <i className="fas fa-chevron-right"></i>
          </a>
        )}
        <div className="deets">
          <div className="block">
            <div className={`title ${load ? 'load flicker' : ''}`}>
              {!load && 'Date'}
            </div>
            {!load && <img src={icon13} alt="Trail Mentor" />}
            <div className={`day ${load ? 'load flicker' : ''}`}>{day}</div>
            <div className={`day ${load ? 'load flicker' : ''}`}>{date}</div>
          </div>
          <div className="block">
            <div className={`title ${load ? 'load flicker' : ''}`}>
              {!load && 'Time'}
            </div>
            {!load && <img src={icon14} alt="Trail Mentor" />}
            <div className={`day ${load ? 'load flicker' : ''}`}>
              {meetTime}
            </div>
            <div className={`day ${load ? 'load flicker' : ''}`}>{zone}</div>
          </div>
          <div className="block">
            <div className={`title ${load ? 'load flicker' : ''}`}>
              {!load && 'Venue'}
            </div>
            {!load && <img src={icon18} alt="Trail Mentor" />}
            <div className={`day ${load ? 'load flicker' : ''}`}>
              {!load && 'Google'}
            </div>
            <div className={`day ${load ? 'load flicker' : ''}`}>
              {!load && 'Meet'}
            </div>
          </div>
        </div>
      </div>
      <div className="img">
        <div className="up">
          {load ? (
            <div className="circle load flicker"></div>
          ) : imgStr ? (
            <img src={imgStr} alt="trail user" />
          ) : (
            <div className="icon">
              <i className="fas fa-user"></i>
            </div>
          )}
          <div className="deets">
            <div className={`name ${load ? 'load flicker' : ''}`}>
              {!load && (
                <>
                  {role === 'mentor' ? (
                    `${e.mentee.firstName} ${e.mentee.lastName}`
                  ) : (
                    <Link to={url}>
                      {e.mentor.firstName} {e.mentor.lastName}
                    </Link>
                  )}
                </>
              )}
            </div>
            <div className={`title ${load ? 'load flicker' : ''}`}>{tag}</div>
          </div>
        </div>
        {!load && (
          <Link to={`/${username}`} className="btn">
            view full profile <i className="fas fa-chevron-right"></i>
          </Link>
        )}
      </div>
      {/* <div className="img">
        {url ? (
          <Link to={url}>
            <div className={`hodl ${load ? 'load flicker' : ''}`}>
              {imgStr ? (
                <img src={imgStr} alt="trail user" />
              ) : (
                <div className="icon">
                  <i className="fas fa-user"></i>
                </div>
              )}
            </div>
          </Link>
        ) : (
          <div className={`hodl ${load ? 'load flicker' : ''}`}>
            {imgStr ? (
              <img src={imgStr} alt="trail user" />
            ) : (
              <i className="fas fa-user"></i>
            )}
          </div>
        )}
        {!load && (
          <span>
            {role === 'mentor' ? (
              `${e.mentee.firstName} ${e.mentee.lastName}`
            ) : (
              <Link to={url}>
                {e.mentor.firstName} {e.mentor.lastName}
              </Link>
            )}
          </span>
        )}
      </div>
      <div className="content">
        <div className={`title ${load ? 'load flicker' : ''}`}>
          {!load && (
            <span>
              {role === 'mentor' ? (
                `${e.mentee.firstName} ${e.mentee.lastName}`
              ) : (
                <Link to={url}>
                  {e.mentor.firstName} {e.mentor.lastName}
                </Link>
              )}
            </span>
          )}
        </div>
        <div className={`bio ${load ? 'load flicker' : ''}`}>
          {!load && tag}
        </div>
        <div className={`title note ${load ? 'load flicker' : ''}`}>
          {!load && 'Note'}
        </div>
        <div className={`bio ${load ? 'load flicker' : ''}`}>{purpose}</div>
        <div className="info">
          <div className="ball">
            <div className={`img ${load ? 'load flicker' : ''}`}>
              <img src={icon30} alt="trail" />
            </div>
            <p>{meetTime}</p>
          </div>
          <div className="ball">
            <div className={`img ${load ? 'load flicker' : ''}`}>
              <img src={icon32} alt="trail" />
            </div>
            <p>{date}</p>
          </div>
          <div className="ball">
            <div className={`img ${load ? 'load flicker' : ''}`}>
              <img src={icon37} alt="trail" />
            </div>
            <a href={link}>
              <p>{!load && 'Google Meet'}</p>
            </a>
          </div>
        </div>
      </div>
     */}
    </div>
  );
};

export default MeetDetail;
