import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { getUser } from '../store/actions/users';
import { withdraw } from '../store/actions/payments';
import { scrollToY } from '../services/scroll';
import {
  getMenteeMeetings,
  getMentorMeetings,
  updateMeeting,
} from '../store/actions/meeting';
import { connect } from 'react-redux';
import { icon60 } from './Copy/Copy';

class Earning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ball: 0,
      fals: false,
      load: false,
      query: '',
      error: '',
      show: false,
      draw: false,
      amount: '',
      success: '',
      view: false,
    };
  }

  handleSwipe = (e) => {
    var pos = e.target.parentNode.childNodes[1].scrollLeft;
    const wid = e.target.parentNode.childNodes[1].childNodes[0].offsetWidth;
    const el = e.target.parentNode.childNodes[1];
    const maxwid = e.target.parentNode.childNodes[1].scrollWidth;
    e.target.parentNode.childNodes[1].classList.remove('snap');
    if (e.target.childNodes[0].classList[1] === 'fa-chevron-left') {
      scrollToY(pos - wid, 1000, el);
      pos -= wid;
    } else if (e.target.childNodes[0].classList[1] === 'fa-chevron-right') {
      scrollToY(pos + wid, 1000, el);
      pos += wid;
    }
    if (pos > maxwid - wid) {
      pos = maxwid - wid;
    } else if (pos < 0) {
      pos = 0;
    }
    setTimeout(() => {
      e.target.parentNode.childNodes[1].classList.add('snap');
    }, 1000);
    const ball = Math.round(pos / wid);
    this.setState({
      ball,
    });
  };

  handleScroll = (e) => {
    var pos = e.target.scrollLeft;
    const wid = e.target.childNodes[0].offsetWidth;
    const ball = Math.round(pos / wid);
    this.setState({
      ball,
    });
  };

  handleBall = (e) => {
    const el = e.target.parentNode.parentNode.childNodes[1];
    const wid =
      e.target.parentNode.parentNode.childNodes[1].childNodes[0].offsetWidth;
    const pos = parseInt(e.target.id.split('.')[1]) * wid;
    e.target.parentNode.parentNode.childNodes[1].classList.remove('snap');
    scrollToY(pos, 1000, el);
    setTimeout(() => {
      e.target.parentNode.parentNode.childNodes[1].classList.add('snap');
    }, 1000);
    const ball = parseInt(e.target.id.split('.')[1]);
    this.setState({
      ball,
    });
  };

  handleMenu = (e) => {
    this.setState({
      fals: !this.state.fals,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleClick = (e) => {
    if (e.target.className.indexOf('ignore') !== -1) {
      return;
    }
    this.setState({
      show: !this.state.show,
    });
  };

  componentDidMount() {
    if (!this.props.currentUser.user.role) {
      this.props.getUser(this.props.user, 'user').then((user) => {
        if (user.role === 'mentor') {
          this.props.getMentorMeetings(user.id).then(() => {
            this.setState({
              load: true,
            });
          });
        } else if (user.role === 'user') {
          this.props.getMenteeMeetings(user.id).then(() => {
            this.setState({
              load: true,
            });
          });
        }
      });
    } else {
      this.setState({
        load: true,
      });
    }
  }

  handleSubmit = (e) => {
    if (!this.state.query) {
      this.setState({
        error: 'Please type in a comment',
      });
      return;
    }
    if (this.props.currentUser.user.role === 'mentor') {
      var data = {
        mentorQuery: this.state.query,
      };
    } else {
      data = {
        menteeQuery: this.state.query,
      };
    }

    this.props.updateMeeting(e.target.id, data).then(() => {
      if (this.props.currentUser.user.role === 'mentor') {
        this.props.getMentorMeetings(this.props.currentUser.user.id);
      } else {
        this.props.getMenteeMeetings(this.props.currentUser.user.id);
      }
      this.setState({
        error: '',
        query: '',
        show: '',
      });
    });
  };

  handleDraw = (e) => {
    if (e.target.className.indexOf('ignore') !== -1) {
      return;
    }
    this.setState({
      draw: !this.state.draw,
    });
  };

  handleCash = (e) => {
    this.setState({
      view: true,
    });
    if (!this.state.amount) {
      this.setState({
        error: 'Please type in an amount',
        view: false,
      });
      return;
    }

    if (this.state.amount < 1) {
      this.setState({
        error: 'Please type in a valid amount',
        view: false,
      });
      return;
    }

    if (
      parseInt(this.state.amount * 100) >
      this.props.currentUser.user.mentorBalance
    ) {
      this.setState({
        error: `Cannot withdraw more than N ${(
          this.props.currentUser.user.mentorBalance / 100
        ).toLocaleString('en-us')}`,
        view: false,
      });
      return;
    }

    this.props
      .withdraw({ amount: this.state.amount })
      .then(() => {
        this.setState({
          error: '',
          amount: '',
          success: 'Withdrawal Successful',
          view: false,
        });
      })
      .catch(() => {
        this.setState({
          error: this.props.errors.message,
          amount: '',
          view: false,
        });
      });
  };

  render() {
    const { fals, query, error, show, draw, amount, success, view } =
      this.state;
    var {
      one,
      menu,
      total,
      avail,
      currentUser,
      free,
      sesh,
      meeting,
      down,
      link,
      swit,
      form,
      img,
      mobile,
      url,
      title,
      title2,
      btn,
      str,
      next,
      na,
      ment,
    } = this.props;
    let downStr;
    let id;
    let arr = [];
    const dayObj = {
      0: 'Sun',
      1: 'Mon',
      2: 'Tues',
      3: 'Wed',
      4: 'Thurs',
      5: 'Fri',
      6: 'Sat',
    };
    let setValue = (val) => (val > 9 ? '' : '0') + val;
    if (this.props.currentUser.user.role) {
      if (total) {
        str = `N ${(currentUser.user.totalBalance / 100).toLocaleString(
          'en-US'
        )}`;
      } else if (avail) {
        str = `N ${(currentUser.user.mentorBalance / 100).toLocaleString(
          'en-US'
        )}`;
      } else if (free) {
        currentUser.user.freeTime.map((e) => {
          return arr.push({
            str: `${dayObj[e.day]} ${setValue(e.hour)}:${setValue(e.minute)}`,
            downStr: `${e.timezone}`,
          });
        });
      } else if (sesh) {
        var meets = [];
        const now = new Date();
        if (meeting.mentorMeetings || meeting.menteeMeetings) {
          if (this.props.currentUser.user.role === 'mentor') {
            meets = meeting.mentorMeetings.filter((e) => {
              const meetTime = new Date(e.time);
              if (now > meetTime) {
                return true;
              }
              return false;
            });
          } else if (this.props.currentUser.user.role === 'user') {
            meets = meeting.menteeMeetings.filter((e) => {
              const meetTime = new Date(e.time);
              if (now > meetTime) {
                return true;
              }
              return false;
            });
          }

          meets.sort((a, b) => {
            const at = new Date(a.time);
            const bt = new Date(b.time);
            return bt - at;
          });
          if (meets[0]) {
            var newTime = new Date(meets[0].time);
            newTime.toDateString();
            newTime = `${newTime}`.split(' ');
            var date = `${newTime[1]} ${newTime[2]}, ${newTime[3]}`;
            var meetTime = `${newTime[4].split(':')[0]}:${
              newTime[4].split(':')[1]
            }`;
            var name;
            var purpose;
            if (this.props.currentUser.user.role === 'mentor') {
              name = `${meets[0].mentee.firstName} ${meets[0].mentee.lastName}`;
              purpose = meets[0].purpose;
              if (meets[0].mentee.picture.indexOf('google') !== -1) {
                var imgStr = meets[0].mentee.picture;
              } else if (
                meets[0].mentee.picture.indexOf('trailbucket12345') !== -1
              ) {
                imgStr =
                  'https://dqe80zoqbuyqe.cloudfront.net' +
                  meets[0].mentee.picture.substring(51);
              } else {
                imgStr = '';
              }
            } else {
              name = `${meets[0].mentor.firstName} ${meets[0].mentor.lastName}`;
              purpose = meets[0].purpose;
              if (meets[0].mentor.picture.indexOf('google') !== -1) {
                imgStr = meets[0].mentor.picture;
              } else if (
                meets[0].mentor.picture.indexOf('trailbucket12345') !== -1
              ) {
                imgStr =
                  'https://dqe80zoqbuyqe.cloudfront.net' +
                  meets[0].mentor.picture.substring(51);
              } else {
                imgStr = '';
              }
            }
            id = meets[0]._id;
            str = name;
            downStr = `${date} ${meetTime}`;
          }
        }
        if (!str && !ment) {
          str =
            'Answer a series of carefully thought-out questions engineered to help you measure how ready you are for your dream career.';
          var test = true;

          title = 'Find your';
          title2 = 'Career path';
          btn = 'Take a test';
          form = false;
          url = '/analytics/test';
          img = icon60;
        }
        if (!str && ment) {
          str =
            'You have not had any sessions yet, as soon as you have a session it would be updated here.';
          test = true;
          title = 'Previous';
          title2 = 'Session';
          btn = 'No previous session';
          form = false;
          na = true;
        }
      }
      if (free && arr.length > 0) {
        title = 'Your';
        title2 = 'free hours';
      }
    }

    return (
      <div className={`upcoming-info earning ${mobile ? 'mob' : ''}`}>
        {!one && (
          <div
            className={`icon ${arr.length < 1 ? 'hide' : ''}`}
            onClick={this.handleSwipe}
          >
            <i className="fas fa-chevron-left"></i>
          </div>
        )}
        <div
          className={`carousel snap ${one || arr.length < 1 ? 'one' : ''}`}
          onScroll={this.handleScroll}
        >
          {one ? (
            <div
              className={`ernij ${down ? 'down' : ''} ${
                test || next ? 'test' : ''
              } ${mobile ? '' : 'ile'}`}
            >
              <div className="img">
                <img src={img} alt="trail icon" />
              </div>
              <p>{title}</p>
              <p>{title2}</p>
              {mobile && (
                <div className="text">
                  {title} {title2}
                </div>
              )}
              <div className={`amount ${next ? 'next' : ''}`}>{str}</div>
              {down && <div className="down">{downStr}</div>}
              {form ? (
                <div className="avail" onClick={this.handleClick}>
                  {mobile ? (
                    <span>
                      <span className="bg">
                        Add a review <i className="fas fa-arrow-right"></i>
                      </span>
                      <i className="fas fa-angle-right"></i>
                    </span>
                  ) : (
                    <span>
                      Add a review <i className="fas fa-arrow-right"></i>
                    </span>
                  )}
                </div>
              ) : na ? (
                <div className="avail" onClick={this.handleDraw}>
                  {mobile ? (
                    <span>
                      <span className="bg">
                        {btn} <i className="fas fa-arrow-right"></i>
                      </span>
                      <i className="fas fa-angle-right"></i>
                    </span>
                  ) : (
                    <span>
                      {btn} <i className="fas fa-arrow-right"></i>
                    </span>
                  )}
                </div>
              ) : (
                <HashLink to={url}>
                  {mobile ? (
                    <span>
                      <span className="bg">
                        {btn} <i className="fas fa-arrow-right"></i>
                      </span>
                      <i className="fas fa-angle-right"></i>
                    </span>
                  ) : (
                    <span>
                      {btn} <i className="fas fa-arrow-right"></i>
                    </span>
                  )}
                </HashLink>
              )}
            </div>
          ) : arr.length === 0 ? (
            <div
              className={`ernij full ${down ? 'down' : ''} ${
                mobile ? '' : 'ile'
              }`}
            >
              <div className="img">
                <img src={img} alt="trail icon" />
              </div>
              <p>{title}</p>
              <p>{title2}</p>
              <div className="amount"></div>
              {down && <div className="down"></div>}
              {link ? (
                <div className="avail" onClick={swit}>
                  {btn} <i className="fas fa-arrow-right"></i>
                </div>
              ) : (
                <Link>
                  {btn} <i className="fas fa-arrow-right"></i>
                </Link>
              )}
            </div>
          ) : (
            arr.map((e, i) => (
              <div
                className={`ernij ${down ? 'down' : ''} ${mobile ? '' : 'ile'}`}
                key={i}
              >
                <div className="img">
                  <img src={img} alt="trail icon" />
                </div>
                <p>{title}</p>
                <p>{title2}</p>
                <div className="amount">{e.str}</div>
                {down && <div className="down">{e.downStr}</div>}
                {link ? (
                  <div className="avail" onClick={swit}>
                    {btn} <i className="fas fa-arrow-right"></i>
                  </div>
                ) : (
                  <Link>
                    {btn} <i className="fas fa-arrow-right"></i>
                  </Link>
                )}
              </div>
            ))
          )}
        </div>
        {!one && (
          <div
            className={`icon ${arr.length < 1 ? 'hide' : ''}`}
            onClick={this.handleSwipe}
          >
            <i className="fas fa-chevron-right"></i>
          </div>
        )}
        {!menu && (
          <div className="menu">
            <span
              className={`${fals ? 'hide' : ''}`}
              onClick={this.handleMenu}
            ></span>
            <span
              className={`${fals ? 'hide' : ''}`}
              onClick={this.handleMenu}
            ></span>
            <span
              className={`${fals ? 'hide' : ''}`}
              onClick={this.handleMenu}
            ></span>
            <div className={`opt ${fals ? '' : 'hide'}`}>
              <div className="cancel" onClick={this.handleMenu}>
                <span></span>
                <span></span>
              </div>
              <p>Cancel</p>
              <p>Go to mentor's page</p>
              <p>Cancel Session</p>
              <p>Session Status</p>
            </div>
          </div>
        )}
        {form && (
          <div
            className={`form ignore ${show ? '' : 'hide'}`}
            onClick={this.handleClick}
          >
            <div className="cancel">
              <span></span>
              <span></span>
            </div>
            <div className="info ignore">
              <div className="img ignore">
                {imgStr ? (
                  <img src={imgStr} alt="trail user" />
                ) : (
                  <div className="icon">
                    <i className="fas fa-user"></i>
                  </div>
                )}
              </div>
              <p className="ignore">Meeting with {str}</p>
            </div>
            <p className="ignore">{purpose}</p>
            {error && <div className="error ignore">{error}</div>}
            <input
              className={`ignore ${error ? 'err' : ''}`}
              type="text"
              name="query"
              value={query}
              onChange={this.handleChange}
              placeholder="Ex: The meeting was ..."
            />
            <div className="links ignore">
              <div className="btn ignore" id={id} onClick={this.handleSubmit}>
                Submit
              </div>
              <div className="back">Cancel</div>
            </div>
          </div>
        )}
        {draw && (
          <div className="form ignore draw" onClick={this.handleDraw}>
            <div className="cancel">
              <span></span>
              <span></span>
            </div>
            <div className="info ignore">
              <p className="ignore">Amount you want to withdraw</p>
            </div>
            {error && <div className="error ignore">{error}</div>}
            {success && <div className="success ignore">{success}</div>}
            <input
              className={`ignore ${error ? 'err' : ''}`}
              type="number"
              name="amount"
              value={amount}
              onChange={this.handleChange}
              placeholder="Ex: 100,000"
              min="0"
            />
            <div className="links ignore">
              <div
                className={`btn ignore ${view ? 'hide' : ''}`}
                id={id}
                onClick={this.handleCash}
              >
                Withdraw
              </div>
              <div className={`loader ${view ? '' : 'hide'}`}>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className="back">Cancel</div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { errors: state.errors, meeting: state.meeting };
}

export default connect(mapStateToProps, {
  getMenteeMeetings,
  getMentorMeetings,
  getUser,
  updateMeeting,
  withdraw,
})(Earning);
