import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Homepage from "../components/Homepage";
import Onboard from "../components/Onboard";
import AuthForm from "../components/AuthForm/AuthForm";
import Aboutpage from "../components/About/Aboutpage";
import Privacy from "../components/Privacy";
import {
  authUser,
  googleUrl,
  googleUser,
  forgotPassword,
  resetPassword,
  wake,
} from "../store/actions/auth";
import { removeError } from "../store/actions/errors";
import MentorPage from "../components/MentorPage";
import Pathway from "../components/Pathway";
import Assessment from "../components/Assessment/Assessment";
import Error from "../components/Error";
import Waitlist from "../components/Waitlist";
import Dashboard from "../components/Dashboard/Dashboard";
import EnterpriseLanding from "../components/Enterprise/EnterpriseLandingPage";
import NewLanding from "../components/NewLanding";

const Main = (props) => {
  const {
    authUser,
    errors,
    removeError,
    currentUser,
    googleUrl,
    authUrl,
    googleUser,
    forgotPassword,
    resetPassword,
  } = props;
  return (
    <div className="container">
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => (
            <Homepage
              currentUser={currentUser}
              onAuth={googleUser}
              removeError={removeError}
              wake={wake}
              {...props}
            />
          )}
        />
        {/* <Route
          exact
          path="/resources"
          render={(props) => (
            // <WaitPathwaypage
            //   currentUser={currentUser}
            //   {...props}
            //   art0={asset12}
            //   mart0={moass44}
            //   art1={asset15}
            //   art2={asset13}
            //   art3={asset16}
            //   mart1={moass47}
            //   mart2={moass45}
            //   mart3={moass48}
            //   heroText={
            //     'Want to move swiftly and smoothly in your career journey?'
            //   }
            //   heroDesc={
            //     'Carefully created resources you need to ease your navigation in your career journey in the Nigerian industry.'
            //   }
            //   intro={['Applicable', 'Multimedia', 'Free']}
            //   title={[
            //     'Not just Content. The ideas that really work',
            //     'The info you need, the way you want it.',
            //     'Access to premium content, for free.',
            //   ]}
            //   desc={[
            //     'Set your career up for success. Get detailed, career-oriented solutions that are applicable for career growth in the Nigerian industry.',
            //     'Trail contents are repurposed into audio, video, and written formats. Same high quality, different media channels. Cut into bite sizes, so you learn only what you need.',
            //     'Get free access to a library of premium resources detailing applicable strategies that can help you move fast, and smoothly in your career journey.',
            //   ]}
            // />
            <Resource />
          )}
        /> */}
        <Route
          exact
          path="/pathway"
          render={(props) => <Dashboard currentUser={currentUser} {...props} />}
        />
        {/* <Route
          exact
          path="/analytics/test"
          render={(props) => (
            <Test
              removeError={removeError}
              currentUser={currentUser}
              {...props}
            />
          )}
        /> */}
        <Route
          exact
          path="/about"
          render={(props) => <Aboutpage currentUser={currentUser} {...props} />}
        />
        <Route
          exact
          path="/about/privacy"
          render={(props) => <Privacy currentUser={currentUser} {...props} />}
        />
        {/* <Route
          exact
          path="/analytics/:name"
          render={(props) => <Info currentUser={currentUser} {...props} />}
        /> */}
        <Route
          exact
          path="/pathway/:name"
          render={(props) => <Pathway currentUser={currentUser} {...props} />}
        />
        <Route
          exact
          path="/waitlist"
          render={(props) => <Waitlist currentUser={currentUser} {...props} />}
        />
        <Route
          exact
          path="/assess/:name"
          render={(props) => (
            <Assessment currentUser={currentUser} {...props} />
          )}
        />
        {/* <Route
          exact
          path="/mentor"
          render={(props) => <Explore currentUser={currentUser} {...props} />}
        /> */}
        {/* <Route
          exact
          path="/pathway/register"
          render={(props) => (
            <Onboard
              currentUser={currentUser}
              removeError={removeError}
              {...props}
              pathway
            />
          )}
        /> */}
        <Route
          exact
          path="/mentor/register"
          render={(props) => (
            <Onboard
              currentUser={currentUser}
              removeError={removeError}
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/mentor/schedule"
          render={(props) => (
            <MentorPage currentUser={currentUser} {...props} />
          )}
        />
        <Route
          exact
          path="/signin"
          render={(props) => {
            return (
              <AuthForm
                removeError={removeError}
                errors={errors}
                authUrl={authUrl}
                onAuth={authUser}
                forgot={forgotPassword}
                googleUrl={googleUrl}
                buttonText="Log in"
                heading="Welcome to the Organized Side of Learning"
                ptext="Keep track of things you want to learn or learning tasks you hope to finish here. Don’t forget to tick them off once done."
                {...props}
              />
            );
          }}
        />
        <Route
          exact
          path="/signup"
          render={(props) => {
            return (
              <AuthForm
                removeError={removeError}
                errors={errors}
                authUrl={authUrl}
                googleUrl={googleUrl}
                onAuth={authUser}
                forgot={forgotPassword}
                signUp
                buttonText="Sign me Up"
                heading="Accelerate your career growth"
                {...props}
              />
            );
          }}
        />
        <Route
          exact
          path="/resetpassword"
          render={(props) => {
            return (
              <AuthForm
                removeError={removeError}
                errors={errors}
                authUrl={authUrl}
                googleUrl={googleUrl}
                onAuth={authUser}
                resetPass={resetPassword}
                reset
                buttonText="Reset"
                heading="Reset your password"
                {...props}
              />
            );
          }}
        />
        {/* <Route
          path="/blog"
          render={(props) => {
            return <Blog {...props} />;
          }}
        /> */}

        <Route path="/individuals" render={(props) => <NewLanding />} />
        <Route
          path="/:slug"
          render={(props) => (
            <MentorPage currentUser={currentUser} {...props} />
          )}
        />

        <Route
          path="*"
          render={(props) => <Error currentUser={currentUser} {...props} />}
        />
      </Switch>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
    errors: state.errors,
    authUrl: state.authUrl,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    authUser,
    removeError,
    googleUrl,
    googleUser,
    forgotPassword,
    resetPassword,
  })(Main)
);
