import React, { Component } from 'react';
import {
  icon01,
  icon02,
  icon03,
  icon04,
  icon05,
  icon06,
  icon09,
  icon25,
} from '../Copy/Copy';

class Dashnav extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { page, logout, role, display, nav, left } = this.props;
    return (
      <div className={`dash-nav ${left ? '' : 'hide'}`}>
        <div className="contain">
          <div className="up">
            <div className="top">
              <div className="brand">
                <img
                  src="https://trail-bucket-074269.s3.us-east-2.amazonaws.com/files/Trail+c-01.png"
                  alt="Trail"
                />
                <div className="title">Trail</div>
              </div>
              <div className="cancel" onClick={nav}>
                <span></span>
                <span></span>
              </div>
            </div>
            <div
              className={`${display === 'hom' ? 'active' : ''} nav-list hom`}
              onClick={page}
            >
              <div className="icon">
                <img src={icon01} alt="Home" />
              </div>
              <div className="nav-item hom" onClick={page}>
                Home
              </div>
            </div>
            <div
              className={`${
                display === 'dahmen' ? 'active' : ''
              } nav-list dahmen`}
              onClick={page}
            >
              <div className="icon">
                <img src={icon04} alt="Mentors" />
              </div>
              <div className="nav-item dahmen" onClick={page}>
                Mentorship Session
              </div>
            </div>
            <div
              className={`${
                display === 'dahpah' || display === 'sect' || display === 'path'
                  ? 'active'
                  : ''
              } nav-list dahpah`}
              onClick={page}
            >
              <div className="icon">
                <img src={icon03} alt="Pathway" />
              </div>
              <div className="nav-item dahpah" onClick={page}>
                Pathway
              </div>
            </div>
            <hr></hr>
            <div
              className={`${display === 'ref' ? 'active' : ''} nav-list ref`}
              onClick={page}
            >
              <div className="icon">
                <img src={icon02} alt="Refer" />
              </div>
              <div className="nav-item ref" onClick={page}>
                Refer Someone
              </div>
            </div>
            <div
              className={`${
                display === 'proff' ? 'active' : ''
              } nav-list alt proff`}
              onClick={page}
            >
              <div className="icon">
                <img src={icon05} alt="Profile" />
              </div>
              <div className="nav-item proff" onClick={page}>
                Profile
              </div>
            </div>
            <div
              className={`nav-list alt ${role === 'user' ? 'hide' : ''} ${
                display === 'account' ? 'active' : ''
              } account`}
              onClick={page}
            >
              <div className="icon">
                <img src={icon25} alt="Profile" />
              </div>
              <div className="nav-item account" onClick={page}>
                Account
              </div>
            </div>
          </div>
          <div className="bottom">
            <a href="mailto:support@trailng.com?subject=We%20want%20to%20hear%20from%20you!">
              <div className="nav-list">
                <div className="icon">
                  <img src={icon06} alt="Help" />
                </div>
                <div className="nav-item">Get Help</div>
              </div>
            </a>
            <div className={`nav-list out`}>
              <div className="icon">
                <img src={icon09} alt="logout" />
              </div>
              <div className="nav-item" onClick={logout}>
                Log Out
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashnav;
