export const asset1 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/New+Illustration+Assets-01.png'
export const asset5 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/New+Illustration+Assets-05.png'
export const asset2 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/New+Illustration+Assets-02.png'
export const asset7 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/New+Illustration+Assets-07.png'
export const asset14 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/New+Illustration+Assets-14.png'
export const asset8 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/New+Illustration+Assets-08.png'
export const asset9 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/New+Illustration+Assets-09.png'
export const asset3 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/New+Illustration+Assets-03.png'
export const asset10 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/New+Illustration+Assets-10.png'
export const asset11 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/New+Illustration+Assets-11.png'
export const asset4 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/New+Illustration+Assets-04.png'
export const asset6 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/New+Illustration+Assets-06.png'
export const asset12 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/New+Illustration+Assets-12.png'
export const asset15 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/New+Illustration+Assets-15.png'
export const asset13 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/New+Illustration+Assets-13.png'
export const asset16 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/New+Illustration+Assets-16.png'
export const art21 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Artboard+21.png'
export const art23 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Artboard+23.png'
export const art22 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Artboard+22.png'
export const art20 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Artboard+20.png'
export const art26 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Artboard+26.png'
export const art24 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Artboard+24.png'
export const art27 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Artboard+27.png'
export const art25 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Artboard+25.png'
export const art30 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Artboard+30.png'
export const art34 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Artboard+34.png'
export const art35 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Artboard+35.png'
export const art31 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Artboard+31.png'
export const art37 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Artboard+37.png'
export const art36 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Artboard+36.png'
export const moass39 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+Illustration+Assets-39.jpg'
export const moass46 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+Illustration+Assets-46.jpg'
export const moass42 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+Illustration+Assets-42.jpg'
export const moass44 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+Illustration+Assets-44.jpg'
export const moass52 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+Illustration+Assets-52.jpg'
export const moass40 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+Illustration+Assets-40.jpg'
export const moass41 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+Illustration+Assets-41.jpg'
export const moass51 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+Illustration+Assets-51.jpg'
export const moass43 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+Illustration+Assets-43.jpg'
export const moass47 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+Illustration+Assets-47.jpg'
export const moass45 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+Illustration+Assets-45.jpg'
export const moass48 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+Illustration+Assets-48.jpg'
export const mart39 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+Artboard+39.png'
export const mart43 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+Artboard+43.png'
export const mart40 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+Artboard+40.png'
export const mart42 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+Artboard+42.png'
export const mart61 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+Artboard+61.png'
export const icon40 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-40.png'
export const icon41 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-41.png'
export const icon32 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-32.png'
export const icon30 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-30.png'
export const icon37 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-37.png'
export const icon34 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-34.png'
export const icon29 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-29.png'
export const icon39 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-39.png'
export const icon38 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-38.png'
export const icon42 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-42.png'
export const icon33 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-33.png'
export const icon44 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-44.png'
export const icon45 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-45.png'
export const icon46 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-46.png'
export const icon28 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-28.png'
export const icon43 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-43.png'
export const icon36 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-36.png'
export const icon35 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-35.png'
export const icon31 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-31.png'
export const icon60 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-60.png'
export const icon61 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-61.png'
export const icon11 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-11.png'
export const icon12 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-12.png'
export const icon10 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-10.png'
export const icon17 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-17.png'
export const icon25 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icon-25.png'
export const icon26 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icon-26.png'
export const icon07 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-07.png'
export const icon08 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-08.png'
export const icon23 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icon-23.png'
export const icon16 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-16.png'
export const icon24 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icon-24.png'
export const icon21 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icon-21.png'
export const icon22 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icon-22.png'
export const icon03 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-03.png'
export const icon20 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-20.png'
export const icon15 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-15.png'
export const icon19 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-19.png'
export const icon13 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-13.png'
export const icon14 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-14.png'
export const icon18 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-18.png'
export const icon01 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-01.png'
export const icon02 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-02.png'
export const icon04 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-04.png'
export const icon05 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-05.png'
export const icon06 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-06.png'
export const icon09 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Icons-09.png'
export const wicon40 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/White+Icons-40.png'
export const land01 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Landing+Page-01.png'
export const land02 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Home+Page+-+Clarity+Session-01.png'
export const land03 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Home+Page+-+Career+Analytics-02.png'
export const land04 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Home+Page+-+Resources-05.png'
export const land05 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/iPhone+11+Pro+Max+Mockup-1.png'
export const land06 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Social+Proof-18.png'
export const land07 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Social+Proof-19.png'
export const land08 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Social+Proof-20.png'
export const land09 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Social+Proof-21.png'
export const land10 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Career+Analytics+-+Landing+Page-09.png'
export const land11 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Career+Analytics+-+Career+test-07.png'
export const land12 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Career+Analytics+-+Career+info-08.png'
export const land13 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/iPhone+11+Pro+Max+Mockup-2.png'
export const land14 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/About+Page+-+Story-11.png'
export const land15 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/About+Page+-+Cofounders-47.png'
export const land16 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/About+Page+-+Cofounders-48.png'
export const land17 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/About+Page+-+Cofounders-49.png'
export const land18 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/iPhone+12+Pro+6.1_+Mockup.png'
export const land19 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Clarity+Page+-+Personal-04.png'
export const land20 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Clarity+Page+-+Community-03.png'
export const land21 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Clarity+Page+-+Mentor-06.png'
export const land22 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/iPhone+11+Pro+Max+Mockup.png'
export const land23 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Add+a+field-24.png'
export const land24 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/About+Page+-+Landing-12.png'
export const land25 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/About+Page+-+Mission-25.png'
export const land26 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/About+Page+-+Mission-26.png'
export const land27 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/About+Page+-+Mission-27.png'
export const land28 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Onboarding-54.png'
export const land29 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Onboarding-52.png'
export const land30 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Onboarding-50.png'
export const land31 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Onboarding-51.png'
export const land32 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Onboarding-53.png'
export const land33 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+-+Landing+Page+-+Home-01.png'
export const land34 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+-+About+Page+-+Landing-10.png'
export const land35 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+-+Landing+Page+-+Clarity-01.png'
export const land36 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+-+Landing+Page+-+Resources-05.png'
export const land37 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+-+Landing+Page+-+Career+Analytics-02.png'
export const land38 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+-+Clarity+Page+-+Personal-04.png'
export const land39 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+-+Clarity+Page+-+Organisation-03.png'
export const land40 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+-+Clarity+Page+-+Be+a+mentor-06.png'
export const land41 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+-+Clarity+Page+-+Add+Career-20.png'
export const land42 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+-+Career+Analytics+Page+-+Landing-09.png'
export const land43 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+-+Career+Analytics+Page+-+Career+testing-08.png'
export const land44 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+-+Career+Analytics+Page+-+Career+details-07.png'
export const land45 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+-+Career+Analytics+Page+-+Social+Proof-18.png'
export const land46 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+-+Landing+Page+-+Social+Proof-17.png'
export const land47 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+-+Clarity+Page+-+Social+Proof-16.png'
export const land48 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Career+Details+Landing+Pages-71.png'
export const land49 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Career+Details+Image-65.png'
export const land50 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Career+Details+Image-62.png'
export const land51 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Career+Details+Image-72.png'
export const land52 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Career+Details+Image-63.png'
export const land53 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Career+Details+Image-64.png'
export const land54 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Career+Details+Image-66.png'
export const land55 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+Career+Details+-+Page-31.png'
export const land56 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Add+a+field-23.png'
export const land57 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+-+Career+Analytics+Result-43.png'
export const land58 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+-+Career+Analytics+Result-44.png'
export const land59 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+-+Career+Analytics+Result-45.png'
export const land60 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+-+Career+Analytics+Result-46.png'
export const land61 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mobile+-+Career+Analytics+Result-47.png'
export const land62 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Waitlist-113.png'
export const land63 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Waitlist-114.png'
export const land64 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Waitlist-115.png'
export const land65 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Waitlist-116.png'
export const land66 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mob+Waitlist-48.png'
export const land67 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mob+Waitlist-49.png'
export const land68 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mob+Waitlist-50.png'
export const land69 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Mob+Waitlist-51.png'
export const result59 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Career+Analytics+Result-59.png'
export const result55 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Career+Analytics+Result-55.png'
export const result56 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Career+Analytics+Result-56.png'
export const result57 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Career+Analytics+Result-57.png'
export const result58 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Career+Analytics+Result-58.png'
export const logo73 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Logos-73.png'
export const logo74 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Logos-74.png'
export const logo75 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Logos-75.png'
export const logo76 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Logos-76.png'
export const logo77 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Logos-77.png'
export const logo78 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Logos-78.png'
export const logo79 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Logos-79.png'
export const logo80 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Logos-80.png'
export const logo81 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Logos-81.png'
export const logo82 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Logos-82.png'
export const logo83 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Logos-83.png'
export const logo84 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Logos-84.png'
export const logo85 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Logos-85.png'
export const oneAss01 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Assets+for+OneTrail-01.png'
export const oneAss02 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Assets+for+OneTrail-02.png'
export const oneAss03 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Assets+for+OneTrail-03.png'
export const oneAss04 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Assets+for+OneTrail-04.png'
export const oneAss05 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Assets+for+OneTrail-05.png'
export const oneAss06 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Assets+for+OneTrail-06.png'
export const oneAss07 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Assets+for+OneTrail-07.png'
export const oneAss08 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Assets+for+OneTrail-08.png'
export const oneAss09 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Assets+for+OneTrail-09.png'
export const oneAss10 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Assets+for+OneTrail-10.png'
export const oneAss11 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Assets+for+OneTrail-11.png'
export const oneAss12 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Assets+for+OneTrail-12.png'
export const oneAss13 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Assets+for+OneTrail-13.png'
export const oneAss14 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Assets+for+OneTrail-14.png'
export const oneAss15 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Assets+for+OneTrail-15.png'
export const oneAss16 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Assets+for+OneTrail-16.png'
export const oneAss17 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Assets+for+OneTrail-17.png'
export const oneAss18 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Assets+for+OneTrail-18.png'
export const oneAss19 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Assets+for+OneTrail-19.png'
export const oneAss20 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Assets+for+OneTrail-20.png'
export const oneAss21 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Assets+for+OneTrail-21.png'
export const oneAss22 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Assets+for+OneTrail-22.png'
export const oneAss23 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Assets+for+OneTrail-23.png'
export const oneAss24 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Onboard-47.png'
export const oneAss25 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Onboard-48.png'
export const oneLand27 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Landing+page-27.png'
export const oneLand28 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Landing+page-28.png'
export const oneLand29 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Landing+page-29.png'
export const oneLand30 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Landing+page-30.png'
export const oneLand31 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Landing+page-31.png'
export const oneLand32 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Landing+page-32.png'
export const oneLand33 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Landing+page-33.png'
export const oneLand34 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Landing+page-34.png'
export const oneLand35 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Landing+page-35.png'
export const oneLand36 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Landing+page-36.png'
export const oneLand37 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Landing+page-37.png'
export const oneLand38 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Landing+page-38.png'
export const oneLand39 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Landing+page-39.png'
export const oneLand40 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Landing+page-40.png'
export const oneLand41 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Landing+page-41.png'
export const oneLand42 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Landing+page-42.png'
export const oneLand43 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Landing+page-43.png'
export const oneLand44 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Landing+page-44.png'
export const oneLand45 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Landing+page-45.png'
export const oneLand46 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Landing+page-46.png'
export const oneFrame1 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Frame+1.png'
export const oneFrame2 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Frame+2.png'
export const oneFrame3 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Frame+3.png'
export const oneFrame4 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Frame+4.png'
export const oneFrame5 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Frame+5.png'
export const oneFrame6 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Frame+6.png'
export const oneFrame7 = 'https://dqe80zoqbuyqe.cloudfront.net/root/Frame+7.png'
export const oneFrame85 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Frame+85.png'
export const oneFrame88 =
  'https://dqe80zoqbuyqe.cloudfront.net/root/Frame+88.png'

export const text01 = 'Career success always leaves a Trail'
export const text02 =
  'Trail gives you the structure, direction, and support you need for career advancement, helping you progress 5 times faster by avoiding information overload, and knowledge leakages.'
export const text03 = 'Searching for answers? Ask an Expert today!'
export const text04 =
  'Hop on a one-on-one call with experts in the industry who are ahead of you to discuss your career goals and decisions.'
export const text05 =
  'Gain access to five years plus hands-on experience; in one hour'
export const text06 =
  'Fast track your journey, avoid mistakes, find your Gallup strength, make connections with industry experts, stand out from the crowd and get personalized solutions. '
export const text07 = 'Learn more about Trail clarity sessions'
export const text08 =
  'Facing a career roadblock, need insight-based career advice, or help with goal setting? Book a session with an expert today.'
export const text09 =
  'Let your community feel confident when working when they know they are a click away from the help of an expert.'
export const text10 = 'Book a session'
export const text11 = 'See how it works'
export const text12 = 'Become the next top career influencer'
export const text13 =
  'Earn while making a positive impact. Share your journey, career-wise, and help millions of young Africans attain success in their career journeys. Become an inspiration for every young person looking to build a career in your field of expertise.'
export const text14 = 'Learn more about how to become a Trail expert'
export const text15 = 'The compass to your career journey.'
export const text16 = 'Find your ideal role with the career compatibility model'
export const text17 =
  'Discover the best career match for you using our system built using advanced machine learning, psychometrics, and career-determining data. Our analytics model can help you analyze your career path, and use your current skill sets to suggest the best upskilling paths to follow.'
export const text18 = 'Stay ahead of the curve by staying informed'
export const text19 =
  'Get all the necessary info required for you to start making well-informed career decisions. We have compiled all you need to know about job roles in the Nigerian industry; average salary, job availability, typical hires, and a lot more.'
export const text20 = 'Information for your career advancement'
export const text21 =
  'Fast, accurate, and reliable content prepared to help you move fast, and smoothly in your career journey.'
export const text22 =
  'Looking for a source of actionable answers? You finally found us.'
export const text23 =
  'We are constantly creating resources designed to help you level up your career in the Nigerian workforce. '
export const text24 =
  'Gain FREE access to a library of premium resources detailing applicable strategies. '
export const text25 =
  'Get detailed, career-oriented solutions that are applicable in the Nigerian industry.'
export const text26 =
  'Trail contents are repurposed into audio, video, and written formats. Same high-quality content, different media channels.'
export const text27 = 'Understand relevant topics at your convenience'
export const text28 =
  'Trail webinars are free-to-attend online sessions that give you access to discussing relevant topics in the industry with professionals and stakeholders who understand it the most. They cover a wide range of topics but go in-depth to details. '
export const text29 = 'Create an account'
export const text30 =
  'Sign up for an account with your name, and email address.'
export const text31 = 'Answer a series of questions'
export const text32 =
  'Have fun with the test, it’s not that serious. Let our systems do all the work'
export const text33 = 'Get your result'
export const text34 =
  'Relax while our system processes your data and gives you feedback.'
export const text35 =
  '" Students who have forged clear and purposeful career goals are more likely to develop a stronger interest in their chosen field of study and make more sensible career-related decisions that better them for future work life. "'
export const text36 = 'Beal & Crockett, 2010'
export const text37 =
  '" The guidance you can potentially receive from an expert in a clarity session is invaluable, and their ability to help you navigate the unknown paths of your position and reach your goals more quickly is what makes them so important. "'
export const text38 = 'prepped'
export const text39 = 'All gas, no breaks!'
export const text40 =
  'Start interacting with experts and accelerate your career growth'
export const text41 =
  'You can now have one-on-one interactions with industry experts to ease the navigation of your career journey, change directions when needed, and get real-time updates on job market changes to make better-informed career decisions.'
export const text42 = 'Always be in the know.'
export const text43 =
  'Start talking to experts who have gone through your journey. Never end up with stale information. Only acquire just-in-time knowledge.'
export const text44 = 'Get the help you need, when you need it'
export const text45 =
  'Cut through the noise and accelerate your career journey through practical and actionable strategies. You don’t have to keep searching, our experts are here with answers to the questions you are asking.'
export const text46 =
  'Experience-based insight from the perspectives of professionals in the Nigerian industry. '
export const text47 =
  'You shouldn’t have to wait for solutions. Experts on Trail are always ready to help you with your career decisions and guidance.'
export const text48 =
  'Cut through the clutter, and work with only tested information that provides results.'
export const text49 = 'Book a clarity session'
export const text50 = 'The easy way to help your team find clarity'
export const text51 = 'Grow your organization by growing your people.'
export const text52 =
  'With Trail clarity session for enterprise, you can give your community access to experts in the industry for help when they require it.'
export const text53 = 'Help them get the help they need'
export const text54 =
  'Help your team find clarity and navigate faster. Connect team members and people in your community with experts in the industry. Using Trail’s enterprise tool will ensure that your people never end up with stale information and knowledge. It is just-in-time knowledge.'
export const text55 =
  'Boost productivity and efficiency. Experts on Trail are always ready to help your team with career decisions and guidance.'
export const text56 =
  'More cost-effective when you pay for a lot of sessions at a time.'
export const text57 =
  'Still remains personal. Each member of the team can use their session slots when they need them.'
export const text58 = "Learn more about Trail's career analytics tool"
export const text59 = 'Check out a job role you are interested'
export const text60 = 'Support your people with clarity sessions'
export const text61 = 'Check out our repository of content'
export const text62 = 'Shape the leaders of tomorrow, today'
export const text63 =
  'Showcase your experience. Create your brand. Build your community. Become a career influencer impacting the lives of millions of young people.'
export const text64 =
  'A short call with an expert like you can be all a young career person needs to kickstart or refocus their career journey. Share the lessons you have learned while building your successful career. Be the inspiration they need.'
export const text65 = 'Put your finger on the pulse of a younger generation'
export const text66 = 'Gain new perspectives and fresh ideas'
export const text67 = 'Increase your professional credibility and social proof'
export const text68 = 'Earn while making a positive impact'
export const text69 =
  'Get access to Trail’s expert community from top organizations in Nigeria.'
export const text70 = 'You crossed, now pass the ladder to the other side'
export const text71 = 'Learn more about what it is here'
export const text72 = 'Discover the best match for you in '
export const text73 = 'Join For Free'
export const text74 = 'Set Up a one-on-one meeting with a Trail expert in '
export const text75 = 'Book a session'
export const text76 =
  'Select a time that you would be free, and send in a quick summary of what you wish to discuss'
export const text77 = 'Make payment'
export const text78 =
  'Pay for your session easily with any of our integrated payment systems'
export const text79 = 'Wait for your session'
export const text80 =
  'Sit back and relax, while a Trail expert gets in touch with you'
export const text81 =
  '" Mentors provide information and knowledge. They find ways to stimulate our professional and personal growth as they can see where individuals need to improve where the individual concerned often cannot see. Also, they are sounding boards so we can bounce ideas off them for an unfiltered opinion. "'
export const text82 = 'Inc.com'
export const text83 =
  '" Clarity sessions are important because they help to tap into the existing knowledge, skills, and experience of senior or high performing experts and transfer these skills to newer or less experienced people in order to advance their careers. "'
export const text84 = 'together'
export const text85 =
  '" Career clarity gives you clear direction, an end goal, and streamlines the job search process for you. Get very specific on what you really want to do and what you are orienting yourself towards. "'
export const text86 = 'Cultivate'
export const text87 =
  'Ready for the most optimal path to a successful product design career?'
export const text88 =
  'Get the personalized structure and support you need to identify the right things you need to learn, and the order you need to learn them.'
export const text89 = 'How fit are you for the job?'
export const text90 =
  'Discover your suitability for a job with the career compatibility tool'
export const text91 =
  'Answer a series of carefully thought-out questions engineered to help you measure how ready you are for your dream career. Find out the skills you are lacking, and how to acquire them easily to level up.'
export const text92 =
  'Trail’s job readiness metric system is the easiest, fastest way to becoming job-ready.'
export const text93 =
  'Our database is always expanding to help you find the best possible career paths for your professional journey. '
export const text94 =
  'Questions include the course of study, classes taken pertaining to the career of interest, skills mastered, skills that come naturally, the industry of interest, etc.'
export const text95 = 'Take your career test'
export const text96 = 'All you need to know about the job.'
export const text97 =
  'We have compiled all the necessary information you need to know about job roles in the Nigerian industry to keep you updated with the industry and help you make well-informed career decisions. '
export const text98 = 'Everything about the job industry, just a click away'
export const text99 =
  'Learn about the role you are looking to get into, the nature of the industry, and every other important information to guide your journey. '
export const text100 =
  'Our database of careers is always expanding and updating to include relevant industry changes.'
export const text101 =
  'Truest reflection of the market. Information is gathered from leading companies in Nigeria and across Africa.'
export const text102 =
  'Data available include average salary, job availability, typical hires, competitiveness, typical requirements, notable professionals, and a lot more.'
export const text103 = 'Get Information about your career path'
export const text104 =
  'Access to the right information is often the difference.'
export const text105 =
  'Every young person in Africa, irrespective of where they live, or who they are, should have access to the resources that would enable them to navigate their career journeys easily in the work industry.'
export const text106 =
  '" Develop the next generation of globally competitive knowledge workers for Africa at an accelerated pace. "'
export const text107 =
  '" Provide the African workforce with the curriculum and support to enable the most direct and quickest pathway to the most in-demand careers. "'
export const text108 =
  'Take the first step to a successful career. Hop on a 1:1 clarity session with renowned experts in the African industry.'
export const text109 = 'Spot-on learning, nothing more, nothing less.'
export const text110 =
  'All you have to do to become a complete product designer.'
export const text111 =
  'Get access to complete pathways, developed to equip you with all necessary skill sets required to become an ideal product designer, making sure you are focused on doing what matters most; learning the right things.'
export const text112 = 'Break through the clutter and save time and energy. '
export const text113 =
  'Studies show that haphazard undirected learning is ineffective and inefficient, as it leads to information overload, and knowledge leakages. Even when success is achieved, the time required for unstructured learning is 5x that of structured, directed, and supported learning.'
export const text114 = 'Get access to a curriculum personalized to you!'
export const text115 =
  'Trail curriculums are designed by an intelligence system that helps you create a learning pathway which automatically syncs your learning progress and adjusts your pathway to fit your career goals.'
export const text116 =
  'There is no end to learning as a product designer, as long as you want to keep growing. Our system keeps adding and editing your curriculum as you progress.'
export const text117 =
  'No need to waste time worrying about whether you are learning the right things, or learning them the right way.'
export const text118 =
  'Identify all necessary skills required to fit into your ideal job role or dream career.'
export const text119 =
  'Make sure you are focused on learning what matters to your career advancement.'
export const text120 = 'Test your strength as you grow'
export const text121 =
  'Apply what you learn by taking regular industry-standard assessments designed to measure your understanding and application of each skill learned.'
export const text122 =
  'These assessments are designed to be thorough, featuring tasks, projects, technical interviews, and proficiency tests.'
export const text123 = 'Here is why you should join the waitlist'
export const text124 = 'Get special offers'
export const text125 =
  'We will make sure to send you emails to notify you of special offers, release dates, and how to make the best use of the opportunities.'
export const text126 = 'Get early access to our product'
export const text127 =
  'You will be the first to know when we launch. Sign up now to get early access to all features as they get released to the market.'
export const text128 = 'Get access to our private beta'
export const text129 =
  'Be a part of the exclusive community that get to use our beta features. You can also report bugs and help improve our product.'
export const text130 = 'Become a part of our story'
export const text131 =
  'We have the vision to create an Africa where people enjoy learning, feel confident while learning, and use what they learn to live a better life.'
export const text132 = 'Sign up to join the waitlist'
export const text133 = 'Achieve your career goals with Trail'
export const text134 = 'Through the lens of a recruiter.'
export const text135 = `Get access to exclusive resources created by the experts you look up to and vetted by the companies you'd like to work with.`
export const text136 = 'Get help. Whenever you need'
export const text137 =
  'Interact with industry experts in your desired field. Get answers, insights, and guides that you really need.'
export const text138 = 'Position yourself for a career'
export const text139 =
  'Start making well informed career decisions. Equip yourself with all the analytics required to choose and build a career.'
export const text140 = 'Actionable, insight based resources'
export const text141 =
  'Free access to strategies that are applicable in the Nigerian industry. Guarranteed to help you build a successful career.'
// export const text112 = 'Join';

// mobile career details home
