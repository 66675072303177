import React from 'react';

const MentorBio = ({ mentor, schedule }) => {
  const { email, picture } = mentor;
  if (picture.indexOf('google') !== -1) {
    var imgStr = picture;
  } else if (picture.indexOf('trailbucket12345') !== -1) {
    imgStr = 'https://dqe80zoqbuyqe.cloudfront.net' + picture.substring(51);
  } else {
    imgStr = '';
  }
  return (
    <div>
      {email ? (
        <div className="home">
          <div className="content">
            <div className="title">
              {mentor.lastName} {mentor.firstName}
            </div>
            <div className="subtitle">{mentor.tag}</div>
            <div className="description">
              <p>{mentor.bio}</p>
            </div>
            <div className="links">
              <div className="join" onClick={schedule}>
                Schedule
              </div>
              <div className="video">
                <a href="/">Go home</a>
              </div>
            </div>
          </div>
          <div className="img">
            <div className="gray">
              {imgStr ? (
                <img src={imgStr} alt="trail user" />
              ) : (
                <div className="icon">
                  <i className="fas fa-user"></i>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div>Mentor no dey Bros</div>
      )}
    </div>
  );
};

export default MentorBio;
