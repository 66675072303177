import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateUser, getUser } from '../store/actions/users';
import { getBanks } from '../store/actions/payments';
import { wicon40 } from './Copy/Copy';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bio: '',
      tag: '',
      accountName: '',
      accountNumber: '',
      bankName: '',
      error: '',
      errArr: [],
      view: true,
    };
  }

  onChange = (e) => {
    if (e.target.files) {
      var formData = new FormData();
      formData.append('avatar', e.target.files[0]);
      this.props.updateUser(formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    }
    this.setState({
      [e.target.name]: e.target.value,
      error: '',
    });
  };

  onClick = (e) => {
    e.target.parentNode.childNodes[1].click();
  };

  handleDiscard = (e) => {
    this.setState({
      accountName: '',
      accountNumber: '',
      bankName: '',
      bio: '',
      tag: '',
    });
    if (window.innerWidth < 480) {
      this.props.page('edit');
    }
  };

  handleSubmit = (e) => {
    this.setState({
      view: false,
    });
    if (this.props.proff) {
      const submitArr = ['tag', 'bio'];
      var submit = {};
      const errArr = submitArr.filter((e) => {
        if (!this.state[e]) {
          return false;
        } else {
          return true;
        }
      });
      if (errArr.length === 0) {
        this.setState({ error: 'Please fill in the fields', view: true });
        return;
      }
      submitArr.forEach((e) => {
        if (this.state[e]) {
          submit = { ...submit, [e]: this.state[e] };
        }
      });
      this.props
        .updateUser(submit)
        .then(() => {
          this.setState({
            bio: '',
            tag: '',
            view: true,
          });
          if (window.innerWidth < 480) {
            this.props.page('edit');
          }
        })
        .catch(() => {
          this.setState({
            view: true,
          });
        });
    } else {
      const submitArr = ['accountName', 'accountNumber', 'bankName'];
      const errArr = submitArr.filter((e) => {
        if (!this.state[e]) {
          return true;
        } else {
          return false;
        }
      });
      if (errArr.length > 0) {
        this.setState({
          error: 'Please fill in the fields',
          errArr,
          view: true,
        });
        return;
      }
      if (this.state.accountNumber.length !== 10) {
        this.setState({
          error: 'Check your Account Number',
          errArr: ['accountNumber'],
          view: true,
        });
        return;
      }
      if (
        this.props.banks.findIndex((e) => e.name === this.state.bankName) === -1
      ) {
        this.setState({
          error: 'Check your bank name',
          errArr: ['bankName'],
          view: true,
        });
        return;
      }
      const bankno = this.props.banks.findIndex(
        (e) => e.name === this.state.bankName
      );
      this.props
        .updateUser({
          accountNumber: `${this.state.accountNumber}`,
          accountName: this.state.accountName,
          bankName: this.state.bankName,
          bankCode: this.props.banks[bankno].code,
        })
        .then(() => {
          this.setState({
            accountName: '',
            accountNumber: '',
            bankName: '',
            view: true,
          });
          if (window.innerWidth < 480) {
            this.props.page('edit');
          }
        })
        .catch(() => {
          this.setState({
            view: true,
          });
        });
    }
  };

  componentDidMount() {
    if (!this.props.role) {
      this.props.getUser(this.props.currentUser.user.id, 'user');
    }
    if (!this.props.proff) {
      this.props.getBanks();
    }
  }

  render() {
    const { title, currentUser, proff, banks } = this.props;
    const {
      bio,
      tag,
      error,
      accountName,
      accountNumber,
      bankName,
      errArr,
      view,
    } = this.state;
    const { picture, firstName, lastName } = currentUser.user;
    if (picture) {
      if (picture.indexOf('google') !== -1) {
        var imgStr = picture;
      } else if (picture.indexOf('trailbucket12345') !== -1) {
        imgStr = 'https://dqe80zoqbuyqe.cloudfront.net' + picture.substring(51);
      } else {
        imgStr = '';
      }
    }
    return (
      <div className="prof">
        <div className="content">
          <div className="title">{title}</div>
          {error && <div className="error">{error}</div>}
          {proff ? (
            <div>
              <div className={`wrapper ${error ? 'error' : ''}`}>
                <label htmlFor="tag">Short title</label>
                <input
                  type="text"
                  name="tag"
                  id="tag"
                  value={tag}
                  onChange={this.onChange}
                />
              </div>
              <div className={`wrapper ${error ? 'error' : ''}`}>
                <label htmlFor="bio">Bio</label>
                <textarea
                  type="text"
                  name="bio"
                  id="bio"
                  value={bio}
                  onChange={this.onChange}
                />
              </div>
            </div>
          ) : (
            <div>
              <div
                className={`wrapper ${
                  errArr.includes('accountNumber') ? 'error' : ''
                }`}
              >
                <label htmlFor="tag">Account Number</label>
                <input
                  type="number"
                  name="accountNumber"
                  id="accountNumber"
                  className="accountno"
                  value={accountNumber}
                  onChange={this.onChange}
                />
              </div>
              <div
                className={`wrapper ${
                  errArr.includes('accountName') ? 'error' : ''
                }`}
              >
                <label htmlFor="bio">Account Name</label>
                <input
                  type="text"
                  name="accountName"
                  id="accountName"
                  value={accountName}
                  onChange={this.onChange}
                />
              </div>
              <div
                className={`wrapper ${
                  errArr.includes('bankName') ? 'error' : ''
                }`}
              >
                <label htmlFor="bio">Bank</label>
                <input
                  type="text"
                  name="bankName"
                  id="bankName"
                  list="banks"
                  value={bankName}
                  onChange={this.onChange}
                />
                <datalist id="banks">
                  {banks.map((e, i) => (
                    <option key={i} value={e.name}>
                      {e.name}
                    </option>
                  ))}
                </datalist>
                <span className="underline"></span>
              </div>
            </div>
          )}
          <div className="links">
            <div
              className={`btn ${view ? '' : 'hide'}`}
              onClick={this.handleSubmit}
            >
              Save Changes
            </div>
            <div className={`loader ${view ? 'hide' : ''}`}>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div className="disc" onClick={this.handleDiscard}>
              Discard
            </div>
          </div>
        </div>
        <div className="side">
          <div>
            <div className="img">
              <div className="cover">
                {imgStr ? (
                  <img src={imgStr} alt="trail user" />
                ) : (
                  <i className="fas fa-user"></i>
                )}
              </div>
              <input type="file" onChange={this.onChange} hidden />
              <div className="icon" onClick={this.onClick}>
                <span></span>
                <span></span>
              </div>
            </div>
            <div className="title">
              {firstName} {lastName}
            </div>
            {currentUser.user.tag && (
              <div className="subtitle">{currentUser.user.tag}</div>
            )}
            {proff ? (
              <div>
                <div className="intro">About</div>
                <p>
                  {currentUser.user.bio
                    ? `${currentUser.user.bio}`
                    : 'Your bio would appear here as soon as you update it'}
                </p>
              </div>
            ) : (
              <div className="account">
                <div className="intro">Account Details</div>
                <p>
                  {currentUser.user.accountName
                    ? `${currentUser.user.accountName}`
                    : 'Your account number would appear here as soon as you update it'}
                </p>
                <div className="no">
                  <p>{currentUser.user.accountNumber}</p>
                  <p>{currentUser.user.bankName}</p>
                </div>
              </div>
            )}
          </div>
          <div className="upcoming">
            <div className="query">
              <div className="bulb">
                <div className="inner">
                  <img src={wicon40} alt="Help Trail" />
                </div>
              </div>
              <div className="content">
                <div className="title">
                  Have any creative idea that might help boost our marketing?
                  Even if it's just by a tiny bit? Share away.
                </div>
                <a
                  href="mailto:marketing@trailng.com?subject=We%20want%20to%20hear%20from%20you!"
                  className="message"
                >
                  Send us a message
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    errors: state.errors,
    banks: state.payment.banks,
  };
}

export default connect(mapStateToProps, {
  updateUser,
  getUser,
  getBanks,
})(Profile);
