import React, { Component } from 'react';
import { HashLink } from 'react-router-hash-link';
import { connect } from 'react-redux';
import { getUser } from '../store/actions/users';
import { getMenteeMeetings, getMentorMeetings } from '../store/actions/meeting';
import { scrollToY } from '../services/scroll';
import MeetDetail from './MeetDetail';
import { icon24, icon03, icon08, icon19 } from './Copy/Copy';

class Upcoming extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ball: 0,
      load: false,
      refresh: 0,
    };
  }

  handleSwipe = (e) => {
    var pos = e.target.parentNode.childNodes[1].scrollLeft;
    const wid = e.target.parentNode.childNodes[1].childNodes[0].offsetWidth;
    const el = e.target.parentNode.childNodes[1];
    const maxwid = e.target.parentNode.childNodes[1].scrollWidth;
    e.target.parentNode.childNodes[1].classList.remove('snap');
    if (e.target.childNodes[0].classList[1] === 'fa-chevron-left') {
      scrollToY(pos - wid, 1000, el);
      pos -= wid;
    } else if (e.target.childNodes[0].classList[1] === 'fa-chevron-right') {
      scrollToY(pos + wid, 1000, el);
      pos += wid;
    }
    if (pos > maxwid - wid) {
      pos = maxwid - wid;
    } else if (pos < 0) {
      pos = 0;
    }
    setTimeout(() => {
      e.target.parentNode.childNodes[1].classList.add('snap');
    }, 1000);
    const ball = Math.round(pos / wid);
    this.setState({
      ball,
    });
  };

  handleScroll = (e) => {
    var pos = e.target.scrollLeft;
    const wid = e.target.childNodes[0].offsetWidth;
    const ball = Math.round(pos / wid);
    this.setState({
      ball,
    });
  };

  handleBall = (e) => {
    const el = e.target.parentNode.parentNode.childNodes[0];
    console.log(el);
    const wid =
      e.target.parentNode.parentNode.childNodes[0].childNodes[0].offsetWidth;
    const pos = parseInt(e.target.id.split('.')[1]) * wid;
    e.target.parentNode.parentNode.childNodes[0].classList.remove('snap');
    scrollToY(pos, 1000, el);
    setTimeout(() => {
      e.target.parentNode.parentNode.childNodes[0].classList.add('snap');
    }, 1000);
    const ball = parseInt(e.target.id.split('.')[1]);
    this.setState({
      ball,
    });
  };

  componentDidMount() {
    if (!this.props.role) {
      this.props.getUser(this.props.user, 'user').then((user) => {
        if (user.role === 'mentor') {
          this.props.getMentorMeetings(user.id).then(() => {
            this.setState({
              load: true,
            });
          });
        } else if (user.role === 'user') {
          this.props.getMenteeMeetings(user.id).then(() => {
            this.setState({
              load: true,
            });
          });
        }
      });
    } else {
      if (this.props.role === 'mentor') {
        this.props.getMentorMeetings(this.props.user).then(() => {
          this.setState({
            load: true,
          });
        });
      } else if (this.props.role === 'user') {
        this.props.getMenteeMeetings(this.props.user).then(() => {
          this.setState({
            load: true,
          });
        });
      }
    }
  }

  handlePath = () => {
    this.props.history.push('/pathway/product-design');
  };

  componentDidUpdate() {
    if (this.state.refresh + 1 === this.props.refScore) {
      this.setState({
        load: false,
        refresh: this.state.refresh + 1,
      });
      this.props.getUser(this.props.user, 'user').then((user) => {
        if (user.role === 'mentor') {
          this.props.getMentorMeetings(user.id).then(() => {
            this.setState({
              load: true,
            });
          });
        } else if (user.role === 'user') {
          this.props.getMenteeMeetings(user.id).then(() => {
            this.setState({
              load: true,
            });
          });
        }
      });
    }
  }

  render() {
    const { ball, load } = this.state;
    const { meeting, role, nil, page } = this.props;
    const now = new Date();
    var meets = [];
    var title = '';
    if (meeting.mentorMeetings || meeting.menteeMeetings) {
      if (role === 'mentor') {
        meets = meeting.mentorMeetings.filter((e) => {
          const meetTime = new Date(e.time);
          if (now < meetTime) {
            return true;
          }
          return false;
        });
      } else if (role === 'user') {
        meets = meeting.menteeMeetings.filter((e) => {
          const meetTime = new Date(e.time);
          if (now < meetTime) {
            return true;
          }
          return false;
        });
      }

      meets.sort((a, b) => {
        const at = new Date(a.time);
        const bt = new Date(b.time);
        return at - bt;
      });
    }

    if (role) {
      var section = this.props.currentUser.user.section.find(
        (e) => e.done === 'pending'
      );
      if (section) {
        title = section.title;
      }
    }

    return (
      <div className="path">
        <p className="sm">My Learning</p>
        <div className="learn">
          <div className="wrappr">
            {!load ? (
              <div className="content">
                <div className="title load flicker"></div>
                <div className="icon  load flicker"></div>
                <div className="desc load flicker"></div>
                <div className="btn load flicker"></div>
              </div>
            ) : section ? (
              <div className="content sect" onClick={page}>
                <div className="title sect">Current Section</div>
                <div className="icon sect">
                  <img className="sect" src={icon24} alt="pathway" />
                </div>
                <div className="desc sect">{title}</div>
                <div className="btn sect" onClick={page}>
                  Continue learning <i className="fas fa-chevron-right"></i>
                </div>
              </div>
            ) : (
              <div className="content" onClick={this.handlePath}>
                <div className="title">No Current Section</div>
                <div className="icon">
                  <img src={icon24} alt="pathway" />
                </div>
                <div className="desc">
                  Get your pathway now and begin the upskilling journey today
                </div>
                <div className="btn" onClick={this.handlePath}>
                  Get Pathway <i className="fas fa-chevron-right"></i>
                </div>
              </div>
            )}

            {!load ? (
              <div className="box load flicker"></div>
            ) : section ? (
              <div className="box">
                <div className="title">Learning Pathway</div>
                <div className="icon">
                  <img src={icon03} alt="pathway" />
                </div>
                <div className="desc">
                  Your personalized pathway to becoming a world class product
                  designer.
                </div>
                <div className="btn path" onClick={page}>
                  View full pathway <i className="fas fa-chevron-right"></i>
                </div>
              </div>
            ) : (
              <div className="box">
                <div className="title">No Learning Pathway</div>
                <div className="icon">
                  <img src={icon03} alt="pathway" />
                </div>
                <div className="desc">
                  Your pathway would be generated before you start learning
                </div>
                <div className="btn" onClick={this.handlePath}>
                  Get Pathway <i className="fas fa-chevron-right"></i>
                </div>
              </div>
            )}

            <div className="ref" onClick={page}>
              <div className="title ref">Referrals</div>
              <div className="icon ref">
                <img className="ref" src={icon08} alt="Referrals" />
              </div>
              <div className="desc ref">
                Get paid when you refer your network to book a session or get a
                pathway.
              </div>
              <div className="btn ref" onClick={page}>
                View Earnings <i className="fas fa-chevron-right"></i>
              </div>
            </div>
            <div className="ref">
              <HashLink to="/mentor#mentorlink">
                <div className="title">Group session</div>
                <div className="icon">
                  <img src={icon19} alt="Group" />
                </div>
                <div className="desc">
                  Book a mentoring session with your friends who have similar
                  interests.
                </div>
                <div className="btn">
                  Book a group session <i className="fas fa-chevron-right"></i>
                </div>
              </HashLink>
            </div>
          </div>
        </div>
        <p className="sm">Upcoming Sessions</p>
        <div className={`upcoming-info ${meets.length > 1 ? '' : 'sing'}`}>
          <div
            className={`carousel snap ${meets.length > 0 ? '' : 'nil'} ${
              meets.length === 1 ? 'one' : ''
            }`}
            onScroll={this.handleScroll}
          >
            {!load ? (
              <MeetDetail load />
            ) : meets.length > 0 ? (
              meets.map((e) => (
                <MeetDetail
                  purpose={e.purpose}
                  e={e}
                  role={role}
                  link={e.meetLink}
                  time={e.time}
                  key={e._id}
                />
              ))
            ) : (
              <div className="nil">
                <div className="title">No Clarity Session yet!</div>
                <div className="bio">
                  Trail Clarity sessions are designed to help you start and
                  accelerate your career growth through access to 1:1
                  interactions with verified industry experts
                </div>
                <HashLink
                  to="/mentor#mentorlink"
                  className={`cir ${nil ? 'hide' : ''}`}
                >
                  Book a session <i className="fas fa-chevron-right"></i>
                </HashLink>
              </div>
            )}
          </div>
          {meets.length > 1 && (
            <div className="bar">
              {meets.map((e, i) => (
                <span
                  className={`${ball === i ? 'fill' : ''}`}
                  id={`ball.${i}`}
                  onClick={this.handleBall}
                  key={i}
                ></span>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { meeting: state.meeting };
}

export default connect(mapStateToProps, {
  getMenteeMeetings,
  getMentorMeetings,
  getUser,
})(Upcoming);
