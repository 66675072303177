import axios from "axios";

export function setTokenHeader(token) {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
}

export function apiCall(method, path, data, config) {
  return new Promise((resolve, reject) => {
    console.log(`${baseUrl}${path}`);
    return axios[method.toLowerCase()](`${baseUrl}${path}`, data, config)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        return reject(err.response.data.error);
      });
  });
}

// export const baseUrl = 'https://trail-server.onrender.com'
export const baseUrl = "http://localhost:5000";
