import React, { Component } from "react";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import "../assets/styles/nav.css";

class OneDashnav extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      page,
      display,
      nav,
      left,
      img,
      fName,
      lName,
      tutStep,
      step,
      scroll,
    } = this.props;

    return (
      <div className={`dash-nav ol one ${left ? "" : "hide"}`}>
        <div className="contain">
          <div className="up">
            <div className="top">
              <div className="brand">
                <img
                  src="https://trailbucket12345.s3.us-east-2.amazonaws.com/root/Brand+Identity+(Trail)-11.png"
                  alt="Trail"
                />
              </div>
              <div className="cancel" onClick={nav}>
                <span></span>
                <span></span>
              </div>
            </div>
            <div
              className={`${display === "hom" ? "active" : ""} ${
                display === "hom-sm" ? "active" : ""
              } nav-list`}
              onClick={(e) => page(e, "hom")}
            >
              <div className="icon">
                <HomeOutlinedIcon />
              </div>
              <div className="nav-item" onClick={(e) => page(e, "hom")}>
                Home
              </div>
            </div>
            <div
              className={`${display === "dahmen" ? "active" : ""} nav-list`}
              onClick={(e) => page(e, "dahmen")}
            >
              <div className="icon">
                <CalendarTodayOutlinedIcon />
              </div>
              <div className="nav-item" onClick={(e) => page(e, "dahmen")}>
                Learning plan
              </div>
            </div>
            <div
              className={`${
                display === "dahpah" || display === "sect" || display === "path"
                  ? "active"
                  : ""
              } nav-list`}
              onClick={(e) => {
                page(e, "dahpah");
                if (tutStep === 11) {
                  step();
                }
              }}
            >
              <div className="icon">
                <HistoryOutlinedIcon />
              </div>
              <div
                className="nav-item"
                onClick={(e) => {
                  page(e, "dahpah");
                  if (tutStep === 11) {
                    step();
                  }
                }}
              >
                Learning history
              </div>
            </div>
            <div
              className={`${display === "ref" ? "active" : ""} nav-list`}
              onClick={(e) => {
                page(e, "ref");
                if (tutStep === 13) {
                  step();
                }
              }}
            >
              <div className="icon">
                <ShoppingCartOutlinedIcon />
              </div>
              <div
                className="nav-item"
                onClick={(e) => {
                  page(e, "ref");
                  if (tutStep === 13) {
                    step();
                  }
                }}
              >
                Wishlist
              </div>
            </div>
            <div
              className={`${display === "proff" ? "active" : ""} nav-list alt`}
              onClick={(e) => {
                page(e, "proff");
                if (tutStep === 15) {
                  step();
                }
              }}
            >
              <div className="icon">
                <SettingsOutlinedIcon />
              </div>
              <div
                className="nav-item"
                onClick={(e) => {
                  page(e, "proff");
                  if (tutStep === 15) {
                    step();
                    setTimeout(() => {
                      scroll(200);
                    }, 100);
                  }
                }}
              >
                Settings
              </div>
            </div>
          </div>
          <div className="bottom">
            <div className={`nav-list out`}>
              <div className="icon">
                {img ? (
                  <img className="profile-img" src={img} alt="user" />
                ) : (
                  <img
                    className="profile-img"
                    src="https://pngimg.com/uploads/avatar/avatar_PNG47.png"
                    alt="user"
                  />
                )}
              </div>
              <div className="nav-item">
                {fName} {lName}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OneDashnav;
