import React from 'react';

const Session = ({
  name,
  time,
  query,
  change,
  submit,
  oldQuery,
  show,
  click,
  id,
  purpose,
  error,
  img,
}) => {
  var newTime = new Date(time);
  const date = newTime.toLocaleString('en-US', {
    weekday: 'short',
    day: 'numeric',
    month: 'numeric',
  });
  newTime.toDateString();
  newTime = `${newTime}`.split(' ');
  const meetTime = `${newTime[4].split(':')[0]}:${newTime[4].split(':')[1]}`;
  name = name.substring(0, 24);
  return (
    <div
      className={`item ${oldQuery ? 'complete' : ''}`}
      onClick={click}
      id={id}
    >
      <div className="img">
        {img ? (
          <img src={img} alt="trail user" />
        ) : (
          <div className="icon">
            <i className="fas fa-user"></i>
          </div>
        )}
      </div>
      <div className="info name bg">{name}</div>
      <div className="info bg">{meetTime}</div>
      <div className="info bg">{date}</div>
      <div className={`info action bg ${oldQuery ? 'complete' : ''}`}>
        {oldQuery ? 'Completed' : 'Pending'}
      </div>
      <div className="sm">
        <div className="name">{name}</div>
        <div className="deet">
          <span>{meetTime}</span> <span>{date}</span>{' '}
          <span>{oldQuery ? '' : 'Pending'}</span>
        </div>
      </div>
      <div className={`check sm ${oldQuery ? 'complete' : ''}`}></div>
      <div className={`form ignore ${show === id ? '' : 'hide'}`}>
        <div className="cancel">
          <span></span>
          <span></span>
        </div>
        <div className="info ignore">
          <div className="img ignore">
            {img ? (
              <img src={img} alt="trail user" />
            ) : (
              <div className="icon">
                <i className="fas fa-user"></i>
              </div>
            )}
          </div>
          <p className="ignore">Meeting with {name}</p>
        </div>
        <p className="ignore">{purpose}</p>
        {error && <div className="error ignore">{error}</div>}
        <input
          className={`ignore ${error ? 'err' : ''}`}
          type="text"
          name="query"
          value={query}
          onChange={change}
          placeholder="Ex: The meeting was ..."
        />
        <div className="links ignore">
          <div className="btn ignore" onClick={submit}>
            Submit
          </div>
          <div className="back">Cancel</div>
        </div>
      </div>
    </div>
  );
};

export default Session;
