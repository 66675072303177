import { LOAD_MENTORS } from '../actionTypes';

const DEFAULT_STATE = { mentors: null };

const user = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case LOAD_MENTORS:
      return { mentors: action.mentors };
    default:
      return state;
  }
};

export default user;
