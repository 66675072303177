import React from 'react';

const Timeslot = ({
  day,
  date,
  month,
  minute,
  hour,
  zone,
  id,
  year,
  info,
  _id,
  today,
  tomorrow,
  time,
  second,
}) => {
  let setValue = (val) => (val > 9 ? '' : '0') + val;
  var end = setValue(parseInt(hour) + 1);
  var newDate = date;
  if (parseInt(date) > 3) {
    newDate = `${date}th`;
  } else if (parseInt(date) < 2) {
    newDate = `${date}st`;
  } else if (parseInt(date) < 3) {
    newDate = `${date}nd`;
  } else {
    newDate = `${date}rd`;
  }
  if (parseInt(end) === 24) {
    end = '00';
  }
  const week = {
    Monday: 'Mon',
    Tuesday: 'Tue',
    Wednesday: 'Wed',
    Thursday: 'Thur',
    Friday: 'Fri',
    Saturday: 'Sat',
    Sunday: 'Sun',
  };
  var show = false;
  if (info.find((el) => el.id === _id && el.second === second)) {
    show = true;
  }
  return (
    <div className={`timeslot ${show ? '' : 'nil'}`}>
      <div className="status big">Available</div>
      <div className="day big">
        {today ? 'Today' : tomorrow ? 'Tomorrow' : day}
      </div>
      <div className="date big">
        {today ? `${day} ` : tomorrow ? `${day} ` : ''}
        {month} {newDate}
      </div>
      <div className="time big">
        {hour}:{minute} - {end}:{minute}
      </div>
      <div className="zone big">({zone})</div>

      <div className="date small">
        <div className="day">{setValue(date)}</div>
        <p>{week[day]}</p>
      </div>
      <div className="moment small">
        <div className="moda">
          {day}, {month} {date}, {year}
        </div>
        <div className="time">
          {hour}:{minute} - {end}:{minute} ({zone})
        </div>
      </div>
      <div className="radio">
        <p className="big">Book this session</p>
        <input
          type="checkbox"
          id={id}
          className="state"
          name="employer.current"
          onClick={time}
          value={_id + '.' + second}
        />
        <label htmlFor={id}></label>
      </div>
    </div>
  );
};

export default Timeslot;
