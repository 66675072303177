export function scrollToY(
  y,
  duration = 0,
  element = document.scrollingElement
) {
  // cancel if already on target position
  if (element.scrollLeft === y) return;

  const cosParameter = (element.scrollLeft - y) / 2;
  let scrollCount = 0,
    oldTimestamp = null;

  function step(newTimestamp) {
    if (oldTimestamp !== null) {
      // if duration is 0 scrollCount will be Infinity
      scrollCount += (Math.PI * (newTimestamp - oldTimestamp)) / duration;
      if (scrollCount >= Math.PI) return (element.scrollLeft = y);
      element.scrollLeft =
        cosParameter + y + cosParameter * Math.cos(scrollCount);
    }
    oldTimestamp = newTimestamp;
    window.requestAnimationFrame(step);
  }
  window.requestAnimationFrame(step);
}
