import { apiCall } from "../../services/api";
import { addError, removeError } from "./errors";
import { ADD_LEARNING, GET_MICROSKILLS, UPDATE_LEARNING } from "../actionTypes";
import { skills } from "../../services/seed";

export const addLearning = (learning) => ({
  type: ADD_LEARNING,
  learning,
});

export const updateLearning = (learning) => ({
  type: UPDATE_LEARNING,
  learning,
});

export const getMicroSkills = (microSkills) => ({
  type: GET_MICROSKILLS,
  microSkills,
});

export const addLearningFn = (learning) => {
  return (dispatch) => {
    return apiCall("post", "/api/v1/learning", learning)
      .then(({ data }) => {
        dispatch(addLearning(data));
        dispatch(removeError());
      })
      .catch((err) => {
        dispatch(addError(err));
      });
  };
};

export const getMicroSkillsFn = () => {
  return (dispatch) => {
    const portfolio = localStorage.getItem("portfolio");
    if (portfolio && Date.now() - parseInt(portfolio, 10) < 3600 * 1000) {
      dispatch(getMicroSkills(skills));
    } else {
      return apiCall("get", `/api/v1/skills`)
        .then(({ data }) => {
          dispatch(getMicroSkills(data));
          dispatch(removeError());
        })
        .catch((err) => {
          dispatch(addError(err));
        });
    }
  };
};

export const updateLearningFn = (learning, id) => {
  return (dispatch) => {
    return apiCall("put", `/api/v1/learning/${id}`, learning)
      .then(({ data }) => {
        dispatch(updateLearning(data));
        dispatch(removeError());
      })
      .catch((err) => {
        dispatch(addError(err));
      });
  };
};
