import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-list">
        <div className="over">
          <div className="group">
            <div className="title">Company</div>
            <Link to="/about">
              <div className="item">About</div>
            </Link>
            <a href="https://forms.gle/LWtnfjCTKyPsYyN99">
              <div className="item">Become a mentor</div>
            </a>
          </div>
          <div className="group">
            <div className="title">Mentorship Tracks</div>
            <Link
              to={{
                pathname: '/mentor/schedule',
                state: {
                  id: '612e27177709ef0c1853e2c1',
                  name: 'Product Management',
                },
              }}
            >
              <div className="item">Product Management</div>
            </Link>
            <Link
              to={{
                pathname: '/mentor/schedule',
                state: { id: '61a29ab0c03fb7455877e184', name: 'Data Science' },
              }}
            >
              <div className="item">Data Science</div>
            </Link>
            <Link
              to={{
                pathname: '/mentor/schedule',
                state: {
                  id: '61a29d61c03fb7455877e185',
                  name: 'Software Engineering',
                },
              }}
            >
              <div className="item">Software Engineering</div>
            </Link>
            <Link
              to={{
                pathname: '/mentor/schedule',
                state: {
                  id: '61a29261205c2f12cc118dcb',
                  name: 'Product Design',
                },
              }}
            >
              <div className="item">Product Design</div>
            </Link>
            <Link
              to={{
                pathname: '/mentor/schedule',
                state: {
                  id: '61a2975a5984980858bd3b42',
                  name: 'Investment Banking',
                },
              }}
            >
              <div className="item">Investment Banking</div>
            </Link>
          </div>
        </div>
        <div className="over">
          <div className="group">
            <div className="title">Features</div>
            <Link to="/mentor">
              <div className="item">Mentor Sessions</div>
            </Link>
            <Link to="/pathway/product-design">
              <div className="item">Product Design Pathway</div>
            </Link>
          </div>
          <div className="group">
            <div className="title">Company</div>
            <a href="mailto:hello@trailng.com?subject=We%20are%20excited%20to%20hear%20from%20you!">
              <div className="item">Contact Us</div>
            </a>
            <a href="mailto:support@trailng.com?subject=We%20want%20to%20hear%20from%20you!">
              <div className="item">Help</div>
            </a>
          </div>
        </div>
      </div>
      <hr />
      <div className="contact">
        <div className="rights">&copy; 2021 Trail Ltd. All Rights Reserved</div>
        <div className="social-links">
          <a href="https://twitter.com/trail_ng?s=20">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="mailto:hello@trailng.com?subject=We%20are%20excited%20to%20hear%20from%20you!">
            <i className="far fa-envelope"></i>
          </a>
          <a href="https://instagram.com/trail.ng">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://www.linkedin.com/company/trailng">
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>
        <div className="info">
          <a href="mailto:hello@trailng.com?subject=We%20are%20excited%20to%20hear%20from%20you!">
            <i className="far fa-envelope"></i> hello@trailng.com
          </a>
          <a href="tel:+2348146770822">
            <i className="fas fa-phone"></i> +234 814 677 0822
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
