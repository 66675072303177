import { apiCall } from "../../services/api";
import { addError, removeError } from "./errors";
import { setCurrentUser, setAuthorizationToken } from "./auth";
import { LOAD_MENTORS } from "../actionTypes";
import { getUrl } from "./auth";
import { jwtS, userS } from "../../services/seed";

export const loadMentors = (mentors) => ({
  type: LOAD_MENTORS,
  mentors,
});

export const updateUser = (data, config) => (dispatch, getState) => {
  let { currentUser } = getState();
  var id = currentUser.user.id;
  if (!id) {
    id = currentUser.user.user.id;
  }
  const portfolio = localStorage.getItem("portfolio");
  if (portfolio && Date.now() - parseInt(portfolio, 10) < 3600 * 1000) {
    localStorage.setItem("jwtToken", jwtS);
    setAuthorizationToken(jwtS);
    dispatch(setCurrentUser(userS));
  } else {
    return apiCall("put", `/api/v1/users/${id}`, data, config)
      .then(({ token, ...user }) => {
        // make an admin safe feature that is updating users without setting auth headers
        if (token) {
          localStorage.setItem("jwtToken", token);
          setAuthorizationToken(token);
          dispatch(setCurrentUser(user.user));
        }
        dispatch(removeError());
      })
      .catch((err) => {
        dispatch(addError(err));
      });
  }
};

export function getUser(id, action) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const portfolio = localStorage.getItem("portfolio");
      if (portfolio && Date.now() - parseInt(portfolio, 10) < 3600 * 1000) {
        dispatch(setCurrentUser(userS));
      } else {
        return apiCall("get", `/api/v1/users/${id}`)
          .then(({ user, data }) => {
            if (action === "user") {
              if (!user) {
                dispatch(setCurrentUser(data));
                console.log(user);
                dispatch(removeError());
                resolve(data);
              } else {
                dispatch(setCurrentUser(user));
                console.log(user);
                dispatch(removeError());
                resolve(user);
              }
            }
          })
          .catch((err) => {
            dispatch(addError(err));
            reject();
          });
      }
    });
  };
}

export const addTime = (data) => (dispatch) => {
  return apiCall("put", `/api/v1/users/freetime`, data)
    .then(({ data, google }) => {
      if (google) {
        dispatch(getUrl(data));
      } else {
        dispatch(setCurrentUser(data));
      }
      dispatch(removeError());
    })
    .catch((err) => {
      dispatch(addError(err));
    });
};

export const removeTime = (id) => (dispatch) => {
  return apiCall("delete", `/api/v1/users/freetime/${id}`)
    .then(({ data }) => {
      dispatch(setCurrentUser(data));
      dispatch(removeError());
    })
    .catch((err) => {
      dispatch(addError(err));
    });
};

export function generateCode() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return apiCall("get", `/api/v1/users/referral`)
        .then(({ data }) => {
          dispatch(setCurrentUser(data));
          dispatch(removeError());
          resolve();
        })
        .catch((err) => {
          dispatch(addError(err));
          reject();
        });
    });
  };
}

export function editAssistant(id, data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return apiCall("post", `/api/v1/users/assistant/${id}`, data)
        .then(({ data }) => {
          dispatch(setCurrentUser(data));
          dispatch(removeError());
          resolve();
        })
        .catch((err) => {
          dispatch(addError(err));
          reject();
        });
    });
  };
}

export function markAsRead(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return apiCall("put", `/api/v1/users/resource/${id}`)
        .then(({ data }) => {
          dispatch(setCurrentUser(data));
          dispatch(removeError());
          resolve();
        })
        .catch((err) => {
          dispatch(addError(err));
          reject();
        });
    });
  };
}

export function pending(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return apiCall("put", `/api/v1/users/currentresource/${id}`)
        .then(({ data }) => {
          dispatch(setCurrentUser(data));
          dispatch(removeError());
          resolve();
        })
        .catch((err) => {
          dispatch(addError(err));
          reject();
        });
    });
  };
}

export function fetchMentors() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return apiCall("get", `/api/v1/users/mentors`)
        .then(({ data }) => {
          dispatch(loadMentors(data));
          dispatch(removeError());
          resolve();
        })
        .catch((err) => {
          dispatch(addError(err));
          reject();
        });
    });
  };
}
