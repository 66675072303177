import { LOAD_RESOURCES, LOAD_SECTION_RESOURCES } from '../actionTypes';

const DEFAULT_STATE = { allResources: [], sectionResources: [] };

const resource = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case LOAD_RESOURCES:
      return { allResources: action.resources };
    case LOAD_SECTION_RESOURCES:
      return { sectionResources: action.sectionResources };
    default:
      return state;
  }
};

export default resource;
