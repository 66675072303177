import React, { Component } from 'react';
import Footer from '../components/Footer';
// import { Link } from 'react-router-dom';
import Trustedby from '../components/Trustedby';
import WaitNavbar from '../containers/WaitNavbar';
import { getMentor, getOneMentor } from '../store/actions/meeting';
import { getUser } from '../store/actions/users';
import { connect } from 'react-redux';
import { art30 } from './Copy/Copy';
import MentorBio from './MentorBio';
import Error from './Error';
import Schedule from './Schedule';

class MentorPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schedule: false,
      time: [],
      error: false,
      onboard: false,
    };
  }

  componentDidMount() {
    if (this.props.location.state) {
      this.props.getMentor(this.props.location.state.id).then(() => {
        if (!this.props.mentor) {
          this.setState({
            error: true,
            onboard: true,
          });
        }
      });
    } else if (this.props.match.path === '/:slug') {
      this.props
        .getOneMentor(this.props.location.pathname.split('/')[1])
        .then()
        .catch(() => {
          this.setState({
            error: true,
          });
        });
    } else {
      this.props.history.push('/mentor');
    }
  }

  handleSchedule(e) {
    const schedule = document.querySelector('.mentor-page .home');
    if (this.props.currentUser.isAuthenticated === false) {
      this.props.history.push('/signin', {
        from: this.props.location.pathname,
        err: 'You need to be logged to book a session',
      });
      return;
    }
    this.setState({
      schedule: true,
    });
    window.scrollTo({
      top: schedule.scrollHeight - 40,
      left: 0,
      behavior: 'smooth',
    });
  }

  handleInput(e) {
    const idVal = e.target.value.split('.');
    const time = [...this.state.time];
    if (
      time.findIndex((e) => {
        if (idVal[1] === 'true' && idVal[0] === e.id && e.second === true) {
          return true;
        } else if (
          idVal[0] === e.id &&
          e.second === undefined &&
          idVal[1] !== 'true'
        ) {
          return true;
        }
        return false;
      }) === -1
    ) {
      if (idVal[1] === 'true') {
        var times = {
          id: idVal[0],
          second: true,
          mentorId: this.props.mentor.id,
        };
      } else {
        times = {
          id: idVal[0],
          mentorId: this.props.mentor.id,
        };
      }
      time.push(times);
      this.setState({
        time,
      });
      return;
    }
    const timeN = time.filter((e) => {
      if (idVal[0] === e.id) {
        if (idVal[1] === 'true' && e.second === true) {
          return false;
        } else if (idVal[1] === 'undefined' && e.second === undefined) {
          return false;
        }
      }
      return true;
    });
    this.setState({
      time: timeN,
    });
  }

  render() {
    const { mentor } = this.props;
    const { schedule, time, error, onboard } = this.state;
    var title;
    var dub;
    onboard
      ? (title = 'Our mentors are currently unavailable')
      : (title = 'Error 404, sorry you got the wrong page');
    onboard
      ? (dub =
          'They would be available shortly as soon as they are free. check back later')
      : (dub =
          'Sorry it seems you have gotten the wrong page, go back or go home');
    return error ? (
      <Error title={title} dub={dub} />
    ) : (
      <div className="mentor-page">
        <WaitNavbar />
        {mentor ? (
          <MentorBio
            mentor={mentor}
            schedule={this.handleSchedule.bind(this)}
          />
        ) : (
          <div className="home">
            <div className="content">
              <div className="title load flicker"></div>
              <div className="subtitle load flicker"></div>
              <div className="description">
                <p className="load flicker"></p>
                <p className="load flicker"></p>
                <p className="load flicker"></p>
                <p className="load flicker"></p>
              </div>
            </div>
            <div className="img">
              <div className="gray flicker"></div>
            </div>
          </div>
        )}
        {mentor && (
          <Schedule
            display={schedule}
            mentor={mentor}
            time={this.handleInput.bind(this)}
            info={time}
          />
        )}
        <Trustedby landing={art30} />
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    mentor: state.meeting.mentor,
  };
}

export default connect(mapStateToProps, { getMentor, getUser, getOneMentor })(
  MentorPage
);
