import { combineReducers } from 'redux'
import currentUser from './currentUser'
import errors from './errors'
import pathway from './pathway'
import authUrl from './auth'
import payment from './payment'
import resource from './resources'
import field from './field'
import meeting from './meeting'
import user from './user'
import todo from './todo'
import learning from './learning'

const rootReducer = combineReducers({
  currentUser,
  errors,
  authUrl,
  pathway,
  payment,
  resource,
  field,
  meeting,
  user,
  learning,
  todo,
})

export default rootReducer
