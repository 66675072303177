import React, { Component } from 'react';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addTime, removeTime } from '../../store/actions/users';

class Available extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zones: [],
      zone: '',
      day: '',
      hour: '',
      minute: '',
      error: [],
    };
  }

  handleClick = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (e.target.value !== '') {
      var error = [...this.state.error];
      error = error.filter((el) => el !== e.target.name);
      this.setState({
        error,
      });
    }
  };

  handleSubmit = (e) => {
    const { zone, hour, day, minute, zones } = this.state;
    var err = ['zone', 'hour', 'day', 'minute'];
    var error = [];
    err.forEach((i) => {
      if (
        this.state[i] === '' ||
        (i === 'zone' && zones.indexOf(this.state[i]) === -1)
      ) {
        error.push(i);
      }
    });
    if (error.length !== 0) {
      this.setState({
        error,
      });
      return;
    }
    const freeTime = {
      day: parseInt(day),
      hour: parseInt(hour),
      minute: parseInt(minute),
      timezone: zone,
      free: true,
      freeT: true,
    };
    this.props.addTime({ freeTime: [freeTime] }).then(() => {
      // if (this.props.authUrl.url) {
      //   window.location.href = this.props.authUrl.url;
      // }
      if (window.innerWidth < 480) {
        this.props.page(e);
      }
      this.setState({
        day: '',
        hour: '',
        minute: '',
      });
    });
  };

  handleDelete = (e) => {
    this.props.removeTime(e);
  };

  componentDidMount() {
    const zone = moment.tz.guess();
    const zones = moment.tz.names();
    this.setState({
      zones,
      zone,
    });
  }

  handleDiscard = (e) => {
    this.setState({
      day: '',
      hour: '',
      minute: '',
    });
    if (window.innerWidth < 480) {
      this.props.page(e);
    }
  };

  render() {
    const { errors, currentUser, display } = this.props;
    var { zones, zone, error, day, hour, minute } = this.state;
    const dayObj = {
      0: 'Sunday',
      1: 'Monday',
      2: 'Tuesday',
      3: 'Wednesday',
      4: 'Thursday',
      5: 'Friday',
      6: 'Saturday',
    };
    let setValue = (val) => (val > 9 ? '' : '0') + val;
    return (
      <div className={`available ${display === 'avail' ? '' : 'hide'}`}>
        <div className="container">
          <div className="title">Availability</div>
          <div className="subtitle">
            please update your free time so users can book you for sessions
          </div>
          {errors.message && (
            <div className="alert-danger">{errors.message}</div>
          )}
          <form action="">
            <label htmlFor="day">Day</label>
            <select
              name="day"
              id="day"
              value={day}
              onChange={this.handleClick}
              className={`${error.indexOf('day') !== -1 ? 'error' : ''}`}
            >
              <option value="">Ex: Monday</option>
              <option value="1">Monday</option>
              <option value="2">Tuesday</option>
              <option value="3">Wednesday</option>
              <option value="4">Thursday</option>
              <option value="5">Friday</option>
              <option value="6">Saturday</option>
              <option value="0">Sunday</option>
            </select>
            <div className="time">
              <div className="container">
                <label htmlFor="startTime">Start Hour</label>
                <select
                  name="hour"
                  id="startTime"
                  value={hour}
                  onChange={this.handleClick}
                  className={`${error.indexOf('hour') !== -1 ? 'error' : ''}`}
                >
                  <option value="">Ex: 08</option>
                  <option value="1">01</option>
                  <option value="2">02</option>
                  <option value="3">03</option>
                  <option value="4">04</option>
                  <option value="5">05</option>
                  <option value="6">06</option>
                  <option value="8">08</option>
                  <option value="9">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                  <option value="21">21</option>
                  <option value="22">22</option>
                  <option value="23">23</option>
                  <option value="0">00</option>
                </select>
              </div>
              <div className="container">
                <label htmlFor="stopTime">Start Minute</label>
                <select
                  name="minute"
                  id="stopTime"
                  value={minute}
                  onChange={this.handleClick}
                  className={`${error.indexOf('minute') !== -1 ? 'error' : ''}`}
                >
                  <option value="">Ex: 15</option>
                  <option value="0">00</option>
                  <option value="15">15</option>
                  <option value="30">30</option>
                  <option value="45">45</option>
                </select>
              </div>
            </div>
            <label htmlFor="time-zone">Time Zone</label>
            <input
              type="text"
              list="timezone"
              name="zone"
              id="time-zone"
              value={zone}
              placeholder="Ex: Africa/Lagos"
              onChange={this.handleClick}
              className={`${error.indexOf('zone') !== -1 ? 'error' : ''}`}
            />
            <datalist id="timezone">
              {zones.map((e, i) => (
                <option key={i} value={e}>
                  {e}
                </option>
              ))}
            </datalist>
            <div className="links">
              <Link to="#" className="join hom" onClick={this.handleSubmit}>
                Add Slot
              </Link>
              <div className="home hom" onClick={this.handleDiscard}>
                Discard
              </div>
            </div>
          </form>
        </div>
        <div className="slots">
          <div className="title">Time Slots Available</div>
          {currentUser.user.freeTime &&
            currentUser.user.freeTime.map((e, i) => (
              <div className="card" key={i}>
                {dayObj[e.day]} {setValue(e.hour)}:{setValue(e.minute)}{' '}
                {e.timezone}{' '}
                <i
                  className="fas fa-trash-alt"
                  onClick={this.handleDelete.bind(this, e._id)}
                ></i>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { errors: state.errors, authUrl: state.authUrl };
}

export default connect(mapStateToProps, { addTime, removeTime })(Available);
