import { apiCall } from '../../services/api';
import { addError, removeError } from './errors';
import { setCurrentUser } from './auth';
import {
  LOAD_FIELD,
  LOAD_RESULT,
  LOAD_ONE_FIELD,
  LOAD_TEST,
  LOAD_CONGRATULATIONS,
  LOAD_TEXT,
} from '../actionTypes';

export const loadFields = (fields) => ({
  type: LOAD_FIELD,
  fields,
});

export const loadField = (field) => ({
  type: LOAD_ONE_FIELD,
  field,
});

export const loadResults = (results) => ({
  type: LOAD_RESULT,
  results,
});

export const loadTest = (tests) => ({
  type: LOAD_TEST,
  tests,
});

export const loadText = (text) => ({
  type: LOAD_TEXT,
  text,
});

export const loadCongrats = (congrats) => ({
  type: LOAD_CONGRATULATIONS,
  congrats,
});

export const fetchFields = () => {
  return (dispatch) => {
    return apiCall('get', '/api/v1/fields')
      .then(({ data }) => {
        dispatch(loadFields(data));
        dispatch(removeError());
      })
      .catch((err) => {
        dispatch(addError(err));
      });
  };
};

export const fetchField = (name) => {
  return (dispatch) => {
    return apiCall('get', `/api/v1/fields/${name}`)
      .then(({ data }) => {
        dispatch(loadField(data));
        dispatch(removeError());
      })
      .catch((err) => {
        console.log(err);
        dispatch(addError(err));
      });
  };
};

export const fieldTest = (data) => {
  return (dispatch) => {
    return apiCall('post', '/api/v1/fields/test', data)
      .then(({ data }) => {
        dispatch(loadResults(data));
        dispatch(removeError());
      })
      .catch((err) => {
        dispatch(addError(err));
      });
  };
};

export const fetchTest = (name) => {
  return (dispatch) => {
    return apiCall('get', `/api/v1/fields/${name}/test`)
      .then(({ data }) => {
        dispatch(loadTest(data));
        dispatch(removeError());
      })
      .catch((err) => {
        console.log(err);
        dispatch(addError(err));
      });
  };
};

export const scoreTest = (name, data) => {
  return (dispatch) => {
    return apiCall('post', `/api/v1/fields/${name}/test`, data)
      .then(({ data }) => {
        dispatch(setCurrentUser(data));
        dispatch(removeError());
      })
      .catch((err) => {
        dispatch(addError(err));
      });
  };
};

export const fetchIndiTest = (name) => {
  return (dispatch) => {
    return apiCall('get', `/api/v1/fields/${name}/assess`)
      .then(({ data }) => {
        dispatch(loadTest(data));
        dispatch(removeError());
      })
      .catch((err) => {
        console.log(err);
        dispatch(addError(err));
      });
  };
};

export const scoreIndiTest = (name, data) => {
  return (dispatch) => {
    return apiCall('post', `/api/v1/fields/${name}/assess`, data)
      .then(({ data, result, congratulations, text }) => {
        dispatch(setCurrentUser(data));
        dispatch(loadCongrats(congratulations));
        dispatch(loadText(text));
        dispatch(loadResults(result));
        dispatch(removeError());
      })
      .catch((err) => {
        console.log(err);
        dispatch(addError(err));
      });
  };
};
