import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  icon36,
  icon35,
  oneAss24,
  oneAss25,
  oneFrame85,
  oneFrame88,
} from "../Copy/Copy";
import "../../assets/styles/auth.css";

export default class AuthForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      profileImgUrl: "",
      view: false,
      tempErr: "",
      loc: {},
      show: false,
      success: false,
      code: "",
      pin: false,
      extension: "inactive",
      pop: false,
      vis: false,
    };
  }

  componentDidMount() {
    this.props.removeError();
    if (this.props.location.state) {
      this.setState({
        tempErr: this.props.location.state.err,
        loc: this.props.location.state,
      });
    }
    if (window.innerWidth > 1200) {
      if (this.props.location.search.includes("extension=")) {
        this.setState({ pin: true, extension: "exist" });
        localStorage.setItem("trail-extension", "exists");
      }
      if (localStorage.getItem("trail-extension")) {
        this.setState({ pin: true, extension: "exist" });
      }
    }
    const portfolio = localStorage.getItem("portfolio");
    if (portfolio && Date.now() - parseInt(portfolio, 10) < 3600 * 1000) {
      this.setState({ pop: true });
      setTimeout(() => {
        this.setState({ vis: true });
      }, 500);
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      tempErr: "",
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.view === false) {
      this.setState({ view: true });
    }
    const authType = this.props.signUp ? "register" : "login";
    const {
      email,
      password,
      firstName,
      profileImgUrl,
      lastName,
      extension,
      pop,
    } = this.state;
    if (pop) {
      this.props
        .onAuth(
          authType,
          {
            email,
            password,
          },
          pop
        )
        .then(() => {
          this.props.history.push("/");
        })
        .catch(() => {
          this.setState({ view: false });
          return;
        });
      if (pop) {
        setTimeout(() => {
          this.props.history.push("/");
        }, 500);
      }
    } else if (this.props.location.search.includes("token=")) {
      this.props
        .resetPass(this.props.location.search.split("=")[1], { password })
        .then(() => {
          this.props.history.push("/");
        })
        .catch(() => {
          this.setState({ view: false });
          return;
        });
    } else if (localStorage.referral) {
      this.props
        .onAuth(authType, {
          email,
          password,
          firstName,
          lastName,
          profileImgUrl,
          refCode: localStorage.referral,
          extension,
        })
        .then(() => {
          if (this.props.location.state) {
            this.props.history.push(this.props.location.state.from);
          } else if (this.state.loc.state) {
            this.props.history.push(this.state.loc.state.from);
          } else {
            this.props.history.push("/");
          }
        })
        .catch(() => {
          this.setState({ view: false });
          return;
        });
    } else {
      this.props
        .onAuth(authType, {
          email,
          password,
          firstName,
          lastName,
          profileImgUrl,
          refCode:
            this.props.location.search.includes("refCode=") &&
            this.props.location.search.split("=")[1],
          extension,
        })
        .then(() => {
          if (this.props.location.state) {
            this.props.history.push(this.props.location.state.from);
          } else if (this.state.loc.state) {
            this.props.history.push(this.state.loc.state.from);
          } else {
            this.props.history.push("/");
          }
        })
        .catch(() => {
          this.setState({ view: false });
          return;
        });
    }
  };

  handleGoogleAuth = () => {
    this.props
      .googleUrl()
      .then(() => {
        window.location.href = this.props.authUrl.url;
      })
      .catch(() => {
        return;
      });
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  handleShow = () => {
    this.setState({
      show: !this.state.show,
    });
  };

  handleforgot = () => {
    this.props.forgot({ email: this.state.email }).then(() => {
      this.setState({
        success: true,
      });
    });
  };

  handleCancel = () => {
    this.setState({
      success: false,
    });
  };

  render() {
    const {
      email,
      firstName,
      lastName,
      tempErr,
      show,
      success,
      pin,
      pop,
      vis,
    } = this.state;
    const {
      heading,
      buttonText,
      signUp,
      errors,
      history,
      removeError,
      reset,
      ptext,
    } = this.props;

    history.listen(() => {
      removeError();
    });

    return (
      <div>
        <div className="auth-container">
          <div className="auth">
            <div className="header">
              <Link to="/">
                <img
                  src="https://trail-bucket-074269.s3.us-east-2.amazonaws.com/files/Trail+c-01.png"
                  alt="Trail"
                />
                <span>Trail</span>
              </Link>
            </div>
            <div className="title">{heading}</div>
            {ptext && <p className="subheader">{ptext}</p>}
            <div>
              <form action="" onSubmit={this.handleSubmit}>
                {!errors.message ? (
                  ""
                ) : errors.message === "Log in with google" ? (
                  <div className="alert-success">{errors.message}</div>
                ) : (
                  <div className="alert-danger">{errors.message}</div>
                )}
                {!tempErr ? "" : <div className="alert-danger">{tempErr}</div>}
                {signUp && (
                  <div className="nam">
                    <div className="col">
                      <label htmlFor="FirstName">First Name</label>
                      <input
                        className=""
                        id="FirstName"
                        name="firstName"
                        onChange={this.handleChange}
                        value={firstName}
                        type="text"
                        placeholder="Ex: Sam"
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="LastName">Last Name</label>
                      <input
                        className=""
                        id="LastName"
                        name="lastName"
                        onChange={this.handleChange}
                        value={lastName}
                        type="text"
                        placeholder="Ex: Smith"
                      />
                    </div>
                  </div>
                )}
                <label htmlFor="email" className={`${reset ? "hide" : ""}`}>
                  Email <img alt="Trial auth" src={icon36} />{" "}
                </label>
                <input
                  className={`${reset ? "hide" : ""}`}
                  id="email"
                  name="email"
                  onChange={this.handleChange}
                  value={email}
                  type="text"
                  placeholder="Ex: JohnSnow@gmail.com"
                />
                <label htmlFor="password">
                  Password <img alt="Trial auth" className="pas" src={icon35} />
                  <div className="show" onClick={this.handleShow}>
                    {show ? "hide" : "show"} password
                  </div>
                </label>
                <input
                  className={`${reset ? "reset" : ""}`}
                  id="password"
                  name="password"
                  onChange={this.handleChange}
                  type={`${show ? "text" : "password"}`}
                  placeholder="Ex: Mn3@lqw%5a"
                />
                <div className="links">
                  <button
                    type="submit"
                    className={`btn ${this.state.view ? "btn-load" : ""}`}
                  >
                    <span className="btn_text">{buttonText}</span>
                  </button>
                  {/* <div className="google" onClick={this.handleGoogleAuth}>
                    login with google
                  </div> */}
                </div>
              </form>
              <div className="footnotes">
                {!signUp ? (
                  <div className="existing">
                    Don't have an account? <Link to="/signup">Sign up</Link>
                  </div>
                ) : (
                  <div className="existing">
                    Have an account? <Link to="/signin">Sign in</Link>
                  </div>
                )}
                <div className="trouble">
                  Forgot your password?{" "}
                  <span className="forgot" onClick={this.handleforgot}>
                    Click here
                  </span>
                </div>
                <div className={`alert ${success ? "" : "hide"}`}>
                  <p>
                    We have sent an email with a reset link to{" "}
                    {this.state.email}
                  </p>
                  <div className="cancel" onClick={this.handleCancel}>
                    Cancel
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="image">
            <img src={oneFrame85} alt="Trail auth" />
            <img src={signUp ? oneAss25 : oneAss24} alt="Trail auth" />
          </div>
          {pin && (
            <div className="pin-extension">
              <h2>
                To get the best out of one-trail, pin the extension for easy
                access
              </h2>
              <img src={oneFrame88} alt="Trail auth" />
              <div
                className="close"
                onClick={() => this.setState({ pin: false })}
              >
                Close
              </div>
            </div>
          )}
          {pop && (
            <div id="popup1" className={`auto-overlay ${vis ? "vis" : ""}`}>
              <div className="auto-popup">
                <h2>👋 Welcome, Recruiter!</h2>
                <a
                  className="close"
                  href="#"
                  onClick={() => this.setState({ vis: false })}
                >
                  ×
                </a>
                <div className="content">
                  <p>
                    Hello there, valued recruiter! 👋 Thank you for visiting my
                    work.
                  </p>
                  <p>
                    For your convenience, I've introduced <b>Auto Login</b>.
                    Just click "Log In" to effortlessly access this platform.
                    Explore the features built. Note that some of the features
                    are temporarily disabled for enhanced security.
                  </p>
                  <p>Warm Regards,</p>
                  <p>Mubarak Lawal</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
