import React from 'react';

const Result = ({ result, title, page, score, congrats, text, refresh }) => (
  <div className="score">
    <div className="details">
      <div className="content">
        <h1>{title} Assessment Result</h1>
        {text && <div className="text">{text}</div>}
        <p>
          {congrats
            ? 'You have finished your product design pathway and we are so proud of the journey you have gone through! We would send in your certificate'
            : 'Congratulations on the assessment you just took. Your results are displayed below and if you have passed all the assesments for this section. You may move on to the next section. Super pumped about your progress!'}
        </p>
      </div>
    </div>
    <div className="results">
      {result &&
        result.map((e, i) => {
          if (i === result.length - 1) {
            return (
              <div className="info last" key={i}>
                <div className="val">{e.title}</div>
                <div className="text">{e.pass}</div>
              </div>
            );
          } else {
            return (
              <div className="info" key={i}>
                <div className="val">{e.title}</div>
                <div className="text">{e.pass}</div>
              </div>
            );
          }
        })}
    </div>
    <div
      className="btn sect"
      onClick={(e) => {
        page(e);
        score();
        refresh();
      }}
    >
      Proceed
    </div>
  </div>
);

export default Result;
