import React, { Component } from 'react'
import { HashLink } from 'react-router-hash-link'
import { fetchResources } from '../../store/actions/resources'
import { getUser } from '../../store/actions/users'
import { logout } from '../../store/actions/auth'
import { connect } from 'react-redux'
// import ResourceItem from './ResourceItem';
// import ResourceDeets from './ResourceDeets';
// import NoPathway from './NoPathway';
import Available from '../Available/Available'
import Upcoming from '../Upcoming'
import Earning from '../Earning'
import Referral from '../Referral'
import RightNav from '../RightNav'
import Profile from '../Profile'
import Dashnav from './Dashnav'
import { icon29, icon32, icon38, icon39, wicon40, icon08, icon19 } from '../Copy/Copy'
import Section from '../Section'
import Quiz from '../Quiz'
import Dashpath from './Dashpath'
import Dashment from './Dashment'
import Fullpath from '../Fullpath'
import Monthly from '../Monthly'

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      close: true,
      course: '',
      courseId: '',
      menu: false,
      right: false,
      left: false,
      display: 'hom',
      progress: 0,
      interval: '',
      refresh: 0,
    }
  }

  handleClick = (e) => {
    this.setState({ close: !this.state.close })
    this.setState({
      right: false,
    })
  }

  containerClick = () => {
    if (this.state.close === true) {
      this.setState({ close: false })
    }
  }

  componentDidMount() {
    this.props.getUser(this.props.currentUser.user.id, 'user')
  }

  handleCourseClick(e, i) {
    const courseShow = document.querySelector('.dash-container .pathway')
    if (this.state.course === '' || this.state.course !== e) {
      window.scrollTo({
        top: courseShow.scrollHeight + 85,
        left: 0,
        behavior: 'smooth',
      })
    }
    if (this.state.course === '' || this.state.course !== e) {
      this.setState({
        course: e,
      })
      const courseId = this.props.resource.findIndex((el) => el._id === e)
      this.setState({
        courseId: courseId,
      })
    } else {
      this.setState({
        course: '',
      })
      this.setState({
        courseId: '',
      })
    }
  }

  handleMenu = (e) => {
    this.setState({
      menu: true,
    })
  }

  handleMenuCancel = (e) => {
    if (e.target.className.indexOf('ignenu') === -1) {
      this.setState({
        menu: false,
        fals: false,
      })
    }
  }

  handleNav = (e) => {
    this.setState({
      left: !this.state.left,
    })
  }

  rightNav = (e) => {
    if (window.innerWidth < 480) {
      this.setState({
        display: 'edit',
      })
      return
    }
    if (e.target.className.indexOf('acti') !== -1) {
      this.setState({
        right: false,
      })
    } else {
      this.setState({
        right: true,
      })
    }
  }

  handlePages = (e) => {
    const { location, currentUser, removeError } = this.props
    clearInterval(this.state.interval)
    removeError()
    this.setState({
      progress: 0,
      interval: '',
      left: false,
    })
    if (e === 'edit') {
      this.setState({
        display: 'edit',
      })
      return
    } else if (e.target.className.indexOf(`hom`) !== -1) {
      if (location.pathname !== '/pathway') {
        this.props.history.push('/pathway')
      } else {
        this.setState({
          display: 'hom',
          close: false,
        })
      }
    } else if (e.target.className.indexOf(`proff`) !== -1) {
      if (location.pathname !== '/pathway') {
        this.props.history.push('/pathway')
      } else {
        this.setState({
          display: 'proff',
          close: false,
          right: false,
        })
      }
    } else if (e.target.className.indexOf(`account`) !== -1) {
      if (location.pathname !== '/pathway') {
        this.props.history.push('/pathway')
      } else {
        this.setState({
          display: 'account',
          close: false,
        })
      }
    } else if (e.target.className.indexOf(`avail`) !== -1) {
      if (location.pathname !== '/pathway') {
        this.props.history.push('/pathway')
      } else {
        this.setState({
          display: 'avail',
          close: false,
        })
      }
    } else if (e.target.className.indexOf(`his`) !== -1) {
      if (location.pathname !== '/pathway') {
        this.props.history.push('/pathway')
      } else {
        this.setState({
          display: 'his',
          close: false,
        })
      }
    } else if (e.target.className.indexOf(`edit`) !== -1) {
      if (location.pathname !== '/pathway') {
        this.props.history.push('/pathway')
      } else {
        this.setState({
          display: 'edit',
          close: false,
        })
      }
    } else if (e.target.className.indexOf(`ref`) !== -1) {
      if (location.pathname !== '/pathway') {
        this.props.history.push('/pathway')
      } else {
        this.setState({
          display: 'ref',
          close: false,
        })
      }
    } else if (e.target.className.indexOf(`dahmen`) !== -1) {
      if (location.pathname !== '/pathway') {
        this.props.history.push('/pathway')
      } else {
        this.setState({
          display: 'dahmen',
          close: false,
        })
      }
    } else if (e.target.className.indexOf(`dahpah`) !== -1) {
      if (location.pathname !== '/pathway') {
        this.props.history.push('/pathway')
      } else {
        this.setState({
          display: 'dahpah',
          close: false,
        })
        var rest = 0
        if (currentUser.user.section) {
          currentUser.user.section.forEach((e) => {
            rest += e.resource
          })
          var prog = Math.round(
            (currentUser.user.completedResource.length / rest) * 100
          )
          if (!prog) {
            prog = 0
          }
          if (prog > 0) {
            const interval = setInterval(() => {
              if (this.state.progress === prog) {
                clearInterval(interval)
              } else {
                this.setState({ progress: this.state.progress + 1 })
              }
            }, 50)
            this.setState({
              interval,
            })
          }
        }
      }
    } else if (e.target.className.indexOf(`sect`) !== -1) {
      if (location.pathname !== '/pathway') {
        this.props.history.push('/pathway')
      } else {
        this.setState({
          display: 'sect',
          close: false,
        })
      }
    } else if (e.target.className.indexOf(`path`) !== -1) {
      if (location.pathname !== '/pathway') {
        this.props.history.push('/pathway')
      } else {
        this.setState({
          display: 'path',
          close: false,
        })
      }
    } else if (e.target.className.indexOf(`quiz`) !== -1) {
      if (location.pathname !== '/pathway') {
        this.props.history.push('/pathway')
      } else {
        this.setState({
          display: 'quiz',
          close: false,
        })
      }
    }
  }

  handleRefresh = (e) => {
    this.setState({
      refresh: this.state.refresh + 1,
    })
  }

  logout = (e) => {
    e.preventDefault()
    this.props.logout()
  }

  render() {
    const { currentUser } = this.props
    const { menu, right, display, progress } = this.state

    var date = new Date()
    date = date
      .toLocaleDateString('en-us', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
      .split(' ')
    if (currentUser.user.picture) {
      if (currentUser.user.picture.indexOf('google') !== -1) {
        var imgStr = currentUser.user.picture
      } else if (currentUser.user.picture.indexOf('trailbucket12345') !== -1) {
        imgStr =
          'https://dqe80zoqbuyqe.cloudfront.net' +
          currentUser.user.picture.substring(51)
      } else {
        imgStr = ''
      }
    }
    return (
      <div className="dash-hero">
        <Dashnav
          page={this.handlePages}
          logout={this.logout}
          role={currentUser.user.role}
          display={display}
          left={this.state.left}
          nav={this.handleNav}
        />
        <div className="dash-container" onClick={this.containerClick}>
          <div
            className={`top-nav ${this.state.close === false ? '' : 'close'}`}
          >
            <div className="open" onClick={this.handleNav}>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div
              className={`profile ${
                display !== 'hom' &&
                display !== 'ref' &&
                display !== 'dahpah' &&
                display !== 'dahmen' &&
                display !== 'path'
                  ? 'hide'
                  : ''
              }`}
            >
              {imgStr ? (
                <img
                  src={imgStr}
                  className="bg"
                  onClick={this.rightNav}
                  alt="trail user"
                />
              ) : (
                <i className="fas fa-user  bg" onClick={this.rightNav}></i>
              )}
              {imgStr ? (
                <img src={imgStr} className="sm" alt="trail user" />
              ) : (
                <i className="fas fa-user sm"></i>
              )}
            </div>
            <RightNav
              right={right}
              click={this.rightNav}
              role={currentUser.user.role}
              {...this.props}
              page={this.handlePages}
              logout={this.logout}
            />
          </div>
          <div
            className={`home ${display === 'hom' ? '' : 'hide'}`}
            onClick={this.handleMenuCancel}
          >
            <div className="info">
              <p>Hello {currentUser.user.firstName}</p>
              <div className="time">
                <span>{`${date[0]} `}</span>
                {`${date[1]} ${date[2]} ${date[3]}`}
              </div>
            </div>
            {currentUser.user.role === 'mentor' ? (
              <div className="upcoming">
                <p>Ready to pick up from where you left off?</p>
                <div className="session">
                  <Upcoming
                    menu={menu}
                    menuOp={this.handleMenu}
                    user={currentUser.user.id}
                    role={currentUser.user.role}
                    {...this.props}
                    nil
                    refScore={this.state.refresh}
                  />
                  <div className="earn-cover">
                    <Earning
                      one
                      menu
                      user={currentUser.user.id}
                      {...this.props}
                      title="Withdrawable"
                      title2="Earning"
                      btn="Available balance"
                      avail
                      img={icon39}
                      na
                    />
                    <Earning
                      one
                      menu
                      user={currentUser.user.id}
                      {...this.props}
                      title="Total"
                      title2="Earning"
                      btn="Total balance"
                      total
                      img={icon38}
                      na
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="upcoming">
                <p>Ready to pick up from where you left off?</p>
                <div className="session">
                  <Upcoming
                    menu={menu}
                    menuOp={this.handleMenu}
                    user={currentUser.user.id}
                    role={currentUser.user.role}
                    {...this.props}
                    page={this.handlePages}
                  />
                  <div className="indir">
                    <div className="ref">
                      <div className="title">Referrals</div>
                      <div className="icon">
                        <img src={icon08} alt="Referrals" />
                      </div>
                      <div className="desc">
                        Get paid when you refer your network to book a session
                        or get a pathway.
                      </div>
                      <div className="btn ref" onClick={this.handlePages}>
                        View Earnings <i className="fas fa-chevron-right"></i>
                      </div>
                    </div>
                    <div className="ref">
                      <div className="title">Group session</div>
                      <div className="icon">
                        <img src={icon19} alt="Group" />
                      </div>
                      <div className="desc">
                        Book a mentoring session with your friends who have
                        similar interests.
                      </div>
                      <HashLink to="/mentor#mentorlink">
                        <div className="btn">
                          Book a group session{' '}
                          <i className="fas fa-chevron-right"></i>
                        </div>
                      </HashLink>
                    </div>
                  </div>
                  <div className="schedule">
                    <div className="up">
                      <div className="title">Monthly Schedule</div>
                      <Monthly role={currentUser.user.role} {...this.props} />
                    </div>
                    <div className="query">
                      <div className="bulb">
                        <div className="inner">
                          <img src={wicon40} alt="Help Trail" />
                        </div>
                      </div>
                      <div className="content">
                        <div className="title">
                          Have any creative idea that might help boost our
                          marketing? Even if it's just by a tiny bit? Share
                          away.
                        </div>
                        <a
                          href="mailto:marketing@trailng.com?subject=We%20want%20to%20hear%20from%20you!"
                          className="message"
                        >
                          Send us a message
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {currentUser.user.role === 'mentor' && (
              <div className="statis">
                <div className="earn">
                  <div className="title">Availability</div>
                  <Earning
                    user={currentUser.user.id}
                    {...this.props}
                    btn="Update free time"
                    title="Add a"
                    title2="free time"
                    free
                    down
                    menu
                    link
                    swit={this.handlePages}
                    img={icon32}
                  />
                </div>
                <div className="earn">
                  <div className="title">Recent Session</div>
                  <Earning
                    user={currentUser.user.id}
                    {...this.props}
                    title="Previous"
                    title2="session"
                    sesh
                    one
                    ment
                    menu
                    mobile
                    down
                    form
                    img={icon29}
                  />
                </div>
                <div className="graph">
                  <div className="title">Performance</div>
                  <div>
                    <i className="fas fa-chevron-left"></i>
                    <div>
                      <div className="stat">
                        <div className="bars"></div>
                        <div className="bars"></div>
                        <div className="bars"></div>
                        <div className="bars"></div>
                      </div>
                      <div className="bar">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                    <i className="fas fa-chevron-right"></i>
                  </div>
                </div>
              </div>
            )}
          </div>
          {display === 'proff' && (
            <Profile
              title="My profile"
              role={currentUser.user.role}
              {...this.props}
              proff
              page={this.handlePages}
            />
          )}
          {display === 'account' && (
            <Profile
              title="My account"
              role={currentUser.user.role}
              {...this.props}
              page={this.handlePages}
            />
          )}
          <Available
            {...this.props}
            display={display}
            page={this.handlePages}
          />
          <RightNav
            role={currentUser.user.role}
            {...this.props}
            logout={this.logout}
            mobile
            display={display}
            page={this.handlePages}
          />
          <Referral {...this.props} display={display} page={this.handlePages} />
          <Dashpath
            {...this.props}
            progress={progress}
            display={display}
            page={this.handlePages}
            refScore={this.state.refresh}
          />
          <Section
            {...this.props}
            display={display}
            page={this.handlePages}
            refScore={this.state.refresh}
          />
          <Quiz
            {...this.props}
            display={display}
            page={this.handlePages}
            refresh={this.handleRefresh}
          />
          <Dashment
            {...this.props}
            role={currentUser.user.role}
            display={display}
            page={this.handlePages}
            user={currentUser.user.id}
          />
          <Fullpath {...this.props} display={display} page={this.handlePages} />
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    errors: state.errors,
    resource: state.resource,
  }
}

export default connect(mapStateToProps, { fetchResources, getUser, logout })(
  Dashboard
)
