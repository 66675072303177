import moment from 'moment-timezone';

export function timeStruc(time) {
  const date = new Date();
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1;
  const day = date.getUTCDate();
  var userDay = time.day;

  const utcTime = moment().tz(time.timezone);
  const timeDiff = utcTime.format('Z');

  let setValue = (val) => (val > 9 ? '' : '0') + val;

  const tzTime = `${year}-${setValue(month)}-${setValue(day)}T${setValue(
    time.hour
  )}:${setValue(time.minute)}:00${timeDiff}`;
  const utcDate = new Date(tzTime);

  if (
    utcDate.getUTCDay() - date.getUTCDay() === -1 ||
    utcDate.getUTCDay() - date.getUTCDay() === 6
  ) {
    userDay -= 1;
  } else if (
    utcDate.getUTCDay() - date.getUTCDay() === 1 ||
    utcDate.getUTCDay() - date.getUTCDay() === -6
  ) {
    userDay += 1;
  }
  const resetDay = date.getUTCDate() + ((1 + 7 - date.getUTCDay()) % 7 || 7);
  const utcReset = new Date();
  var resetHour = 0;
  timeDiff.split('')[0] === '+'
    ? (resetHour =
        0 - parseInt(`${timeDiff.split('')[1]}${timeDiff.split('')[2]}`))
    : (resetHour =
        0 + parseInt(`${timeDiff.split('')[1]}${timeDiff.split('')[2]}`));
  utcReset.setUTCDate(resetDay);
  utcReset.setUTCHours(resetHour, 0, 0);

  const meetingDay = date.getUTCDate() + ((userDay + 7 - date.getUTCDay()) % 7);
  utcDate.setUTCDate(meetingDay);
  if (month - (utcDate.getUTCMonth() + 1) === 1) {
    utcDate.setUTCMonth(utcDate.getUTCMonth() + 1);
  }

  if (parseInt(year) - parseInt(utcDate.getUTCFullYear()) === 1) {
    utcDate.setUTCFullYear(year);
  }

  if (time.second && utcDate - utcReset < 0) {
    utcDate.setUTCDate(meetingDay + 7);
  }

  if (!time.second && (date - utcDate > 0 || utcDate - utcReset > 0)) {
    let err;
    return err;
  }

  if (!time.second && utcDate.getUTCDay() - date.getUTCDay() === 0) {
    var today = true;
  }

  if (
    !time.second &&
    (utcDate.getUTCDay() - date.getUTCDay() === 1 ||
      utcDate.getUTCDay() - date.getUTCDay() === -6)
  ) {
    var tomorrow = true;
  }

  return { date: utcDate, id: time._id, today, tomorrow, second: time.second };
}

export function dateStruc(date, id, today, tomorrow, second) {
  const dayT = new Date(date.split('T')[0]);
  var day = new Date(date.split('T')[0]);
  const dayNo = day.getUTCDay();
  const dayObj = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
  };
  day = dayObj[dayNo];
  const monthObj = {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December',
  };
  const monthNo = dayT.getUTCMonth();
  const year = dayT.getUTCFullYear();
  var month = monthObj[monthNo];
  var dateNo = dayT.getUTCDate();
  return {
    day,
    month,
    dateNo,
    hour: date.split('T')[1].split(':')[0],
    minute: date.split('T')[1].split(':')[1],
    id,
    year,
    today,
    tomorrow,
    second,
  };
}
