import {
  LOAD_MENTOR,
  LOAD_MENTOR_MEETINGS,
  LOAD_MENTEE_MEETINGS,
} from '../actionTypes';

const DEFAULT_STATE = { mentor: null, mentorMeetings: [], menteeMeetings: [] };

const meeting = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case LOAD_MENTOR:
      return { mentor: action.mentor };
    case LOAD_MENTOR_MEETINGS:
      return { mentorMeetings: action.mentorMeetings };
    case LOAD_MENTEE_MEETINGS:
      return { menteeMeetings: action.menteeMeetings };
    default:
      return state;
  }
};

export default meeting;
