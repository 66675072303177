import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUser } from '../store/actions/users';
import Byte from './Byte/Byte';

class Fullpath extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (!this.props.currentUser.user.role) {
      this.props.getUser(this.props.currentUser.user.id, 'user');
    }
  }

  render() {
    const { currentUser, display } = this.props;
    var date = new Date();
    date = date.toLocaleDateString('en-us', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    if (currentUser.user.section) {
      var courseList = currentUser.user.section.map((e, i) => {
        let setValue = (val) => (val > 9 ? '' : '0') + val;
        const photo = `https://trailbucket12345.s3.us-east-2.amazonaws.com/content/Content+Images-${setValue(
          i + 1
        )}.png`;
        return (
          <Byte
            key={i}
            title={e.title}
            duration={e.duration}
            alt
            photo={photo}
          />
        );
      });
    }

    return (
      <div className={`fullpath ${display === 'path' ? '' : 'hide'}`}>
        <div className="info">
          <h1>My Product Design Pathway</h1>
          <div className="time">{date}</div>
        </div>
        <ul>{courseList}</ul>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { errors: state.errors };
}

export default connect(mapStateToProps, { getUser })(Fullpath);
