import React, { Component } from "react";
import NewDashboard from "./NewDashboard";
import { configureStore } from "../store";
import { setAuthorizationToken, setCurrentUser } from "../store/actions/auth";
import jwtDecode from "jwt-decode";
import EnterpriseLanding from "./Enterprise/EnterpriseLandingPage";

class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (!this.props.currentUser.isAuthenticated) {
      const query = this.props.location.search;
      if (query.includes("code=")) {
        this.props
          .onAuth(query)
          .then(() => {})
          .catch(() => {
            this.props.history.push("/signin");
            return;
          });
      } else if (query.includes("refCode=")) {
        localStorage.setItem(
          "referral",
          this.props.location.search.split("=")[1]
        );
      } else if (query.includes("token=")) {
        const store = configureStore();
        const token = this.props.location.search.split("=")[1];
        try {
          const decode = jwtDecode(token);
          console.log(decode);
          localStorage.setItem("jwtToken", token);
          setAuthorizationToken(token);
          if (decode.exp * 1000 < Date.now()) {
            localStorage.clear();
            setAuthorizationToken(false);
            store.dispatch(setCurrentUser({}));
            window.location.href = "/signin";
          } else {
            store.dispatch(setCurrentUser({ ...jwtDecode(token) }));
            window.location.href = "/";
          }
        } catch (e) {
          store.dispatch(setCurrentUser({}));
        }
      }
    }
  }

  render() {
    const { currentUser } = this.props;
    if (!currentUser.isAuthenticated) {
      return <EnterpriseLanding {...this.props} />;
    }
    return (
      <div>
        <NewDashboard currentUser={currentUser} {...this.props} />
      </div>
    );
  }
}

export default Homepage;
