import React, { Component } from 'react';

class File extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    var { e, change, id } = this.props;

    return (
      <div className="container">
        <div className="intro">
          <span>{id + 1}.</span>
          <p>{e.question}</p>
        </div>
        <div className="input">
          <input type="file" onChange={change} name={e.question} />
          <div className="file">
            Drag your file here or click to upload. File size limit 5mb
          </div>
        </div>
      </div>
    );
  }
}

export default File;
