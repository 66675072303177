import React, { Component } from 'react';
import { connect } from 'react-redux';
import Mcq from './Mcq';
import Text from './Text';
import File from './File';
import Result from './Result';
import { fetchIndiTest, scoreIndiTest } from '../store/actions/field';
import { getUser } from '../store/actions/users';

class Quiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assessment: [],
      view: false,
      id: '',
      score: false,
      load: false,
      title: '',
      data: false,
    };
  }

  componentDidMount() {
    this.props.getUser(this.props.currentUser.user.id, 'user').then((user) => {
      var section = user.section.find((e) => e.done === 'pending');
      if (section) {
        this.props.fetchIndiTest(section.field).then(() => {
          if (this.props.test) {
            var assessment = this.props.test.map((e) => {
              if (e.type === 'check') {
                return { question: e.question, answers: [] };
              } else {
                return { question: e.question, answer: '' };
              }
            });
            this.setState({
              assessment,
              id: section.field,
              title: section.title,
              load: true,
            });
          }
        });
      }
    });
  }

  handleFile = (e) => {
    var { data } = this.state;
    const el = e.target.parentNode.childNodes[1];
    const filename = e.target.value.split('\\').pop();
    el.innerText = filename;
    if (data) {
      data.append('assignments', e.target.files[0], e.target.name);
      this.setState({
        data,
      });
    } else {
      var formData = new FormData();
      formData.append('assignments', e.target.files[0], e.target.name);
      this.setState({
        data: formData,
      });
    }
  };

  handleChange = (e) => {
    var { assessment } = this.state;

    var i = assessment.findIndex((el) => el.question === e.target.name);
    if (e.target.type === 'checkbox') {
      if (i === -1) {
        var arr = [];
      } else {
        arr = assessment[i].answers;
        assessment.splice(i, 1);
      }

      var id = arr.findIndex((el) => el === e.target.value);
      if (id !== -1) {
        arr.splice(id, 1);
      } else {
        arr.push(e.target.value);
      }
      assessment.push({
        question: e.target.name,
        answers: arr,
      });
    } else {
      if (i !== -1) {
        assessment.splice(i, 1);
      }
      assessment.push({
        question: e.target.name,
        answer: e.target.value,
      });
    }

    this.setState({
      assessment,
    });
  };

  handleSubmit = (e) => {
    const { id, assessment, data } = this.state;
    this.setState({ view: true });
    if (data) {
      data.append('assessment', JSON.stringify(assessment));
      this.props.scoreIndiTest(id, data).then(() => {
        if (this.props.errors.message) {
          this.setState({
            view: false,
          });
        } else {
          this.setState({
            view: false,
            score: true,
          });
        }
      });
    } else {
      this.props.scoreIndiTest(id, { assessment }).then(() => {
        if (this.props.errors.message) {
          this.setState({
            view: false,
          });
        } else {
          this.setState({
            view: false,
            score: true,
          });
        }
      });
    }
  };

  handleScore = (e) => {
    this.setState({
      score: false,
      load: false,
    });
    var section = this.props.currentUser.user.section.find(
      (e) => e.done === 'pending'
    );
    if (section) {
      this.props.fetchIndiTest(section.field).then(() => {
        if (this.props.test) {
          var assessment = this.props.test.map((e) => {
            if (e.type === 'check') {
              return { question: e.question, answers: [] };
            } else {
              return { question: e.question, answer: '' };
            }
          });
          this.setState({
            assessment,
            id: section.field,
            title: section.title,
            load: true,
          });
        }
      });
    }
  };

  render() {
    const { display, page, test, result, errors, congrats, text } = this.props;
    const { view, score, title, load } = this.state;
    if (test) {
      var Questions = test.map((e, i) => {
        if (e.type === 'obj') {
          return <Mcq key={i} e={e} id={i} change={this.handleChange} />;
        } else if (e.type === 'text' || e.type === 'link') {
          return (
            <Text
              key={i}
              e={e}
              id={i}
              change={this.handleChange}
              inc
              img={e.img}
            />
          );
        } else if (e.type === 'check') {
          return <Mcq key={i} e={e} id={i} change={this.handleChange} check />;
        } else if (e.type === 'file') {
          return <File key={i} e={e} id={i} change={this.handleFile} check />;
        }
        return undefined;
      });
    }
    return (
      <div className={`quiz og ${display === 'quiz' ? '' : 'hide'}`}>
        {score ? (
          <Result
            result={result}
            title={title}
            page={page}
            congrats={congrats}
            text={text}
            score={this.handleScore}
            refresh={this.props.refresh}
          />
        ) : (
          <>
            <div className="title">{title} Assessment</div>
            {/* <p>
              Please Wait while we prepare your assesment we would reach out to
              you in less than 48 hours
            </p> */}
            {!load ? (
              <>
                <div className="container mcq">
                  <div className="intro">
                    <span>1.</span>
                    <p className="load flicker"></p>
                  </div>
                  <div className="input">
                    <p className="form-control load flicker"></p>
                    <p className="form-control load flicker"></p>
                    <p className="form-control load flicker"></p>
                    <p className="form-control load flicker"></p>
                  </div>
                </div>
                <div className="container mcq">
                  <div className="intro">
                    <span>2.</span>
                    <p className="load flicker"></p>
                  </div>
                  <div className="input">
                    <p className="form-control load flicker"></p>
                    <p className="form-control load flicker"></p>
                    <p className="form-control load flicker"></p>
                    <p className="form-control load flicker"></p>
                  </div>
                </div>
                <div className="container mcq">
                  <div className="intro">
                    <span>3.</span>
                    <p className="load flicker"></p>
                  </div>
                  <div className="input">
                    <p className="form-control load flicker"></p>
                    <p className="form-control load flicker"></p>
                    <p className="form-control load flicker"></p>
                    <p className="form-control load flicker"></p>
                  </div>
                </div>
              </>
            ) : (
              Questions
            )}
            {errors.message && <div className="error">{errors.message}</div>}
            {load && (
              <div
                className={`btn ${view === false ? '' : 'hide'}`}
                onClick={this.handleSubmit}
              >
                Submit
              </div>
            )}
            <div className="box">
              <div className={`loader ${view === false ? 'hide' : ''}`}>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    errors: state.errors,
    test: state.field.test,
    result: state.field.result,
    congrats: state.field.congrats,
    text: state.field.text,
  };
}

export default connect(mapStateToProps, {
  fetchIndiTest,
  getUser,
  scoreIndiTest,
})(Quiz);
