export const jwtS =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxZTM0Yjc2NzZiNDY1NDBlY2FkN2IwOCIsImZpcnN0TmFtZSI6Ik11YmFyYWsiLCJwaWN0dXJlIjoiaHR0cHM6Ly90cmFpbGJ1Y2tldDEyMzQ1LnMzLnVzLWVhc3QtMi5hbWF6b25hd3MuY29tL2F2YXRhci8zZDVjNDQyYi0wOWJjLTQ4YWItOTk0Yi1iNThkZmM5OTQ5NGZIdWl0YW4tVmlraW5nLU5vcmRpYy1NeXRob2xvZ3ktR2lhbnQtV29sZi1NZW4tUmluZy1EZWZlbnNlLVRvdGVtLVdvbGYtRmFzaGlvbi1IaXAtSG9wLVJvY2stVW5pc2V4LmpwZ19ROTAuanBnXy53ZWJwIiwicm9sZSI6ImJ1c2luZXNzIiwiaWF0IjoxNzA0Mzc4OTY0LCJleHAiOjE3MDY5NzA5NjR9.L2fGflGhoDRFszfiPqvCAss-vNDNORZk-zjSyO4NOxg";

export const userS = {
  role: "business",
  extension: "inactive",
  mentorHours: 0,
  mentorBalance: 800000,
  totalBalance: 800000,
  picture:
    "https://trailbucket12345.s3.us-east-2.amazonaws.com/avatar/3d5c442b-09bc-48ab-994b-b58dfc99494fHuitan-Viking-Nordic-Mythology-Giant-Wolf-Men-Ring-Defense-Totem-Wolf-Fashion-Hip-Hop-Rock-Unisex.jpg_Q90.jpg_.webp",
  backPicture: "no-user.jpg",
  mentorField: [],
  mentorInterest: [],
  completedResource: ["61268dac00e84b3d38405d9f", "61268db900e84b3d38405da2"],
  currentResource: ["61f8165ec5e8fb01e841b9f7", "61268db900e84b3d38405da2"],
  clarity: [
    "Talk to me man.",
    "Mo need epp",
    "epp my laife",
    "epp me",
    "epppp",
    "blah blah",
    "wew",
    "bmans",
    "nsh hsh ",
    "blah blah",
    "bkah bkah",
    "nah last",
    "I don't ",
    "hjashd",
    "mnm nb n",
    "mnm nb n",
    "jkkjjnj ",
    "hey hhad",
    "sds dsnj",
    "hgjhgj",
    "kjhkjh",
  ],
  pathwayReferral: 0,
  mentorReferral: 0,
  referred: 1,
  freeMentor: true,
  mentorPrice: 200000,
  _id: "61e34b7676b46540ecad7b08",
  firstName: "Mubarak",
  lastName: "Lawal",
  email: "mubylaww@gmail.com",
  pathwayPayment: [],
  authorization: [],
  subscription: [],
  freeTime: [
    {
      free: true,
      freeT: true,
      _id: "61e6cfabd12a4710ec6744d8",
      day: 0,
      hour: 14,
      minute: 0,
      timezone: "Africa/Lagos",
    },
    {
      free: true,
      freeT: true,
      _id: "61e6cfabd12a4710ec6744d9",
      day: 0,
      hour: 16,
      minute: 0,
      timezone: "Africa/Lagos",
    },
    {
      free: true,
      freeT: true,
      _id: "61e6cfabd12a4710ec6744da",
      day: 6,
      hour: 14,
      minute: 0,
      timezone: "Africa/Lagos",
    },
  ],
  employer: [],
  university: [],
  goal: [],
  test: [
    {
      values: [
        "Security",
        "Objectivity",
        "Authenticity",
        "Team work",
        "Consistency",
      ],
      skill: [],
      trait: [
        {
          question:
            "You’d rather use a method you’re sure about than try a new method.",
          score: 5,
        },
        {
          question: "You tend to trust conclusions that algorithms provide.",
          score: 5,
        },
        {
          question:
            "Failures are easy to forget and successes are hard to remember",
          score: 5,
        },
        {
          question: "You follow your set-schedules to the latter.",
          score: 5,
        },
        {
          question:
            "The end goal and bigger picture appeals to you more than the journey and the little details",
          score: 4,
        },
        {
          question:
            "You would step up to answer a question in a group of a thousand people",
          score: 5,
        },
        {
          question:
            "You prefer what you've done to be very fine even if you might have to sacrifice a little functionality.",
          score: 5,
        },
        {
          question: "Taking action before being instructed saves time",
          score: 5,
        },
        {
          question:
            "Failures are easy to forget and successes are hard to remember.",
          score: 5,
        },
        {
          question: "You trust your instincts often times than not",
          score: 5,
        },
        {
          question: "You can attend a lecture by someone that is openly racist",
          score: 5,
        },
        {
          question: "You love routines",
          score: 5,
        },
        {
          question: "You often get what you want from people.",
          score: 5,
        },
        {
          question: "What word was underlined in the question before the last?",
          score: 0,
        },
        {
          question: "You love Multitasking.",
          score: 5,
        },
        {
          question:
            "A simple write-up is sufficient to explain an idea effectively.",
          score: 5,
        },
        {
          question:
            "You prefer to try all you can on a problem, before asking for help",
          score: 5,
        },
        {
          question:
            "Brainstorming a new idea is better than reforming an old idea",
          score: 5,
        },
      ],
    },
    {
      values: [
        "Enthusiasm",
        "Innovation",
        "Leadership",
        "Objectivity",
        "Optimism",
      ],
      skill: [
        {
          name: "Git",
          level: 7,
        },
        {
          name: "Google sheets",
          level: 2,
        },
        {
          name: "LibreOffice Calc",
          level: 2,
        },
        {
          name: "HTML/CSS",
          level: 10,
        },
        {
          name: "Powerpoint",
          level: 4,
        },
        {
          name: "MongoDB",
          level: 4,
        },
        {
          name: "Google Docs",
          level: 10,
        },
        {
          name: "WPS Spreadsheets",
          level: 10,
        },
        {
          name: "Microsoft Excel",
          level: 2,
        },
        {
          name: "Google Slides",
          level: 4,
        },
        {
          name: "JavaScript",
          level: 7,
        },
        {
          name: "Microsoft Word",
          level: 10,
        },
      ],
      trait: [
        {
          question: "You tend to trust conclusions that algorithms provide.",
          score: 5,
        },
        {
          question: "You love routines",
          score: 2,
        },
        {
          question:
            "If a task seems above your skillset, would you abandon it?",
          score: 1,
        },
        {
          question:
            "You prefer what you've done to be very fine even if you might have to sacrifice a little functionality.",
          score: 2,
        },
        {
          question: "You trust your instincts often times than not",
          score: 4,
        },
        {
          question:
            "The end goal and bigger picture appeals to you more than the journey and the little details",
          score: 5,
        },
        {
          question: "You follow your set-schedules to the latter.",
          score: 1,
        },
        {
          question: "You love Multitasking.",
          score: 2,
        },
        {
          question:
            "A simple write-up is sufficient to explain an idea effectively.",
          score: 5,
        },
        {
          question:
            "You’d rather use a method you’re sure about than try a new method.",
          score: 4,
        },
        {
          question:
            "Brainstorming a new idea is better than reforming an old idea",
          score: 5,
        },
        {
          question:
            "Failures are easy to forget and successes are hard to remember.",
          score: 2,
        },
        {
          question:
            "You prefer to try all you can on a problem, before asking for help",
          score: 5,
        },
        {
          question: "What word was underlined in the question before the last?",
          score: 5,
        },
        {
          question: "You often get what you want from people.",
          score: 2,
        },
        {
          question: "You can attend a lecture by someone that is openly racist",
          score: 5,
        },
        {
          question:
            "You would step up to answer a question in a group of a thousand people",
          score: 5,
        },
        {
          question: "Taking action before being instructed saves time",
          score: 2,
        },
      ],
    },
  ],
  createdAt: "2022-01-15T22:32:22.750Z",
  username: "Mubarak",
  __v: 0,
  resetPasswordExpire: "2022-01-17T15:20:40.735Z",
  resetPasswordToken:
    "ed99d64cf516f044554dd07a338deb3024b3dd6e0ec91242422ad9b10671df0f",
  accountName: "Raymond Chen",
  accountNumber: "0716890588",
  bankCode: "044",
  bankName: "Access Bank",
  transfer: {
    bankName: "Access Bank",
    accountNumber: "0716890588",
    code: "RCP_mhk1x3t9dzl5oib",
  },
  code: "mub-law-61e",
  bio: "I am the king over the strongest and most ruthless of men",
  tag: "Tag",
  fieldPayment: [
    {
      subscriptionNo: 0,
      field: "61a29261205c2f12cc118dcb",
      fullPayment: true,
    },
    {
      subscriptionNo: 0,
      field: "61a29261205c2f12cc118dcb",
      fullPayment: true,
    },
  ],
  assessment: [
    {
      options: [
        "Improve the pleasantness of a digital product",
        "Improve the marketability of a product",
        "Improve the accessibility of a product",
        "Improve the usability of a product",
      ],
      img: [],
      question:
        "The goal of User Experience Design is to achieve which of the following?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [],
      img: [],
      question:
        "If you’re designing a new app to help working parents and guardians, your team might start the brainstorming stage by listing common problems that working parents and guardians face, like a lack of reliable childcare, transportation concerns, or trouble managing schedules. Your team might review user feedback about other similar products or the results of user surveys to help guide your ideas. After you’ve brainstormed lots of user problems, your team chooses one. What stage of the development cycle will your team move to?",
      answer: "Design",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "The product development life cycle",
    },
    {
      options: [],
      img: [],
      question:
        "As you get further along in your career, you can choose to specialize in a certain area of UX design or keep your skillset more broad. Assuming you want to keep your knowledge broad, which type of designer will you be?",
      answer: "Generalist",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [],
      img: [],
      question:
        "Fill in the blank: Hunter works at a startup as a user experience designer. Since Hunter is one of the only designers, she gets to oversee the majority of her UX processes. Throughout her work, Hunter discovers that she enjoys talking to users and testing designs more than actually designing things. For her next role, Hunter should consider becoming a _____.",
      answer: "UX Researcher",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "A UX design career journey",
    },
    {
      options: [
        "Wireframing",
        "User research",
        "Writing design specifications",
        "Prototyping",
      ],
      img: [],
      question:
        "As an entry-level UX designer, you’ll have a lot of exciting opportunities to gain experience. When you first start out, you’ll probably take on a lot of different roles and responsibilities. This includes all of these but one",
      answer: "Writing design specifications",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [
        "Are there aspects of the design that consider the user’s feelings?",
        "Does the design help the user achieve a specific goal?",
        "Does the design inspire delight in the user?",
        "Does the design keep the user engaged throughout their experience?",
      ],
      img: [],
      question:
        "As you evaluate how enjoyable a product’s UX is, you can ask questions like: Identify the wrong statement",
      answer:
        "Are there aspects of the design that consider the user’s feelings?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [
        "Demonstrate an understanding of needs and goals for both the business and users",
        "Support mid and senior-level UX professionals with their projects",
        "Help execute processes and create artifacts for information architecture (card sorting, sitemaps, flow diagrams, etc.)",
        "Assist with designing concepts, wireframes, and prototypes",
      ],
      img: [],
      question:
        "Which of these is/are job responsibilities of an entry-level UX designer?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: ["this is a false statement", "this is a true statement"],
      img: [],
      question:
        "There is no universal definition of the product development cycle. Businesses disagree about how many stages the cycle includes. Even those who agree on the number of steps disagree about where one ends and next starts.",
      answer: "this is a true statement",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "The product development life cycle",
    },
    {
      options: [],
      img: [],
      question:
        "_____means providing people with the tools they need to accomplish their goals and support improved quality of life.",
      answer: "Equity",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "Characteristics of a good user experience",
    },
    {
      options: ["Generalist", "T-shaped", "Specialist", "Manager"],
      img: [],
      question:
        "Duduke is a newly employed designer who does know how to empathize and take feedback from users very well but knows little about interaction design and other aspects of designs. Which role best describes Trail's new designer?",
      answer: "T-shaped",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [
        "work closely with a supervisor to learn more about a job or industry",
        "market their services to businesses to find customers",
        "receive on-the-job training from an experienced professional",
        "find short-term jobs with limited responsibility",
      ],
      img: [],
      question: "Designers that work as freelancers can expect to _____.",
      answer: "find short-term jobs with limited responsibility",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "A UX design career journey",
    },
    {
      options: [
        "Interface design",
        "Human Computer Interaction",
        "Fielding",
        "Information architecture",
      ],
      img: [],
      question:
        "User Experience Design (UXD or UED) is a design process whose sole objective is to design a system that offers a great experience to its users. Thus UXD embraces the theories of a number of disciplines. Which of these is not a field related to UXD?",
      answer: "Fielding",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [
        "Expanding your skills in many different types of UX work.",
        "Becoming well-known in the industry for your expertise in a particular type of design.",
        "Trying a variety of responsibilities and finding an area of UX that you’re especially passionate about.",
        "Focusing on one type of design that you enjoy more than others.",
      ],
      img: [],
      question:
        "A generalist has a broad number of responsibilities. A majority of UX design jobs are generalist positions, especially at companies with fewer UX designers. Typically, entry-level UX designers work in generalist roles, and some people choose to stay in generalist design roles for their entire careers. There are a lot of benefits to being a generalist UX designer, like:",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [
        "Working at a design studio",
        "Working for a city government",
        "Working for a large company",
        "Working at a small startup",
      ],
      img: [],
      question:
        "John is starting a career in UX and wants to work in a company with room for growth. John prefers a fast-paced, unstructured work environment. Which job is a good fit for John?",
      answer: "Working at a small startup",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: ["Usable", "Unique", "Cool", "Useful"],
      img: [],
      question:
        "Good design is easy to spot but often hard to pin down. What exactly makes a product effective to its users? Which of these characteristics can help you evaluate a product’s design?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Characteristics of a good user experience",
    },
    {
      options: ["1,2,3,4,5", "3,2,5,1,4", "3,5,1,2,4", "2,3,5,1,4"],
      img: [],
      question:
        "Every new product, whether it’s an app or a physical object, follows a specific set of steps that take it from the first spark of an idea to the release of the final product. This is called the product development life cycle, and it has five stages:, 1. (test) 2. (define), 3. ( brainstorm), 4. (Launch) and 5. (Design). Which of the following has the right arrangement;",
      answer: "3,2,5,1,4",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "The product development life cycle",
    },
    {
      options: [
        "Interface design",
        "Human Computer Interaction",
        "Fielding",
        "Information architecture",
      ],
      img: [],
      question:
        "User Experience Design (UXD or UED) is a design process whose sole objective is to design a system that offers a great experience to its users. Thus UXD embraces the theories of a number of disciplines. Which of these is not a field related to UXD?",
      answer: "Fielding",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [],
      img: [],
      question:
        "Fill in the blank: Hunter works at a startup as a user experience designer. Since Hunter is one of the only designers, she gets to oversee the majority of her UX processes. Throughout her work, Hunter discovers that she enjoys talking to users and testing designs more than actually designing things. For her next role, Hunter should consider becoming a _____.",
      answer: "UX Researcher",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "A UX design career journey",
    },
    {
      options: [
        "Interface design",
        "Human Computer Interaction",
        "Fielding",
        "Information architecture",
      ],
      img: [],
      question:
        "User Experience Design (UXD or UED) is a design process whose sole objective is to design a system that offers a great experience to its users. Thus UXD embraces the theories of a number of disciplines. Which of these is not a field related to UXD?",
      answer: "Fielding",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Foundations of User Experience Design",
    },
    {
      options: ["Generalist", "T-shaped", "Specialist", "Manager"],
      img: [],
      question:
        "Duduke is a newly employed designer who does know how to empathize and take feedback from users very well but knows little about interaction design and other aspects of designs. Which role best describes Trail's new designer?",
      answer: "T-shaped",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [
        "Wireframing",
        "User research",
        "Writing design specifications",
        "Prototyping",
      ],
      img: [],
      question:
        "As an entry-level UX designer, you’ll have a lot of exciting opportunities to gain experience. When you first start out, you’ll probably take on a lot of different roles and responsibilities. This includes all of these but one",
      answer: "Writing design specifications",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [],
      img: [],
      question:
        "Fill in the blank: Hunter works at a startup as a user experience designer. Since Hunter is one of the only designers, she gets to oversee the majority of her UX processes. Throughout her work, Hunter discovers that she enjoys talking to users and testing designs more than actually designing things. For her next role, Hunter should consider becoming a _____.",
      answer: "UX Researcher",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "A UX design career journey",
    },
    {
      options: [
        "Are there aspects of the design that consider the user’s feelings?",
        "Does the design help the user achieve a specific goal?",
        "Does the design inspire delight in the user?",
        "Does the design keep the user engaged throughout their experience?",
      ],
      img: [],
      question:
        "As you evaluate how enjoyable a product’s UX is, you can ask questions like: Identify the wrong statement",
      answer:
        "Are there aspects of the design that consider the user’s feelings?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Characteristics of a good user experience",
    },
    {
      options: ["1,2,3,4,5", "3,2,5,1,4", "3,5,1,2,4", "2,3,5,1,4"],
      img: [],
      question:
        "Every new product, whether it’s an app or a physical object, follows a specific set of steps that take it from the first spark of an idea to the release of the final product. This is called the product development life cycle, and it has five stages:, 1. (test) 2. (define), 3. ( brainstorm), 4. (Launch) and 5. (Design). Which of the following has the right arrangement;",
      answer: "3,2,5,1,4",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "The product development life cycle",
    },
    {
      options: [],
      img: [],
      question:
        "_____means providing people with the tools they need to accomplish their goals and support improved quality of life.",
      answer: "Equity",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [
        "work closely with a supervisor to learn more about a job or industry",
        "market their services to businesses to find customers",
        "receive on-the-job training from an experienced professional",
        "find short-term jobs with limited responsibility",
      ],
      img: [],
      question: "Designers that work as freelancers can expect to _____.",
      answer: "find short-term jobs with limited responsibility",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "A UX design career journey",
    },
    {
      options: [
        "Interface design",
        "Human Computer Interaction",
        "Fielding",
        "Information architecture",
      ],
      img: [],
      question:
        "User Experience Design (UXD or UED) is a design process whose sole objective is to design a system that offers a great experience to its users. Thus UXD embraces the theories of a number of disciplines. Which of these is not a field related to UXD?",
      answer: "Fielding",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Foundations of User Experience Design",
    },
    {
      options: ["Generalist", "T-shaped", "Specialist", "Manager"],
      img: [],
      question:
        "Duduke is a newly employed designer who does know how to empathize and take feedback from users very well but knows little about interaction design and other aspects of designs. Which role best describes Trail's new designer?",
      answer: "T-shaped",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [
        "Working at a design studio",
        "Working for a city government",
        "Working for a large company",
        "Working at a small startup",
      ],
      img: [],
      question:
        "John is starting a career in UX and wants to work in a company with room for growth. John prefers a fast-paced, unstructured work environment. Which job is a good fit for John?",
      answer: "Working at a small startup",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [],
      img: [],
      question:
        "If you’re designing a new app to help working parents and guardians, your team might start the brainstorming stage by listing common problems that working parents and guardians face, like a lack of reliable childcare, transportation concerns, or trouble managing schedules. Your team might review user feedback about other similar products or the results of user surveys to help guide your ideas. After you’ve brainstormed lots of user problems, your team chooses one. What stage of the development cycle will your team move to?",
      answer: "Design",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "The product development life cycle",
    },
    {
      options: [
        "work closely with a supervisor to learn more about a job or industry",
        "market their services to businesses to find customers",
        "receive on-the-job training from an experienced professional",
        "find short-term jobs with limited responsibility",
      ],
      img: [],
      question: "Designers that work as freelancers can expect to _____.",
      answer: "find short-term jobs with limited responsibility",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "A UX design career journey",
    },
    {
      options: ["1,2,3,4,5", "3,2,5,1,4", "3,5,1,2,4", "2,3,5,1,4"],
      img: [],
      question:
        "Every new product, whether it’s an app or a physical object, follows a specific set of steps that take it from the first spark of an idea to the release of the final product. This is called the product development life cycle, and it has five stages:, 1. (test) 2. (define), 3. ( brainstorm), 4. (Launch) and 5. (Design). Which of the following has the right arrangement;",
      answer: "3,2,5,1,4",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "The product development life cycle",
    },
    {
      options: [
        "Demonstrate an understanding of needs and goals for both the business and users",
        "Support mid and senior-level UX professionals with their projects",
        "Help execute processes and create artifacts for information architecture (card sorting, sitemaps, flow diagrams, etc.)",
        "Assist with designing concepts, wireframes, and prototypes",
      ],
      img: [],
      question:
        "Which of these is/are job responsibilities of an entry-level UX designer?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [],
      img: [],
      question:
        "As you get further along in your career, you can choose to specialize in a certain area of UX design or keep your skillset more broad. Assuming you want to keep your knowledge broad, which type of designer will you be?",
      answer: "Generalist",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [
        "Improve the pleasantness of a digital product",
        "Improve the marketability of a product",
        "Improve the accessibility of a product",
        "Improve the usability of a product",
      ],
      img: [],
      question:
        "The goal of User Experience Design is to achieve which of the following?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Foundations of User Experience Design",
    },
    {
      options: ["Usable", "Unique", "Cool", "Useful"],
      img: [],
      question:
        "Good design is easy to spot but often hard to pin down. What exactly makes a product effective to its users? Which of these characteristics can help you evaluate a product’s design?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [
        "Are there aspects of the design that consider the user’s feelings?",
        "Does the design help the user achieve a specific goal?",
        "Does the design inspire delight in the user?",
        "Does the design keep the user engaged throughout their experience?",
      ],
      img: [],
      question:
        "As you evaluate how enjoyable a product’s UX is, you can ask questions like: Identify the wrong statement",
      answer:
        "Are there aspects of the design that consider the user’s feelings?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [],
      img: [],
      question:
        "As you get further along in your career, you can choose to specialize in a certain area of UX design or keep your skillset more broad. Assuming you want to keep your knowledge broad, which type of designer will you be?",
      answer: "Generalist",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [
        "Wireframing",
        "User research",
        "Writing design specifications",
        "Prototyping",
      ],
      img: [],
      question:
        "As an entry-level UX designer, you’ll have a lot of exciting opportunities to gain experience. When you first start out, you’ll probably take on a lot of different roles and responsibilities. This includes all of these but one",
      answer: "Writing design specifications",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [
        "work closely with a supervisor to learn more about a job or industry",
        "market their services to businesses to find customers",
        "receive on-the-job training from an experienced professional",
        "find short-term jobs with limited responsibility",
      ],
      img: [],
      question: "Designers that work as freelancers can expect to _____.",
      answer: "find short-term jobs with limited responsibility",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "A UX design career journey",
    },
    {
      options: [],
      img: [],
      question:
        "If you’re designing a new app to help working parents and guardians, your team might start the brainstorming stage by listing common problems that working parents and guardians face, like a lack of reliable childcare, transportation concerns, or trouble managing schedules. Your team might review user feedback about other similar products or the results of user surveys to help guide your ideas. After you’ve brainstormed lots of user problems, your team chooses one. What stage of the development cycle will your team move to?",
      answer: "Design",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "The product development life cycle",
    },
    {
      options: [
        "Improve the pleasantness of a digital product",
        "Improve the marketability of a product",
        "Improve the accessibility of a product",
        "Improve the usability of a product",
      ],
      img: [],
      question:
        "The goal of User Experience Design is to achieve which of the following?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [
        "Improve the pleasantness of a digital product",
        "Improve the marketability of a product",
        "Improve the accessibility of a product",
        "Improve the usability of a product",
      ],
      img: [],
      question:
        "The goal of User Experience Design is to achieve which of the following?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [
        "Demonstrate an understanding of needs and goals for both the business and users",
        "Support mid and senior-level UX professionals with their projects",
        "Help execute processes and create artifacts for information architecture (card sorting, sitemaps, flow diagrams, etc.)",
        "Assist with designing concepts, wireframes, and prototypes",
      ],
      img: [],
      question:
        "Which of these is/are job responsibilities of an entry-level UX designer?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: ["1,2,3,4,5", "3,2,5,1,4", "3,5,1,2,4", "2,3,5,1,4"],
      img: [],
      question:
        "Every new product, whether it’s an app or a physical object, follows a specific set of steps that take it from the first spark of an idea to the release of the final product. This is called the product development life cycle, and it has five stages:, 1. (test) 2. (define), 3. ( brainstorm), 4. (Launch) and 5. (Design). Which of the following has the right arrangement;",
      answer: "3,2,5,1,4",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "The product development life cycle",
    },
    {
      options: [
        "Expanding your skills in many different types of UX work.",
        "Becoming well-known in the industry for your expertise in a particular type of design.",
        "Trying a variety of responsibilities and finding an area of UX that you’re especially passionate about.",
        "Focusing on one type of design that you enjoy more than others.",
      ],
      img: [],
      question:
        "A generalist has a broad number of responsibilities. A majority of UX design jobs are generalist positions, especially at companies with fewer UX designers. Typically, entry-level UX designers work in generalist roles, and some people choose to stay in generalist design roles for their entire careers. There are a lot of benefits to being a generalist UX designer, like:",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [
        "Are there aspects of the design that consider the user’s feelings?",
        "Does the design help the user achieve a specific goal?",
        "Does the design inspire delight in the user?",
        "Does the design keep the user engaged throughout their experience?",
      ],
      img: [],
      question:
        "As you evaluate how enjoyable a product’s UX is, you can ask questions like: Identify the wrong statement",
      answer:
        "Are there aspects of the design that consider the user’s feelings?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [],
      img: [],
      question:
        "Fill in the blank: Hunter works at a startup as a user experience designer. Since Hunter is one of the only designers, she gets to oversee the majority of her UX processes. Throughout her work, Hunter discovers that she enjoys talking to users and testing designs more than actually designing things. For her next role, Hunter should consider becoming a _____.",
      answer: "UX Researcher",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "A UX design career journey",
    },
    {
      options: [],
      img: [],
      question:
        "If you’re designing a new app to help working parents and guardians, your team might start the brainstorming stage by listing common problems that working parents and guardians face, like a lack of reliable childcare, transportation concerns, or trouble managing schedules. Your team might review user feedback about other similar products or the results of user surveys to help guide your ideas. After you’ve brainstormed lots of user problems, your team chooses one. What stage of the development cycle will your team move to?",
      answer: "Design",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "The product development life cycle",
    },
    {
      options: [
        "Interface design",
        "Human Computer Interaction",
        "Fielding",
        "Information architecture",
      ],
      img: [],
      question:
        "User Experience Design (UXD or UED) is a design process whose sole objective is to design a system that offers a great experience to its users. Thus UXD embraces the theories of a number of disciplines. Which of these is not a field related to UXD?",
      answer: "Fielding",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Foundations of User Experience Design",
    },
    {
      options: ["Usable", "Unique", "Cool", "Useful"],
      img: [],
      question:
        "Good design is easy to spot but often hard to pin down. What exactly makes a product effective to its users? Which of these characteristics can help you evaluate a product’s design?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Characteristics of a good user experience",
    },
    {
      options: ["Generalist", "T-shaped", "Specialist", "Manager"],
      img: [],
      question:
        "Duduke is a newly employed designer who does know how to empathize and take feedback from users very well but knows little about interaction design and other aspects of designs. Which role best describes Trail's new designer?",
      answer: "T-shaped",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [],
      img: [],
      question:
        "Fill in the blank: Hunter works at a startup as a user experience designer. Since Hunter is one of the only designers, she gets to oversee the majority of her UX processes. Throughout her work, Hunter discovers that she enjoys talking to users and testing designs more than actually designing things. For her next role, Hunter should consider becoming a _____.",
      answer: "UX Researcher",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "A UX design career journey",
    },
    {
      options: [
        "Working at a design studio",
        "Working for a city government",
        "Working for a large company",
        "Working at a small startup",
      ],
      img: [],
      question:
        "John is starting a career in UX and wants to work in a company with room for growth. John prefers a fast-paced, unstructured work environment. Which job is a good fit for John?",
      answer: "Working at a small startup",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [
        "Interface design",
        "Human Computer Interaction",
        "Fielding",
        "Information architecture",
      ],
      img: [],
      question:
        "User Experience Design (UXD or UED) is a design process whose sole objective is to design a system that offers a great experience to its users. Thus UXD embraces the theories of a number of disciplines. Which of these is not a field related to UXD?",
      answer: "Fielding",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Foundations of User Experience Design",
    },
    {
      options: ["Generalist", "T-shaped", "Specialist", "Manager"],
      img: [],
      question:
        "Duduke is a newly employed designer who does know how to empathize and take feedback from users very well but knows little about interaction design and other aspects of designs. Which role best describes Trail's new designer?",
      answer: "T-shaped",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [
        "Are there aspects of the design that consider the user’s feelings?",
        "Does the design help the user achieve a specific goal?",
        "Does the design inspire delight in the user?",
        "Does the design keep the user engaged throughout their experience?",
      ],
      img: [],
      question:
        "As you evaluate how enjoyable a product’s UX is, you can ask questions like: Identify the wrong statement",
      answer:
        "Are there aspects of the design that consider the user’s feelings?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [
        "work closely with a supervisor to learn more about a job or industry",
        "market their services to businesses to find customers",
        "receive on-the-job training from an experienced professional",
        "find short-term jobs with limited responsibility",
      ],
      img: [],
      question: "Designers that work as freelancers can expect to _____.",
      answer: "find short-term jobs with limited responsibility",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "A UX design career journey",
    },
    {
      options: [],
      img: [],
      question:
        "If you’re designing a new app to help working parents and guardians, your team might start the brainstorming stage by listing common problems that working parents and guardians face, like a lack of reliable childcare, transportation concerns, or trouble managing schedules. Your team might review user feedback about other similar products or the results of user surveys to help guide your ideas. After you’ve brainstormed lots of user problems, your team chooses one. What stage of the development cycle will your team move to?",
      answer: "Design",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "The product development life cycle",
    },
    {
      options: [
        "Wireframing",
        "User research",
        "Writing design specifications",
        "Prototyping",
      ],
      img: [],
      question:
        "As an entry-level UX designer, you’ll have a lot of exciting opportunities to gain experience. When you first start out, you’ll probably take on a lot of different roles and responsibilities. This includes all of these but one",
      answer: "Writing design specifications",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [],
      img: [],
      question:
        "If you’re designing a new app to help working parents and guardians, your team might start the brainstorming stage by listing common problems that working parents and guardians face, like a lack of reliable childcare, transportation concerns, or trouble managing schedules. Your team might review user feedback about other similar products or the results of user surveys to help guide your ideas. After you’ve brainstormed lots of user problems, your team chooses one. What stage of the development cycle will your team move to?",
      answer: "Design",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "The product development life cycle",
    },
    {
      options: [],
      img: [],
      question:
        "_____means providing people with the tools they need to accomplish their goals and support improved quality of life.",
      answer: "Equity",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [
        "work closely with a supervisor to learn more about a job or industry",
        "market their services to businesses to find customers",
        "receive on-the-job training from an experienced professional",
        "find short-term jobs with limited responsibility",
      ],
      img: [],
      question: "Designers that work as freelancers can expect to _____.",
      answer: "find short-term jobs with limited responsibility",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "A UX design career journey",
    },
    {
      options: [
        "Improve the pleasantness of a digital product",
        "Improve the marketability of a product",
        "Improve the accessibility of a product",
        "Improve the usability of a product",
      ],
      img: [],
      question:
        "The goal of User Experience Design is to achieve which of the following?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [
        "Wireframing",
        "User research",
        "Writing design specifications",
        "Prototyping",
      ],
      img: [],
      question:
        "As an entry-level UX designer, you’ll have a lot of exciting opportunities to gain experience. When you first start out, you’ll probably take on a lot of different roles and responsibilities. This includes all of these but one",
      answer: "Writing design specifications",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [
        "Expanding your skills in many different types of UX work.",
        "Becoming well-known in the industry for your expertise in a particular type of design.",
        "Trying a variety of responsibilities and finding an area of UX that you’re especially passionate about.",
        "Focusing on one type of design that you enjoy more than others.",
      ],
      img: [],
      question:
        "A generalist has a broad number of responsibilities. A majority of UX design jobs are generalist positions, especially at companies with fewer UX designers. Typically, entry-level UX designers work in generalist roles, and some people choose to stay in generalist design roles for their entire careers. There are a lot of benefits to being a generalist UX designer, like:",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: ["1,2,3,4,5", "3,2,5,1,4", "3,5,1,2,4", "2,3,5,1,4"],
      img: [],
      question:
        "Every new product, whether it’s an app or a physical object, follows a specific set of steps that take it from the first spark of an idea to the release of the final product. This is called the product development life cycle, and it has five stages:, 1. (test) 2. (define), 3. ( brainstorm), 4. (Launch) and 5. (Design). Which of the following has the right arrangement;",
      answer: "3,2,5,1,4",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "The product development life cycle",
    },
    {
      options: [
        "Wireframing",
        "User research",
        "Writing design specifications",
        "Prototyping",
      ],
      img: [],
      question:
        "As an entry-level UX designer, you’ll have a lot of exciting opportunities to gain experience. When you first start out, you’ll probably take on a lot of different roles and responsibilities. This includes all of these but one",
      answer: "Writing design specifications",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: ["Generalist", "T-shaped", "Specialist", "Manager"],
      img: [],
      question:
        "Duduke is a newly employed designer who does know how to empathize and take feedback from users very well but knows little about interaction design and other aspects of designs. Which role best describes Trail's new designer?",
      answer: "T-shaped",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [],
      img: [],
      question:
        "_____means providing people with the tools they need to accomplish their goals and support improved quality of life.",
      answer: "Equity",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [],
      img: [],
      question:
        "Fill in the blank: Hunter works at a startup as a user experience designer. Since Hunter is one of the only designers, she gets to oversee the majority of her UX processes. Throughout her work, Hunter discovers that she enjoys talking to users and testing designs more than actually designing things. For her next role, Hunter should consider becoming a _____.",
      answer: "UX Researcher",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "A UX design career journey",
    },
    {
      options: [
        "Interface design",
        "Human Computer Interaction",
        "Fielding",
        "Information architecture",
      ],
      img: [],
      question:
        "User Experience Design (UXD or UED) is a design process whose sole objective is to design a system that offers a great experience to its users. Thus UXD embraces the theories of a number of disciplines. Which of these is not a field related to UXD?",
      answer: "Fielding",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [
        "work closely with a supervisor to learn more about a job or industry",
        "market their services to businesses to find customers",
        "receive on-the-job training from an experienced professional",
        "find short-term jobs with limited responsibility",
      ],
      img: [],
      question: "Designers that work as freelancers can expect to _____.",
      answer: "find short-term jobs with limited responsibility",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "A UX design career journey",
    },
    {
      options: ["1,2,3,4,5", "3,2,5,1,4", "3,5,1,2,4", "2,3,5,1,4"],
      img: [],
      question:
        "Every new product, whether it’s an app or a physical object, follows a specific set of steps that take it from the first spark of an idea to the release of the final product. This is called the product development life cycle, and it has five stages:, 1. (test) 2. (define), 3. ( brainstorm), 4. (Launch) and 5. (Design). Which of the following has the right arrangement;",
      answer: "3,2,5,1,4",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "The product development life cycle",
    },
    {
      options: [
        "Interface design",
        "Human Computer Interaction",
        "Fielding",
        "Information architecture",
      ],
      img: [],
      question:
        "User Experience Design (UXD or UED) is a design process whose sole objective is to design a system that offers a great experience to its users. Thus UXD embraces the theories of a number of disciplines. Which of these is not a field related to UXD?",
      answer: "Fielding",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Foundations of User Experience Design",
    },
    {
      options: ["Generalist", "T-shaped", "Specialist", "Manager"],
      img: [],
      question:
        "Duduke is a newly employed designer who does know how to empathize and take feedback from users very well but knows little about interaction design and other aspects of designs. Which role best describes Trail's new designer?",
      answer: "T-shaped",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: ["Usable", "Unique", "Cool", "Useful"],
      img: [],
      question:
        "Good design is easy to spot but often hard to pin down. What exactly makes a product effective to its users? Which of these characteristics can help you evaluate a product’s design?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [
        "Working at a design studio",
        "Working for a city government",
        "Working for a large company",
        "Working at a small startup",
      ],
      img: [],
      question:
        "John is starting a career in UX and wants to work in a company with room for growth. John prefers a fast-paced, unstructured work environment. Which job is a good fit for John?",
      answer: "Working at a small startup",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [],
      img: [],
      question:
        "_____means providing people with the tools they need to accomplish their goals and support improved quality of life.",
      answer: "Equity",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [
        "Wireframing",
        "User research",
        "Writing design specifications",
        "Prototyping",
      ],
      img: [],
      question:
        "As an entry-level UX designer, you’ll have a lot of exciting opportunities to gain experience. When you first start out, you’ll probably take on a lot of different roles and responsibilities. This includes all of these but one",
      answer: "Writing design specifications",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: ["1,2,3,4,5", "3,2,5,1,4", "3,5,1,2,4", "2,3,5,1,4"],
      img: [],
      question:
        "Every new product, whether it’s an app or a physical object, follows a specific set of steps that take it from the first spark of an idea to the release of the final product. This is called the product development life cycle, and it has five stages:, 1. (test) 2. (define), 3. ( brainstorm), 4. (Launch) and 5. (Design). Which of the following has the right arrangement;",
      answer: "3,2,5,1,4",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "The product development life cycle",
    },
    {
      options: [
        "Expanding your skills in many different types of UX work.",
        "Becoming well-known in the industry for your expertise in a particular type of design.",
        "Trying a variety of responsibilities and finding an area of UX that you’re especially passionate about.",
        "Focusing on one type of design that you enjoy more than others.",
      ],
      img: [],
      question:
        "A generalist has a broad number of responsibilities. A majority of UX design jobs are generalist positions, especially at companies with fewer UX designers. Typically, entry-level UX designers work in generalist roles, and some people choose to stay in generalist design roles for their entire careers. There are a lot of benefits to being a generalist UX designer, like:",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [
        "work closely with a supervisor to learn more about a job or industry",
        "market their services to businesses to find customers",
        "receive on-the-job training from an experienced professional",
        "find short-term jobs with limited responsibility",
      ],
      img: [],
      question: "Designers that work as freelancers can expect to _____.",
      answer: "find short-term jobs with limited responsibility",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "A UX design career journey",
    },
    {
      options: [
        "Interface design",
        "Human Computer Interaction",
        "Fielding",
        "Information architecture",
      ],
      img: [],
      question:
        "User Experience Design (UXD or UED) is a design process whose sole objective is to design a system that offers a great experience to its users. Thus UXD embraces the theories of a number of disciplines. Which of these is not a field related to UXD?",
      answer: "Fielding",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [
        "Expanding your skills in many different types of UX work.",
        "Becoming well-known in the industry for your expertise in a particular type of design.",
        "Trying a variety of responsibilities and finding an area of UX that you’re especially passionate about.",
        "Focusing on one type of design that you enjoy more than others.",
      ],
      img: [],
      question:
        "A generalist has a broad number of responsibilities. A majority of UX design jobs are generalist positions, especially at companies with fewer UX designers. Typically, entry-level UX designers work in generalist roles, and some people choose to stay in generalist design roles for their entire careers. There are a lot of benefits to being a generalist UX designer, like:",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: ["1,2,3,4,5", "3,2,5,1,4", "3,5,1,2,4", "2,3,5,1,4"],
      img: [],
      question:
        "Every new product, whether it’s an app or a physical object, follows a specific set of steps that take it from the first spark of an idea to the release of the final product. This is called the product development life cycle, and it has five stages:, 1. (test) 2. (define), 3. ( brainstorm), 4. (Launch) and 5. (Design). Which of the following has the right arrangement;",
      answer: "3,2,5,1,4",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "The product development life cycle",
    },
    {
      options: [
        "Are there aspects of the design that consider the user’s feelings?",
        "Does the design help the user achieve a specific goal?",
        "Does the design inspire delight in the user?",
        "Does the design keep the user engaged throughout their experience?",
      ],
      img: [],
      question:
        "As you evaluate how enjoyable a product’s UX is, you can ask questions like: Identify the wrong statement",
      answer:
        "Are there aspects of the design that consider the user’s feelings?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [
        "Demonstrate an understanding of needs and goals for both the business and users",
        "Support mid and senior-level UX professionals with their projects",
        "Help execute processes and create artifacts for information architecture (card sorting, sitemaps, flow diagrams, etc.)",
        "Assist with designing concepts, wireframes, and prototypes",
      ],
      img: [],
      question:
        "Which of these is/are job responsibilities of an entry-level UX designer?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [
        "work closely with a supervisor to learn more about a job or industry",
        "market their services to businesses to find customers",
        "receive on-the-job training from an experienced professional",
        "find short-term jobs with limited responsibility",
      ],
      img: [],
      question: "Designers that work as freelancers can expect to _____.",
      answer: "find short-term jobs with limited responsibility",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "A UX design career journey",
    },
    {
      options: [
        "Improve the pleasantness of a digital product",
        "Improve the marketability of a product",
        "Improve the accessibility of a product",
        "Improve the usability of a product",
      ],
      img: [],
      question:
        "The goal of User Experience Design is to achieve which of the following?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [
        "Improve the pleasantness of a digital product",
        "Improve the marketability of a product",
        "Improve the accessibility of a product",
        "Improve the usability of a product",
      ],
      img: [],
      question:
        "The goal of User Experience Design is to achieve which of the following?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [],
      img: [],
      question:
        "_____means providing people with the tools they need to accomplish their goals and support improved quality of life.",
      answer: "Equity",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [],
      img: [],
      question:
        "As you get further along in your career, you can choose to specialize in a certain area of UX design or keep your skillset more broad. Assuming you want to keep your knowledge broad, which type of designer will you be?",
      answer: "Generalist",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [
        "Wireframing",
        "User research",
        "Writing design specifications",
        "Prototyping",
      ],
      img: [],
      question:
        "As an entry-level UX designer, you’ll have a lot of exciting opportunities to gain experience. When you first start out, you’ll probably take on a lot of different roles and responsibilities. This includes all of these but one",
      answer: "Writing design specifications",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [],
      img: [],
      question:
        "Fill in the blank: Hunter works at a startup as a user experience designer. Since Hunter is one of the only designers, she gets to oversee the majority of her UX processes. Throughout her work, Hunter discovers that she enjoys talking to users and testing designs more than actually designing things. For her next role, Hunter should consider becoming a _____.",
      answer: "UX Researcher",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "A UX design career journey",
    },
    {
      options: [],
      img: [],
      question:
        "If you’re designing a new app to help working parents and guardians, your team might start the brainstorming stage by listing common problems that working parents and guardians face, like a lack of reliable childcare, transportation concerns, or trouble managing schedules. Your team might review user feedback about other similar products or the results of user surveys to help guide your ideas. After you’ve brainstormed lots of user problems, your team chooses one. What stage of the development cycle will your team move to?",
      answer: "Design",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "The product development life cycle",
    },
    {
      options: ["1,2,3,4,5", "3,2,5,1,4", "3,5,1,2,4", "2,3,5,1,4"],
      img: [],
      question:
        "Every new product, whether it’s an app or a physical object, follows a specific set of steps that take it from the first spark of an idea to the release of the final product. This is called the product development life cycle, and it has five stages:, 1. (test) 2. (define), 3. ( brainstorm), 4. (Launch) and 5. (Design). Which of the following has the right arrangement;",
      answer: "3,2,5,1,4",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "The product development life cycle",
    },
    {
      options: [
        "Demonstrate an understanding of needs and goals for both the business and users",
        "Support mid and senior-level UX professionals with their projects",
        "Help execute processes and create artifacts for information architecture (card sorting, sitemaps, flow diagrams, etc.)",
        "Assist with designing concepts, wireframes, and prototypes",
      ],
      img: [],
      question:
        "Which of these is/are job responsibilities of an entry-level UX designer?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [
        "work closely with a supervisor to learn more about a job or industry",
        "market their services to businesses to find customers",
        "receive on-the-job training from an experienced professional",
        "find short-term jobs with limited responsibility",
      ],
      img: [],
      question: "Designers that work as freelancers can expect to _____.",
      answer: "find short-term jobs with limited responsibility",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "A UX design career journey",
    },
    {
      options: [
        "Interface design",
        "Human Computer Interaction",
        "Fielding",
        "Information architecture",
      ],
      img: [],
      question:
        "User Experience Design (UXD or UED) is a design process whose sole objective is to design a system that offers a great experience to its users. Thus UXD embraces the theories of a number of disciplines. Which of these is not a field related to UXD?",
      answer: "Fielding",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Foundations of User Experience Design",
    },
    {
      options: ["Usable", "Unique", "Cool", "Useful"],
      img: [],
      question:
        "Good design is easy to spot but often hard to pin down. What exactly makes a product effective to its users? Which of these characteristics can help you evaluate a product’s design?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [
        "Expanding your skills in many different types of UX work.",
        "Becoming well-known in the industry for your expertise in a particular type of design.",
        "Trying a variety of responsibilities and finding an area of UX that you’re especially passionate about.",
        "Focusing on one type of design that you enjoy more than others.",
      ],
      img: [],
      question:
        "A generalist has a broad number of responsibilities. A majority of UX design jobs are generalist positions, especially at companies with fewer UX designers. Typically, entry-level UX designers work in generalist roles, and some people choose to stay in generalist design roles for their entire careers. There are a lot of benefits to being a generalist UX designer, like:",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [
        "Are there aspects of the design that consider the user’s feelings?",
        "Does the design help the user achieve a specific goal?",
        "Does the design inspire delight in the user?",
        "Does the design keep the user engaged throughout their experience?",
      ],
      img: [],
      question:
        "As you evaluate how enjoyable a product’s UX is, you can ask questions like: Identify the wrong statement",
      answer:
        "Are there aspects of the design that consider the user’s feelings?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Characteristics of a good user experience",
    },
    {
      options: ["1,2,3,4,5", "3,2,5,1,4", "3,5,1,2,4", "2,3,5,1,4"],
      img: [],
      question:
        "Every new product, whether it’s an app or a physical object, follows a specific set of steps that take it from the first spark of an idea to the release of the final product. This is called the product development life cycle, and it has five stages:, 1. (test) 2. (define), 3. ( brainstorm), 4. (Launch) and 5. (Design). Which of the following has the right arrangement;",
      answer: "3,2,5,1,4",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "The product development life cycle",
    },
    {
      options: [
        "work closely with a supervisor to learn more about a job or industry",
        "market their services to businesses to find customers",
        "receive on-the-job training from an experienced professional",
        "find short-term jobs with limited responsibility",
      ],
      img: [],
      question: "Designers that work as freelancers can expect to _____.",
      answer: "find short-term jobs with limited responsibility",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "A UX design career journey",
    },
    {
      options: [
        "Wireframing",
        "User research",
        "Writing design specifications",
        "Prototyping",
      ],
      img: [],
      question:
        "As an entry-level UX designer, you’ll have a lot of exciting opportunities to gain experience. When you first start out, you’ll probably take on a lot of different roles and responsibilities. This includes all of these but one",
      answer: "Writing design specifications",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [
        "Improve the pleasantness of a digital product",
        "Improve the marketability of a product",
        "Improve the accessibility of a product",
        "Improve the usability of a product",
      ],
      img: [],
      question:
        "The goal of User Experience Design is to achieve which of the following?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [],
      img: [],
      question:
        "As you get further along in your career, you can choose to specialize in a certain area of UX design or keep your skillset more broad. Assuming you want to keep your knowledge broad, which type of designer will you be?",
      answer: "Generalist",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [
        "Improve the pleasantness of a digital product",
        "Improve the marketability of a product",
        "Improve the accessibility of a product",
        "Improve the usability of a product",
      ],
      img: [],
      question:
        "The goal of User Experience Design is to achieve which of the following?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [
        "Wireframing",
        "User research",
        "Writing design specifications",
        "Prototyping",
      ],
      img: [],
      question:
        "As an entry-level UX designer, you’ll have a lot of exciting opportunities to gain experience. When you first start out, you’ll probably take on a lot of different roles and responsibilities. This includes all of these but one",
      answer: "Writing design specifications",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [],
      img: [],
      question:
        "Fill in the blank: Hunter works at a startup as a user experience designer. Since Hunter is one of the only designers, she gets to oversee the majority of her UX processes. Throughout her work, Hunter discovers that she enjoys talking to users and testing designs more than actually designing things. For her next role, Hunter should consider becoming a _____.",
      answer: "UX Researcher",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "A UX design career journey",
    },
    {
      options: [],
      img: [],
      question:
        "If you’re designing a new app to help working parents and guardians, your team might start the brainstorming stage by listing common problems that working parents and guardians face, like a lack of reliable childcare, transportation concerns, or trouble managing schedules. Your team might review user feedback about other similar products or the results of user surveys to help guide your ideas. After you’ve brainstormed lots of user problems, your team chooses one. What stage of the development cycle will your team move to?",
      answer: "Design",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "The product development life cycle",
    },
    {
      options: ["Generalist", "T-shaped", "Specialist", "Manager"],
      img: [],
      question:
        "Duduke is a newly employed designer who does know how to empathize and take feedback from users very well but knows little about interaction design and other aspects of designs. Which role best describes Trail's new designer?",
      answer: "T-shaped",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [
        "Are there aspects of the design that consider the user’s feelings?",
        "Does the design help the user achieve a specific goal?",
        "Does the design inspire delight in the user?",
        "Does the design keep the user engaged throughout their experience?",
      ],
      img: [],
      question:
        "As you evaluate how enjoyable a product’s UX is, you can ask questions like: Identify the wrong statement",
      answer:
        "Are there aspects of the design that consider the user’s feelings?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [],
      img: [],
      question:
        "_____means providing people with the tools they need to accomplish their goals and support improved quality of life.",
      answer: "Equity",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [
        "Wireframing",
        "User research",
        "Writing design specifications",
        "Prototyping",
      ],
      img: [],
      question:
        "As an entry-level UX designer, you’ll have a lot of exciting opportunities to gain experience. When you first start out, you’ll probably take on a lot of different roles and responsibilities. This includes all of these but one",
      answer: "Writing design specifications",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [],
      img: [],
      question:
        "Fill in the blank: Hunter works at a startup as a user experience designer. Since Hunter is one of the only designers, she gets to oversee the majority of her UX processes. Throughout her work, Hunter discovers that she enjoys talking to users and testing designs more than actually designing things. For her next role, Hunter should consider becoming a _____.",
      answer: "UX Researcher",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "A UX design career journey",
    },
    {
      options: [],
      img: [],
      question:
        "If you’re designing a new app to help working parents and guardians, your team might start the brainstorming stage by listing common problems that working parents and guardians face, like a lack of reliable childcare, transportation concerns, or trouble managing schedules. Your team might review user feedback about other similar products or the results of user surveys to help guide your ideas. After you’ve brainstormed lots of user problems, your team chooses one. What stage of the development cycle will your team move to?",
      answer: "Design",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "The product development life cycle",
    },
    {
      options: [],
      img: [],
      question:
        "As you get further along in your career, you can choose to specialize in a certain area of UX design or keep your skillset more broad. Assuming you want to keep your knowledge broad, which type of designer will you be?",
      answer: "Generalist",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [
        "Interface design",
        "Human Computer Interaction",
        "Fielding",
        "Information architecture",
      ],
      img: [],
      question:
        "User Experience Design (UXD or UED) is a design process whose sole objective is to design a system that offers a great experience to its users. Thus UXD embraces the theories of a number of disciplines. Which of these is not a field related to UXD?",
      answer: "Fielding",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [],
      img: [],
      question:
        "As you get further along in your career, you can choose to specialize in a certain area of UX design or keep your skillset more broad. Assuming you want to keep your knowledge broad, which type of designer will you be?",
      answer: "Generalist",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [
        "Improve the pleasantness of a digital product",
        "Improve the marketability of a product",
        "Improve the accessibility of a product",
        "Improve the usability of a product",
      ],
      img: [],
      question:
        "The goal of User Experience Design is to achieve which of the following?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [],
      img: [],
      question:
        "If you’re designing a new app to help working parents and guardians, your team might start the brainstorming stage by listing common problems that working parents and guardians face, like a lack of reliable childcare, transportation concerns, or trouble managing schedules. Your team might review user feedback about other similar products or the results of user surveys to help guide your ideas. After you’ve brainstormed lots of user problems, your team chooses one. What stage of the development cycle will your team move to?",
      answer: "Design",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "The product development life cycle",
    },
    {
      options: [
        "Wireframing",
        "User research",
        "Writing design specifications",
        "Prototyping",
      ],
      img: [],
      question:
        "As an entry-level UX designer, you’ll have a lot of exciting opportunities to gain experience. When you first start out, you’ll probably take on a lot of different roles and responsibilities. This includes all of these but one",
      answer: "Writing design specifications",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: ["Usable", "Unique", "Cool", "Useful"],
      img: [],
      question:
        "Good design is easy to spot but often hard to pin down. What exactly makes a product effective to its users? Which of these characteristics can help you evaluate a product’s design?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [
        "work closely with a supervisor to learn more about a job or industry",
        "market their services to businesses to find customers",
        "receive on-the-job training from an experienced professional",
        "find short-term jobs with limited responsibility",
      ],
      img: [],
      question: "Designers that work as freelancers can expect to _____.",
      answer: "find short-term jobs with limited responsibility",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "A UX design career journey",
    },
    {
      options: [],
      img: [],
      question:
        "_____means providing people with the tools they need to accomplish their goals and support improved quality of life.",
      answer: "Equity",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "Characteristics of a good user experience",
    },
    {
      options: ["Generalist", "T-shaped", "Specialist", "Manager"],
      img: [],
      question:
        "Duduke is a newly employed designer who does know how to empathize and take feedback from users very well but knows little about interaction design and other aspects of designs. Which role best describes Trail's new designer?",
      answer: "T-shaped",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [
        "Improve the pleasantness of a digital product",
        "Improve the marketability of a product",
        "Improve the accessibility of a product",
        "Improve the usability of a product",
      ],
      img: [],
      question:
        "The goal of User Experience Design is to achieve which of the following?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [
        "Wireframing",
        "User research",
        "Writing design specifications",
        "Prototyping",
      ],
      img: [],
      question:
        "As an entry-level UX designer, you’ll have a lot of exciting opportunities to gain experience. When you first start out, you’ll probably take on a lot of different roles and responsibilities. This includes all of these but one",
      answer: "Writing design specifications",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [],
      img: [],
      question:
        "If you’re designing a new app to help working parents and guardians, your team might start the brainstorming stage by listing common problems that working parents and guardians face, like a lack of reliable childcare, transportation concerns, or trouble managing schedules. Your team might review user feedback about other similar products or the results of user surveys to help guide your ideas. After you’ve brainstormed lots of user problems, your team chooses one. What stage of the development cycle will your team move to?",
      answer: "Design",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "The product development life cycle",
    },
    {
      options: [],
      img: [],
      question:
        "Fill in the blank: Hunter works at a startup as a user experience designer. Since Hunter is one of the only designers, she gets to oversee the majority of her UX processes. Throughout her work, Hunter discovers that she enjoys talking to users and testing designs more than actually designing things. For her next role, Hunter should consider becoming a _____.",
      answer: "UX Researcher",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "A UX design career journey",
    },
    {
      options: ["Usable", "Unique", "Cool", "Useful"],
      img: [],
      question:
        "Good design is easy to spot but often hard to pin down. What exactly makes a product effective to its users? Which of these characteristics can help you evaluate a product’s design?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [
        "Demonstrate an understanding of needs and goals for both the business and users",
        "Support mid and senior-level UX professionals with their projects",
        "Help execute processes and create artifacts for information architecture (card sorting, sitemaps, flow diagrams, etc.)",
        "Assist with designing concepts, wireframes, and prototypes",
      ],
      img: [],
      question:
        "Which of these is/are job responsibilities of an entry-level UX designer?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [
        "Improve the pleasantness of a digital product",
        "Improve the marketability of a product",
        "Improve the accessibility of a product",
        "Improve the usability of a product",
      ],
      img: [],
      question:
        "The goal of User Experience Design is to achieve which of the following?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [
        "Expanding your skills in many different types of UX work.",
        "Becoming well-known in the industry for your expertise in a particular type of design.",
        "Trying a variety of responsibilities and finding an area of UX that you’re especially passionate about.",
        "Focusing on one type of design that you enjoy more than others.",
      ],
      img: [],
      question:
        "A generalist has a broad number of responsibilities. A majority of UX design jobs are generalist positions, especially at companies with fewer UX designers. Typically, entry-level UX designers work in generalist roles, and some people choose to stay in generalist design roles for their entire careers. There are a lot of benefits to being a generalist UX designer, like:",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: ["1,2,3,4,5", "3,2,5,1,4", "3,5,1,2,4", "2,3,5,1,4"],
      img: [],
      question:
        "Every new product, whether it’s an app or a physical object, follows a specific set of steps that take it from the first spark of an idea to the release of the final product. This is called the product development life cycle, and it has five stages:, 1. (test) 2. (define), 3. ( brainstorm), 4. (Launch) and 5. (Design). Which of the following has the right arrangement;",
      answer: "3,2,5,1,4",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "The product development life cycle",
    },
    {
      options: [],
      img: [],
      question:
        "Fill in the blank: Hunter works at a startup as a user experience designer. Since Hunter is one of the only designers, she gets to oversee the majority of her UX processes. Throughout her work, Hunter discovers that she enjoys talking to users and testing designs more than actually designing things. For her next role, Hunter should consider becoming a _____.",
      answer: "UX Researcher",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "A UX design career journey",
    },
    {
      options: [],
      img: [],
      question:
        "If you’re designing a new app to help working parents and guardians, your team might start the brainstorming stage by listing common problems that working parents and guardians face, like a lack of reliable childcare, transportation concerns, or trouble managing schedules. Your team might review user feedback about other similar products or the results of user surveys to help guide your ideas. After you’ve brainstormed lots of user problems, your team chooses one. What stage of the development cycle will your team move to?",
      answer: "Design",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "The product development life cycle",
    },
    {
      options: [
        "Are there aspects of the design that consider the user’s feelings?",
        "Does the design help the user achieve a specific goal?",
        "Does the design inspire delight in the user?",
        "Does the design keep the user engaged throughout their experience?",
      ],
      img: [],
      question:
        "As you evaluate how enjoyable a product’s UX is, you can ask questions like: Identify the wrong statement",
      answer:
        "Are there aspects of the design that consider the user’s feelings?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [
        "Demonstrate an understanding of needs and goals for both the business and users",
        "Support mid and senior-level UX professionals with their projects",
        "Help execute processes and create artifacts for information architecture (card sorting, sitemaps, flow diagrams, etc.)",
        "Assist with designing concepts, wireframes, and prototypes",
      ],
      img: [],
      question:
        "Which of these is/are job responsibilities of an entry-level UX designer?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [
        "Expanding your skills in many different types of UX work.",
        "Becoming well-known in the industry for your expertise in a particular type of design.",
        "Trying a variety of responsibilities and finding an area of UX that you’re especially passionate about.",
        "Focusing on one type of design that you enjoy more than others.",
      ],
      img: [],
      question:
        "A generalist has a broad number of responsibilities. A majority of UX design jobs are generalist positions, especially at companies with fewer UX designers. Typically, entry-level UX designers work in generalist roles, and some people choose to stay in generalist design roles for their entire careers. There are a lot of benefits to being a generalist UX designer, like:",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [
        "Interface design",
        "Human Computer Interaction",
        "Fielding",
        "Information architecture",
      ],
      img: [],
      question:
        "User Experience Design (UXD or UED) is a design process whose sole objective is to design a system that offers a great experience to its users. Thus UXD embraces the theories of a number of disciplines. Which of these is not a field related to UXD?",
      answer: "Fielding",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [
        "work closely with a supervisor to learn more about a job or industry",
        "market their services to businesses to find customers",
        "receive on-the-job training from an experienced professional",
        "find short-term jobs with limited responsibility",
      ],
      img: [],
      question: "Designers that work as freelancers can expect to _____.",
      answer: "find short-term jobs with limited responsibility",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "A UX design career journey",
    },
    {
      options: [
        "Interface design",
        "Human Computer Interaction",
        "Fielding",
        "Information architecture",
      ],
      img: [],
      question:
        "User Experience Design (UXD or UED) is a design process whose sole objective is to design a system that offers a great experience to its users. Thus UXD embraces the theories of a number of disciplines. Which of these is not a field related to UXD?",
      answer: "Fielding",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Foundations of User Experience Design",
    },
    {
      options: ["Generalist", "T-shaped", "Specialist", "Manager"],
      img: [],
      question:
        "Duduke is a newly employed designer who does know how to empathize and take feedback from users very well but knows little about interaction design and other aspects of designs. Which role best describes Trail's new designer?",
      answer: "T-shaped",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [
        "work closely with a supervisor to learn more about a job or industry",
        "market their services to businesses to find customers",
        "receive on-the-job training from an experienced professional",
        "find short-term jobs with limited responsibility",
      ],
      img: [],
      question: "Designers that work as freelancers can expect to _____.",
      answer: "find short-term jobs with limited responsibility",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "A UX design career journey",
    },
    {
      options: [
        "Are there aspects of the design that consider the user’s feelings?",
        "Does the design help the user achieve a specific goal?",
        "Does the design inspire delight in the user?",
        "Does the design keep the user engaged throughout their experience?",
      ],
      img: [],
      question:
        "As you evaluate how enjoyable a product’s UX is, you can ask questions like: Identify the wrong statement",
      answer:
        "Are there aspects of the design that consider the user’s feelings?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Characteristics of a good user experience",
    },
    {
      options: ["this is a false statement", "this is a true statement"],
      img: [],
      question:
        "There is no universal definition of the product development cycle. Businesses disagree about how many stages the cycle includes. Even those who agree on the number of steps disagree about where one ends and next starts.",
      answer: "this is a true statement",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "The product development life cycle",
    },
    {
      options: [
        "Demonstrate an understanding of needs and goals for both the business and users",
        "Support mid and senior-level UX professionals with their projects",
        "Help execute processes and create artifacts for information architecture (card sorting, sitemaps, flow diagrams, etc.)",
        "Assist with designing concepts, wireframes, and prototypes",
      ],
      img: [],
      question:
        "Which of these is/are job responsibilities of an entry-level UX designer?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [
        "Expanding your skills in many different types of UX work.",
        "Becoming well-known in the industry for your expertise in a particular type of design.",
        "Trying a variety of responsibilities and finding an area of UX that you’re especially passionate about.",
        "Focusing on one type of design that you enjoy more than others.",
      ],
      img: [],
      question:
        "A generalist has a broad number of responsibilities. A majority of UX design jobs are generalist positions, especially at companies with fewer UX designers. Typically, entry-level UX designers work in generalist roles, and some people choose to stay in generalist design roles for their entire careers. There are a lot of benefits to being a generalist UX designer, like:",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [],
      img: [],
      question:
        "_____means providing people with the tools they need to accomplish their goals and support improved quality of life.",
      answer: "Equity",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [
        "work closely with a supervisor to learn more about a job or industry",
        "market their services to businesses to find customers",
        "receive on-the-job training from an experienced professional",
        "find short-term jobs with limited responsibility",
      ],
      img: [],
      question: "Designers that work as freelancers can expect to _____.",
      answer: "find short-term jobs with limited responsibility",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "A UX design career journey",
    },
    {
      options: [
        "Wireframing",
        "User research",
        "Writing design specifications",
        "Prototyping",
      ],
      img: [],
      question:
        "As an entry-level UX designer, you’ll have a lot of exciting opportunities to gain experience. When you first start out, you’ll probably take on a lot of different roles and responsibilities. This includes all of these but one",
      answer: "Writing design specifications",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [],
      img: [],
      question:
        "If you’re designing a new app to help working parents and guardians, your team might start the brainstorming stage by listing common problems that working parents and guardians face, like a lack of reliable childcare, transportation concerns, or trouble managing schedules. Your team might review user feedback about other similar products or the results of user surveys to help guide your ideas. After you’ve brainstormed lots of user problems, your team chooses one. What stage of the development cycle will your team move to?",
      answer: "Design",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "The product development life cycle",
    },
    {
      options: [
        "Interface design",
        "Human Computer Interaction",
        "Fielding",
        "Information architecture",
      ],
      img: [],
      question:
        "User Experience Design (UXD or UED) is a design process whose sole objective is to design a system that offers a great experience to its users. Thus UXD embraces the theories of a number of disciplines. Which of these is not a field related to UXD?",
      answer: "Fielding",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [],
      img: [],
      question:
        "As you get further along in your career, you can choose to specialize in a certain area of UX design or keep your skillset more broad. Assuming you want to keep your knowledge broad, which type of designer will you be?",
      answer: "Generalist",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: ["this is a false statement", "this is a true statement"],
      img: [],
      question:
        "There is no universal definition of the product development cycle. Businesses disagree about how many stages the cycle includes. Even those who agree on the number of steps disagree about where one ends and next starts.",
      answer: "this is a true statement",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "The product development life cycle",
    },
    {
      options: [
        "Are there aspects of the design that consider the user’s feelings?",
        "Does the design help the user achieve a specific goal?",
        "Does the design inspire delight in the user?",
        "Does the design keep the user engaged throughout their experience?",
      ],
      img: [],
      question:
        "As you evaluate how enjoyable a product’s UX is, you can ask questions like: Identify the wrong statement",
      answer:
        "Are there aspects of the design that consider the user’s feelings?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [
        "work closely with a supervisor to learn more about a job or industry",
        "market their services to businesses to find customers",
        "receive on-the-job training from an experienced professional",
        "find short-term jobs with limited responsibility",
      ],
      img: [],
      question: "Designers that work as freelancers can expect to _____.",
      answer: "find short-term jobs with limited responsibility",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "A UX design career journey",
    },
    {
      options: [
        "Working at a design studio",
        "Working for a city government",
        "Working for a large company",
        "Working at a small startup",
      ],
      img: [],
      question:
        "John is starting a career in UX and wants to work in a company with room for growth. John prefers a fast-paced, unstructured work environment. Which job is a good fit for John?",
      answer: "Working at a small startup",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [
        "Improve the pleasantness of a digital product",
        "Improve the marketability of a product",
        "Improve the accessibility of a product",
        "Improve the usability of a product",
      ],
      img: [],
      question:
        "The goal of User Experience Design is to achieve which of the following?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [],
      img: [],
      question:
        "As you get further along in your career, you can choose to specialize in a certain area of UX design or keep your skillset more broad. Assuming you want to keep your knowledge broad, which type of designer will you be?",
      answer: "Generalist",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: ["Usable", "Unique", "Cool", "Useful"],
      img: [],
      question:
        "Good design is easy to spot but often hard to pin down. What exactly makes a product effective to its users? Which of these characteristics can help you evaluate a product’s design?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [
        "work closely with a supervisor to learn more about a job or industry",
        "market their services to businesses to find customers",
        "receive on-the-job training from an experienced professional",
        "find short-term jobs with limited responsibility",
      ],
      img: [],
      question: "Designers that work as freelancers can expect to _____.",
      answer: "find short-term jobs with limited responsibility",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "A UX design career journey",
    },
    {
      options: [
        "Interface design",
        "Human Computer Interaction",
        "Fielding",
        "Information architecture",
      ],
      img: [],
      question:
        "User Experience Design (UXD or UED) is a design process whose sole objective is to design a system that offers a great experience to its users. Thus UXD embraces the theories of a number of disciplines. Which of these is not a field related to UXD?",
      answer: "Fielding",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [
        "Working at a design studio",
        "Working for a city government",
        "Working for a large company",
        "Working at a small startup",
      ],
      img: [],
      question:
        "John is starting a career in UX and wants to work in a company with room for growth. John prefers a fast-paced, unstructured work environment. Which job is a good fit for John?",
      answer: "Working at a small startup",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [],
      img: [],
      question:
        "If you’re designing a new app to help working parents and guardians, your team might start the brainstorming stage by listing common problems that working parents and guardians face, like a lack of reliable childcare, transportation concerns, or trouble managing schedules. Your team might review user feedback about other similar products or the results of user surveys to help guide your ideas. After you’ve brainstormed lots of user problems, your team chooses one. What stage of the development cycle will your team move to?",
      answer: "Design",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "The product development life cycle",
    },
    {
      options: [
        "work closely with a supervisor to learn more about a job or industry",
        "market their services to businesses to find customers",
        "receive on-the-job training from an experienced professional",
        "find short-term jobs with limited responsibility",
      ],
      img: [],
      question: "Designers that work as freelancers can expect to _____.",
      answer: "find short-term jobs with limited responsibility",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "A UX design career journey",
    },
    {
      options: [
        "Wireframing",
        "User research",
        "Writing design specifications",
        "Prototyping",
      ],
      img: [],
      question:
        "As an entry-level UX designer, you’ll have a lot of exciting opportunities to gain experience. When you first start out, you’ll probably take on a lot of different roles and responsibilities. This includes all of these but one",
      answer: "Writing design specifications",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [],
      img: [],
      question:
        "As you get further along in your career, you can choose to specialize in a certain area of UX design or keep your skillset more broad. Assuming you want to keep your knowledge broad, which type of designer will you be?",
      answer: "Generalist",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [],
      img: [],
      question:
        "If you’re designing a new app to help working parents and guardians, your team might start the brainstorming stage by listing common problems that working parents and guardians face, like a lack of reliable childcare, transportation concerns, or trouble managing schedules. Your team might review user feedback about other similar products or the results of user surveys to help guide your ideas. After you’ve brainstormed lots of user problems, your team chooses one. What stage of the development cycle will your team move to?",
      answer: "Design",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "The product development life cycle",
    },
    {
      options: [],
      img: [],
      question:
        "_____means providing people with the tools they need to accomplish their goals and support improved quality of life.",
      answer: "Equity",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [
        "Interface design",
        "Human Computer Interaction",
        "Fielding",
        "Information architecture",
      ],
      img: [],
      question:
        "User Experience Design (UXD or UED) is a design process whose sole objective is to design a system that offers a great experience to its users. Thus UXD embraces the theories of a number of disciplines. Which of these is not a field related to UXD?",
      answer: "Fielding",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [],
      img: [],
      question:
        "If you’re designing a new app to help working parents and guardians, your team might start the brainstorming stage by listing common problems that working parents and guardians face, like a lack of reliable childcare, transportation concerns, or trouble managing schedules. Your team might review user feedback about other similar products or the results of user surveys to help guide your ideas. After you’ve brainstormed lots of user problems, your team chooses one. What stage of the development cycle will your team move to?",
      answer: "Design",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "The product development life cycle",
    },
    {
      options: [
        "Working at a design studio",
        "Working for a city government",
        "Working for a large company",
        "Working at a small startup",
      ],
      img: [],
      question:
        "John is starting a career in UX and wants to work in a company with room for growth. John prefers a fast-paced, unstructured work environment. Which job is a good fit for John?",
      answer: "Working at a small startup",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [
        "work closely with a supervisor to learn more about a job or industry",
        "market their services to businesses to find customers",
        "receive on-the-job training from an experienced professional",
        "find short-term jobs with limited responsibility",
      ],
      img: [],
      question: "Designers that work as freelancers can expect to _____.",
      answer: "find short-term jobs with limited responsibility",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "A UX design career journey",
    },
    {
      options: [],
      img: [],
      question:
        "As you get further along in your career, you can choose to specialize in a certain area of UX design or keep your skillset more broad. Assuming you want to keep your knowledge broad, which type of designer will you be?",
      answer: "Generalist",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [
        "Interface design",
        "Human Computer Interaction",
        "Fielding",
        "Information architecture",
      ],
      img: [],
      question:
        "User Experience Design (UXD or UED) is a design process whose sole objective is to design a system that offers a great experience to its users. Thus UXD embraces the theories of a number of disciplines. Which of these is not a field related to UXD?",
      answer: "Fielding",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Foundations of User Experience Design",
    },
    {
      options: ["Usable", "Unique", "Cool", "Useful"],
      img: [],
      question:
        "Good design is easy to spot but often hard to pin down. What exactly makes a product effective to its users? Which of these characteristics can help you evaluate a product’s design?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [],
      img: [],
      question:
        "Fill in the blank: Hunter works at a startup as a user experience designer. Since Hunter is one of the only designers, she gets to oversee the majority of her UX processes. Throughout her work, Hunter discovers that she enjoys talking to users and testing designs more than actually designing things. For her next role, Hunter should consider becoming a _____.",
      answer: "UX Researcher",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "A UX design career journey",
    },
    {
      options: ["Generalist", "T-shaped", "Specialist", "Manager"],
      img: [],
      question:
        "Duduke is a newly employed designer who does know how to empathize and take feedback from users very well but knows little about interaction design and other aspects of designs. Which role best describes Trail's new designer?",
      answer: "T-shaped",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: ["1,2,3,4,5", "3,2,5,1,4", "3,5,1,2,4", "2,3,5,1,4"],
      img: [],
      question:
        "Every new product, whether it’s an app or a physical object, follows a specific set of steps that take it from the first spark of an idea to the release of the final product. This is called the product development life cycle, and it has five stages:, 1. (test) 2. (define), 3. ( brainstorm), 4. (Launch) and 5. (Design). Which of the following has the right arrangement;",
      answer: "3,2,5,1,4",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "The product development life cycle",
    },
    {
      options: [
        "Wireframing",
        "User research",
        "Writing design specifications",
        "Prototyping",
      ],
      img: [],
      question:
        "As an entry-level UX designer, you’ll have a lot of exciting opportunities to gain experience. When you first start out, you’ll probably take on a lot of different roles and responsibilities. This includes all of these but one",
      answer: "Writing design specifications",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [
        "Are there aspects of the design that consider the user’s feelings?",
        "Does the design help the user achieve a specific goal?",
        "Does the design inspire delight in the user?",
        "Does the design keep the user engaged throughout their experience?",
      ],
      img: [],
      question:
        "As you evaluate how enjoyable a product’s UX is, you can ask questions like: Identify the wrong statement",
      answer:
        "Are there aspects of the design that consider the user’s feelings?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [
        "Improve the pleasantness of a digital product",
        "Improve the marketability of a product",
        "Improve the accessibility of a product",
        "Improve the usability of a product",
      ],
      img: [],
      question:
        "The goal of User Experience Design is to achieve which of the following?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Foundations of User Experience Design",
    },
    {
      options: ["this is a false statement", "this is a true statement"],
      img: [],
      question:
        "There is no universal definition of the product development cycle. Businesses disagree about how many stages the cycle includes. Even those who agree on the number of steps disagree about where one ends and next starts.",
      answer: "this is a true statement",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "The product development life cycle",
    },
    {
      options: [
        "Wireframing",
        "User research",
        "Writing design specifications",
        "Prototyping",
      ],
      img: [],
      question:
        "As an entry-level UX designer, you’ll have a lot of exciting opportunities to gain experience. When you first start out, you’ll probably take on a lot of different roles and responsibilities. This includes all of these but one",
      answer: "Writing design specifications",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [],
      img: [],
      question:
        "_____means providing people with the tools they need to accomplish their goals and support improved quality of life.",
      answer: "Equity",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [
        "Interface design",
        "Human Computer Interaction",
        "Fielding",
        "Information architecture",
      ],
      img: [],
      question:
        "User Experience Design (UXD or UED) is a design process whose sole objective is to design a system that offers a great experience to its users. Thus UXD embraces the theories of a number of disciplines. Which of these is not a field related to UXD?",
      answer: "Fielding",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [],
      img: [],
      question:
        "Fill in the blank: Hunter works at a startup as a user experience designer. Since Hunter is one of the only designers, she gets to oversee the majority of her UX processes. Throughout her work, Hunter discovers that she enjoys talking to users and testing designs more than actually designing things. For her next role, Hunter should consider becoming a _____.",
      answer: "UX Researcher",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "A UX design career journey",
    },
    {
      options: [
        "Expanding your skills in many different types of UX work.",
        "Becoming well-known in the industry for your expertise in a particular type of design.",
        "Trying a variety of responsibilities and finding an area of UX that you’re especially passionate about.",
        "Focusing on one type of design that you enjoy more than others.",
      ],
      img: [],
      question:
        "A generalist has a broad number of responsibilities. A majority of UX design jobs are generalist positions, especially at companies with fewer UX designers. Typically, entry-level UX designers work in generalist roles, and some people choose to stay in generalist design roles for their entire careers. There are a lot of benefits to being a generalist UX designer, like:",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [
        "Are there aspects of the design that consider the user’s feelings?",
        "Does the design help the user achieve a specific goal?",
        "Does the design inspire delight in the user?",
        "Does the design keep the user engaged throughout their experience?",
      ],
      img: [],
      question:
        "As you evaluate how enjoyable a product’s UX is, you can ask questions like: Identify the wrong statement",
      answer:
        "Are there aspects of the design that consider the user’s feelings?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [],
      img: [],
      question:
        "If you’re designing a new app to help working parents and guardians, your team might start the brainstorming stage by listing common problems that working parents and guardians face, like a lack of reliable childcare, transportation concerns, or trouble managing schedules. Your team might review user feedback about other similar products or the results of user surveys to help guide your ideas. After you’ve brainstormed lots of user problems, your team chooses one. What stage of the development cycle will your team move to?",
      answer: "Design",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "The product development life cycle",
    },
    {
      options: [
        "Wireframing",
        "User research",
        "Writing design specifications",
        "Prototyping",
      ],
      img: [],
      question:
        "As an entry-level UX designer, you’ll have a lot of exciting opportunities to gain experience. When you first start out, you’ll probably take on a lot of different roles and responsibilities. This includes all of these but one",
      answer: "Writing design specifications",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [
        "Interface design",
        "Human Computer Interaction",
        "Fielding",
        "Information architecture",
      ],
      img: [],
      question:
        "User Experience Design (UXD or UED) is a design process whose sole objective is to design a system that offers a great experience to its users. Thus UXD embraces the theories of a number of disciplines. Which of these is not a field related to UXD?",
      answer: "Fielding",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [
        "Expanding your skills in many different types of UX work.",
        "Becoming well-known in the industry for your expertise in a particular type of design.",
        "Trying a variety of responsibilities and finding an area of UX that you’re especially passionate about.",
        "Focusing on one type of design that you enjoy more than others.",
      ],
      img: [],
      question:
        "A generalist has a broad number of responsibilities. A majority of UX design jobs are generalist positions, especially at companies with fewer UX designers. Typically, entry-level UX designers work in generalist roles, and some people choose to stay in generalist design roles for their entire careers. There are a lot of benefits to being a generalist UX designer, like:",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [],
      img: [],
      question:
        "Fill in the blank: Hunter works at a startup as a user experience designer. Since Hunter is one of the only designers, she gets to oversee the majority of her UX processes. Throughout her work, Hunter discovers that she enjoys talking to users and testing designs more than actually designing things. For her next role, Hunter should consider becoming a _____.",
      answer: "UX Researcher",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "A UX design career journey",
    },
    {
      options: [
        "Demonstrate an understanding of needs and goals for both the business and users",
        "Support mid and senior-level UX professionals with their projects",
        "Help execute processes and create artifacts for information architecture (card sorting, sitemaps, flow diagrams, etc.)",
        "Assist with designing concepts, wireframes, and prototypes",
      ],
      img: [],
      question:
        "Which of these is/are job responsibilities of an entry-level UX designer?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: ["Usable", "Unique", "Cool", "Useful"],
      img: [],
      question:
        "Good design is easy to spot but often hard to pin down. What exactly makes a product effective to its users? Which of these characteristics can help you evaluate a product’s design?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [
        "Improve the pleasantness of a digital product",
        "Improve the marketability of a product",
        "Improve the accessibility of a product",
        "Improve the usability of a product",
      ],
      img: [],
      question:
        "The goal of User Experience Design is to achieve which of the following?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [],
      img: [],
      question:
        "Fill in the blank: Hunter works at a startup as a user experience designer. Since Hunter is one of the only designers, she gets to oversee the majority of her UX processes. Throughout her work, Hunter discovers that she enjoys talking to users and testing designs more than actually designing things. For her next role, Hunter should consider becoming a _____.",
      answer: "UX Researcher",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "A UX design career journey",
    },
    {
      options: [],
      img: [],
      question:
        "As you get further along in your career, you can choose to specialize in a certain area of UX design or keep your skillset more broad. Assuming you want to keep your knowledge broad, which type of designer will you be?",
      answer: "Generalist",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: ["this is a false statement", "this is a true statement"],
      img: [],
      question:
        "There is no universal definition of the product development cycle. Businesses disagree about how many stages the cycle includes. Even those who agree on the number of steps disagree about where one ends and next starts.",
      answer: "this is a true statement",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "The product development life cycle",
    },
    {
      options: [
        "Interface design",
        "Human Computer Interaction",
        "Fielding",
        "Information architecture",
      ],
      img: [],
      question:
        "User Experience Design (UXD or UED) is a design process whose sole objective is to design a system that offers a great experience to its users. Thus UXD embraces the theories of a number of disciplines. Which of these is not a field related to UXD?",
      answer: "Fielding",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [],
      img: [],
      question:
        "_____means providing people with the tools they need to accomplish their goals and support improved quality of life.",
      answer: "Equity",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [
        "Expanding your skills in many different types of UX work.",
        "Becoming well-known in the industry for your expertise in a particular type of design.",
        "Trying a variety of responsibilities and finding an area of UX that you’re especially passionate about.",
        "Focusing on one type of design that you enjoy more than others.",
      ],
      img: [],
      question:
        "A generalist has a broad number of responsibilities. A majority of UX design jobs are generalist positions, especially at companies with fewer UX designers. Typically, entry-level UX designers work in generalist roles, and some people choose to stay in generalist design roles for their entire careers. There are a lot of benefits to being a generalist UX designer, like:",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [
        "Wireframing",
        "User research",
        "Writing design specifications",
        "Prototyping",
      ],
      img: [],
      question:
        "As an entry-level UX designer, you’ll have a lot of exciting opportunities to gain experience. When you first start out, you’ll probably take on a lot of different roles and responsibilities. This includes all of these but one",
      answer: "Writing design specifications",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: ["this is a false statement", "this is a true statement"],
      img: [],
      question:
        "There is no universal definition of the product development cycle. Businesses disagree about how many stages the cycle includes. Even those who agree on the number of steps disagree about where one ends and next starts.",
      answer: "this is a true statement",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "The product development life cycle",
    },
    {
      options: [],
      img: [],
      question:
        "Fill in the blank: Hunter works at a startup as a user experience designer. Since Hunter is one of the only designers, she gets to oversee the majority of her UX processes. Throughout her work, Hunter discovers that she enjoys talking to users and testing designs more than actually designing things. For her next role, Hunter should consider becoming a _____.",
      answer: "UX Researcher",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "A UX design career journey",
    },
    {
      options: [],
      img: [],
      question:
        "If you’re designing a new app to help working parents and guardians, your team might start the brainstorming stage by listing common problems that working parents and guardians face, like a lack of reliable childcare, transportation concerns, or trouble managing schedules. Your team might review user feedback about other similar products or the results of user surveys to help guide your ideas. After you’ve brainstormed lots of user problems, your team chooses one. What stage of the development cycle will your team move to?",
      answer: "Design",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "The product development life cycle",
    },
    {
      options: [],
      img: [],
      question:
        "As you get further along in your career, you can choose to specialize in a certain area of UX design or keep your skillset more broad. Assuming you want to keep your knowledge broad, which type of designer will you be?",
      answer: "Generalist",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [
        "Working at a design studio",
        "Working for a city government",
        "Working for a large company",
        "Working at a small startup",
      ],
      img: [],
      question:
        "John is starting a career in UX and wants to work in a company with room for growth. John prefers a fast-paced, unstructured work environment. Which job is a good fit for John?",
      answer: "Working at a small startup",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: ["Usable", "Unique", "Cool", "Useful"],
      img: [],
      question:
        "Good design is easy to spot but often hard to pin down. What exactly makes a product effective to its users? Which of these characteristics can help you evaluate a product’s design?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [
        "Interface design",
        "Human Computer Interaction",
        "Fielding",
        "Information architecture",
      ],
      img: [],
      question:
        "User Experience Design (UXD or UED) is a design process whose sole objective is to design a system that offers a great experience to its users. Thus UXD embraces the theories of a number of disciplines. Which of these is not a field related to UXD?",
      answer: "Fielding",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [],
      img: [],
      question:
        "Fill in the blank: Hunter works at a startup as a user experience designer. Since Hunter is one of the only designers, she gets to oversee the majority of her UX processes. Throughout her work, Hunter discovers that she enjoys talking to users and testing designs more than actually designing things. For her next role, Hunter should consider becoming a _____.",
      answer: "UX Researcher",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "A UX design career journey",
    },
    {
      options: [
        "Wireframing",
        "User research",
        "Writing design specifications",
        "Prototyping",
      ],
      img: [],
      question:
        "As an entry-level UX designer, you’ll have a lot of exciting opportunities to gain experience. When you first start out, you’ll probably take on a lot of different roles and responsibilities. This includes all of these but one",
      answer: "Writing design specifications",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [
        "work closely with a supervisor to learn more about a job or industry",
        "market their services to businesses to find customers",
        "receive on-the-job training from an experienced professional",
        "find short-term jobs with limited responsibility",
      ],
      img: [],
      question: "Designers that work as freelancers can expect to _____.",
      answer: "find short-term jobs with limited responsibility",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "A UX design career journey",
    },
    {
      options: [
        "Are there aspects of the design that consider the user’s feelings?",
        "Does the design help the user achieve a specific goal?",
        "Does the design inspire delight in the user?",
        "Does the design keep the user engaged throughout their experience?",
      ],
      img: [],
      question:
        "As you evaluate how enjoyable a product’s UX is, you can ask questions like: Identify the wrong statement",
      answer:
        "Are there aspects of the design that consider the user’s feelings?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Characteristics of a good user experience",
    },
    {
      options: ["Generalist", "T-shaped", "Specialist", "Manager"],
      img: [],
      question:
        "Duduke is a newly employed designer who does know how to empathize and take feedback from users very well but knows little about interaction design and other aspects of designs. Which role best describes Trail's new designer?",
      answer: "T-shaped",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: ["this is a false statement", "this is a true statement"],
      img: [],
      question:
        "There is no universal definition of the product development cycle. Businesses disagree about how many stages the cycle includes. Even those who agree on the number of steps disagree about where one ends and next starts.",
      answer: "this is a true statement",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "The product development life cycle",
    },
    {
      options: [
        "Interface design",
        "Human Computer Interaction",
        "Fielding",
        "Information architecture",
      ],
      img: [],
      question:
        "User Experience Design (UXD or UED) is a design process whose sole objective is to design a system that offers a great experience to its users. Thus UXD embraces the theories of a number of disciplines. Which of these is not a field related to UXD?",
      answer: "Fielding",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [
        "Demonstrate an understanding of needs and goals for both the business and users",
        "Support mid and senior-level UX professionals with their projects",
        "Help execute processes and create artifacts for information architecture (card sorting, sitemaps, flow diagrams, etc.)",
        "Assist with designing concepts, wireframes, and prototypes",
      ],
      img: [],
      question:
        "Which of these is/are job responsibilities of an entry-level UX designer?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [
        "Expanding your skills in many different types of UX work.",
        "Becoming well-known in the industry for your expertise in a particular type of design.",
        "Trying a variety of responsibilities and finding an area of UX that you’re especially passionate about.",
        "Focusing on one type of design that you enjoy more than others.",
      ],
      img: [],
      question:
        "A generalist has a broad number of responsibilities. A majority of UX design jobs are generalist positions, especially at companies with fewer UX designers. Typically, entry-level UX designers work in generalist roles, and some people choose to stay in generalist design roles for their entire careers. There are a lot of benefits to being a generalist UX designer, like:",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [
        "Are there aspects of the design that consider the user’s feelings?",
        "Does the design help the user achieve a specific goal?",
        "Does the design inspire delight in the user?",
        "Does the design keep the user engaged throughout their experience?",
      ],
      img: [],
      question:
        "As you evaluate how enjoyable a product’s UX is, you can ask questions like: Identify the wrong statement",
      answer:
        "Are there aspects of the design that consider the user’s feelings?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [
        "Interface design",
        "Human Computer Interaction",
        "Fielding",
        "Information architecture",
      ],
      img: [],
      question:
        "User Experience Design (UXD or UED) is a design process whose sole objective is to design a system that offers a great experience to its users. Thus UXD embraces the theories of a number of disciplines. Which of these is not a field related to UXD?",
      answer: "Fielding",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Foundations of User Experience Design",
    },
    {
      options: ["this is a false statement", "this is a true statement"],
      img: [],
      question:
        "There is no universal definition of the product development cycle. Businesses disagree about how many stages the cycle includes. Even those who agree on the number of steps disagree about where one ends and next starts.",
      answer: "this is a true statement",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "The product development life cycle",
    },
    {
      options: [
        "work closely with a supervisor to learn more about a job or industry",
        "market their services to businesses to find customers",
        "receive on-the-job training from an experienced professional",
        "find short-term jobs with limited responsibility",
      ],
      img: [],
      question: "Designers that work as freelancers can expect to _____.",
      answer: "find short-term jobs with limited responsibility",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "A UX design career journey",
    },
    {
      options: [],
      img: [],
      question:
        "If you’re designing a new app to help working parents and guardians, your team might start the brainstorming stage by listing common problems that working parents and guardians face, like a lack of reliable childcare, transportation concerns, or trouble managing schedules. Your team might review user feedback about other similar products or the results of user surveys to help guide your ideas. After you’ve brainstormed lots of user problems, your team chooses one. What stage of the development cycle will your team move to?",
      answer: "Design",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "The product development life cycle",
    },
    {
      options: ["Generalist", "T-shaped", "Specialist", "Manager"],
      img: [],
      question:
        "Duduke is a newly employed designer who does know how to empathize and take feedback from users very well but knows little about interaction design and other aspects of designs. Which role best describes Trail's new designer?",
      answer: "T-shaped",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [
        "Wireframing",
        "User research",
        "Writing design specifications",
        "Prototyping",
      ],
      img: [],
      question:
        "As an entry-level UX designer, you’ll have a lot of exciting opportunities to gain experience. When you first start out, you’ll probably take on a lot of different roles and responsibilities. This includes all of these but one",
      answer: "Writing design specifications",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [
        "Interface design",
        "Human Computer Interaction",
        "Fielding",
        "Information architecture",
      ],
      img: [],
      question:
        "User Experience Design (UXD or UED) is a design process whose sole objective is to design a system that offers a great experience to its users. Thus UXD embraces the theories of a number of disciplines. Which of these is not a field related to UXD?",
      answer: "Fielding",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [
        "work closely with a supervisor to learn more about a job or industry",
        "market their services to businesses to find customers",
        "receive on-the-job training from an experienced professional",
        "find short-term jobs with limited responsibility",
      ],
      img: [],
      question: "Designers that work as freelancers can expect to _____.",
      answer: "find short-term jobs with limited responsibility",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "A UX design career journey",
    },
    {
      options: [],
      img: [],
      question:
        "_____means providing people with the tools they need to accomplish their goals and support improved quality of life.",
      answer: "Equity",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [
        "Are there aspects of the design that consider the user’s feelings?",
        "Does the design help the user achieve a specific goal?",
        "Does the design inspire delight in the user?",
        "Does the design keep the user engaged throughout their experience?",
      ],
      img: [],
      question:
        "As you evaluate how enjoyable a product’s UX is, you can ask questions like: Identify the wrong statement",
      answer:
        "Are there aspects of the design that consider the user’s feelings?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [
        "Improve the pleasantness of a digital product",
        "Improve the marketability of a product",
        "Improve the accessibility of a product",
        "Improve the usability of a product",
      ],
      img: [],
      question:
        "The goal of User Experience Design is to achieve which of the following?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [
        "Working at a design studio",
        "Working for a city government",
        "Working for a large company",
        "Working at a small startup",
      ],
      img: [],
      question:
        "John is starting a career in UX and wants to work in a company with room for growth. John prefers a fast-paced, unstructured work environment. Which job is a good fit for John?",
      answer: "Working at a small startup",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [],
      img: [],
      question:
        "Fill in the blank: Hunter works at a startup as a user experience designer. Since Hunter is one of the only designers, she gets to oversee the majority of her UX processes. Throughout her work, Hunter discovers that she enjoys talking to users and testing designs more than actually designing things. For her next role, Hunter should consider becoming a _____.",
      answer: "UX Researcher",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "A UX design career journey",
    },
    {
      options: ["Generalist", "T-shaped", "Specialist", "Manager"],
      img: [],
      question:
        "Duduke is a newly employed designer who does know how to empathize and take feedback from users very well but knows little about interaction design and other aspects of designs. Which role best describes Trail's new designer?",
      answer: "T-shaped",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: ["1,2,3,4,5", "3,2,5,1,4", "3,5,1,2,4", "2,3,5,1,4"],
      img: [],
      question:
        "Every new product, whether it’s an app or a physical object, follows a specific set of steps that take it from the first spark of an idea to the release of the final product. This is called the product development life cycle, and it has five stages:, 1. (test) 2. (define), 3. ( brainstorm), 4. (Launch) and 5. (Design). Which of the following has the right arrangement;",
      answer: "3,2,5,1,4",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "The product development life cycle",
    },
    {
      options: ["Usable", "Unique", "Cool", "Useful"],
      img: [],
      question:
        "Good design is easy to spot but often hard to pin down. What exactly makes a product effective to its users? Which of these characteristics can help you evaluate a product’s design?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Characteristics of a good user experience",
    },
    {
      options: ["1,2,3,4,5", "3,2,5,1,4", "3,5,1,2,4", "2,3,5,1,4"],
      img: [],
      question:
        "Every new product, whether it’s an app or a physical object, follows a specific set of steps that take it from the first spark of an idea to the release of the final product. This is called the product development life cycle, and it has five stages:, 1. (test) 2. (define), 3. ( brainstorm), 4. (Launch) and 5. (Design). Which of the following has the right arrangement;",
      answer: "3,2,5,1,4",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "The product development life cycle",
    },
    {
      options: [
        "Wireframing",
        "User research",
        "Writing design specifications",
        "Prototyping",
      ],
      img: [],
      question:
        "As an entry-level UX designer, you’ll have a lot of exciting opportunities to gain experience. When you first start out, you’ll probably take on a lot of different roles and responsibilities. This includes all of these but one",
      answer: "Writing design specifications",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [
        "Improve the pleasantness of a digital product",
        "Improve the marketability of a product",
        "Improve the accessibility of a product",
        "Improve the usability of a product",
      ],
      img: [],
      question:
        "The goal of User Experience Design is to achieve which of the following?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [
        "Expanding your skills in many different types of UX work.",
        "Becoming well-known in the industry for your expertise in a particular type of design.",
        "Trying a variety of responsibilities and finding an area of UX that you’re especially passionate about.",
        "Focusing on one type of design that you enjoy more than others.",
      ],
      img: [],
      question:
        "A generalist has a broad number of responsibilities. A majority of UX design jobs are generalist positions, especially at companies with fewer UX designers. Typically, entry-level UX designers work in generalist roles, and some people choose to stay in generalist design roles for their entire careers. There are a lot of benefits to being a generalist UX designer, like:",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [],
      img: [],
      question:
        "Fill in the blank: Hunter works at a startup as a user experience designer. Since Hunter is one of the only designers, she gets to oversee the majority of her UX processes. Throughout her work, Hunter discovers that she enjoys talking to users and testing designs more than actually designing things. For her next role, Hunter should consider becoming a _____.",
      answer: "UX Researcher",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "A UX design career journey",
    },
    {
      options: ["Usable", "Unique", "Cool", "Useful"],
      img: [],
      question:
        "Good design is easy to spot but often hard to pin down. What exactly makes a product effective to its users? Which of these characteristics can help you evaluate a product’s design?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Characteristics of a good user experience",
    },
    {
      options: ["1,2,3,4,5", "3,2,5,1,4", "3,5,1,2,4", "2,3,5,1,4"],
      img: [],
      question:
        "Every new product, whether it’s an app or a physical object, follows a specific set of steps that take it from the first spark of an idea to the release of the final product. This is called the product development life cycle, and it has five stages:, 1. (test) 2. (define), 3. ( brainstorm), 4. (Launch) and 5. (Design). Which of the following has the right arrangement;",
      answer: "3,2,5,1,4",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "The product development life cycle",
    },
    {
      options: [
        "Demonstrate an understanding of needs and goals for both the business and users",
        "Support mid and senior-level UX professionals with their projects",
        "Help execute processes and create artifacts for information architecture (card sorting, sitemaps, flow diagrams, etc.)",
        "Assist with designing concepts, wireframes, and prototypes",
      ],
      img: [],
      question:
        "Which of these is/are job responsibilities of an entry-level UX designer?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [
        "Interface design",
        "Human Computer Interaction",
        "Fielding",
        "Information architecture",
      ],
      img: [],
      question:
        "User Experience Design (UXD or UED) is a design process whose sole objective is to design a system that offers a great experience to its users. Thus UXD embraces the theories of a number of disciplines. Which of these is not a field related to UXD?",
      answer: "Fielding",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [
        "Expanding your skills in many different types of UX work.",
        "Becoming well-known in the industry for your expertise in a particular type of design.",
        "Trying a variety of responsibilities and finding an area of UX that you’re especially passionate about.",
        "Focusing on one type of design that you enjoy more than others.",
      ],
      img: [],
      question:
        "A generalist has a broad number of responsibilities. A majority of UX design jobs are generalist positions, especially at companies with fewer UX designers. Typically, entry-level UX designers work in generalist roles, and some people choose to stay in generalist design roles for their entire careers. There are a lot of benefits to being a generalist UX designer, like:",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [],
      img: [],
      question:
        "Fill in the blank: Hunter works at a startup as a user experience designer. Since Hunter is one of the only designers, she gets to oversee the majority of her UX processes. Throughout her work, Hunter discovers that she enjoys talking to users and testing designs more than actually designing things. For her next role, Hunter should consider becoming a _____.",
      answer: "UX Researcher",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "A UX design career journey",
    },
    {
      options: [],
      img: [],
      question:
        "_____means providing people with the tools they need to accomplish their goals and support improved quality of life.",
      answer: "Equity",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "Characteristics of a good user experience",
    },
    {
      options: ["1,2,3,4,5", "3,2,5,1,4", "3,5,1,2,4", "2,3,5,1,4"],
      img: [],
      question:
        "Every new product, whether it’s an app or a physical object, follows a specific set of steps that take it from the first spark of an idea to the release of the final product. This is called the product development life cycle, and it has five stages:, 1. (test) 2. (define), 3. ( brainstorm), 4. (Launch) and 5. (Design). Which of the following has the right arrangement;",
      answer: "3,2,5,1,4",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "The product development life cycle",
    },
    {
      options: [
        "Wireframing",
        "User research",
        "Writing design specifications",
        "Prototyping",
      ],
      img: [],
      question:
        "As an entry-level UX designer, you’ll have a lot of exciting opportunities to gain experience. When you first start out, you’ll probably take on a lot of different roles and responsibilities. This includes all of these but one",
      answer: "Writing design specifications",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [],
      img: [],
      question:
        "Fill in the blank: Hunter works at a startup as a user experience designer. Since Hunter is one of the only designers, she gets to oversee the majority of her UX processes. Throughout her work, Hunter discovers that she enjoys talking to users and testing designs more than actually designing things. For her next role, Hunter should consider becoming a _____.",
      answer: "UX Researcher",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "A UX design career journey",
    },
    {
      options: [
        "Improve the pleasantness of a digital product",
        "Improve the marketability of a product",
        "Improve the accessibility of a product",
        "Improve the usability of a product",
      ],
      img: [],
      question:
        "The goal of User Experience Design is to achieve which of the following?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [
        "Expanding your skills in many different types of UX work.",
        "Becoming well-known in the industry for your expertise in a particular type of design.",
        "Trying a variety of responsibilities and finding an area of UX that you’re especially passionate about.",
        "Focusing on one type of design that you enjoy more than others.",
      ],
      img: [],
      question:
        "A generalist has a broad number of responsibilities. A majority of UX design jobs are generalist positions, especially at companies with fewer UX designers. Typically, entry-level UX designers work in generalist roles, and some people choose to stay in generalist design roles for their entire careers. There are a lot of benefits to being a generalist UX designer, like:",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [
        "Expanding your skills in many different types of UX work.",
        "Becoming well-known in the industry for your expertise in a particular type of design.",
        "Trying a variety of responsibilities and finding an area of UX that you’re especially passionate about.",
        "Focusing on one type of design that you enjoy more than others.",
      ],
      img: [],
      question:
        "A generalist has a broad number of responsibilities. A majority of UX design jobs are generalist positions, especially at companies with fewer UX designers. Typically, entry-level UX designers work in generalist roles, and some people choose to stay in generalist design roles for their entire careers. There are a lot of benefits to being a generalist UX designer, like:",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [
        "Are there aspects of the design that consider the user’s feelings?",
        "Does the design help the user achieve a specific goal?",
        "Does the design inspire delight in the user?",
        "Does the design keep the user engaged throughout their experience?",
      ],
      img: [],
      question:
        "As you evaluate how enjoyable a product’s UX is, you can ask questions like: Identify the wrong statement",
      answer:
        "Are there aspects of the design that consider the user’s feelings?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [
        "Working at a design studio",
        "Working for a city government",
        "Working for a large company",
        "Working at a small startup",
      ],
      img: [],
      question:
        "John is starting a career in UX and wants to work in a company with room for growth. John prefers a fast-paced, unstructured work environment. Which job is a good fit for John?",
      answer: "Working at a small startup",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [],
      img: [],
      question:
        "Fill in the blank: Hunter works at a startup as a user experience designer. Since Hunter is one of the only designers, she gets to oversee the majority of her UX processes. Throughout her work, Hunter discovers that she enjoys talking to users and testing designs more than actually designing things. For her next role, Hunter should consider becoming a _____.",
      answer: "UX Researcher",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "A UX design career journey",
    },
    {
      options: ["this is a false statement", "this is a true statement"],
      img: [],
      question:
        "There is no universal definition of the product development cycle. Businesses disagree about how many stages the cycle includes. Even those who agree on the number of steps disagree about where one ends and next starts.",
      answer: "this is a true statement",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "The product development life cycle",
    },
    {
      options: [
        "Improve the pleasantness of a digital product",
        "Improve the marketability of a product",
        "Improve the accessibility of a product",
        "Improve the usability of a product",
      ],
      img: [],
      question:
        "The goal of User Experience Design is to achieve which of the following?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [
        "Interface design",
        "Human Computer Interaction",
        "Fielding",
        "Information architecture",
      ],
      img: [],
      question:
        "User Experience Design (UXD or UED) is a design process whose sole objective is to design a system that offers a great experience to its users. Thus UXD embraces the theories of a number of disciplines. Which of these is not a field related to UXD?",
      answer: "Fielding",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [
        "Wireframing",
        "User research",
        "Writing design specifications",
        "Prototyping",
      ],
      img: [],
      question:
        "As an entry-level UX designer, you’ll have a lot of exciting opportunities to gain experience. When you first start out, you’ll probably take on a lot of different roles and responsibilities. This includes all of these but one",
      answer: "Writing design specifications",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [],
      img: [],
      question:
        "Fill in the blank: Hunter works at a startup as a user experience designer. Since Hunter is one of the only designers, she gets to oversee the majority of her UX processes. Throughout her work, Hunter discovers that she enjoys talking to users and testing designs more than actually designing things. For her next role, Hunter should consider becoming a _____.",
      answer: "UX Researcher",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "A UX design career journey",
    },
    {
      options: [
        "Expanding your skills in many different types of UX work.",
        "Becoming well-known in the industry for your expertise in a particular type of design.",
        "Trying a variety of responsibilities and finding an area of UX that you’re especially passionate about.",
        "Focusing on one type of design that you enjoy more than others.",
      ],
      img: [],
      question:
        "A generalist has a broad number of responsibilities. A majority of UX design jobs are generalist positions, especially at companies with fewer UX designers. Typically, entry-level UX designers work in generalist roles, and some people choose to stay in generalist design roles for their entire careers. There are a lot of benefits to being a generalist UX designer, like:",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [],
      img: [],
      question:
        "If you’re designing a new app to help working parents and guardians, your team might start the brainstorming stage by listing common problems that working parents and guardians face, like a lack of reliable childcare, transportation concerns, or trouble managing schedules. Your team might review user feedback about other similar products or the results of user surveys to help guide your ideas. After you’ve brainstormed lots of user problems, your team chooses one. What stage of the development cycle will your team move to?",
      answer: "Design",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "The product development life cycle",
    },
    {
      options: [
        "Are there aspects of the design that consider the user’s feelings?",
        "Does the design help the user achieve a specific goal?",
        "Does the design inspire delight in the user?",
        "Does the design keep the user engaged throughout their experience?",
      ],
      img: [],
      question:
        "As you evaluate how enjoyable a product’s UX is, you can ask questions like: Identify the wrong statement",
      answer:
        "Are there aspects of the design that consider the user’s feelings?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [
        "Interface design",
        "Human Computer Interaction",
        "Fielding",
        "Information architecture",
      ],
      img: [],
      question:
        "User Experience Design (UXD or UED) is a design process whose sole objective is to design a system that offers a great experience to its users. Thus UXD embraces the theories of a number of disciplines. Which of these is not a field related to UXD?",
      answer: "Fielding",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [
        "Demonstrate an understanding of needs and goals for both the business and users",
        "Support mid and senior-level UX professionals with their projects",
        "Help execute processes and create artifacts for information architecture (card sorting, sitemaps, flow diagrams, etc.)",
        "Assist with designing concepts, wireframes, and prototypes",
      ],
      img: [],
      question:
        "Which of these is/are job responsibilities of an entry-level UX designer?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [],
      img: [],
      question:
        "If you’re designing a new app to help working parents and guardians, your team might start the brainstorming stage by listing common problems that working parents and guardians face, like a lack of reliable childcare, transportation concerns, or trouble managing schedules. Your team might review user feedback about other similar products or the results of user surveys to help guide your ideas. After you’ve brainstormed lots of user problems, your team chooses one. What stage of the development cycle will your team move to?",
      answer: "Design",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "The product development life cycle",
    },
    {
      options: ["Generalist", "T-shaped", "Specialist", "Manager"],
      img: [],
      question:
        "Duduke is a newly employed designer who does know how to empathize and take feedback from users very well but knows little about interaction design and other aspects of designs. Which role best describes Trail's new designer?",
      answer: "T-shaped",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [],
      img: [],
      question:
        "_____means providing people with the tools they need to accomplish their goals and support improved quality of life.",
      answer: "Equity",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [],
      img: [],
      question:
        "Fill in the blank: Hunter works at a startup as a user experience designer. Since Hunter is one of the only designers, she gets to oversee the majority of her UX processes. Throughout her work, Hunter discovers that she enjoys talking to users and testing designs more than actually designing things. For her next role, Hunter should consider becoming a _____.",
      answer: "UX Researcher",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "A UX design career journey",
    },
    {
      options: ["1,2,3,4,5", "3,2,5,1,4", "3,5,1,2,4", "2,3,5,1,4"],
      img: [],
      question:
        "Every new product, whether it’s an app or a physical object, follows a specific set of steps that take it from the first spark of an idea to the release of the final product. This is called the product development life cycle, and it has five stages:, 1. (test) 2. (define), 3. ( brainstorm), 4. (Launch) and 5. (Design). Which of the following has the right arrangement;",
      answer: "3,2,5,1,4",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "The product development life cycle",
    },
    {
      options: [
        "Demonstrate an understanding of needs and goals for both the business and users",
        "Support mid and senior-level UX professionals with their projects",
        "Help execute processes and create artifacts for information architecture (card sorting, sitemaps, flow diagrams, etc.)",
        "Assist with designing concepts, wireframes, and prototypes",
      ],
      img: [],
      question:
        "Which of these is/are job responsibilities of an entry-level UX designer?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [],
      img: [],
      question:
        "_____means providing people with the tools they need to accomplish their goals and support improved quality of life.",
      answer: "Equity",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [
        "Interface design",
        "Human Computer Interaction",
        "Fielding",
        "Information architecture",
      ],
      img: [],
      question:
        "User Experience Design (UXD or UED) is a design process whose sole objective is to design a system that offers a great experience to its users. Thus UXD embraces the theories of a number of disciplines. Which of these is not a field related to UXD?",
      answer: "Fielding",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [
        "work closely with a supervisor to learn more about a job or industry",
        "market their services to businesses to find customers",
        "receive on-the-job training from an experienced professional",
        "find short-term jobs with limited responsibility",
      ],
      img: [],
      question: "Designers that work as freelancers can expect to _____.",
      answer: "find short-term jobs with limited responsibility",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "A UX design career journey",
    },
    {
      options: [],
      img: [],
      question:
        "As you get further along in your career, you can choose to specialize in a certain area of UX design or keep your skillset more broad. Assuming you want to keep your knowledge broad, which type of designer will you be?",
      answer: "Generalist",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [
        "Improve the pleasantness of a digital product",
        "Improve the marketability of a product",
        "Improve the accessibility of a product",
        "Improve the usability of a product",
      ],
      img: [],
      question:
        "The goal of User Experience Design is to achieve which of the following?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [
        "work closely with a supervisor to learn more about a job or industry",
        "market their services to businesses to find customers",
        "receive on-the-job training from an experienced professional",
        "find short-term jobs with limited responsibility",
      ],
      img: [],
      question: "Designers that work as freelancers can expect to _____.",
      answer: "find short-term jobs with limited responsibility",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "A UX design career journey",
    },
    {
      options: ["Generalist", "T-shaped", "Specialist", "Manager"],
      img: [],
      question:
        "Duduke is a newly employed designer who does know how to empathize and take feedback from users very well but knows little about interaction design and other aspects of designs. Which role best describes Trail's new designer?",
      answer: "T-shaped",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [
        "Demonstrate an understanding of needs and goals for both the business and users",
        "Support mid and senior-level UX professionals with their projects",
        "Help execute processes and create artifacts for information architecture (card sorting, sitemaps, flow diagrams, etc.)",
        "Assist with designing concepts, wireframes, and prototypes",
      ],
      img: [],
      question:
        "Which of these is/are job responsibilities of an entry-level UX designer?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [],
      img: [],
      question:
        "_____means providing people with the tools they need to accomplish their goals and support improved quality of life.",
      answer: "Equity",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "Characteristics of a good user experience",
    },
    {
      options: ["this is a false statement", "this is a true statement"],
      img: [],
      question:
        "There is no universal definition of the product development cycle. Businesses disagree about how many stages the cycle includes. Even those who agree on the number of steps disagree about where one ends and next starts.",
      answer: "this is a true statement",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "The product development life cycle",
    },
    {
      options: [
        "Interface design",
        "Human Computer Interaction",
        "Fielding",
        "Information architecture",
      ],
      img: [],
      question:
        "User Experience Design (UXD or UED) is a design process whose sole objective is to design a system that offers a great experience to its users. Thus UXD embraces the theories of a number of disciplines. Which of these is not a field related to UXD?",
      answer: "Fielding",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [
        "Expanding your skills in many different types of UX work.",
        "Becoming well-known in the industry for your expertise in a particular type of design.",
        "Trying a variety of responsibilities and finding an area of UX that you’re especially passionate about.",
        "Focusing on one type of design that you enjoy more than others.",
      ],
      img: [],
      question:
        "A generalist has a broad number of responsibilities. A majority of UX design jobs are generalist positions, especially at companies with fewer UX designers. Typically, entry-level UX designers work in generalist roles, and some people choose to stay in generalist design roles for their entire careers. There are a lot of benefits to being a generalist UX designer, like:",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [
        "Are there aspects of the design that consider the user’s feelings?",
        "Does the design help the user achieve a specific goal?",
        "Does the design inspire delight in the user?",
        "Does the design keep the user engaged throughout their experience?",
      ],
      img: [],
      question:
        "As you evaluate how enjoyable a product’s UX is, you can ask questions like: Identify the wrong statement",
      answer:
        "Are there aspects of the design that consider the user’s feelings?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [
        "Demonstrate an understanding of needs and goals for both the business and users",
        "Support mid and senior-level UX professionals with their projects",
        "Help execute processes and create artifacts for information architecture (card sorting, sitemaps, flow diagrams, etc.)",
        "Assist with designing concepts, wireframes, and prototypes",
      ],
      img: [],
      question:
        "Which of these is/are job responsibilities of an entry-level UX designer?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: ["this is a false statement", "this is a true statement"],
      img: [],
      question:
        "There is no universal definition of the product development cycle. Businesses disagree about how many stages the cycle includes. Even those who agree on the number of steps disagree about where one ends and next starts.",
      answer: "this is a true statement",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "The product development life cycle",
    },
    {
      options: [
        "work closely with a supervisor to learn more about a job or industry",
        "market their services to businesses to find customers",
        "receive on-the-job training from an experienced professional",
        "find short-term jobs with limited responsibility",
      ],
      img: [],
      question: "Designers that work as freelancers can expect to _____.",
      answer: "find short-term jobs with limited responsibility",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "A UX design career journey",
    },
    {
      options: ["Generalist", "T-shaped", "Specialist", "Manager"],
      img: [],
      question:
        "Duduke is a newly employed designer who does know how to empathize and take feedback from users very well but knows little about interaction design and other aspects of designs. Which role best describes Trail's new designer?",
      answer: "T-shaped",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [],
      img: [],
      question:
        "_____means providing people with the tools they need to accomplish their goals and support improved quality of life.",
      answer: "Equity",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [
        "Working at a design studio",
        "Working for a city government",
        "Working for a large company",
        "Working at a small startup",
      ],
      img: [],
      question:
        "John is starting a career in UX and wants to work in a company with room for growth. John prefers a fast-paced, unstructured work environment. Which job is a good fit for John?",
      answer: "Working at a small startup",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: ["this is a false statement", "this is a true statement"],
      img: [],
      question:
        "There is no universal definition of the product development cycle. Businesses disagree about how many stages the cycle includes. Even those who agree on the number of steps disagree about where one ends and next starts.",
      answer: "this is a true statement",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "The product development life cycle",
    },
    {
      options: [
        "work closely with a supervisor to learn more about a job or industry",
        "market their services to businesses to find customers",
        "receive on-the-job training from an experienced professional",
        "find short-term jobs with limited responsibility",
      ],
      img: [],
      question: "Designers that work as freelancers can expect to _____.",
      answer: "find short-term jobs with limited responsibility",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "A UX design career journey",
    },
    {
      options: [
        "Improve the pleasantness of a digital product",
        "Improve the marketability of a product",
        "Improve the accessibility of a product",
        "Improve the usability of a product",
      ],
      img: [],
      question:
        "The goal of User Experience Design is to achieve which of the following?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [],
      img: [],
      question:
        "As you get further along in your career, you can choose to specialize in a certain area of UX design or keep your skillset more broad. Assuming you want to keep your knowledge broad, which type of designer will you be?",
      answer: "Generalist",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: ["this is a false statement", "this is a true statement"],
      img: [],
      question:
        "There is no universal definition of the product development cycle. Businesses disagree about how many stages the cycle includes. Even those who agree on the number of steps disagree about where one ends and next starts.",
      answer: "this is a true statement",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "The product development life cycle",
    },
    {
      options: [
        "Demonstrate an understanding of needs and goals for both the business and users",
        "Support mid and senior-level UX professionals with their projects",
        "Help execute processes and create artifacts for information architecture (card sorting, sitemaps, flow diagrams, etc.)",
        "Assist with designing concepts, wireframes, and prototypes",
      ],
      img: [],
      question:
        "Which of these is/are job responsibilities of an entry-level UX designer?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [
        "Improve the pleasantness of a digital product",
        "Improve the marketability of a product",
        "Improve the accessibility of a product",
        "Improve the usability of a product",
      ],
      img: [],
      question:
        "The goal of User Experience Design is to achieve which of the following?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [
        "work closely with a supervisor to learn more about a job or industry",
        "market their services to businesses to find customers",
        "receive on-the-job training from an experienced professional",
        "find short-term jobs with limited responsibility",
      ],
      img: [],
      question: "Designers that work as freelancers can expect to _____.",
      answer: "find short-term jobs with limited responsibility",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "A UX design career journey",
    },
    {
      options: ["Usable", "Unique", "Cool", "Useful"],
      img: [],
      question:
        "Good design is easy to spot but often hard to pin down. What exactly makes a product effective to its users? Which of these characteristics can help you evaluate a product’s design?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Characteristics of a good user experience",
    },
    {
      options: ["1,2,3,4,5", "3,2,5,1,4", "3,5,1,2,4", "2,3,5,1,4"],
      img: [],
      question:
        "Every new product, whether it’s an app or a physical object, follows a specific set of steps that take it from the first spark of an idea to the release of the final product. This is called the product development life cycle, and it has five stages:, 1. (test) 2. (define), 3. ( brainstorm), 4. (Launch) and 5. (Design). Which of the following has the right arrangement;",
      answer: "3,2,5,1,4",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "The product development life cycle",
    },
    {
      options: [
        "Improve the pleasantness of a digital product",
        "Improve the marketability of a product",
        "Improve the accessibility of a product",
        "Improve the usability of a product",
      ],
      img: [],
      question:
        "The goal of User Experience Design is to achieve which of the following?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Foundations of User Experience Design",
    },
    {
      options: ["Generalist", "T-shaped", "Specialist", "Manager"],
      img: [],
      question:
        "Duduke is a newly employed designer who does know how to empathize and take feedback from users very well but knows little about interaction design and other aspects of designs. Which role best describes Trail's new designer?",
      answer: "T-shaped",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [
        "Demonstrate an understanding of needs and goals for both the business and users",
        "Support mid and senior-level UX professionals with their projects",
        "Help execute processes and create artifacts for information architecture (card sorting, sitemaps, flow diagrams, etc.)",
        "Assist with designing concepts, wireframes, and prototypes",
      ],
      img: [],
      question:
        "Which of these is/are job responsibilities of an entry-level UX designer?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: ["Usable", "Unique", "Cool", "Useful"],
      img: [],
      question:
        "Good design is easy to spot but often hard to pin down. What exactly makes a product effective to its users? Which of these characteristics can help you evaluate a product’s design?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [
        "work closely with a supervisor to learn more about a job or industry",
        "market their services to businesses to find customers",
        "receive on-the-job training from an experienced professional",
        "find short-term jobs with limited responsibility",
      ],
      img: [],
      question: "Designers that work as freelancers can expect to _____.",
      answer: "find short-term jobs with limited responsibility",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "A UX design career journey",
    },
    {
      options: [
        "Interface design",
        "Human Computer Interaction",
        "Fielding",
        "Information architecture",
      ],
      img: [],
      question:
        "User Experience Design (UXD or UED) is a design process whose sole objective is to design a system that offers a great experience to its users. Thus UXD embraces the theories of a number of disciplines. Which of these is not a field related to UXD?",
      answer: "Fielding",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [
        "Are there aspects of the design that consider the user’s feelings?",
        "Does the design help the user achieve a specific goal?",
        "Does the design inspire delight in the user?",
        "Does the design keep the user engaged throughout their experience?",
      ],
      img: [],
      question:
        "As you evaluate how enjoyable a product’s UX is, you can ask questions like: Identify the wrong statement",
      answer:
        "Are there aspects of the design that consider the user’s feelings?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Characteristics of a good user experience",
    },
    {
      options: ["this is a false statement", "this is a true statement"],
      img: [],
      question:
        "There is no universal definition of the product development cycle. Businesses disagree about how many stages the cycle includes. Even those who agree on the number of steps disagree about where one ends and next starts.",
      answer: "this is a true statement",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "The product development life cycle",
    },
    {
      options: [
        "work closely with a supervisor to learn more about a job or industry",
        "market their services to businesses to find customers",
        "receive on-the-job training from an experienced professional",
        "find short-term jobs with limited responsibility",
      ],
      img: [],
      question: "Designers that work as freelancers can expect to _____.",
      answer: "find short-term jobs with limited responsibility",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "A UX design career journey",
    },
    {
      options: [
        "Expanding your skills in many different types of UX work.",
        "Becoming well-known in the industry for your expertise in a particular type of design.",
        "Trying a variety of responsibilities and finding an area of UX that you’re especially passionate about.",
        "Focusing on one type of design that you enjoy more than others.",
      ],
      img: [],
      question:
        "A generalist has a broad number of responsibilities. A majority of UX design jobs are generalist positions, especially at companies with fewer UX designers. Typically, entry-level UX designers work in generalist roles, and some people choose to stay in generalist design roles for their entire careers. There are a lot of benefits to being a generalist UX designer, like:",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [
        "Demonstrate an understanding of needs and goals for both the business and users",
        "Support mid and senior-level UX professionals with their projects",
        "Help execute processes and create artifacts for information architecture (card sorting, sitemaps, flow diagrams, etc.)",
        "Assist with designing concepts, wireframes, and prototypes",
      ],
      img: [],
      question:
        "Which of these is/are job responsibilities of an entry-level UX designer?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [
        "Working at a design studio",
        "Working for a city government",
        "Working for a large company",
        "Working at a small startup",
      ],
      img: [],
      question:
        "John is starting a career in UX and wants to work in a company with room for growth. John prefers a fast-paced, unstructured work environment. Which job is a good fit for John?",
      answer: "Working at a small startup",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [],
      img: [],
      question:
        "If you’re designing a new app to help working parents and guardians, your team might start the brainstorming stage by listing common problems that working parents and guardians face, like a lack of reliable childcare, transportation concerns, or trouble managing schedules. Your team might review user feedback about other similar products or the results of user surveys to help guide your ideas. After you’ve brainstormed lots of user problems, your team chooses one. What stage of the development cycle will your team move to?",
      answer: "Design",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "The product development life cycle",
    },
    {
      options: [
        "Improve the pleasantness of a digital product",
        "Improve the marketability of a product",
        "Improve the accessibility of a product",
        "Improve the usability of a product",
      ],
      img: [],
      question:
        "The goal of User Experience Design is to achieve which of the following?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [],
      img: [],
      question:
        "As you get further along in your career, you can choose to specialize in a certain area of UX design or keep your skillset more broad. Assuming you want to keep your knowledge broad, which type of designer will you be?",
      answer: "Generalist",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [],
      img: [],
      question:
        "Fill in the blank: Hunter works at a startup as a user experience designer. Since Hunter is one of the only designers, she gets to oversee the majority of her UX processes. Throughout her work, Hunter discovers that she enjoys talking to users and testing designs more than actually designing things. For her next role, Hunter should consider becoming a _____.",
      answer: "UX Researcher",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "A UX design career journey",
    },
    {
      options: ["Usable", "Unique", "Cool", "Useful"],
      img: [],
      question:
        "Good design is easy to spot but often hard to pin down. What exactly makes a product effective to its users? Which of these characteristics can help you evaluate a product’s design?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [
        "Expanding your skills in many different types of UX work.",
        "Becoming well-known in the industry for your expertise in a particular type of design.",
        "Trying a variety of responsibilities and finding an area of UX that you’re especially passionate about.",
        "Focusing on one type of design that you enjoy more than others.",
      ],
      img: [],
      question:
        "A generalist has a broad number of responsibilities. A majority of UX design jobs are generalist positions, especially at companies with fewer UX designers. Typically, entry-level UX designers work in generalist roles, and some people choose to stay in generalist design roles for their entire careers. There are a lot of benefits to being a generalist UX designer, like:",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: [
        "Working at a design studio",
        "Working for a city government",
        "Working for a large company",
        "Working at a small startup",
      ],
      img: [],
      question:
        "John is starting a career in UX and wants to work in a company with room for growth. John prefers a fast-paced, unstructured work environment. Which job is a good fit for John?",
      answer: "Working at a small startup",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [
        "Improve the pleasantness of a digital product",
        "Improve the marketability of a product",
        "Improve the accessibility of a product",
        "Improve the usability of a product",
      ],
      img: [],
      question:
        "The goal of User Experience Design is to achieve which of the following?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Foundations of User Experience Design",
    },
    {
      options: ["1,2,3,4,5", "3,2,5,1,4", "3,5,1,2,4", "2,3,5,1,4"],
      img: [],
      question:
        "Every new product, whether it’s an app or a physical object, follows a specific set of steps that take it from the first spark of an idea to the release of the final product. This is called the product development life cycle, and it has five stages:, 1. (test) 2. (define), 3. ( brainstorm), 4. (Launch) and 5. (Design). Which of the following has the right arrangement;",
      answer: "3,2,5,1,4",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "The product development life cycle",
    },
    {
      options: [
        "Are there aspects of the design that consider the user’s feelings?",
        "Does the design help the user achieve a specific goal?",
        "Does the design inspire delight in the user?",
        "Does the design keep the user engaged throughout their experience?",
      ],
      img: [],
      question:
        "As you evaluate how enjoyable a product’s UX is, you can ask questions like: Identify the wrong statement",
      answer:
        "Are there aspects of the design that consider the user’s feelings?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [],
      img: [],
      question:
        "Fill in the blank: Hunter works at a startup as a user experience designer. Since Hunter is one of the only designers, she gets to oversee the majority of her UX processes. Throughout her work, Hunter discovers that she enjoys talking to users and testing designs more than actually designing things. For her next role, Hunter should consider becoming a _____.",
      answer: "UX Researcher",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "A UX design career journey",
    },
    {
      options: [
        "Demonstrate an understanding of needs and goals for both the business and users",
        "Support mid and senior-level UX professionals with their projects",
        "Help execute processes and create artifacts for information architecture (card sorting, sitemaps, flow diagrams, etc.)",
        "Assist with designing concepts, wireframes, and prototypes",
      ],
      img: [],
      question:
        "Which of these is/are job responsibilities of an entry-level UX designer?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Job responsibilities of entry-level UX designers",
    },
    {
      options: [
        "Improve the pleasantness of a digital product",
        "Improve the marketability of a product",
        "Improve the accessibility of a product",
        "Improve the usability of a product",
      ],
      img: [],
      question:
        "The goal of User Experience Design is to achieve which of the following?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Foundations of User Experience Design",
    },
    {
      options: [],
      img: [],
      question:
        "As you get further along in your career, you can choose to specialize in a certain area of UX design or keep your skillset more broad. Assuming you want to keep your knowledge broad, which type of designer will you be?",
      answer: "Generalist",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "text",
      sub: "Specialists, generalists, and T-shaped designers",
    },
    {
      options: ["Usable", "Unique", "Cool", "Useful"],
      img: [],
      question:
        "Good design is easy to spot but often hard to pin down. What exactly makes a product effective to its users? Which of these characteristics can help you evaluate a product’s design?",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "check",
      sub: "Characteristics of a good user experience",
    },
    {
      options: [
        "work closely with a supervisor to learn more about a job or industry",
        "market their services to businesses to find customers",
        "receive on-the-job training from an experienced professional",
        "find short-term jobs with limited responsibility",
      ],
      img: [],
      question: "Designers that work as freelancers can expect to _____.",
      answer: "find short-term jobs with limited responsibility",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "A UX design career journey",
    },
    {
      options: ["1,2,3,4,5", "3,2,5,1,4", "3,5,1,2,4", "2,3,5,1,4"],
      img: [],
      question:
        "Every new product, whether it’s an app or a physical object, follows a specific set of steps that take it from the first spark of an idea to the release of the final product. This is called the product development life cycle, and it has five stages:, 1. (test) 2. (define), 3. ( brainstorm), 4. (Launch) and 5. (Design). Which of the following has the right arrangement;",
      answer: "3,2,5,1,4",
      section: {
        sub: [
          "Foundations of User Experience Design",
          "The product development life cycle",
          "Job responsibilities of entry-level UX designers",
          "Specialists, generalists, and T-shaped designers",
          "A UX design career journey",
          "Characteristics of a good user experience",
        ],
        title: "Getting Started With User Experience (UX) Design",
        no: 1,
      },
      type: "obj",
      sub: "The product development life cycle",
    },
  ],
  section: [
    {
      done: "pending",
      sub: [
        "Foundations of User Experience Design",
        "The product development life cycle",
        "Job responsibilities of entry-level UX designers",
        "Specialists, generalists, and T-shaped designers",
        "A UX design career journey",
        "Characteristics of a good user experience",
      ],
      title: "Getting Started With User Experience (UX) Design",
      no: 1,
      field: "61a29261205c2f12cc118dcb",
      resource: 12,
      duration: "2 hours",
      due: "2022-04-22T12:04:54.259Z",
    },
    {
      done: "undone",
      sub: [
        "The user, end-user, and user experience",
        "Key frameworks in UX design",
        "Universal design, inclusive design, and equity-focused design",
        "Designing cross-platform experiences & the Four Cs",
      ],
      title: "Introduction to Terms, Tools, & Frameworks in User Experience",
      no: 2,
      field: "61a29261205c2f12cc118dcb",
      resource: 7,
      duration: "48 mins",
      due: "2022-04-22T12:04:54.259Z",
    },
    {
      done: "undone",
      sub: [
        "Design sprints",
        "Designer’s role in a sprint",
        "Common retrospective questions in design",
      ],
      title: "Introduction to Design Sprints in UX Design",
      no: 3,
      field: "61a29261205c2f12cc118dcb",
      resource: 5,
      duration: "33 mins",
      due: "2022-04-29T12:04:54.259Z",
    },
    {
      done: "undone",
      sub: [
        "UX design portfolios",
        "Build an online presence",
        "Impostor syndrome",
        "Plan your portfolio website",
      ],
      title: "How to Build an Online Presence as a UX Designer",
      no: 4,
      field: "61a29261205c2f12cc118dcb",
      resource: 7,
      duration: "42 mins",
      due: "2022-04-29T12:04:54.259Z",
    },
    {
      done: "undone",
      sub: [
        "Empathize, Define, and Ideate",
        "UX research",
        "Design and the importance of feedback",
        "Research methods",
        "Biases in UX research",
      ],
      title: "Research in User Experience Design",
      no: 5,
      field: "61a29261205c2f12cc118dcb",
      resource: 10,
      duration: "2 hours",
      due: "2022-05-06T12:04:54.259Z",
    },
    {
      done: "undone",
      sub: [
        "Empathy in UX design",
        "User interviews",
        "Empathy Maps",
        "Identify user pain points",
      ],
      title: "Empathy in User Experience Research",
      no: 6,
      field: "61a29261205c2f12cc118dcb",
      resource: 8,
      duration: "2 hours",
      due: "2022-05-06T12:04:54.259Z",
    },
    {
      done: "undone",
      sub: [
        "User stories",
        "Edge cases",
        "Create a user journey map",
        "Understand the curb cut effect",
      ],
      title: "Integrating User Stories and User Journey Maps in UX Design",
      no: 7,
      field: "61a29261205c2f12cc118dcb",
      resource: 8,
      duration: "2 hours",
      due: "2022-05-13T12:04:54.259Z",
    },
    {
      done: "undone",
      sub: [
        "Create problem statements",
        "Define hypothesis statements",
        "Determine a value proposition",
        "Understand human factors",
        "Psychology principles that influence the design",
      ],
      title: "Understanding User Problems",
      no: 8,
      field: "61a29261205c2f12cc118dcb",
      resource: 8,
      duration: "56 mins",
      due: "2022-05-13T12:04:54.259Z",
    },
    {
      done: "undone",
      sub: [
        "Build Wireframes and Low-Fidelity Prototypes",
        "Use research to inform ideation",
        "Create goal statements",
        "User flows",
      ],
      title: "Getting Started With Storyboarding and Wireframing",
      no: 9,
      field: "61a29261205c2f12cc118dcb",
      resource: 7,
      duration: "57 mins",
      due: "2022-05-20T12:04:54.259Z",
    },
    {
      done: "undone",
      sub: [
        "Information architecture (IA)",
        "Create paper wireframes",
        "Introduction to Figma",
        "Create digital wireframes in Figma",
        "Use Gestalt Principles when creating wireframes",
        "Low-fidelity prototypes",
        "Create a paper prototype",
        "Build low-fidelity prototypes in Figma",
      ],
      title: "Getting Started With Paper and Digital Wireframes",
      no: 10,
      field: "61a29261205c2f12cc118dcb",
      resource: 14,
      duration: "4 hours",
      due: "2022-05-20T12:04:54.259Z",
    },
    {
      done: "undone",
      sub: [
        "Recognize implicit bias in design",
        "Identify deceptive patterns in UX design",
        "The importance of equitable design",
      ],
      title: "Integrating Ethics in UX Design",
      no: 11,
      field: "61a29261205c2f12cc118dcb",
      resource: 6,
      duration: "42 mins",
      due: "2022-05-27T12:04:54.259Z",
    },
    {
      done: "undone",
      sub: [
        "The UX research process",
        "Elements of a research plan",
        "Building a research plan: project background, research goals, and research questions",
        "Make privacy a part of UX practices",
        "Privacy issues",
      ],
      title: "How to Create User Experience Research Case Studies",
      no: 12,
      field: "61a29261205c2f12cc118dcb",
      resource: 6,
      duration: "48 mins",
      due: "2022-05-27T12:04:54.259Z",
    },
    {
      done: "undone",
      sub: [
        "Usability studies",
        "Bias in interviews",
        "Introduction to note-taking methods during usability studies",
      ],
      title: "Usability Studies",
      no: 13,
      field: "61a29261205c2f12cc118dcb",
      resource: 6,
      duration: "51 mins",
      due: "2022-06-03T12:04:54.259Z",
    },
    {
      done: "undone",
      sub: [
        "Developing insights from usability studies",
        "Organizing data from research",
      ],
      title: "Working With Results of User Research",
      no: 14,
      field: "61a29261205c2f12cc118dcb",
      resource: 2,
      duration: "9 mins",
      due: "2022-06-03T12:04:54.259Z",
    },
    {
      done: "undone",
      sub: [
        "Create a strong presentation",
        "Modify low-fidelity designs based on insights",
      ],
      title: "Getting Feedbacks in UX Design",
      no: 15,
      field: "61a29261205c2f12cc118dcb",
      resource: 3,
      duration: "26 mins",
      due: "2022-06-10T12:04:54.259Z",
    },
    {
      done: "undone",
      sub: [
        "The transition from low-fidelity to high-fidelity designs",
        "Elements of visual design in UX",
        "Typography in UX design",
        "Color in UX design",
        "Iconography in UX design",
        "Layouts",
      ],
      title: "Introduction to High Fidelity (Hifi) Designs",
      no: 16,
      field: "61a29261205c2f12cc118dcb",
      resource: 12,
      duration: "2 hours",
      due: "2022-06-10T12:04:54.259Z",
    },
    {
      done: "undone",
      sub: [
        "Emphasis in UX design",
        "Hierarchy in UX design",
        "Scale and proportion in UX design",
        "Unity and variety in UX design",
      ],
      title: "Integrating Visual Design Principles With UX Design",
      no: 17,
      field: "61a29261205c2f12cc118dcb",
      resource: 8,
      duration: "2 hours",
      due: "2022-06-17T12:04:54.259Z",
    },
    {
      done: "undone",
      sub: [
        "Design systems",
        "Google's design system: Material Design",
        "Shopify's design system: Polaris",
        "Ios design system: Aqua",
        "Use a design system library in Figma",
      ],
      title: "Introduction to Design Systems",
      no: 18,
      field: "61a29261205c2f12cc118dcb",
      resource: 9,
      duration: "2 hours",
      due: "2022-06-17T12:04:54.259Z",
    },
    {
      done: "undone",
      sub: [
        "Feedback in UX design",
        "The basics of design critique sessions",
        "Mock crit session",
      ],
      title: "Design Critique Sessions",
      no: 19,
      field: "61a29261205c2f12cc118dcb",
      resource: 6,
      duration: "53 mins",
      due: "2022-06-24T12:04:54.259Z",
    },
    {
      done: "undone",
      sub: [
        "High-fidelity prototypes",
        "Create a high-fidelity prototype in Figma",
        "Gestures and motion in UX design",
        "Add gestures and motion in Figma",
        "Learn from Figma - Prototype interactions and feedback",
      ],
      title: "How to Create High-Fidelity Prototypes",
      no: 20,
      field: "61a29261205c2f12cc118dcb",
      resource: 8,
      duration: "2 hours",
      due: "2022-06-24T12:04:54.259Z",
    },
    {
      done: "undone",
      sub: [
        "Conduct a usability study",
        "Analyze and synthesize usability study results",
        "Iterate on high-fidelity designs",
        "Identify when a design is complete",
        "Hand off designs for production",
        "Create a case study for your portfolio project",
      ],
      title: "User Testing & Design Iterations",
      no: 21,
      field: "61a29261205c2f12cc118dcb",
      resource: 11,
      duration: "3 hours",
      due: "2022-07-01T12:04:54.259Z",
    },
    {
      done: "undone",
      sub: [
        "Introduction to Adobe XD",
        "Create a low-fidelity prototype in Adobe XD",
        "Create hifi for a website homepage in Adobe XD",
        "Create a high-fidelity prototype in Adobe XD",
        "Annotate prototypes for web accessibility",
      ],
      title: "Getting Started With Adobe XD",
      no: 22,
      field: "61a29261205c2f12cc118dcb",
      resource: 10,
      duration: "3 hours",
      due: "2022-07-01T12:04:54.259Z",
    },
    {
      done: "undone",
      sub: [
        "Create personas",
        "Conduct a competitive audit",
        "‘How Might We’ ideation process",
        "‘Crazy Eights’ ideation process",
        "Build a sitemap for your website",
      ],
      title: "Ideation in the UX Design Journey",
      no: 23,
      field: "61a29261205c2f12cc118dcb",
      resource: 10,
      duration: "2 hours",
      due: "2022-07-08T12:04:54.259Z",
    },
    {
      done: "undone",
      sub: [
        "Understand responsive websites",
        "Responsive wireframes",
        "Create digital wireframes in Adobe XD",
      ],
      title: "Integrating Wireframes for a Responsive Website",
      no: 24,
      field: "61a29261205c2f12cc118dcb",
      resource: 6,
      duration: "36 mins",
      due: "2022-07-08T12:04:54.259Z",
    },
    {
      done: "undone",
      sub: [
        "How to collaborate with engineering teams",
        "Handoff design specs in Adobe XD",
        "Understand UX design resumes",
      ],
      title: "Collaboration with Non-Design Stakeholders",
      no: 25,
      field: "61a29261205c2f12cc118dcb",
      resource: 9,
      duration: "2 hours",
      due: "2022-07-15T12:04:54.259Z",
    },
  ],
  refCode: "orgDiscount",
  assistant: [
    {
      rate: "regular",
      field: "61a29261205c2f12cc118dcb",
    },
  ],
  phoneNo: "+2348123884098",
  reminder: {
    whatsapp: true,
    gmail: true,
  },
  segment: ["jjjj", "Tag", " Red", "tech"],
  jobPercent: [
    {
      name: "UX Design",
      value: 1,
      jobId: "646f55434da50052a17a132e",
    },
  ],
  skillPercent: [
    {
      name: "Data Analysis",
      value: 39,
      role: "646f55434da50052a17a132e",
      skillGroup: "646fda03eb15635ce11e3296",
    },
  ],
  dataHistory: [
    {
      date: "2023-02-28T21:17:14.216Z",
      skill: {
        value: 25,
        percent: 83,
      },
      timeLearning: {
        value: 575,
        percent: 100,
      },
      resourceCount: {
        value: 25,
        percent: 100,
      },
      skillGap: {
        value: 0,
        percent: 100,
      },
    },
    {
      date: "2023-03-04T20:52:19.530Z",
      skill: {
        value: 25,
        percent: 83,
      },
      timeLearning: {
        value: 575,
        percent: 100,
      },
      resourceCount: {
        value: 25,
        percent: 100,
      },
      skillGap: {
        value: 0,
        percent: 100,
      },
    },
    {
      date: "2023-03-05T22:21:41.259Z",
      skill: {
        value: 25,
        percent: 83,
      },
      timeLearning: {
        value: 575,
        percent: 100,
      },
      resourceCount: {
        value: 25,
        percent: 100,
      },
      skillGap: {
        value: 0,
        percent: 100,
      },
    },
    {
      date: "2023-03-06T11:03:40.886Z",
      skill: {
        value: 25,
        percent: 83,
      },
      timeLearning: {
        value: 575,
        percent: 100,
      },
      resourceCount: {
        value: 25,
        percent: 100,
      },
      skillGap: {
        value: 0,
        percent: 100,
      },
    },
    {
      date: "2023-03-07T01:17:32.575Z",
      skill: {
        value: 25,
        percent: 83,
      },
      timeLearning: {
        value: 575,
        percent: 100,
      },
      resourceCount: {
        value: 25,
        percent: 100,
      },
      skillGap: {
        value: 0,
        percent: 100,
      },
    },
    {
      date: "2023-03-10T12:16:54.642Z",
      skill: {
        value: 13,
        percent: 43,
      },
      timeLearning: {
        value: 316,
        percent: 100,
      },
      resourceCount: {
        value: 13,
        percent: 100,
      },
      skillGap: {
        value: 0,
        percent: 100,
      },
    },
    {
      date: "2023-03-11T21:15:44.597Z",
      skill: {
        value: 12,
        percent: 33,
      },
      timeLearning: {
        value: 290,
        percent: 100,
      },
      resourceCount: {
        value: 17,
        percent: 100,
      },
      skillGap: {
        value: 0,
        percent: 100,
      },
    },
    {
      date: "2023-03-13T10:44:12.894Z",
      skill: {
        value: 10,
        percent: 33,
      },
      timeLearning: {
        value: 250,
        percent: 100,
      },
      resourceCount: {
        value: 16,
        percent: 100,
      },
      skillGap: {
        value: 0,
        percent: 100,
      },
    },
    {
      date: "2023-03-14T18:32:55.200Z",
      skill: {
        value: 10,
        percent: 33,
      },
      timeLearning: {
        value: 340,
        percent: 100,
      },
      resourceCount: {
        value: 19,
        percent: 100,
      },
      skillGap: {
        value: 0,
        percent: 100,
      },
    },
    {
      date: "2023-03-15T11:53:26.564Z",
      skill: {
        value: 10,
        percent: 33,
      },
      timeLearning: {
        value: 430,
        percent: 100,
      },
      resourceCount: {
        value: 22,
        percent: 100,
      },
      skillGap: {
        value: 0,
        percent: 100,
      },
    },
    {
      date: "2023-03-20T11:26:40.541Z",
      skill: {
        value: 10,
        percent: 33,
      },
      timeLearning: {
        value: 430,
        percent: 100,
      },
      resourceCount: {
        value: 22,
        percent: 100,
      },
      skillGap: {
        value: 0,
        percent: 100,
      },
    },
    {
      date: "2023-03-21T21:39:15.347Z",
      skill: {
        value: 5,
        percent: 33,
      },
      timeLearning: {
        value: 420,
        percent: 100,
      },
      resourceCount: {
        value: 12,
        percent: 100,
      },
      skillGap: {
        value: 20,
        percent: 100,
      },
    },
    {
      date: "2023-03-22T20:36:01.876Z",
      skill: {
        value: 10,
        percent: 33,
      },
      timeLearning: {
        value: 430,
        percent: 100,
      },
      resourceCount: {
        value: 22,
        percent: 100,
      },
      skillGap: {
        value: 0,
        percent: 100,
      },
    },
    {
      date: "2023-03-27T10:43:56.307Z",
      skill: {
        value: 15,
        percent: 33,
      },
      timeLearning: {
        value: 435,
        percent: 100,
      },
      resourceCount: {
        value: 34,
        percent: 100,
      },
      skillGap: {
        value: 10,
        percent: 100,
      },
    },
    {
      date: "2023-04-17T10:33:56.451Z",
      skill: {
        value: 10,
        percent: 33,
      },
      timeLearning: {
        value: 430,
        percent: 100,
      },
      resourceCount: {
        value: 22,
        percent: 100,
      },
      skillGap: {
        value: 0,
        percent: 100,
      },
    },
    {
      date: "2023-05-01T14:59:52.612Z",
      skill: {
        value: 6,
        percent: 50,
      },
      timeLearning: {
        value: 50,
        percent: 80,
      },
      resourceCount: {
        value: 30,
        percent: 39,
      },
      skillGap: {
        value: 39,
        percent: 39,
      },
    },
    {
      date: "2023-05-16T13:20:19.078Z",
      skill: {
        value: 10,
        percent: 33,
      },
      timeLearning: {
        value: 430,
        percent: 100,
      },
      resourceCount: {
        value: 22,
        percent: 100,
      },
      skillGap: {
        value: 0,
        percent: 100,
      },
    },
    {
      date: "2023-05-20T22:54:20.172Z",
      skill: {
        value: 10,
        percent: 33,
      },
      timeLearning: {
        value: 430,
        percent: 100,
      },
      resourceCount: {
        value: 22,
        percent: 100,
      },
      skillGap: {
        value: 0,
        percent: 100,
      },
    },
    {
      date: "2023-05-21T12:39:54.106Z",
      skill: {
        value: 10,
        percent: 33,
      },
      timeLearning: {
        value: 430,
        percent: 100,
      },
      resourceCount: {
        value: 22,
        percent: 100,
      },
      skillGap: {
        value: 0,
        percent: 100,
      },
    },
    {
      date: "2023-05-23T16:01:20.143Z",
      skill: {
        value: 10,
        percent: 33,
      },
      timeLearning: {
        value: 430,
        percent: 100,
      },
      resourceCount: {
        value: 22,
        percent: 100,
      },
      skillGap: {
        value: 0,
        percent: 100,
      },
    },
    {
      date: "2023-05-24T11:50:24.939Z",
      skill: {
        value: 10,
        percent: 33,
      },
      timeLearning: {
        value: 430,
        percent: 100,
      },
      resourceCount: {
        value: 22,
        percent: 100,
      },
      skillGap: {
        value: 0,
        percent: 100,
      },
    },
    {
      date: "2023-05-25T22:43:43.737Z",
      skill: {
        value: 13,
        percent: 33,
      },
      timeLearning: {
        value: 434.1858166666666,
        percent: 100,
      },
      resourceCount: {
        value: 25,
        percent: 100,
      },
      skillGap: {
        value: 0,
        percent: 100,
      },
    },
    {
      date: "2023-05-26T22:58:33.336Z",
      skill: {
        value: 13,
        percent: 33,
      },
      timeLearning: {
        value: 434.1858166666666,
        percent: 100,
      },
      resourceCount: {
        value: 25,
        percent: 100,
      },
      skillGap: {
        value: 0,
        percent: 100,
      },
    },
    {
      date: "2023-05-27T21:06:07.084Z",
      skill: {
        value: 13,
        percent: 33,
      },
      timeLearning: {
        value: 434.1858166666666,
        percent: 100,
      },
      resourceCount: {
        value: 25,
        percent: 100,
      },
      skillGap: {
        value: 0,
        percent: 100,
      },
    },
    {
      date: "2024-01-31T19:49:58.980Z",
      skill: {
        value: 13,
        percent: 33,
      },
      timeLearning: {
        value: 434.1858166666666,
        percent: 100,
      },
      resourceCount: {
        value: 25,
        percent: 100,
      },
      skillGap: {
        value: 0,
        percent: 100,
      },
    },
    {
      date: "2024-01-24T19:49:58.980Z",
      skill: {
        value: 12,
        percent: 0,
      },
      timeLearning: {
        value: 440,
        percent: 0,
      },
      resourceCount: {
        value: 24,
        percent: 0,
      },
      skillGap: {
        value: 0,
        percent: 0,
      },
    },
    {
      date: "2024-01-19T19:49:58.980Z",
      skill: {
        value: 12,
        percent: 0,
      },
      timeLearning: {
        value: 440,
        percent: 0,
      },
      resourceCount: {
        value: 24,
        percent: 0,
      },
      skillGap: {
        value: 0,
        percent: 0,
      },
    },
    {
      date: "2024-01-12T19:49:58.980Z",
      skill: {
        value: 12,
        percent: 0,
      },
      timeLearning: {
        value: 440,
        percent: 0,
      },
      resourceCount: {
        value: 24,
        percent: 0,
      },
      skillGap: {
        value: 0,
        percent: 0,
      },
    },
    {
      date: "2024-01-08T19:49:58.980Z",
      skill: {
        value: 14,
        percent: 1,
      },
      timeLearning: {
        value: 487.85,
        percent: 1,
      },
      resourceCount: {
        value: 26,
        percent: 3,
      },
      skillGap: {
        value: 29,
        percent: 0,
      },
    },
    {
      date: "2024-01-04T19:49:58.980Z",
      skill: {
        value: 14,
        percent: 1,
      },
      timeLearning: {
        value: 487.85,
        percent: 1,
      },
      resourceCount: {
        value: 26,
        percent: 3,
      },
      skillGap: {
        value: 29,
        percent: 0,
      },
    },
  ],
  resourceCount: {
    value: 26,
    percent: 3,
  },
  skill: {
    value: 14,
    percent: 1,
  },
  skillGap: {
    value: 29,
    percent: 0,
  },
  timeLearning: {
    value: 487.85,
    percent: 1,
  },
  lastSeen: "2023-10-05T19:49:58.980Z",
  bisAdmin: true,
  onBoarded: true,
  company: "6405b9cb4330633224b99df0",
  job: "63eba47b6a2f631c08390849",
  companyOnBoarded: true,
  refreshToken:
    "1//09NZtm6zSMiujCgYIARAAGAkSNwF-L9Irbl_54CV93l3M-J-mfYAljbq9p3-Yx28lZKi2U6ctqzNN0t4THrapy78rj-9mOaSI2F4",
  learningResource: [
    {
      microSkill: ["Data visualization"],
      flag: false,
      learningType: "Article",
      _id: "64c149ff323c94003c271b55",
      skillGroup: [],
      tag: [],
      time: 35.81,
      totalTime: 6,
      image: "",
      imageSrc: "https://ssl.gstatic.com/docs/spreadsheets/favicon3.ico",
      link: "https://docs.google.com/spreadsheets/d/1hee686W6MD2QbsGdE2Qq2bzX8PGUH8e2/edit",
      offeredBy: "google",
      title: "trail founders' information.xlsx - google sheets",
      wishList: {
        state: false,
        date: "2023-07-26T16:29:51.986Z",
      },
      reminder: {
        state: false,
        date: "2023-07-26T16:29:51.986Z",
      },
      user: "61e34b7676b46540ecad7b08",
      createdAt: "2023-07-26T16:29:51.986Z",
      lastSeen: "2023-07-26T18:28:58.382Z",
      __v: 0,
    },
    {
      microSkill: ["Data visualization"],
      flag: false,
      learningType: "Article",
      _id: "64c14906323c94003c271abd",
      skillGroup: [],
      tag: [],
      time: 12.04,
      totalTime: 5,
      image: "",
      imageSrc: "https://ssl.gstatic.com/docs/spreadsheets/favicon3.ico",
      link: "https://docs.google.com/spreadsheets/d/1tnYRbBmi_7115fgtU2V2xAxC3rpxwfeO/edit",
      offeredBy: "google",
      title: "founders' information.xlsx - google sheets",
      wishList: {
        state: false,
        date: "2023-07-26T16:25:42.978Z",
      },
      reminder: {
        state: false,
        date: "2023-07-26T16:25:42.978Z",
      },
      user: "61e34b7676b46540ecad7b08",
      createdAt: "2023-07-26T16:25:42.979Z",
      lastSeen: "2023-07-26T16:25:42.979Z",
      __v: 0,
    },
    {
      microSkill: ["Persona creatio"],
      flag: false,
      learningType: "Video",
      _id: "64a3fabeabbb593d08671e08",
      skillGroup: [],
      tag: ["tech"],
      time: 5,
      totalTime: 0,
      title: "why you should launch a micro startup | by richrsd fang  df",
      link: "https://medium.com/swlh/defining-user-personas/dssd",
      offeredBy: "medium",
      reminder: {
        state: true,
        date: "2023-03-09T19:25:19.460Z",
      },
      user: "61e34b7676b46540ecad7b08",
      createdAt: "2023-07-04T10:55:58.063Z",
      lastSeen: "2023-07-04T10:55:58.063Z",
      __v: 0,
    },
    {
      microSkill: ["Persona creatio"],
      flag: true,
      learningType: "Video",
      _id: "64a3f8045c2923a8ac441ea1",
      skillGroup: [],
      tag: ["tech"],
      time: 5,
      totalTime: 0,
      title: "why you should launch a micro startup | by richrd fang  df",
      link: "https://medium.com/swlh/defining-user-personas/dsd",
      offeredBy: "medium",
      reminder: {
        state: true,
        date: "2023-03-09T19:25:19.460Z",
      },
      user: "61e34b7676b46540ecad7b08",
      createdAt: "2023-07-04T10:44:20.812Z",
      lastSeen: "2023-07-04T10:44:20.812Z",
      __v: 0,
    },
    {
      microSkill: [],
      flag: false,
      learningType: "Article",
      _id: "6411b1b2778085003f14a54e",
      skillGroup: [],
      tag: [" Red", "Tag"],
      time: 50,
      title: "forms",
      link: "https://reactjs.org/docs/forms.html",
      image: "",
      offeredBy: "reactjs.org",
      wishList: {
        state: false,
        date: "2023-03-15T11:53:22.770Z",
      },
      reminder: {
        state: false,
        date: "2023-03-15T11:53:22.770Z",
      },
      user: "61e34b7676b46540ecad7b08",
      createdAt: "2023-03-15T11:53:22.771Z",
      lastSeen: "2023-03-15T11:53:22.771Z",
      __v: 0,
    },
    {
      microSkill: [],
      flag: false,
      learningType: "Article",
      _id: "6411b0da778085003f14a54d",
      skillGroup: [],
      tag: ["jjjj", " Red"],
      time: 20,
      title: "persian rugs",
      link: "https://stackoverflow.com/questions/54261316/can-you-pass-down-state-to-child-components-with-react-hooks",
      image: "",
      offeredBy: "stackoverflow.com",
      wishList: {
        state: false,
        date: "2023-03-15T11:49:46.269Z",
      },
      reminder: {
        state: false,
        date: "2023-03-15T11:49:46.269Z",
      },
      user: "61e34b7676b46540ecad7b08",
      createdAt: "2023-03-15T11:49:46.270Z",
      lastSeen: "2023-03-15T11:49:46.270Z",
      __v: 0,
    },
    {
      microSkill: [],
      flag: false,
      learningType: "Article",
      _id: "6411988890dc0b003ec9dc5d",
      skillGroup: [],
      tag: [" Red"],
      time: 20,
      title: "input tags",
      link: "https://beta.reactjs.org/reference/react-dom/components/input",
      image: "",
      offeredBy: "beta.reactjs.org",
      wishList: {
        state: false,
        date: "2023-03-15T10:06:00.169Z",
      },
      reminder: {
        state: false,
        date: "2023-03-15T10:06:00.169Z",
      },
      user: "61e34b7676b46540ecad7b08",
      createdAt: "2023-03-15T10:06:00.170Z",
      lastSeen: "2023-03-15T10:06:00.170Z",
      __v: 0,
    },
    {
      microSkill: [],
      flag: false,
      learningType: "Article",
      _id: "6410bdd48a017e003e892754",
      skillGroup: [],
      tag: ["jjjj"],
      time: 50,
      title: "wakatime",
      link: "https://wakatime.com/dashboard/day?date=2023-03-14",
      image: "",
      offeredBy: "wakatime.com",
      wishList: {
        state: false,
        date: "2023-03-14T18:32:52.424Z",
      },
      reminder: {
        state: false,
        date: "2023-03-14T18:32:52.425Z",
      },
      user: "61e34b7676b46540ecad7b08",
      createdAt: "2023-03-14T18:32:52.426Z",
      lastSeen: "2023-03-14T18:32:52.426Z",
      __v: 0,
    },
    {
      microSkill: [],
      flag: false,
      learningType: "Article",
      _id: "6410ba9b8a017e003e892753",
      skillGroup: [],
      tag: [],
      time: 20,
      title: "whatsapp",
      link: "https://web.whatsapp.com/",
      image: "",
      offeredBy: "web.whatsapp.com",
      wishList: {
        state: false,
        date: "2023-03-14T18:19:08.011Z",
      },
      reminder: {
        state: false,
        date: "2023-03-14T18:19:08.011Z",
      },
      user: "61e34b7676b46540ecad7b08",
      createdAt: "2023-03-14T18:19:08.011Z",
      lastSeen: "2023-03-14T18:19:08.011Z",
      __v: 0,
    },
    {
      microSkill: [],
      flag: false,
      learningType: "Article",
      _id: "6410b5358a017e003e89274f",
      skillGroup: [],
      tag: [],
      time: 20,
      title: "custom underline",
      link: "https://ishadeed.com/article/custom-underline-svg/",
      image: "",
      offeredBy: "ishadeed.com",
      wishList: {
        state: false,
        date: "2023-03-14T17:56:05.120Z",
      },
      reminder: {
        state: false,
        date: "2023-03-14T17:56:05.120Z",
      },
      user: "61e34b7676b46540ecad7b08",
      createdAt: "2023-03-14T17:56:05.121Z",
      lastSeen: "2023-03-14T17:56:05.121Z",
      __v: 0,
    },
    {
      microSkill: [],
      flag: false,
      learningType: "Article",
      _id: "640b415130ffed003e5ecdbb",
      title: "convert string to number",
      link: "https://www.freecodecamp.org/news/how-to-convert-a-string-to-a-number-in-javascript/",
      image: "",
      offeredBy: "www.freecodecamp.org",
      wishList: {
        state: false,
        date: "2023-03-10T14:40:17.824Z",
      },
      reminder: {
        state: false,
        date: "2023-03-10T14:40:17.825Z",
      },
      user: "61e34b7676b46540ecad7b08",
      createdAt: "2023-03-10T14:40:17.825Z",
      __v: 0,
    },
    {
      microSkill: [],
      flag: false,
      learningType: "Article",
      _id: "640b40eb30ffed003e5ecdba",
      title: "o ro mo logo",
      link: "https://www.w3schools.com/REACT/react_forms.asp",
      image: "",
      offeredBy: "www.w3schools.com",
      wishList: {
        state: false,
        date: "2023-03-10T14:38:35.224Z",
      },
      reminder: {
        state: false,
        date: "2023-03-10T14:38:35.225Z",
      },
      user: "61e34b7676b46540ecad7b08",
      createdAt: "2023-03-10T14:38:35.226Z",
      __v: 0,
    },
    {
      microSkill: [],
      flag: false,
      learningType: "Article",
      _id: "640b405830ffed003e5ecdb9",
      title: "to ba ti rimi",
      link: "https://stackoverflow.com/questions/70447195/removing-an-element-in-array-within-map-in-react",
      image: "",
      offeredBy: "stackoverflow.com",
      wishList: {
        state: false,
        date: "2023-03-10T14:36:08.330Z",
      },
      reminder: {
        state: false,
        date: "2023-03-10T14:36:08.331Z",
      },
      user: "61e34b7676b46540ecad7b08",
      createdAt: "2023-03-10T14:36:08.331Z",
      __v: 0,
    },
    {
      microSkill: [],
      flag: false,
      learningType: "Podcast",
      _id: "640b3e2b30ffed003e5ecdb8",
      title: "omo ologo",
      link: "https://open.spotify.com/album/6WVOGN9cZXklfo0aLKaCjj?si=koMogjG-RlWW0MCt35J-6g",
      image: "",
      offeredBy: "open.spotify.com",
      wishList: {
        state: false,
        date: "2023-03-10T14:26:51.328Z",
      },
      reminder: {
        state: false,
        date: "2023-03-10T14:26:51.329Z",
      },
      user: "61e34b7676b46540ecad7b08",
      createdAt: "2023-03-10T14:26:51.329Z",
      __v: 0,
    },
    {
      microSkill: [],
      flag: false,
      learningType: "Video",
      _id: "640b274dfe9d67003f2f3033",
      title: "how to make scrambled eggs",
      link: "https://www.youtube.com/watch?v=yyi55ZrpJ0E",
      image: "",
      offeredBy: "www.youtube.com",
      wishList: {
        state: false,
        date: "2023-03-10T12:49:17.107Z",
      },
      reminder: {
        state: false,
        date: "2023-03-10T12:49:17.108Z",
      },
      user: "61e34b7676b46540ecad7b08",
      createdAt: "2023-03-10T12:49:17.108Z",
      __v: 0,
    },
    {
      microSkill: [],
      flag: false,
      learningType: "Article",
      _id: "640b2493fe9d67003f2f3032",
      title: "exporting modules",
      link: "https://stackoverflow.com/questions/46039976/exporting-multiple-modules-in-react-js",
      image: "",
      offeredBy: "stackoverflow.com",
      wishList: {
        state: false,
        date: "2023-03-10T12:37:39.014Z",
      },
      reminder: {
        state: false,
        date: "2023-03-10T12:37:39.104Z",
      },
      user: "61e34b7676b46540ecad7b08",
      createdAt: "2023-03-10T12:37:39.105Z",
      __v: 0,
    },
    {
      microSkill: ["Network sniffing"],
      flag: false,
      learningType: "Course",
      _id: "635edac97e32c74660e21908",
      title: "MERN Stack Master Course - Building your own Instagram",
      link: "https://www.udemy.com/course-dashboard-redirect/?course_id=2962134",
      image: "https://img-c.udemycdn.com/course/240x135/2962134_7552_8.jpg",
      offeredBy: "Udemy",
      wishList: {
        state: false,
        date: "2022-10-30T20:12:57.977Z",
      },
      reminder: {
        state: false,
        date: "2022-10-30T20:12:57.977Z",
      },
      user: "61e34b7676b46540ecad7b08",
      createdAt: "2022-10-30T20:12:57.979Z",
      __v: 0,
      time: 49,
      tag: [],
    },
    {
      microSkill: ["Hashcat"],
      flag: false,
      learningType: "Course",
      _id: "635edac67e32c74660e21905",
      title: "Starting a Business: a step-by-step guide by Slidebean CEO",
      link: "https://www.udemy.com/course-dashboard-redirect/?course_id=2483284",
      image: "https://img-c.udemycdn.com/course/240x135/2483284_43ed.jpg",
      offeredBy: "Udemy",
      wishList: {
        state: false,
        date: "2022-10-30T20:12:55.163Z",
      },
      reminder: {
        state: false,
        date: "2022-10-30T20:12:55.165Z",
      },
      user: "61e34b7676b46540ecad7b08",
      createdAt: "2022-10-30T20:12:55.166Z",
      __v: 0,
      time: 26,
      tag: [],
    },
    {
      microSkill: ["CIS-CAT Lite"],
      flag: false,
      learningType: "Video",
      _id: "635bf3e3c349680004ad82b9",
      title:
        "3D STEREO BY 3D AND MATERIALS EFFECT | Adobe Illustrator Tutorials - YouTube",
      link: "https://www.youtube.com/",
      image: "",
      imageSrc:
        "https://www.youtube.com/s/desktop/88de6a77/img/favicon_32x32.png",
      wishList: {
        state: false,
        date: "2022-10-28T15:23:15.513Z",
      },
      reminder: {
        state: false,
        date: "2022-10-28T15:23:15.513Z",
      },
      user: "61e34b7676b46540ecad7b08",
      offeredBy: "www.youtube.com",
      createdAt: "2022-10-28T15:23:15.513Z",
      __v: 0,
      time: 25,
      tag: [],
    },
    {
      microSkill: ["Advanced assessment tools"],
      flag: false,
      learningType: "Course",
      _id: "635bea34c349680004ad82b8",
      title: "The Ultimate Drawing Masterclass: Start Drawing Better Today",
      link: "https://www.udemy.com/course-dashboard-redirect/?course_id=2651072",
      image: "https://img-c.udemycdn.com/course/240x135/2651072_3634_5.jpg",
      offeredBy: "Udemy",
      wishList: {
        state: false,
        date: "2022-10-28T14:41:56.598Z",
      },
      reminder: {
        state: false,
        date: "2022-10-28T14:41:56.598Z",
      },
      user: "61e34b7676b46540ecad7b08",
      createdAt: "2022-10-28T14:41:56.598Z",
      __v: 0,
      time: 20,
      tag: [],
    },
    {
      microSkill: ["Aircrack-ng"],
      flag: false,
      learningType: "Course",
      _id: "635bea34c349680004ad82b7",
      title: "YouTube Masterclass - Your Complete Guide to YouTube",
      link: "https://www.udemy.com/course-dashboard-redirect/?course_id=703140",
      image: "https://img-c.udemycdn.com/course/240x135/703140_703a_4.jpg",
      offeredBy: "Udemy",
      wishList: {
        state: false,
        date: "2022-10-28T14:41:56.417Z",
      },
      reminder: {
        state: false,
        date: "2022-10-28T14:41:56.417Z",
      },
      user: "61e34b7676b46540ecad7b08",
      createdAt: "2022-10-28T14:41:56.417Z",
      __v: 0,
      time: 8,
      tag: [],
    },
    {
      microSkill: ["Compliance assessments"],
      flag: false,
      learningType: "Course",
      _id: "635bea34c349680004ad82b6",
      title: "Adobe InDesign CC: Your Complete Guide to InDesign",
      link: "https://www.udemy.com/course-dashboard-redirect/?course_id=1351634",
      image: "https://img-c.udemycdn.com/course/240x135/1351634_7dc7_5.jpg",
      offeredBy: "Udemy",
      wishList: {
        state: false,
        date: "2022-10-28T14:41:56.178Z",
      },
      reminder: {
        state: false,
        date: "2022-10-28T14:41:56.178Z",
      },
      user: "61e34b7676b46540ecad7b08",
      createdAt: "2022-10-28T14:41:56.178Z",
      __v: 0,
      time: 23,
      tag: [],
    },
    {
      microSkill: ["CIS-CAT Lite"],
      flag: false,
      learningType: "Course",
      _id: "635bea34c349680004ad82b5",
      title: "Content Marketing: Grow Your Business with Content Marketing",
      link: "https://www.udemy.com/course-dashboard-redirect/?course_id=2295965",
      image: "https://img-c.udemycdn.com/course/240x135/2295965_720a_3.jpg",
      offeredBy: "Udemy",
      wishList: {
        state: false,
        date: "2022-10-28T14:41:56.119Z",
      },
      reminder: {
        state: false,
        date: "2022-10-28T14:41:56.119Z",
      },
      user: "61e34b7676b46540ecad7b08",
      createdAt: "2022-10-28T14:41:56.119Z",
      __v: 0,
      time: 31,
      tag: [],
    },
    {
      microSkill: ["Advanced assessment tools"],
      flag: false,
      learningType: "Course",
      _id: "635bea33c349680004ad82b4",
      title: "Getting started with NFTs, Polygon, Web3, Airdrops, Crypto",
      link: "https://www.udemy.com/course-dashboard-redirect/?course_id=4506596",
      image: "https://img-c.udemycdn.com/course/240x135/4506596_f2d8.jpg",
      offeredBy: "Udemy",
      wishList: {
        state: false,
        date: "2022-10-28T14:41:55.718Z",
      },
      reminder: {
        state: false,
        date: "2022-10-28T14:41:55.718Z",
      },
      user: "61e34b7676b46540ecad7b08",
      createdAt: "2022-10-28T14:41:55.718Z",
      __v: 0,
      time: 18,
      tag: [],
    },
    {
      microSkill: ["Nessus"],
      flag: false,
      learningType: "Course",
      _id: "635bea33c349680004ad82b3",
      title: "SEO Training: Complete SEO Course & SEO Copywriting MASTERY",
      link: "https://www.udemy.com/course-dashboard-redirect/?course_id=2051729",
      image: "https://img-c.udemycdn.com/course/240x135/2051729_e4cd_6.jpg",
      offeredBy: "Udemy",
      wishList: {
        state: false,
        date: "2022-10-28T14:41:55.648Z",
      },
      reminder: {
        state: false,
        date: "2022-10-28T14:41:55.648Z",
      },
      user: "61e34b7676b46540ecad7b08",
      createdAt: "2022-10-28T14:41:55.648Z",
      __v: 0,
      time: 3,
      tag: [],
    },
    {
      microSkill: ["Kali Linux"],
      flag: false,
      learningType: "Course",
      _id: "635bea30c349680004ad82b2",
      title: "Digital Marketing Masterclass - 23 Marketing Courses in 1",
      link: "https://www.udemy.com/course-dashboard-redirect/?course_id=1270870",
      image: "https://img-c.udemycdn.com/course/240x135/1270870_8e95_14.jpg",
      offeredBy: "Udemy",
      wishList: {
        state: false,
        date: "2022-10-28T14:41:52.406Z",
      },
      reminder: {
        state: false,
        date: "2022-10-28T14:41:52.406Z",
      },
      user: "61e34b7676b46540ecad7b08",
      createdAt: "2022-10-28T14:41:52.406Z",
      __v: 0,
      time: 47,
      tag: [],
    },
  ],
  toDo: [
    {
      done: true,
      _id: "6315f84aa248041648c31fed",
      title: "teah frog to fly",
      user: "61e34b7676b46540ecad7b08",
      createdAt: "2022-09-05T13:23:22.762Z",
      __v: 0,
      updateDate: "2022-09-07T18:10:13.733Z",
    },
    {
      done: true,
      _id: "631617f2a248041648c31fee",
      title:
        "The old woman and her pupils need to find a new shelter home to be in and this is a feature that tests just that.",
      user: "61e34b7676b46540ecad7b08",
      createdAt: "2022-09-05T15:38:26.307Z",
      __v: 0,
      updateDate: "2022-09-05T16:12:52.269Z",
    },
    {
      done: true,
      _id: "6318de49a248041648c31ff2",
      title: "jump two fence",
      user: "61e34b7676b46540ecad7b08",
      createdAt: "2022-09-07T18:09:13.123Z",
      __v: 0,
      updateDate: "2022-10-07T09:10:21.083Z",
    },
    {
      done: true,
      _id: "6318de58a248041648c31ff3",
      title: "arrange boys",
      user: "61e34b7676b46540ecad7b08",
      createdAt: "2022-09-07T18:09:28.785Z",
      __v: 0,
      updateDate: "2022-10-07T09:10:22.894Z",
    },
    {
      done: true,
      _id: "6318de7da248041648c31ff4",
      title: "asd fdfa a",
      user: "61e34b7676b46540ecad7b08",
      createdAt: "2022-09-07T18:10:05.832Z",
      __v: 0,
      updateDate: "2022-09-07T18:10:18.759Z",
    },
    {
      done: true,
      _id: "6319f5c9b5b45a160018b5d8",
      title: "test loader",
      user: "61e34b7676b46540ecad7b08",
      createdAt: "2022-09-08T14:01:45.623Z",
      __v: 0,
      updateDate: "2022-09-08T14:49:38.472Z",
    },
    {
      done: true,
      _id: "631b1b06c507444cac874f9a",
      title: "fly mountain",
      user: "61e34b7676b46540ecad7b08",
      createdAt: "2022-09-09T10:52:54.794Z",
      __v: 0,
      updateDate: "2022-10-07T09:10:23.245Z",
    },
    {
      done: true,
      _id: "631b1b07c507444cac874f9b",
      title: "fly mountain",
      user: "61e34b7676b46540ecad7b08",
      createdAt: "2022-09-09T10:52:55.788Z",
      __v: 0,
      updateDate: "2022-10-07T09:10:25.641Z",
    },
  ],
  id: "61e34b7676b46540ecad7b08",
};

export const skills = [
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371ae",
    name: "Visual hierarchy",
    priority: "Visual",
    exact: false,
    skillGroup: "646fda03eb15635ce11e3290",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371af",
    name: "Alignment",
    exact: true,
    skillGroup: "646fda03eb15635ce11e3290",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371b3",
    name: "Font pairing",
    priority: "font",
    exact: true,
    skillGroup: "646fda03eb15635ce11e3291",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371ba",
    name: "Cognitive psychology",
    priority: "Cognitive",
    exact: false,
    skillGroup: "646fda03eb15635ce11e3293",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371bf",
    name: "Design standards",
    priority: "standards",
    exact: true,
    skillGroup: "646fda03eb15635ce11e3294",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371b0",
    name: "Grid systems",
    priority: "Grid",
    exact: true,
    skillGroup: "646fda03eb15635ce11e3290",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371b1",
    name: "White space",
    priority: "White",
    exact: true,
    skillGroup: "646fda03eb15635ce11e3290",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371b4",
    name: "Line length and spacing",
    priority: "Line",
    exact: false,
    skillGroup: "646fda03eb15635ce11e3291",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371bc",
    name: "Emotion and perception",
    priority: "Emotion",
    exact: false,
    skillGroup: "646fda03eb15635ce11e3293",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371bd",
    name: "User experience psychology",
    priority: "User experience",
    exact: false,
    skillGroup: "646fda03eb15635ce11e3293",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371c1",
    name: "Design documentation",
    priority: "documentation",
    exact: true,
    skillGroup: "646fda03eb15635ce11e3294",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371c6",
    name: "Quantitative data analysis",
    priority: "Quantitative",
    exact: false,
    skillGroup: "646fda03eb15635ce11e3296",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371c7",
    name: "Qualitative data analysis",
    priority: "Qualitative",
    exact: false,
    skillGroup: "646fda03eb15635ce11e3296",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371b2",
    name: "Font selection",
    priority: "font",
    exact: true,
    skillGroup: "646fda03eb15635ce11e3291",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371b5",
    name: "Readability",
    exact: true,
    skillGroup: "646fda03eb15635ce11e3291",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371b6",
    name: "Color palettes",
    priority: "Color",
    exact: true,
    skillGroup: "646fda03eb15635ce11e3292",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371c0",
    name: "Consistency in design",
    priority: "Consistency",
    exact: false,
    skillGroup: "646fda03eb15635ce11e3294",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371c5",
    name: "User feedback",
    priority: "User feedback",
    exact: true,
    skillGroup: "646fda03eb15635ce11e3295",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371c8",
    name: "Statistical analysis",
    priority: "Statistical",
    exact: false,
    skillGroup: "646fda03eb15635ce11e3296",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371b7",
    name: "Color contrast",
    priority: "Color",
    exact: true,
    skillGroup: "646fda03eb15635ce11e3292",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371b8",
    name: "Color psychology",
    priority: "Color",
    exact: true,
    skillGroup: "646fda03eb15635ce11e3292",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371b9",
    name: "Color harmonies",
    priority: "Color",
    exact: true,
    skillGroup: "646fda03eb15635ce11e3292",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371bb",
    name: "Behavioral psychology",
    priority: "Behavioral",
    exact: true,
    skillGroup: "646fda03eb15635ce11e3293",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371be",
    name: "Component libraries",
    priority: "Component",
    exact: true,
    skillGroup: "646fda03eb15635ce11e3294",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371c2",
    name: "Survey design and implementation",
    priority: "Survey",
    exact: false,
    skillGroup: "646fda03eb15635ce11e3295",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371c3",
    name: "Interview techniques",
    priority: "Interview",
    exact: false,
    skillGroup: "646fda03eb15635ce11e3295",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371c4",
    name: "Usability testing",
    priority: "Usability",
    exact: true,
    skillGroup: "646fda03eb15635ce11e3295",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371c9",
    name: "Data visualization",
    priority: "Data",
    exact: false,
    skillGroup: "646fda03eb15635ce11e3296",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371ca",
    name: "Task analysis",
    priority: "Task",
    exact: true,
    skillGroup: "646fda03eb15635ce11e3297",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371cf",
    name: "Scenarios and user stories",
    priority: "Scenarios",
    exact: false,
    skillGroup: "646fda03eb15635ce11e3298",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371cd",
    name: "User needs assessment",
    priority: "User needs",
    exact: false,
    skillGroup: "646fda03eb15635ce11e3297",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371cb",
    name: "User modeling",
    priority: "Modeling",
    exact: true,
    skillGroup: "646fda03eb15635ce11e3297",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371cc",
    name: "User goals and objectives",
    priority: "User goals",
    exact: false,
    skillGroup: "646fda03eb15635ce11e3297",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371ce",
    name: "Persona creation",
    priority: "Persona",
    exact: true,
    skillGroup: "646fda03eb15635ce11e3298",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371d4",
    name: "Content inventory",
    priority: "Content",
    exact: true,
    skillGroup: "646fda03eb15635ce11e3299",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371d3",
    name: "Content strategy",
    priority: "Content",
    exact: true,
    skillGroup: "646fda03eb15635ce11e3299",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371d5",
    name: "Taxonomy and tagging",
    priority: "Taxonomy",
    exact: true,
    skillGroup: "646fda03eb15635ce11e3299",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371d0",
    name: "User journey mapping",
    priority: "journey mapping",
    exact: false,
    skillGroup: "646fda03eb15635ce11e3298",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371d1",
    name: "Use case development",
    priority: "Use case",
    exact: false,
    skillGroup: "646fda03eb15635ce11e3298",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371d6",
    name: "Site mapping and planning",
    priority: "Site mapping",
    exact: false,
    skillGroup: "646fda03eb15635ce11e329a",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371d2",
    name: "Information architecture",
    priority: "Information",
    exact: true,
    skillGroup: "646fda03eb15635ce11e3299",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371d7",
    name: "Navigation design",
    priority: "Navigation",
    exact: true,
    skillGroup: "646fda03eb15635ce11e329a",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371db",
    name: "User testing and feedback",
    priority: "User testing",
    exact: true,
    skillGroup: "646fda03eb15635ce11e329b",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371d8",
    name: "User flows",
    priority: "flows",
    exact: true,
    skillGroup: "646fda03eb15635ce11e329a",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371d9",
    name: "User interface design patterns",
    priority: "User interface",
    exact: true,
    skillGroup: "646fda03eb15635ce11e329a",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371dc",
    name: "Interaction design",
    priority: "Interaction",
    exact: true,
    skillGroup: "646fda03eb15635ce11e329b",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371de",
    name: "Wireframe design",
    priority: "Wireframe",
    exact: true,
    skillGroup: "646fda03eb15635ce11e329c",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371da",
    name: "User experience design",
    priority: "User experience",
    exact: true,
    skillGroup: "646fda03eb15635ce11e329b",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371dd",
    name: "Animation design",
    priority: "Animation",
    exact: true,
    skillGroup: "646fda03eb15635ce11e329b",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371df",
    name: "Rapid prototyping",
    priority: "Prototyping",
    exact: true,
    skillGroup: "646fda03eb15635ce11e329c",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371e3",
    name: "On-page optimization",
    priority: "On-page",
    exact: true,
    skillGroup: "646fda03eb15635ce11e329d",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371e4",
    name: "Link building strategies",
    priority: "Link building",
    exact: false,
    skillGroup: "646fda03eb15635ce11e329d",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371e6",
    name: "Accessibility standards and guidelines",
    priority: "Accessibility",
    exact: true,
    skillGroup: "646fda03eb15635ce11e329e",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371e1",
    name: "Responsive design",
    priority: "Responsive",
    exact: true,
    skillGroup: "646fda03eb15635ce11e329c",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371e2",
    name: "Keyword research and analysis",
    priority: "Keyword research",
    exact: false,
    skillGroup: "646fda03eb15635ce11e329d",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371e0",
    name: "Mobile-first design",
    priority: "Mobile-first",
    exact: true,
    skillGroup: "646fda03eb15635ce11e329c",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371e5",
    name: "SEO audits and reporting",
    priority: "SEO audits",
    exact: true,
    skillGroup: "646fda03eb15635ce11e329d",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371e7",
    name: "Assistive technology",
    priority: "Assistive",
    exact: true,
    skillGroup: "646fda03eb15635ce11e329e",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371e8",
    name: "Accessibility testing",
    priority: "Accessibility",
    exact: true,
    skillGroup: "646fda03eb15635ce11e329e",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371ea",
    name: "Button design",
    priority: "Button",
    exact: true,
    skillGroup: "646fda03eb15635ce11e329f",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371eb",
    name: "Icon design",
    priority: "Icon",
    exact: true,
    skillGroup: "646fda03eb15635ce11e329f",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371e9",
    name: "Accessibility design patterns",
    priority: "Accessibility",
    exact: true,
    skillGroup: "646fda03eb15635ce11e329e",
  },
  {
    flag: false,
    _id: "64736f1abdfe70ef5f9371ec",
    name: "Form design",
    priority: "Form",
    exact: true,
    skillGroup: "646fda03eb15635ce11e329f",
  },
  {
    flag: false,
    _id: "647371abbdfe70ef5f93730d",
    name: "User-centered design",
    priority: "User-centered",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32a0",
  },
  {
    flag: false,
    _id: "647371e9bdfe70ef5f93732e",
    name: "Feedback mechanisms",
    priority: "Feedback",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32a0",
  },
  {
    flag: false,
    _id: "64737229bdfe70ef5f93734d",
    name: "Low-fidelity prototyping",
    priority: "Low-fidelity",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32a1",
  },
  {
    flag: false,
    _id: "64737229bdfe70ef5f93734e",
    name: "High-fidelity prototyping",
    priority: "High-fidelity",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32a1",
  },
  {
    flag: false,
    _id: "64737246bdfe70ef5f93736a",
    name: "A/B testing",
    priority: "A/B",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32a2",
  },
  {
    flag: false,
    _id: "64737246bdfe70ef5f937369",
    name: "Motion design",
    priority: "Motion",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32a1",
  },
  {
    flag: false,
    _id: "64737246bdfe70ef5f93736b",
    name: "Analytics tracking",
    priority: "Analytics",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32a2",
  },
  {
    flag: false,
    _id: "64737246bdfe70ef5f937381",
    name: "jQuery",
    priority: "jQuery",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32a7",
  },
  {
    flag: false,
    _id: "64737246bdfe70ef5f93736c",
    name: "Conversion optimization",
    priority: "Conversion",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32a2",
  },
  {
    flag: false,
    _id: "64737246bdfe70ef5f93736e",
    name: "Logo design",
    priority: "Logo",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32a3",
  },
  {
    flag: false,
    _id: "64737246bdfe70ef5f93736f",
    name: "Brand strategy",
    priority: "Brand",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32a3",
  },
  {
    flag: false,
    _id: "64737246bdfe70ef5f937373",
    name: "Composition and layout",
    priority: "Composition",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32a4",
  },
  {
    flag: false,
    _id: "64737246bdfe70ef5f937374",
    name: "Color theory and usage",
    priority: "Color theory",
    exact: false,
    skillGroup: "646fda03eb15635ce11e32a4",
  },
  {
    flag: false,
    _id: "64737246bdfe70ef5f937375",
    name: "Typography and font usage",
    priority: "Typography",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32a4",
  },
  {
    flag: false,
    _id: "64737246bdfe70ef5f937377",
    name: "Balance and symmetry",
    priority: "Balance",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32a5",
  },
  {
    flag: false,
    _id: "64737246bdfe70ef5f93737b",
    name: "Sketch",
    priority: "Sketch",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32a6",
  },
  {
    flag: false,
    _id: "64737246bdfe70ef5f93737e",
    name: "HTML",
    priority: "HTML",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32a7",
  },
  {
    flag: false,
    _id: "64737246bdfe70ef5f937380",
    name: "JavaScript",
    priority: "JavaScript",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32a7",
  },
  {
    flag: false,
    _id: "64737246bdfe70ef5f937370",
    name: "Brand voice and messaging",
    priority: "Brand",
    exact: false,
    skillGroup: "646fda03eb15635ce11e32a3",
  },
  {
    flag: false,
    _id: "64737246bdfe70ef5f937372",
    name: "Design aesthetics and principles",
    priority: "Design aesthetics",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32a4",
  },
  {
    flag: false,
    _id: "64737246bdfe70ef5f937376",
    name: "Visual hierarchy and emphasis",
    priority: "Visual hierarchy",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32a5",
  },
  {
    flag: false,
    _id: "64737246bdfe70ef5f937378",
    name: "Contrast and emphasis",
    priority: "Contrast",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32a5",
  },
  {
    flag: false,
    _id: "64737246bdfe70ef5f937379",
    name: "Repetition and consistency",
    priority: "Repetition",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32a5",
  },
  {
    flag: false,
    _id: "64737246bdfe70ef5f93737a",
    name: "Adobe Creative Suite",
    priority: "Adobe",
    exact: false,
    skillGroup: "646fda03eb15635ce11e32a6",
  },
  {
    flag: false,
    _id: "64737246bdfe70ef5f93737c",
    name: "Figma",
    priority: "Figma",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32a6",
  },
  {
    flag: false,
    _id: "64737246bdfe70ef5f93736d",
    name: "Heat maps and click tracking",
    priority: "Heat maps",
    exact: false,
    skillGroup: "646fda03eb15635ce11e32a2",
  },
  {
    flag: false,
    _id: "64737246bdfe70ef5f937371",
    name: "Brand guidelines and style guides",
    priority: "Brand",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32a3",
  },
  {
    flag: false,
    _id: "64737246bdfe70ef5f93737d",
    name: "Canva",
    priority: "Canva",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32a6",
  },
  {
    flag: false,
    _id: "64737246bdfe70ef5f93737f",
    name: "CSS",
    priority: "CSS",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32a7",
  },
  {
    flag: false,
    _id: "64737275bdfe70ef5f937383",
    name: "Responsive design principles",
    priority: "Responsive",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32a8",
  },
  {
    flag: false,
    _id: "64737275bdfe70ef5f937385",
    name: "Fluid layouts and images",
    priority: "Fluid",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32a8",
  },
  {
    flag: false,
    _id: "64737275bdfe70ef5f937384",
    name: "Media queries",
    priority: "Media",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32a8",
  },
  {
    flag: false,
    _id: "6473728bbdfe70ef5f93739b",
    name: "Collaboration and teamwork",
    priority: "Collaboration",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32aa",
  },
  {
    flag: false,
    _id: "6473728bbdfe70ef5f93739d",
    name: "Empathy and emotional intelligence",
    priority: "Empathy",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32aa",
  },
  {
    flag: false,
    _id: "6473728bbdfe70ef5f93739f",
    name: "Verbal and written communication",
    priority: "Verbal",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32ab",
  },
  {
    flag: false,
    _id: "6473728bbdfe70ef5f9373a1",
    name: "Persuasion and influence",
    priority: "Persuasion",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32ab",
  },
  {
    flag: false,
    _id: "6473728bbdfe70ef5f9373a6",
    name: "Resource allocation and budgeting",
    priority: "Resource",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32ac",
  },
  {
    flag: false,
    _id: "6473728bbdfe70ef5f937397",
    name: "Website optimization techniques",
    priority: "Website",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32a9",
  },
  {
    flag: false,
    _id: "6473728bbdfe70ef5f937398",
    name: "Page speed optimization",
    priority: "Page",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32a9",
  },
  {
    flag: false,
    _id: "6473728bbdfe70ef5f93739c",
    name: "Conflict resolution and negotiation",
    priority: "Conflict resolution",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32aa",
  },
  {
    flag: false,
    _id: "6473728bbdfe70ef5f9373a4",
    name: "Scrum and sprint planning",
    priority: "Scrum",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32ac",
  },
  {
    flag: false,
    _id: "6473728bbdfe70ef5f93739a",
    name: "Image optimization",
    priority: "Image",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32a9",
  },
  {
    flag: false,
    _id: "6473728bbdfe70ef5f93739e",
    name: "Active listening and feedback",
    priority: "feedback",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32aa",
  },
  {
    flag: false,
    _id: "6473728bbdfe70ef5f9373a2",
    name: "Stakeholder management",
    priority: "Stakeholder",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32ab",
  },
  {
    flag: false,
    _id: "6473728bbdfe70ef5f9373a3",
    name: "Agile development methodologies",
    priority: "Agile",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32ac",
  },
  {
    flag: false,
    _id: "6473728bbdfe70ef5f937399",
    name: "Compression and caching",
    priority: "Compression",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32a9",
  },
  {
    flag: false,
    _id: "6473728bbdfe70ef5f9373a0",
    name: "Presentation skills",
    priority: "Presentation",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32ab",
  },
  {
    flag: false,
    _id: "6473728bbdfe70ef5f9373a5",
    name: "Project management software",
    priority: "Project",
    exact: true,
    skillGroup: "646fda03eb15635ce11e32ac",
  },
  {
    flag: true,
    _id: "64a3f8045c2923a8ac441ea0",
    name: "Persona creatio",
    __v: 0,
  },
];
