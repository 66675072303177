import React, { Component } from "react";
// import { HashLink } from 'react-router-hash-link'
import { getUser, updateUser } from "../store/actions/users";
import { logout } from "../store/actions/auth";
import { connect } from "react-redux";
import { addTodoFn, updateTodoFn } from "../store/actions/todo";
import {
  addLearningFn,
  getMicroSkillsFn,
  updateLearningFn,
} from "../store/actions/learning";
import OneDashnav from "./OneDashnav";
import {
  oneAss15,
  oneAss14,
  oneAss17,
  oneAss13,
  oneAss06,
  oneAss01,
  oneAss02,
  oneAss03,
  oneAss04,
  oneAss05,
  oneAss23,
  oneAss07,
  oneAss08,
  oneAss09,
  oneAss19,
  oneAss21,
  oneAss22,
} from "./Copy/Copy";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import EditIcon from "@mui/icons-material/Edit";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import "../assets/styles/learningplan.css";

class NewDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: "hom",
      showRes: false,
      showWish: false,
      showTodo: false,
      newTodo: "",
      todo: [],
      vid: 0,
      art: 0,
      webin: 0,
      pod: 0,
      course: 0,
      error: "",
      title: "",
      type: "Article",
      wishList: false,
      tempSkill: "",
      chosenSkill: [],
      reminder: false,
      link: "",
      img: "",
      microSkill: [],
      showAdd: false,
      resources: [],
      wishlist: [],
      search: "",
      allLearning: [],
      searchQuery: [],
      reminderRes: [],
      gmail: true,
      whatsapp: true,
      showMainRes: false,
      showMainWish: false,
      video: false,
      webinar: false,
      article: false,
      podcast: false,
      courseFil: false,
      keyword: "",
      tempSkillFil: "",
      chosenSkillFil: [],
      source: "",
      username: "",
      bio: "",
      tag: "",
      email: "",
      view: false,
      load: false,
      left: false,
      mobileFilter: "",
      tutorial: false,
      tutStep: 0,
    };
  }

  componentDidMount() {
    this.props.getUser(this.props.currentUser.user.id, "user").then(() => {
      var { resources, wishlist, reminderRes, allLearning } = this.state;
      const { currentUser } = this.props;
      if (currentUser.user.learningResource) {
        allLearning = currentUser.user.learningResource.sort((a, b) => {
          const at = new Date(a.createdAt);
          const bt = new Date(b.createdAt);
          return bt - at;
        });
        this.setState({
          allLearning,
        });
        resources = currentUser.user.learningResource.filter((e) => {
          if (e.wishList) {
            return !e.wishList.state;
          } else {
            return false;
          }
        });
        resources.sort((a, b) => {
          const at = new Date(a.createdAt);
          const bt = new Date(b.createdAt);
          return bt - at;
        });
        wishlist = currentUser.user.learningResource.filter((e) => {
          if (e.wishList) {
            return e.wishList.state;
          } else {
            return true;
          }
        });
        wishlist.sort((a, b) => {
          const at = new Date(a.createdAt);
          const bt = new Date(b.createdAt);
          return bt - at;
        });
        reminderRes = currentUser.user.learningResource.filter((e) => {
          if (e.reminder) {
            return e.reminder.state;
          } else {
            return true;
          }
        });
        this.setState({ resources, wishlist, reminderRes, load: true });
      }
      this.props
        .getMicroSkillsFn()
        .then(() => this.setState({ microSkill: this.props.microSkills }));
      if (currentUser.user.reminder) {
        this.setState({
          gmail: currentUser.user.reminder.gmail,
          whatsapp: currentUser.user.reminder.whatsapp,
        });
      }
      this.setState({
        todo: currentUser.user.toDo,
        email: currentUser.user.email,
        tag: currentUser.user.tag,
        bio: currentUser.user.bio,
        username: currentUser.user.username,
      });
      if (!localStorage.tutorial && window.innerWidth > 1200) {
        this.setState({
          tutorial: true,
        });
      }
    });
    const portfolio = localStorage.getItem("portfolio");
    if (portfolio && Date.now() - parseInt(portfolio, 10) < 3600 * 1000) {
      setTimeout(() => {
        var { resources, wishlist, reminderRes, allLearning } = this.state;
        const { currentUser } = this.props;
        if (currentUser.user.learningResource) {
          allLearning = currentUser.user.learningResource.sort((a, b) => {
            const at = new Date(a.createdAt);
            const bt = new Date(b.createdAt);
            return bt - at;
          });
          this.setState({
            allLearning,
          });
          resources = currentUser.user.learningResource.filter((e) => {
            if (e.wishList) {
              return !e.wishList.state;
            } else {
              return false;
            }
          });
          resources.sort((a, b) => {
            const at = new Date(a.createdAt);
            const bt = new Date(b.createdAt);
            return bt - at;
          });
          wishlist = currentUser.user.learningResource.filter((e) => {
            if (e.wishList) {
              return e.wishList.state;
            } else {
              return true;
            }
          });
          wishlist.sort((a, b) => {
            const at = new Date(a.createdAt);
            const bt = new Date(b.createdAt);
            return bt - at;
          });
          reminderRes = currentUser.user.learningResource.filter((e) => {
            if (e.reminder) {
              return e.reminder.state;
            } else {
              return true;
            }
          });
          this.setState({ resources, wishlist, reminderRes, load: true });
        }
        this.props.getMicroSkillsFn();
        setTimeout(
          () => this.setState({ microSkill: this.props.microSkills }),
          250
        );
        if (currentUser.user.reminder) {
          this.setState({
            gmail: currentUser.user.reminder.gmail,
            whatsapp: currentUser.user.reminder.whatsapp,
          });
        }
        this.setState({
          todo: currentUser.user.toDo,
          email: currentUser.user.email,
          tag: currentUser.user.tag,
          bio: currentUser.user.bio,
          username: currentUser.user.username,
        });
        if (!localStorage.tutorial && window.innerWidth > 1200) {
          this.setState({
            tutorial: true,
          });
        }
      }, 500);
    }
  }

  handlePages = (e, info) => {
    const { removeError } = this.props;
    removeError();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this.setState({
      display: info,
      mobileFilter: "",
      left: false,
    });
  };

  logout = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this.props.logout();
  };

  handleAddTodo = () => {
    this.setState({ view: true });
    this.props
      .addTodoFn({ title: this.state.newTodo })
      .then(() =>
        this.setState({
          todo: [...this.state.todo, this.props.newTodo],
          newTodo: "",
          view: false,
        })
      )
      .catch((err) => console.log(err));
  };

  handleUpdate = (id) => {
    var oldTodoo = this.state.todo;
    oldTodoo = oldTodoo.filter((e) => id !== e._id);
    this.setState({ todo: [...oldTodoo] });
    this.props
      .updateTodoFn({ done: true }, id)
      .then(() => {
        var oldTodo = this.state.todo;
        oldTodo = oldTodo.filter((e) => id !== e._id);
        this.setState({ todo: [...oldTodo, this.props.updatedTodo] });
      })
      .catch((err) => console.log(err));
  };

  handleWishToggle = (val) => {
    this.setState({ wishList: val });
  };

  handleAdd = (val) => {
    const { tempSkill, chosenSkill, tempSkillFil, chosenSkillFil } = this.state;
    if (val === "filter") {
      this.setState({ tempSkillFil: "" });
      if (!chosenSkillFil.includes(tempSkillFil) && tempSkillFil !== "") {
        this.setState({
          chosenSkillFil: [...chosenSkillFil, tempSkillFil.toLowerCase()],
        });
      }
    } else {
      this.setState({ tempSkill: "" });
      if (!chosenSkill.includes(tempSkill) && tempSkill !== "") {
        this.setState({ chosenSkill: [...chosenSkill, tempSkill] });
      }
    }
  };

  handleRemove = (i) => {
    const { chosenSkill } = this.state;
    var skil = [...chosenSkill];
    skil.splice(i, 1);
    this.setState({
      chosenSkill: [...skil],
    });
  };

  handleRemoveFil = (i) => {
    const { chosenSkillFil } = this.state;
    var skil = [...chosenSkillFil];
    skil.splice(i, 1);
    this.setState({
      chosenSkillFil: [...skil],
    });
  };

  handleForm = () => {
    const {
      title,
      link,
      img,
      chosenSkill,
      wishList,
      reminder,
      type,
      resources,
      wishlist,
      allLearning,
      reminderRes,
    } = this.state;
    let urlParts = /^(?:\w+\:\/\/)?([^\/]+)([^\?]*)\??(.*)$/.exec(link);
    this.setState({
      error: "",
      view: true,
    });

    this.props
      .addLearningFn({
        title,
        link,
        image: img,
        offeredBy: urlParts[1],
        microSkill: chosenSkill,
        wishList: { state: !wishList },
        reminder: { state: reminder },
        learningType: type,
      })
      .then(() => {
        if (wishList) {
          resources.unshift(this.props.newLearning);
          this.setState({
            resources,
          });
        } else {
          wishlist.unshift(this.props.newLearning);
          this.setState({ wishlist });
        }
        if (reminder) {
          reminderRes.unshift(this.props.newLearning);
          this.setState({
            reminderRes,
          });
        }
        this.setState({
          title: "",
          link: "",
          chosenSkill: [],
          allLearning: [this.props.newLearning, ...allLearning],
          view: false,
          showAdd: false,
        });
      })
      .catch((err) => {
        this.setState({
          view: false,
        });
      });
  };

  handleSearch = (e) => {
    var { allLearning, searchQuery } = this.state;
    this.setState({ search: e.target.value, showMainRes: true });
    searchQuery = allLearning.filter((el) => {
      if (el.title.toLowerCase().includes(e.target.value.toLowerCase())) {
        return true;
      } else if (
        el.offeredBy.toLowerCase().includes(e.target.value.toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    });
    this.setState({ searchQuery });
  };

  handleUpdateReminder = (id) => {
    var oldReminder = this.state.reminderRes;
    oldReminder = oldReminder.filter((e) => id !== e._id);
    this.setState({ reminderRes: [...oldReminder] });
    this.props
      .updateLearningFn({ reminder: { state: false } }, id)
      .then(() => {
        var oldReminder = this.state.reminderRes;
        oldReminder = oldReminder.filter((e) => id !== e._id);
        this.setState({
          reminderRes: [...oldReminder],
        });
      })
      .catch((err) => console.log(err));
  };

  handleUserReminder = (rem) => {
    const { gmail, whatsapp } = this.state;
    if (rem === "gmail") {
      this.setState({ gmail: !gmail });
      this.props.updateUser({ reminder: { gmail: !gmail, whatsapp } });
    } else {
      this.setState({ whatsapp: !whatsapp });
      this.props.updateUser({ reminder: { gmail, whatsapp: !whatsapp } });
    }
  };

  onChange = (e) => {
    if (e.target.files) {
      var formData = new FormData();
      formData.append("avatar", e.target.files[0]);
      this.props.updateUser(formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    }
    this.setState({
      [e.target.name]: e.target.value,
      error: "",
    });
  };

  onClick = (e) => {
    e.target.parentNode.childNodes[1].click();
  };

  handleSubmit = (e) => {
    this.setState({ view: true });
    const submitArr = ["tag", "bio", "username"];
    var submit = {};
    const errArr = submitArr.filter((e) => {
      if (!this.state[e]) {
        return false;
      } else {
        return true;
      }
    });
    if (errArr.length === 0) {
      this.setState({ error: "Please fill in the fields", view: false });
      return;
    }
    submitArr.forEach((e) => {
      if (this.state[e]) {
        submit = { ...submit, [e]: this.state[e] };
      }
    });
    this.props
      .updateUser(submit)
      .then(() => {
        this.setState({ view: false });
      })
      .catch(() => {});
  };

  handleScroll = (add) => {
    window.scrollTo({
      top: add,
      behavior: "smooth",
    });
  };

  handleSkip = () => {
    localStorage.setItem("tutorial", true);
    this.setState({
      tutorial: false,
      tutStep: 0,
    });
  };

  render() {
    const { currentUser } = this.props;
    var {
      display,
      showRes,
      showWish,
      showTodo,
      newTodo,
      todo,
      vid,
      webin,
      art,
      pod,
      course,
      error,
      title,
      type,
      tempSkill,
      chosenSkill,
      microSkill,
      link,
      wishList,
      showAdd,
      resources,
      wishlist,
      search,
      searchQuery,
      reminderRes,
      reminder,
      allLearning,
      gmail,
      whatsapp,
      showMainRes,
      showMainWish,
      video,
      webinar,
      article,
      podcast,
      courseFil,
      keyword,
      source,
      tempSkillFil,
      chosenSkillFil,
      email,
      tag,
      username,
      bio,
      view,
      load,
      mobileFilter,
      tutorial,
      tutStep,
    } = this.state;
    if (currentUser.user.picture) {
      if (currentUser.user.picture.indexOf("google") !== -1) {
        var imgStr = currentUser.user.picture;
      } else if (currentUser.user.picture.indexOf("trailbucket12345") !== -1) {
        imgStr =
          "https://dqe80zoqbuyqe.cloudfront.net" +
          currentUser.user.picture.substring(51);
      } else {
        imgStr = "";
      }
    }
    var week = { Sun: 0, Mon: 0, Tue: 0, Wed: 0, Thur: 0, Fri: 0, Sat: 0 };
    const days = Object.keys(week);
    if (currentUser.user.learningResource) {
      resources.forEach((e) => {
        if (e.learningType === "Video") {
          vid++;
        } else if (e.learningType === "Article") {
          art++;
        } else if (e.learningType === "Podcast") {
          pod++;
        } else if (e.learningType === "Webinar") {
          webin++;
        } else if (e.learningType === "Course") {
          course++;
        }
      });
      const prevSunday = new Date();
      prevSunday.setDate(prevSunday.getDate() - prevSunday.getDay());
      prevSunday.setHours(0, 0, 0, 0);
      resources.forEach((e) => {
        const date = new Date(e.createdAt);
        if (date > prevSunday) {
          days.forEach((el, i) => {
            if (date.getDay() === i) {
              week[el]++;
            }
          });
        }
      });
      days.forEach((e, i) => {
        if (week[e] > 10) {
          week[e] = 100;
        } else {
          week[e] = week[e] * 10;
        }
      });
      if (display === "hom") {
        if (!showRes && window.innerWidth > 600) {
          resources = resources.slice(0, 3);
        }
        if (!showWish && window.innerWidth > 600) {
          wishlist = wishlist.slice(0, 3);
        }
      } else {
        if (display === "dahpah") {
          var filter = false;
          let newLearnings;
          newLearnings = allLearning.filter((e) => {
            if (video) {
              filter = true;
              if (e.learningType === "Video") {
                return true;
              }
            }
            return false;
          });
          if (filter) {
            newLearnings = newLearnings.filter((e) => {
              if (podcast) {
                filter = true;
                if (e.learningType === "Podcast") {
                  return true;
                } else {
                  return false;
                }
              }
              return true;
            });
          } else {
            newLearnings = allLearning.filter((e) => {
              if (podcast) {
                filter = true;
                if (e.learningType === "Podcast") {
                  return true;
                }
              }
              return false;
            });
          }
          if (filter) {
            newLearnings = newLearnings.filter((e) => {
              if (article) {
                filter = true;
                if (e.learningType === "Article") {
                  return true;
                } else {
                  return false;
                }
              }
              return true;
            });
          } else {
            newLearnings = allLearning.filter((e) => {
              if (article) {
                filter = true;
                if (e.learningType === "Article") {
                  return true;
                }
              }
              return false;
            });
          }

          if (filter) {
            newLearnings = newLearnings.filter((e) => {
              if (webinar) {
                filter = true;
                if (e.learningType === "Webinar") {
                  return true;
                } else {
                  return false;
                }
              }
              return true;
            });
          } else {
            newLearnings = allLearning.filter((e) => {
              if (webinar) {
                filter = true;
                if (e.learningType === "Webinar") {
                  return true;
                }
              }
              return false;
            });
          }

          if (filter) {
            newLearnings = newLearnings.filter((e) => {
              if (courseFil) {
                filter = true;
                if (e.learningType === "Course") {
                  return true;
                } else {
                  return false;
                }
              }
              return true;
            });
          } else {
            newLearnings = allLearning.filter((e) => {
              if (courseFil) {
                filter = true;
                if (e.learningType === "Course") {
                  return true;
                }
              }
              return false;
            });
          }

          if (filter) {
            newLearnings = newLearnings.filter((e) => {
              if (keyword) {
                filter = true;
                if (e.title.toLowerCase().includes(keyword.toLowerCase())) {
                  return true;
                } else {
                  return false;
                }
              }
              return true;
            });
          } else {
            newLearnings = allLearning.filter((e) => {
              if (keyword) {
                filter = true;
                if (e.title.toLowerCase().includes(keyword.toLowerCase())) {
                  return true;
                }
              }
              return false;
            });
          }

          if (filter) {
            newLearnings = newLearnings.filter((e) => {
              if (source) {
                filter = true;
                if (e.offeredBy.toLowerCase().includes(source.toLowerCase())) {
                  return true;
                } else {
                  return false;
                }
              }
              return true;
            });
          } else {
            newLearnings = allLearning.filter((e) => {
              if (source) {
                filter = true;
                if (e.offeredBy.toLowerCase().includes(source.toLowerCase())) {
                  return true;
                }
              }
              return false;
            });
          }

          if (filter) {
            newLearnings = newLearnings.filter((e) => {
              if (chosenSkillFil.length > 0) {
                filter = true;
                if (e.microSkill) {
                  var mSki = false;
                  e.microSkill.forEach((el) => {
                    if (chosenSkillFil.includes(el.toLowerCase())) {
                      mSki = true;
                      return true;
                    }
                  });
                  if (mSki) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return false;
                }
              }
              return true;
            });
          } else {
            newLearnings = allLearning.filter((e) => {
              if (chosenSkillFil.length > 0) {
                filter = true;
                if (e.microSkill) {
                  var mSki = false;
                  e.microSkill.forEach((el) => {
                    if (chosenSkillFil.includes(el.toLowerCase())) {
                      mSki = true;
                      return true;
                    }
                  });
                  if (mSki) {
                    return true;
                  } else {
                    return false;
                  }
                }
              }
              return false;
            });
          }
          if (filter) {
            allLearning = newLearnings;
          } else if (!showMainRes && !mobileFilter) {
            allLearning = allLearning.slice(0, 6);
          } else if (mobileFilter) {
            allLearning = allLearning.filter((e) => {
              if (mobileFilter === "vid" && e.learningType === "Video") {
                return true;
              } else if (
                mobileFilter === "art" &&
                e.learningType === "Article"
              ) {
                return true;
              } else if (
                mobileFilter === "cor" &&
                e.learningType === "Course"
              ) {
                return true;
              }
              return false;
            });
          }
        }
        if (!showMainWish && !mobileFilter) {
          wishlist = wishlist.slice(0, 6);
        } else if (mobileFilter) {
          wishlist = wishlist.filter((e) => {
            if (mobileFilter === "vid" && e.learningType === "Video") {
              return true;
            } else if (mobileFilter === "art" && e.learningType === "Article") {
              return true;
            } else if (mobileFilter === "cor" && e.learningType === "Course") {
              return true;
            }
            return false;
          });
        }
      }
      todo = todo.filter((e) => !e.done);
      if (mobileFilter) {
        resources = resources.filter((e) => {
          if (mobileFilter === "vid" && e.learningType === "Video") {
            return true;
          } else if (mobileFilter === "art" && e.learningType === "Article") {
            return true;
          } else if (mobileFilter === "cor" && e.learningType === "Course") {
            return true;
          }
          return false;
        });
      }
      if (search && window.innerWidth < 600) {
        allLearning = allLearning.filter((el) => {
          if (el.title.toLowerCase().includes(search.toLowerCase())) {
            return true;
          } else if (
            el.offeredBy.toLowerCase().includes(search.toLowerCase())
          ) {
            return true;
          } else {
            return false;
          }
        });
        resources = resources.filter((el) => {
          if (el.title.toLowerCase().includes(search.toLowerCase())) {
            return true;
          } else if (
            el.offeredBy.toLowerCase().includes(search.toLowerCase())
          ) {
            return true;
          } else {
            return false;
          }
        });
        wishlist = wishlist.filter((el) => {
          if (el.title.toLowerCase().includes(search.toLowerCase())) {
            return true;
          } else if (
            el.offeredBy.toLowerCase().includes(search.toLowerCase())
          ) {
            return true;
          } else {
            return false;
          }
        });
      }
    }
    let setValue = (val) => (val > 9 ? "" : "0") + val;
    let setDate = (date) => {
      const dateSet = new Date(date);
      date = dateSet.toLocaleDateString("en-us", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
      return date;
    };
    let capitalize = (str) =>
      str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    let trunk = (str, no) => (str.length > no ? `${str.slice(0, no)}...` : str);
    let ain = [
      "",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
      "ten",
      "eleven",
      "twelve",
      "thirteen",
      "fourteen",
      "fifteen",
      "sixteen",
      "seventeen",
    ];
    return (
      <div className="dash-hero">
        <OneDashnav
          page={this.handlePages}
          display={display}
          left={this.state.left}
          nav={() => this.setState({ left: !this.state.left })}
          img={imgStr}
          fName={currentUser.user.firstName}
          lName={currentUser.user.lastName}
          step={() =>
            this.setState({
              tutStep: tutStep + 1,
            })
          }
          scroll={this.handleScroll}
          tutStep={tutStep}
        />
        <div className="dash-container one" onClick={this.containerClick}>
          <div className="mobile-head">
            <div
              className="menu-op"
              onClick={() => this.setState({ left: !this.state.left })}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div className="name">
              {display === "dahmen"
                ? "Learning plan"
                : display === "dahpah"
                ? "Learning history"
                : display === "ref"
                ? "Wishlist"
                : "Home"}
            </div>
            <div className="profile">
              <div className="profile-img">
                <img
                  src={
                    imgStr
                      ? imgStr
                      : "https://pngimg.com/uploads/avatar/avatar_PNG47.png"
                  }
                  alt="profile pic"
                />
              </div>
            </div>
          </div>
          <div
            className={`search ${display === "dahmen" ? "full" : ""} ${
              display === "proff" ? "hide" : ""
            } ${display === "hom" ? "sm-hide" : ""} ${
              tutStep === 1 ? "tutsie" : ""
            }`}
          >
            <div className="up">
              <input
                type="text"
                placeholder="search by name or where the resource is from"
                value={search}
                onChange={this.handleSearch}
              />
              <i className="fa fa-search"></i>
              <div className={`results-tab progress ${search ? "" : "hide"}`}>
                <div className="card-container">
                  {!load ? (
                    <>
                      <div className="card">
                        <div className="date load flicker"></div>
                        <div className="img load flicker"></div>
                        <div className="band">
                          <div className="title load flicker"></div>
                          <div className="info">
                            <div className="mskill load flicker"></div>
                            <div className="mskill load flicker"></div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="date load flicker"></div>
                        <div className="img load flicker"></div>
                        <div className="band">
                          <div className="title load flicker"></div>
                          <div className="info">
                            <div className="mskill load flicker"></div>
                            <div className="mskill load flicker"></div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="date load flicker"></div>
                        <div className="img load flicker"></div>
                        <div className="band">
                          <div className="title load flicker"></div>
                          <div className="info">
                            <div className="mskill load flicker"></div>
                            <div className="mskill load flicker"></div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : searchQuery.length === 0 ? (
                    <div className="inner">
                      <div className="img">
                        <img src={oneAss22} alt="Trail welcome" />
                      </div>
                      <p>
                        We couldn't find a that resource. You can add a resource
                        here manually
                      </p>
                      <div
                        className="addResource"
                        onClick={() =>
                          this.setState({
                            showAdd: true,
                            wishList: true,
                            reminder: false,
                          })
                        }
                      >
                        <div className="iconcontainer">
                          <span></span>
                          <span></span>
                        </div>
                        <p>Add a course</p>
                      </div>
                    </div>
                  ) : (
                    searchQuery.map((e, i) => (
                      <div
                        className={`card ${
                          e.wishList
                            ? e.wishList.state
                              ? "light"
                              : ""
                            : "light"
                        }`}
                        key={i}
                      >
                        <div className="img">
                          <img
                            src={
                              e.image
                                ? e.image
                                : `https://trailbucket12345.s3.us-east-2.amazonaws.com/content/Content+Images-${setValue(
                                    i + 1
                                  )}.png`
                            }
                            alt="course-cover"
                          />
                        </div>
                        <div className="band">
                          <div className="title">
                            {trunk(capitalize(e.title), 100)}
                            <div className="space"></div>
                            <a
                              className="btn"
                              target="_blank"
                              rel="noreferrer"
                              href={e.link}
                            >
                              <div className="arrow">
                                <span></span>
                                <span></span>
                                <span></span>
                              </div>
                            </a>
                          </div>
                          <div className="info">
                            <div className="mskill">
                              {e.microSkill.length > 0
                                ? trunk(capitalize(e.microSkill[0]), 17)
                                : trunk(capitalize(e.offeredBy), 17)}
                            </div>
                            <div className="date">{setDate(e.createdAt)}</div>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
              <CloseOutlinedIcon
                className={search ? "" : "hide"}
                onClick={() => this.setState({ search: "" })}
              />
            </div>
            {tutStep === 1 && (
              <>
                <div className="highlight"></div>
                <div className="inner-tut">
                  <h3>
                    Add a learning resource manually from any of your devices
                  </h3>
                  <p>
                    To add a learning resource manually from your phone, tablet
                    or laptop, simply click on this button and fill up the form
                    that pops up.
                  </p>
                  <div className="btn-group">
                    <div className="skip" onClick={this.handleSkip}>
                      Skip all
                    </div>
                    <div
                      className="start"
                      onClick={() =>
                        this.setState({ tutStep: tutStep + 1, showAdd: true })
                      }
                    >
                      Next
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="bottom">
              <div className={`back-active ${mobileFilter}`}></div>
              <div
                className="sm-filter"
                onClick={() => this.setState({ mobileFilter: "" })}
              >
                All
              </div>
              <div
                className="sm-filter"
                onClick={() => this.setState({ mobileFilter: "vid" })}
              >
                Videos
              </div>
              <div
                className="sm-filter"
                onClick={() => this.setState({ mobileFilter: "cor" })}
              >
                Courses
              </div>
              <div
                className="sm-filter"
                onClick={() => this.setState({ mobileFilter: "art" })}
              >
                Articles
              </div>
              <div
                className="addResource"
                onClick={() =>
                  this.setState({
                    showAdd: true,
                    wishList: true,
                    reminder: false,
                  })
                }
              >
                <div className="iconcontainer">
                  <span></span>
                  <span></span>
                </div>
                <p>Add a course</p>
              </div>
            </div>
          </div>
          <div
            className={`outer-pop ${showAdd ? "" : "hide"} ${
              tutStep === 2 || tutStep === 3 ? "tutsie" : ""
            }`}
          >
            <div
              className="inner"
              onClick={() => this.setState({ showAdd: false })}
            ></div>
            <div className="container addModal">
              <div
                className="cancel-btn"
                onClick={() => this.setState({ showAdd: false })}
              >
                <CloseOutlinedIcon />
              </div>
              <h2>Add resource to one Trail and track your learning</h2>

              {error && <div className="error">{error}</div>}
              <div className="form-container">
                <div className="item">
                  <p>Title</p>
                  <input
                    type="text"
                    value={title}
                    placeholder="Add a title"
                    onChange={(event) =>
                      this.setState({ title: event.target.value })
                    }
                  />
                </div>
                <div className="item">
                  <p>URL link</p>
                  <input
                    type="text"
                    value={link}
                    placeholder="Add a link"
                    onChange={(event) =>
                      this.setState({ link: event.target.value })
                    }
                  />
                </div>
                <div className="item">
                  <p>Type</p>
                  <div className="input">
                    <div className="up">
                      <div className="type">
                        <select
                          name="type"
                          id="type-option"
                          value={type}
                          onChange={(event) =>
                            this.setState({ type: event.target.value })
                          }
                        >
                          <option value="Article">Article</option>
                          <option value="Course">Course</option>
                          <option value="Video">Video</option>
                          <option value="Webinar">Webinar</option>
                          <option value="Podcast">Podcast</option>
                        </select>
                      </div>
                    </div>
                    <div className="down">
                      <div className="toggle">
                        <div
                          className={`back ${wishList ? "" : "forward"}`}
                        ></div>
                        <div
                          className={`tog-item ${wishList ? "active" : ""}`}
                          onClick={this.handleWishToggle.bind(this, true)}
                        >
                          Learning
                        </div>
                        <div
                          className={`tog-item ${wishList ? "" : "active"}`}
                          onClick={this.handleWishToggle.bind(this, false)}
                        >
                          WishList
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <p className="quest">
                    Micro skill <HelpOutlineOutlinedIcon />
                  </p>
                  <div className="input">
                    <div className="top">
                      <input
                        type="search"
                        id="skil-search"
                        list="skill-option"
                        placeholder="Type in a Micro skill"
                        value={tempSkill}
                        onChange={(event) =>
                          this.setState({ tempSkill: event.target.value })
                        }
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            this.handleAdd();
                          }
                        }}
                      />
                      <datalist id="skill-option">
                        {microSkill.map((skill, i) => (
                          <option value={skill.name} key={i}>
                            {skill.name}
                          </option>
                        ))}
                      </datalist>
                    </div>
                    <div className="bottom">
                      <AddOutlinedIcon onClick={this.handleAdd} />
                    </div>
                  </div>
                </div>
                <div className="skillset item">
                  {chosenSkill.map((el, i) => (
                    <div className="skill-item" key={i}>
                      <span>{el}</span>
                      <CloseOutlinedIcon
                        onClick={this.handleRemove.bind(this, i)}
                      />
                    </div>
                  ))}
                </div>
                <div className="reminder  item">
                  <p>Click here to get a reminder</p>
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      this.setState({ reminder: e.target.checked });
                    }}
                    checked={reminder}
                  />
                </div>

                <div className="btn">
                  <div
                    className={`submit ${view ? "btn-load" : ""}`}
                    onClick={this.handleForm}
                  >
                    <span className="btn_text">Submit</span>
                  </div>
                  <div
                    className="cancel"
                    onClick={() => this.setState({ showAdd: false })}
                  >
                    Cancel
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`tutorial ${ain[tutStep]} ${tutorial ? "" : "hide"}`}>
            <div className={`popup ${tutStep !== 0 ? "hide" : ""}`}>
              <div className="containing">
                <div className="img">
                  <img src={oneAss14} alt="" />
                </div>
                <div className="content">
                  <h1>Welcome to the organized side of learning.</h1>
                  <p>
                    You have a learning personal assistant waiting to be
                    assigned to you. Just quickly take a look around your
                    learning dashboard to get a hang of how things work for you
                    here.
                  </p>
                </div>
              </div>
              <div className="btn-groups">
                <div className="skip" onClick={this.handleSkip}>
                  Skip all
                </div>
                <div
                  className="start"
                  onClick={() => {
                    this.setState({ tutStep: tutStep + 1 });
                    this.handleScroll(0);
                    localStorage.setItem("tutorial", true);
                  }}
                >
                  Start the tour
                </div>
              </div>
            </div>
            {tutStep === 17 && (
              <div className="popup">
                <div className="containing">
                  <div className="img">
                    <img src={oneAss17} alt="" />
                  </div>
                  <div className="content">
                    <h1>
                      You are finally set! Start your journey of organized
                      learning now.
                    </h1>
                    <p>
                      You seem to know your way around your learning dashboard
                      now, it’s time to show you around the extension to see how
                      it works for you.
                    </p>
                  </div>
                </div>
                <div className="btn-groups">
                  <div className="skip" onClick={this.handleSkip}>
                    Cancel
                  </div>
                  {localStorage.getItem("trail-extension") ? (
                    <a
                      className="start"
                      href="https://www.youtube.com/watch?v=k2MFJEmnrj4"
                      target="_blank"
                      rel="noreferrer"
                      onClick={() => {
                        this.setState({ tutorial: false, tutStep: 0 });
                      }}
                    >
                      Go to extension
                    </a>
                  ) : (
                    <a
                      className="start"
                      href="https://bit.ly/3SjOoWZ"
                      target="_blank"
                      rel="noreferrer"
                      onClick={() => {
                        this.setState({ tutorial: false, tutStep: 0 });
                      }}
                    >
                      Download Extension
                    </a>
                  )}
                </div>
              </div>
            )}
            <div className="background-float"></div>
            {tutStep === 2 && (
              <div className="inner-tut">
                <div
                  className="back"
                  onClick={() =>
                    this.setState({ tutStep: tutStep - 1, showAdd: false })
                  }
                >
                  <div className="icon">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                  Go back
                </div>
                <h3>Fill up the form to add resource to your learning board</h3>
                <p>
                  Fill the form and the resource will be added to your learning
                  board. Don’t forget to tick the reminder button if you want to
                  get weekly reminders on the resource you are learning.
                </p>
                <div className="btn-group">
                  <div className="skip" onClick={this.handleSkip}>
                    Skip all
                  </div>
                  <div
                    className="start"
                    onClick={() => this.setState({ tutStep: tutStep + 1 })}
                  >
                    Next
                  </div>
                </div>
              </div>
            )}
            {tutStep === 3 && (
              <div className="inner-tut">
                <div
                  className="back"
                  onClick={() => this.setState({ tutStep: tutStep - 1 })}
                >
                  <div className="icon">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                  Go back
                </div>
                <h3>Add Microskills for better analysis</h3>
                <p>
                  Microskills are tiny skills gained from learning. Microskills
                  help the system properly track and analyse your learning. You
                  can add more than one microskill. click the button to add or
                  press enter.
                </p>
                <div className="btn-group">
                  <div className="skip" onClick={this.handleSkip}>
                    Skip all
                  </div>
                  <div
                    className="start"
                    onClick={() => {
                      this.setState({ tutStep: tutStep + 1, showAdd: false });
                      this.handleScroll(172);
                    }}
                  >
                    Next
                  </div>
                </div>
              </div>
            )}
            {tutStep === 11 && (
              <div className="inner-tut">
                <div
                  className="back"
                  onClick={() => {
                    this.setState({ tutStep: tutStep - 1 });
                    this.handleScroll(100);
                  }}
                >
                  <div className="icon">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                  Go back
                </div>
                <h3>Go through all you have learned in the past</h3>
                <p>
                  This section is the archive of all the learning resources you
                  have consumed in the past, all organized and visualized in one
                  place.
                </p>
                <div className="btn-group">
                  <div className="skip" onClick={this.handleSkip}>
                    Skip all
                  </div>
                  <div
                    className="start"
                    onClick={(e) => {
                      this.setState({ tutStep: tutStep + 1 });
                      this.handlePages(e, "dahpah");
                      this.handleScroll(0);
                    }}
                  >
                    Next
                  </div>
                </div>
              </div>
            )}
            {tutStep === 13 && (
              <div className="inner-tut">
                <div
                  className="back"
                  onClick={(e) => {
                    this.setState({ tutStep: tutStep - 1 });
                    this.handlePages(e, "dahpah");
                  }}
                >
                  <div className="icon">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                  Go back
                </div>
                <h3>
                  This section houses all the learning resources in your
                  wishlist
                </h3>
                <p>
                  When you save courses and other learning resources to your
                  wishlist, you get to see them here.
                </p>
                <div className="btn-group">
                  <div className="skip" onClick={this.handleSkip}>
                    Skip all
                  </div>
                  <div
                    className="start"
                    onClick={(e) => {
                      this.setState({ tutStep: tutStep + 1 });
                      this.handlePages(e, "ref");
                    }}
                  >
                    Next
                  </div>
                </div>
              </div>
            )}
            {tutStep === 15 && (
              <div className="inner-tut">
                <div
                  className="back"
                  onClick={(e) => {
                    this.setState({ tutStep: tutStep - 1 });
                  }}
                >
                  <div className="icon">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                  Go back
                </div>
                <h3>Go here to personalize your dashboard</h3>
                <p>
                  If you are looking to create/edit your profile, here is the
                  place to go, look no further.
                </p>
                <div className="btn-group">
                  <div className="skip" onClick={this.handleSkip}>
                    Skip all
                  </div>
                  <div
                    className="start"
                    onClick={(e) => {
                      this.setState({ tutStep: tutStep + 1 });
                      this.handlePages(e, "proff");
                      setTimeout(() => {
                        this.handleScroll(200);
                      }, 100);
                    }}
                  >
                    Next
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={`one-home ${display === "hom" ? "" : "hide"} `}>
            <div
              className={`main ${
                tutStep > 3 && tutStep < 7 ? ain[tutStep] : ""
              }`}
            >
              <div className="welcome">
                <div className="icon">
                  <img src={oneAss15} alt="Trail welcome" />
                </div>
                <div className="text">
                  <div className="head">
                    <span>Hello,</span>{" "}
                    <span>
                      {currentUser.user.firstName}{" "}
                      <span>{currentUser.user.lastName}</span>!
                    </span>
                  </div>
                  <p>
                    Welcome to your dashboard. You can add resources manually,
                    view your learning history and track your performance here.
                  </p>
                </div>
              </div>
              <div className="sm-brief">
                <div
                  className="tile"
                  onClick={(e) => this.handlePages(e, "hom-sm")}
                >
                  <div className="img">
                    <img
                      src="https://trailbucket12345.s3.us-east-2.amazonaws.com/content/Content+Images-02.png"
                      alt=""
                    />
                  </div>
                  <div className="info">
                    <div className="title">In Progress</div>
                    <p>Your learning from the past few days</p>
                  </div>
                  <div className="val">
                    <div className="no">{resources.length}</div>
                    <div className="icon">
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
                <div
                  className="tile"
                  onClick={(e) => this.handlePages(e, "dahmen")}
                >
                  <div className="img">
                    <img
                      src="https://trailbucket12345.s3.us-east-2.amazonaws.com/content/Content+Images-03.png"
                      alt=""
                    />
                  </div>
                  <div className="info">
                    <div className="title">Learning to-do</div>
                    <p>What you plan to learn</p>
                  </div>
                  <div className="val">
                    <div className="no">{reminderRes.length}</div>
                    <div className="icon">
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
                <div
                  className="tile"
                  onClick={(e) => this.handlePages(e, "ref")}
                >
                  <div className="img">
                    <img
                      src="https://trailbucket12345.s3.us-east-2.amazonaws.com/content/Content+Images-04.png"
                      alt=""
                    />
                  </div>
                  <div className="info">
                    <div className="title">Wishlist</div>
                    <p>Things you wish to learn</p>
                  </div>
                  <div className="val">
                    <div className="no">{wishlist.length}</div>
                    <div className="icon">
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
                <div
                  className="tile"
                  onClick={(e) => this.handlePages(e, "dahpah")}
                >
                  <div className="img">
                    <img
                      src="https://trailbucket12345.s3.us-east-2.amazonaws.com/content/Content+Images-05.png"
                      alt=""
                    />
                  </div>
                  <div className="info">
                    <div className="title">History</div>
                    <p>Your life's learning in one place</p>
                  </div>
                  <div className="val">
                    <div className="no">{allLearning.length}</div>
                    <div className="icon">
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
              {tutStep === 4 && (
                <>
                  <div className="highlight"></div>
                  <div className="inner-tut">
                    <div
                      className="back"
                      onClick={() =>
                        this.setState({ tutStep: tutStep - 1, showAdd: true })
                      }
                    >
                      <div className="icon">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                      Go back
                    </div>
                    <h3>You will see your most recent resources here</h3>
                    <p>
                      All your most recent resources will be displayed here for
                      you to see. When it exceeds 6, you can click on view all
                      to see them all.
                    </p>
                    <div className="btn-group">
                      <div className="skip" onClick={this.handleSkip}>
                        Skip all
                      </div>
                      <div
                        className="start"
                        onClick={() => {
                          this.setState({ tutStep: tutStep + 1 });
                          this.handleScroll(255);
                        }}
                      >
                        Next
                      </div>
                    </div>
                  </div>
                </>
              )}
              {tutStep === 5 && (
                <>
                  <div className="highlight five"></div>
                  <div className="inner-tut">
                    <div
                      className="back"
                      onClick={() => {
                        this.setState({ tutStep: tutStep - 1 });
                        this.handleScroll(172);
                      }}
                    >
                      <div className="icon">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                      Go back
                    </div>
                    <h3>
                      A quick way to see the kind of learning materials you
                      consume
                    </h3>
                    <p>
                      This section displays the resources you are learning in
                      the formats you are consuming them.
                    </p>
                    <div className="btn-group">
                      <div className="skip" onClick={this.handleSkip}>
                        Skip all
                      </div>
                      <div
                        className="start"
                        onClick={() => {
                          this.setState({ tutStep: tutStep + 1 });
                          this.handleScroll(670);
                        }}
                      >
                        Next
                      </div>
                    </div>
                  </div>
                </>
              )}
              {tutStep === 6 && (
                <>
                  <div className="highlight six"></div>
                  <div className="inner-tut">
                    <div
                      className="back"
                      onClick={() => {
                        this.setState({ tutStep: tutStep - 1 });
                        this.handleScroll(225);
                      }}
                    >
                      <div className="icon">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                      Go back
                    </div>
                    <h3>Here is where you keep learning resources for later</h3>
                    <p>
                      Know how you see courses/videos, want to learn from them
                      but don’t have the time to complete them right away? You
                      can simply save them to your wishlist and see them here.
                    </p>
                    <div className="btn-group">
                      <div className="skip" onClick={this.handleSkip}>
                        Skip all
                      </div>
                      <div
                        className="start"
                        onClick={() => {
                          this.setState({ tutStep: tutStep + 1 });
                          this.handleScroll(92);
                        }}
                      >
                        Next
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="progress">
                <div className="top">
                  <p>In Progress</p>
                  <div
                    className="btn"
                    onClick={() => this.setState({ showRes: !showRes })}
                  >
                    {showRes ? "Hide all" : "View all"}
                  </div>
                </div>
                <div className="card-container">
                  {!load ? (
                    <>
                      <div className="card">
                        <div className="date load flicker"></div>
                        <div className="img load flicker"></div>
                        <div className="band">
                          <div className="title load flicker"></div>
                          <div className="info">
                            <div className="mskill load flicker"></div>
                            <div className="mskill load flicker"></div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="date load flicker"></div>
                        <div className="img load flicker"></div>
                        <div className="band">
                          <div className="title load flicker"></div>
                          <div className="info">
                            <div className="mskill load flicker"></div>
                            <div className="mskill load flicker"></div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="date load flicker"></div>
                        <div className="img load flicker"></div>
                        <div className="band">
                          <div className="title load flicker"></div>
                          <div className="info">
                            <div className="mskill load flicker"></div>
                            <div className="mskill load flicker"></div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : resources.length === 0 ? (
                    <div className="inner">
                      <div className="img">
                        <img src={oneAss19} alt="Trail welcome" />
                      </div>
                      <p>
                        We haven’t picked anything yet. You can add a resource
                        here manually.
                      </p>
                      <div
                        className="addResource"
                        onClick={() =>
                          this.setState({
                            showAdd: true,
                            wishList: true,
                            reminder: false,
                          })
                        }
                      >
                        <div className="iconcontainer">
                          <span></span>
                          <span></span>
                        </div>
                        <p>Add a course</p>
                      </div>
                    </div>
                  ) : (
                    resources.map((e, i) => (
                      <div className="card" key={i}>
                        <div className="img">
                          <img
                            src={
                              e.image
                                ? e.image
                                : `https://trailbucket12345.s3.us-east-2.amazonaws.com/content/Content+Images-${setValue(
                                    i + 1
                                  )}.png`
                            }
                            alt="course-cover"
                          />
                        </div>
                        <div className="band">
                          <div className="title">
                            {trunk(capitalize(e.title), 100)}
                            <div className="space"></div>
                            <a
                              className="btn"
                              target="_blank"
                              rel="noreferrer"
                              href={e.link}
                            >
                              <div className="arrow">
                                <span></span>
                                <span></span>
                                <span></span>
                              </div>
                            </a>
                          </div>
                          <div className="info">
                            <div className="mskill">
                              {e.microSkill.length > 0
                                ? trunk(capitalize(e.microSkill[0]), 17)
                                : trunk(capitalize(e.offeredBy), 17)}
                            </div>
                            <div className="date">{setDate(e.createdAt)}</div>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
              <div className="pace">
                <div className="title">Learning Pace</div>
                <div className="pace-containers">
                  <div className="card bg">
                    <div className="up">
                      <p>Videos</p>
                      <img src={oneAss02} alt="trail videos" />
                    </div>
                    <div className="down">
                      <div className="no">{setValue(vid)}</div>
                      <div className="arrow">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </div>
                  <div className="card bg">
                    <div className="up">
                      <p>Articles</p>
                      <img src={oneAss03} alt="trail videos" />
                    </div>
                    <div className="down">
                      <div className="no">{setValue(art)}</div>
                      <div className="arrow">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </div>
                  <div className="card bg">
                    <div className="up">
                      <p>Webinar</p>
                      <img src={oneAss01} alt="trail videos" />
                    </div>
                    <div className="down">
                      <div className="no">{setValue(webin)}</div>
                      <div className="arrow">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </div>
                  <div className="card bg">
                    <div className="up">
                      <p>Courses</p>
                      <img src={oneAss05} alt="trail videos" />
                    </div>
                    <div className="down">
                      <div className="no">{setValue(course)}</div>
                      <div className="arrow">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </div>
                  <div className="card bg">
                    <div className="up">
                      <p>Podcasts</p>
                      <img src={oneAss04} alt="trail videos" />
                    </div>
                    <div className="down">
                      <div className="no">{setValue(pod)}</div>
                      <div className="arrow">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </div>
                  <div className="card sm">
                    <div className="up">
                      <p>Videos</p>
                      <div className="no">{setValue(vid)}</div>
                    </div>
                    <div className="down">
                      <img src={oneAss02} alt="trail videos" />
                    </div>
                  </div>
                  <div className="card sm">
                    <div className="up">
                      <p>Articles</p>
                      <div className="no">{setValue(art)}</div>
                    </div>
                    <div className="down">
                      <img src={oneAss03} alt="trail videos" />
                    </div>
                  </div>
                  <div className="card sm">
                    <div className="up">
                      <p>Webinar</p>
                      <div className="no">{setValue(webin)}</div>
                    </div>
                    <div className="down">
                      <img src={oneAss01} alt="trail videos" />
                    </div>
                  </div>
                  <div className="card sm">
                    <div className="up">
                      <p>Courses</p>
                      <div className="no">{setValue(course)}</div>
                    </div>
                    <div className="down">
                      <img src={oneAss05} alt="trail videos" />
                    </div>
                  </div>
                  <div className="card sm">
                    <div className="up">
                      <p>Podcasts</p>
                      <div className="no">{setValue(pod)}</div>
                    </div>
                    <div className="down">
                      <img src={oneAss04} alt="trail videos" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="side sm">
                <div className="learn-todo">
                  <div className="todo-container">
                    <div className="title">
                      Learning to-do <span>({todo.length})</span>
                    </div>
                    <div className="list-container">
                      {!load ? (
                        <>
                          <div className="list">
                            <div className="no load flicker"></div>
                            <p className="load flicker"></p>
                          </div>
                          <div className="list">
                            <div className="no load flicker"></div>
                            <p className="load flicker"></p>
                          </div>
                          <div className="list">
                            <div className="no load flicker"></div>
                            <p className="load flicker"></p>
                          </div>
                        </>
                      ) : todo.length === 0 ? (
                        <div className="inner">
                          <div className="img">
                            <img src={oneAss22} alt="Trail welcome" />
                          </div>
                          <p>
                            Keep track of things you want to learn or learning
                            tasks you hope to finish here. Don’t forget to tick
                            them off once done.
                          </p>
                        </div>
                      ) : (
                        todo.map((e, i) => (
                          <div className="list" key={i}>
                            <div className="no">{setValue(i + 1)}</div>
                            <p>{trunk(e.title, 50)}</p>
                            <div className="check">
                              <input
                                type="checkbox"
                                name={`${trunk(e.title, 10)}${i}`}
                                id={`${trunk(e.title, 10)}${i}`}
                                onClick={this.handleUpdate.bind(this, e._id)}
                              />
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                    {showTodo ? (
                      <div className={`input ${todo.length > 0 ? "" : "null"}`}>
                        <input
                          type="text"
                          placeholder="Add a new todo"
                          value={newTodo}
                          onChange={(e) =>
                            this.setState({ newTodo: e.target.value })
                          }
                        />
                        <div
                          className={`iconcontainer ${view ? "btn-load" : ""}`}
                          onClick={this.handleAddTodo}
                        >
                          <span className="btn_text"></span>
                          <span className="btn_text"></span>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`add-todo ${todo.length > 0 ? "" : "null"}`}
                        onClick={() => this.setState({ showTodo: true })}
                      >
                        Add a new task to your Todo
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="wishlist">
                <div className="wish-container">
                  <div className="top">
                    <h3>Wishlist</h3>
                    <div
                      className="btn"
                      onClick={() => this.setState({ showWish: !showWish })}
                    >
                      {showWish ? "Hide all" : "View all"}
                    </div>
                  </div>
                  <div className="wish-card">
                    {!load ? (
                      <>
                        <div className="card">
                          <div className="img load flicker"></div>
                          <p className="load flicker"></p>
                        </div>
                        <div className="card">
                          <div className="img load flicker"></div>
                          <p className="load flicker"></p>
                        </div>
                        <div className="card">
                          <div className="img load flicker"></div>
                          <p className="load flicker"></p>
                        </div>
                      </>
                    ) : wishlist.length === 0 ? (
                      <div className="inner">
                        <div className="img">
                          <img src={oneAss21} alt="Trail welcome" />
                        </div>
                        <p>
                          Your wishlist is empty! Add a resource you would like
                          to go through soon
                        </p>
                        <div
                          className="add-wish"
                          onClick={() =>
                            this.setState({
                              showAdd: true,
                              wishList: false,
                              reminder: false,
                            })
                          }
                        >
                          Add a new resource to your wishlist
                        </div>
                      </div>
                    ) : (
                      wishlist.map((e, i) => (
                        <a
                          className="card"
                          key={i}
                          target="_blank"
                          rel="noreferrer"
                          href={e.link}
                        >
                          <div className="img">
                            <img
                              src={
                                e.image
                                  ? e.image
                                  : `https://trailbucket12345.s3.us-east-2.amazonaws.com/content/Content+Images-${setValue(
                                      i + 1
                                    )}.png`
                              }
                              alt="Trail wish"
                            />
                          </div>
                          <p>{trunk(capitalize(e.title), 70)}</p>
                        </a>
                      ))
                    )}
                  </div>
                  <div
                    className={`add-wish ${wishlist.length > 0 ? "" : "null"}`}
                    onClick={() =>
                      this.setState({
                        showAdd: true,
                        wishList: false,
                        reminder: false,
                      })
                    }
                  >
                    Add a new resource to your wishlist
                  </div>
                </div>
                <div className="marketing">
                  <a
                    href="https://docs.google.com/forms/d/1WrmMe82I5LJpONSYysaLn6uPxqAjNeQ5tSIczjMLSy4/edit"
                    className="card"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="img">
                      <img src={oneAss08} alt="" />
                    </div>
                    <h4>Slack community</h4>
                  </a>
                  <a
                    href="mailto:hello@trailng.com?subject=We%20want%20to%20hear%20from%20you!"
                    className="card"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="img">
                      <img src={oneAss09} alt="" />
                    </div>
                    <h4>Get Help</h4>
                  </a>
                  <a
                    href="https://twitter.com/trail_ng?s=20"
                    className="card"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="img">
                      <img src={oneAss07} alt="" />
                    </div>
                    <h4>Social Media</h4>
                  </a>
                </div>
              </div>
              <div className="skill-graph">
                <div className="title">
                  Trail Bank <span>(coming soon)</span>
                </div>
                <div className="container">
                  <div className="img">
                    <img src={oneAss23} alt="Trail Bank" />
                  </div>
                  <div className="content">
                    <h2>Find out where your skills place you!</h2>
                    <p>
                      Trail Bank stores your micro-skills and uses them to
                      determine where you stand in the job market.
                    </p>
                    <div className="btn">Coming soon</div>
                  </div>
                </div>
              </div>
              <a
                href="mailto:hello@trailng.com?subject=We%20want%20to%20hear%20from%20you!"
                className="sm-home"
                target="_blank"
                rel="noreferrer"
              >
                Have any dea on how we can make Trail better for you? Reach out
                to us.
              </a>
            </div>
            <div className={`side ${tutStep === 7 ? ain[tutStep] : ""}`}>
              <div className="profile-head">
                <div className="img-cover">
                  <img
                    src="https://trailbucket12345.s3.us-east-2.amazonaws.com/content/Content+Images-17.png"
                    alt="Trail cover"
                  />
                </div>
                <div className="profile-img">
                  <img
                    src={
                      imgStr
                        ? imgStr
                        : "https://pngimg.com/uploads/avatar/avatar_PNG47.png"
                    }
                    alt="profile pic"
                  />
                </div>
                <h2>
                  {currentUser.user.firstName} {currentUser.user.lastName}
                </h2>
                <p>{currentUser.user.tag}</p>
              </div>
              {tutStep === 7 && (
                <>
                  <div className="highlight six"></div>
                  <div className="inner-tut">
                    <div
                      className="back"
                      onClick={() => {
                        this.setState({ tutStep: tutStep - 1 });
                        this.handleScroll(670);
                      }}
                    >
                      <div className="icon">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                      Go back
                    </div>
                    <h3>This section is for your learning tasks</h3>
                    <p>
                      You can document your learning tasks here and have the
                      option of getting gentle reminders sent to you via
                      WhatsApp or Gmail weekly.
                    </p>
                    <div className="btn-group">
                      <div className="skip" onClick={this.handleSkip}>
                        Skip all
                      </div>
                      <div
                        className="start"
                        onClick={(e) => {
                          this.setState({ tutStep: tutStep + 1 });
                          this.handleScroll(0);
                          this.handlePages(e, "dahmen");
                        }}
                      >
                        Next
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="learn-todo">
                <div className="todo-container">
                  <div className="title">
                    Learning to-do <span>({todo.length})</span>
                  </div>
                  <div className="list-container">
                    {!load ? (
                      <>
                        <div className="list">
                          <div className="no load flicker"></div>
                          <p className="load flicker"></p>
                        </div>
                        <div className="list">
                          <div className="no load flicker"></div>
                          <p className="load flicker"></p>
                        </div>
                        <div className="list">
                          <div className="no load flicker"></div>
                          <p className="load flicker"></p>
                        </div>
                      </>
                    ) : todo.length === 0 ? (
                      <div className="inner">
                        <div className="img">
                          <img src={oneAss22} alt="Trail welcome" />
                        </div>
                        <p>
                          Keep track of things you want to learn or learning
                          tasks you hope to finish here. Don’t forget to tick
                          them off once done.
                        </p>
                      </div>
                    ) : (
                      todo.map((e, i) => (
                        <div className="list" key={i}>
                          <div className="no">{setValue(i + 1)}</div>
                          <p>{trunk(e.title, 50)}</p>
                          <div className="check">
                            <input
                              type="checkbox"
                              name={`${trunk(e.title, 10)}${i}`}
                              id={`${trunk(e.title, 10)}${i}`}
                              onClick={this.handleUpdate.bind(this, e._id)}
                            />
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                  {showTodo ? (
                    <div className={`input ${todo.length > 0 ? "" : "null"}`}>
                      <input
                        type="text"
                        placeholder="Add a new todo"
                        value={newTodo}
                        onChange={(e) =>
                          this.setState({ newTodo: e.target.value })
                        }
                      />
                      <div
                        className={`iconcontainer ${view ? "btn-load" : ""}`}
                        onClick={this.handleAddTodo}
                      >
                        <span className="btn_text"></span>
                        <span className="btn_text"></span>
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`add-todo ${todo.length > 0 ? "" : "null"}`}
                      onClick={() => this.setState({ showTodo: true })}
                    >
                      Add a new task to your Todo
                    </div>
                  )}
                </div>
              </div>
              <div className="marketing-card">
                <img src={oneAss06} alt="Reach Trail" />
                <h3>Get Help</h3>
                <p>Having any issues?</p>
                <p>
                  Or just want to chat with us -{" "}
                  <span>That's totally fine.</span>
                </p>
                <a
                  href="mailto:hello@trailng.com?subject=We%20want%20to%20hear%20from%20you!"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="btn">Contact us</div>
                </a>
              </div>
            </div>
          </div>
          <div className={`home-sm ${display === "hom-sm" ? "" : "hide"}`}>
            <div className="card-container">
              {!load ? (
                <>
                  <div className="card">
                    <div className="date load flicker"></div>
                    <div className="img load flicker"></div>
                    <div className="band">
                      <div className="title load flicker"></div>
                      <div className="info">
                        <div className="mskill load flicker"></div>
                        <div className="mskill load flicker"></div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="date load flicker"></div>
                    <div className="img load flicker"></div>
                    <div className="band">
                      <div className="title load flicker"></div>
                      <div className="info">
                        <div className="mskill load flicker"></div>
                        <div className="mskill load flicker"></div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="date load flicker"></div>
                    <div className="img load flicker"></div>
                    <div className="band">
                      <div className="title load flicker"></div>
                      <div className="info">
                        <div className="mskill load flicker"></div>
                        <div className="mskill load flicker"></div>
                      </div>
                    </div>
                  </div>
                </>
              ) : resources.length === 0 ? (
                <div className="inner">
                  <div className="img">
                    <img src={oneAss19} alt="Trail welcome" />
                  </div>
                  {mobileFilter ? (
                    <p>No Resource found under these filters</p>
                  ) : (
                    <p>
                      We haven’t picked anything yet. You can add a resource
                      here manually.
                    </p>
                  )}
                  <div
                    className="addResource"
                    onClick={() =>
                      this.setState({
                        showAdd: true,
                        wishList: true,
                        reminder: false,
                      })
                    }
                  >
                    <div className="iconcontainer">
                      <span></span>
                      <span></span>
                    </div>
                    <p>Add a course</p>
                  </div>
                </div>
              ) : (
                resources.map((e, i) => (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={e.link}
                    className="card"
                    key={i}
                  >
                    <div className="img">
                      <img
                        src={
                          e.image
                            ? e.image
                            : `https://trailbucket12345.s3.us-east-2.amazonaws.com/content/Content+Images-${setValue(
                                i + 1
                              )}.png`
                        }
                        alt="course-cover"
                      />
                    </div>
                    <div className="band">
                      <div className="title">
                        {trunk(capitalize(e.title), 100)}
                      </div>
                      <div className="info">
                        <div className="mskill">
                          {e.microSkill.length > 0
                            ? trunk(capitalize(e.microSkill[0]), 17)
                            : trunk(capitalize(e.offeredBy), 17)}
                        </div>
                        <div className="mskill">{setDate(e.createdAt)}</div>
                      </div>
                    </div>
                  </a>
                ))
              )}
            </div>
          </div>
          <div
            className={`plan ${display === "dahmen" ? "" : "hide"}`}
            id="plan"
          >
            <div className={`main ${tutStep === 8 ? ain[tutStep] : ""}`}>
              <div className="welcome sm">
                <div className="icon">
                  <img src={oneAss13} alt="Trail welcome" />
                </div>
                <div className="text">
                  <div className="head">Hi {currentUser.user.firstName}!</div>
                  <p>
                    Welcome to your dashboard. You can add resources manually,
                    view your learning history and track your performance here.
                  </p>
                </div>
              </div>
              <div className="learn-container sm">
                <div className="learn-todo">
                  <div className="todo-container">
                    <div className="add-header">
                      <div className="title">
                        Learning to-do <span>({reminderRes.length})</span>
                      </div>
                      <div
                        className="add"
                        onClick={() =>
                          this.setState({ showAdd: true, reminder: true })
                        }
                      >
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                    <div className="list-container">
                      {!load ? (
                        <>
                          <div className="list">
                            <div className="no load flicker"></div>
                            <p className="load flicker"></p>
                          </div>
                          <div className="list">
                            <div className="no load flicker"></div>
                            <p className="load flicker"></p>
                          </div>
                          <div className="list">
                            <div className="no load flicker"></div>
                            <p className="load flicker"></p>
                          </div>
                        </>
                      ) : reminderRes.length === 0 ? (
                        <div className="inner">
                          <div className="img">
                            <img src={oneAss22} alt="Trail welcome" />
                          </div>
                          <p>
                            Keep track of things you want to learn or learning
                            tasks you hope to finish here. Don’t forget to tick
                            them off once done.
                          </p>
                        </div>
                      ) : (
                        reminderRes.map((e, i) => (
                          <div className="list" key={i}>
                            <p>{trunk(capitalize(e.title), 50)}</p>
                            <div className="check">
                              <input
                                type="checkbox"
                                name={`${trunk(capitalize(e.title), 10)}${i}`}
                                id={`${trunk(capitalize(e.title), 10)}${i}`}
                                onChange={this.handleUpdateReminder.bind(
                                  this,
                                  e._id
                                )}
                                checked={e.title ? false : true}
                              />
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {tutStep === 8 && (
                <>
                  <div className="highlight"></div>
                  <div className="inner-tut">
                    <div
                      className="back"
                      onClick={(e) => {
                        this.setState({ tutStep: tutStep - 1 });
                        this.handlePages(e, "hom");
                      }}
                    >
                      <div className="icon">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                      Go back
                    </div>
                    <h3>
                      See how much work you are putting into learning weekly
                    </h3>
                    <p>
                      Here is a chart that shows you a quick view of just how
                      much effort you are putting into learning week by week.
                      This helps you stay on track and know when to take a
                      break.
                    </p>
                    <div className="btn-group">
                      <div className="skip" onClick={this.handleSkip}>
                        Skip all
                      </div>
                      <div
                        className="start"
                        onClick={() => {
                          this.setState({ tutStep: tutStep + 1 });
                          this.handleScroll(0);
                        }}
                      >
                        Next
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="perform">
                <div className="title">Performance</div>
                <div className="chart">
                  {days.map((e, i) => (
                    <div className="tile" key={i}>
                      <div className="bar">
                        <div
                          className={`inner ${
                            display === "dahmen" ? "full" : ""
                          }`}
                          style={{
                            height: `${week[e]}%`,
                          }}
                        ></div>
                      </div>
                      <div className="tag">{e}</div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="notification sm">
                <div className="title">Notification</div>
                <p>
                  Get weekly reports of your online learning in your mailbox and
                  receive reminders of your learning goals for the week on
                  Whatsapp.
                </p>
                <div className="toggle">
                  <div className="img">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/281/281769.png"
                      alt="Gmail"
                    />
                  </div>
                  <div className="input">
                    <input
                      type="checkbox"
                      id="switch"
                      checked={gmail}
                      onChange={this.handleUserReminder.bind(this, "gmail")}
                    />
                    <label htmlFor="switch">Toggle</label>
                  </div>
                </div>
                <div className="toggle">
                  <div className="img">
                    <img src="/whatsapp.png" alt="Whatsapp" />
                  </div>
                  <div className="input">
                    <input
                      type="checkbox"
                      id="switch-2"
                      checked={whatsapp}
                      onChange={this.handleUserReminder.bind(this, "whatsapp")}
                    />
                    <label htmlFor="switch-2">Toggle</label>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`side ${
                tutStep > 8 && tutStep < 11 ? ain[tutStep] : ""
              }`}
            >
              {tutStep === 9 && (
                <>
                  <div className="highlight nine"></div>
                  <div className="highlight nine alt"></div>
                  <div className="inner-tut">
                    <div
                      className="back"
                      onClick={() => {
                        this.setState({ tutStep: tutStep - 1 });
                        this.handleScroll(172);
                      }}
                    >
                      <div className="icon">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                      Go back
                    </div>
                    <h3>
                      The learning resources you want to be reminded of appear
                      here
                    </h3>
                    <p>
                      This section helps to remind you of the resources you want
                      to be reminded to consume weekly. In addition, a reminder
                      is sent to your preferred medium.
                    </p>
                    <div className="btn-group">
                      <div className="skip" onClick={this.handleSkip}>
                        Skip all
                      </div>
                      <div
                        className="start"
                        onClick={() => {
                          this.setState({ tutStep: tutStep + 1 });
                          this.handleScroll(100);
                        }}
                      >
                        Next
                      </div>
                    </div>
                  </div>
                </>
              )}
              {tutStep === 10 && (
                <>
                  <div className="highlight ten"></div>
                  <div className="inner-tut">
                    <div
                      className="back"
                      onClick={() => {
                        this.setState({ tutStep: tutStep - 1 });
                        this.handleScroll(0);
                      }}
                    >
                      <div className="icon">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                      Go back
                    </div>
                    <h3>Change your notification and reminder settings</h3>
                    <p>
                      If you would like your personal assistant to send you a
                      weekly check up on the resources you are learning and your
                      learning tasks, then turn on the notifications.
                    </p>
                    <div className="btn-group">
                      <div className="skip" onClick={this.handleSkip}>
                        Skip all
                      </div>
                      <div
                        className="start"
                        onClick={() => {
                          this.setState({ tutStep: tutStep + 1 });
                          this.handleScroll(0);
                        }}
                      >
                        Next
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="learn-todo">
                <div className="todo-container rem">
                  <div className="uptip">
                    <div className="title">
                      Learning to-do <span>({reminderRes.length})</span>
                    </div>
                    <div className="list-container">
                      {!load ? (
                        <>
                          <div className="list">
                            <div className="no load flicker"></div>
                            <p className="load flicker"></p>
                          </div>
                          <div className="list">
                            <div className="no load flicker"></div>
                            <p className="load flicker"></p>
                          </div>
                          <div className="list">
                            <div className="no load flicker"></div>
                            <p className="load flicker"></p>
                          </div>
                        </>
                      ) : reminderRes.length === 0 ? (
                        <div className="inner">
                          <div className="img">
                            <img src={oneAss22} alt="Trail welcome" />
                          </div>
                          <p>
                            Keep track of things you want to learn or learning
                            tasks you hope to finish here. Don’t forget to tick
                            them off once done.
                          </p>
                        </div>
                      ) : (
                        reminderRes.map((e, i) => (
                          <div className="list" key={i}>
                            <div className="no">{setValue(i + 1)}</div>
                            <p>{trunk(capitalize(e.title), 50)}</p>
                            <div className="check">
                              <input
                                type="checkbox"
                                name={`${trunk(capitalize(e.title), 10)}${i}`}
                                id={`${trunk(capitalize(e.title), 10)}${i}`}
                                onChange={this.handleUpdateReminder.bind(
                                  this,
                                  e._id
                                )}
                                checked={e.title ? false : true}
                              />
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                  <div
                    className={`add-todo ${todo.length > 0 ? "" : "null"}`}
                    onClick={() =>
                      this.setState({ showAdd: true, reminder: true })
                    }
                  >
                    Add a new resource with reminder
                  </div>
                </div>
                <div className="notification not">
                  <div className="title">Notification</div>
                  <p>
                    Get weekly reports of your online learning in your mailbox
                    and receive reminders of your learning goals for the week on
                    Whatsapp.
                  </p>
                  <div className="toggle">
                    <div className="img">
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/281/281769.png"
                        alt="Gmail"
                      />
                    </div>
                    <div className="input">
                      <input
                        type="checkbox"
                        id="switch"
                        checked={gmail}
                        onChange={this.handleUserReminder.bind(this, "gmail")}
                      />
                      <label htmlFor="switch">Toggle</label>
                    </div>
                  </div>
                  <div className="toggle">
                    <div className="img">
                      <img src="/whatsapp.png" alt="Whatsapp" />
                    </div>
                    <div className="input">
                      <input
                        type="checkbox"
                        id="switch-2"
                        checked={whatsapp}
                        onChange={this.handleUserReminder.bind(
                          this,
                          "whatsapp"
                        )}
                      />
                      <label htmlFor="switch-2">Toggle</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`one-home wish ${display === "ref" ? "" : "hide"}`}>
            <div className={`main ${tutStep === 14 ? ain[tutStep] : ""}`}>
              {tutStep === 14 && (
                <>
                  <div className="highlight"></div>
                  <div className="inner-tut">
                    <div
                      className="back"
                      onClick={(e) => {
                        this.setState({ tutStep: tutStep - 1 });
                      }}
                    >
                      <div className="icon">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                      Go back
                    </div>
                    <h3>Here is where you keep learning resources for later</h3>
                    <p>
                      Know how you see courses/videos, want to learn from them
                      but don’t have the time to complete them right away? You
                      can simply save them to your wishlist and see them here.
                    </p>
                    <div className="btn-group">
                      <div className="skip" onClick={this.handleSkip}>
                        Skip all
                      </div>
                      <div
                        className="start"
                        onClick={() => {
                          this.setState({ tutStep: tutStep + 1 });
                        }}
                      >
                        Next
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="progress">
                <div className="top">
                  <p>Wishlist</p>
                  <div
                    className="btn"
                    onClick={() =>
                      this.setState({ showMainWish: !showMainWish })
                    }
                  >
                    {showMainWish ? "Hide all" : "View all"}
                  </div>
                </div>
                <div className="card-container">
                  {!load ? (
                    <>
                      <div className="card">
                        <div className="date load flicker"></div>
                        <div className="img load flicker"></div>
                        <div className="band">
                          <div className="title load flicker"></div>
                          <div className="info">
                            <div className="mskill load flicker"></div>
                            <div className="mskill load flicker"></div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="date load flicker"></div>
                        <div className="img load flicker"></div>
                        <div className="band">
                          <div className="title load flicker"></div>
                          <div className="info">
                            <div className="mskill load flicker"></div>
                            <div className="mskill load flicker"></div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="date load flicker"></div>
                        <div className="img load flicker"></div>
                        <div className="band">
                          <div className="title load flicker"></div>
                          <div className="info">
                            <div className="mskill load flicker"></div>
                            <div className="mskill load flicker"></div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="date load flicker"></div>
                        <div className="img load flicker"></div>
                        <div className="band">
                          <div className="title load flicker"></div>
                          <div className="info">
                            <div className="mskill load flicker"></div>
                            <div className="mskill load flicker"></div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="date load flicker"></div>
                        <div className="img load flicker"></div>
                        <div className="band">
                          <div className="title load flicker"></div>
                          <div className="info">
                            <div className="mskill load flicker"></div>
                            <div className="mskill load flicker"></div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="date load flicker"></div>
                        <div className="img load flicker"></div>
                        <div className="band">
                          <div className="title load flicker"></div>
                          <div className="info">
                            <div className="mskill load flicker"></div>
                            <div className="mskill load flicker"></div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : wishlist.length === 0 ? (
                    <div className="inner">
                      <div className="img">
                        <img src={oneAss21} alt="Trail welcome" />
                      </div>
                      <p>
                        Your wishlist is empty! Add a resource you would like to
                        go through soon
                      </p>
                      <div
                        className="addResource"
                        onClick={() =>
                          this.setState({
                            showAdd: true,
                            wishList: false,
                            reminder: false,
                          })
                        }
                      >
                        <div className="iconcontainer">
                          <span></span>
                          <span></span>
                        </div>
                        <p>Add a course</p>
                      </div>
                    </div>
                  ) : (
                    wishlist.map((e, i) => (
                      <div className="card" key={i}>
                        <div className="img">
                          <img
                            src={
                              e.image
                                ? e.image
                                : `https://trailbucket12345.s3.us-east-2.amazonaws.com/content/Content+Images-${setValue(
                                    i + 1
                                  )}.png`
                            }
                            alt="course-cover"
                          />
                        </div>
                        <div className="band">
                          <div className="title">
                            {trunk(capitalize(e.title), 100)}
                            <div className="space"></div>
                            <a
                              className="btn"
                              target="_blank"
                              rel="noreferrer"
                              href={e.link}
                            >
                              <div className="arrow">
                                <span></span>
                                <span></span>
                                <span></span>
                              </div>
                            </a>
                          </div>
                          <div className="info">
                            <div className="mskill">
                              {e.microSkill.length > 0
                                ? trunk(capitalize(e.microSkill[0]), 17)
                                : trunk(capitalize(e.offeredBy), 17)}
                            </div>
                            <div className="date">{setDate(e.createdAt)}</div>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
            <div className="side">
              <div className="marketing-card">
                <img src={oneAss23} alt="Skill bank" />
                <h3>
                  Trail Bank <span>(coming soon)</span>
                </h3>
                <p>
                  Trail Bank stores your micro-skills and uses them to determine
                  where you stand in the job market.
                </p>
                <div className="btn">Coming soon</div>
              </div>
              <div className="marketing-card">
                <img src={oneAss06} alt="" />
                <h3>Get Help</h3>
                <p>Having any issues?</p>
                <p>
                  Or just want to chat with us -{" "}
                  <span>That's totally fine.</span>
                </p>
                <a
                  href="mailto:hello@trailng.com?subject=We%20want%20to%20hear%20from%20you!"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="btn">Contact us</div>
                </a>
              </div>
            </div>
          </div>
          <div className={`home-sm ${display === "ref" ? "" : "hide"}`}>
            <div className="card-container">
              {!load ? (
                <>
                  <div className="card">
                    <div className="date load flicker"></div>
                    <div className="img load flicker"></div>
                    <div className="band">
                      <div className="title load flicker"></div>
                      <div className="info">
                        <div className="mskill load flicker"></div>
                        <div className="mskill load flicker"></div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="date load flicker"></div>
                    <div className="img load flicker"></div>
                    <div className="band">
                      <div className="title load flicker"></div>
                      <div className="info">
                        <div className="mskill load flicker"></div>
                        <div className="mskill load flicker"></div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="date load flicker"></div>
                    <div className="img load flicker"></div>
                    <div className="band">
                      <div className="title load flicker"></div>
                      <div className="info">
                        <div className="mskill load flicker"></div>
                        <div className="mskill load flicker"></div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="date load flicker"></div>
                    <div className="img load flicker"></div>
                    <div className="band">
                      <div className="title load flicker"></div>
                      <div className="info">
                        <div className="mskill load flicker"></div>
                        <div className="mskill load flicker"></div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="date load flicker"></div>
                    <div className="img load flicker"></div>
                    <div className="band">
                      <div className="title load flicker"></div>
                      <div className="info">
                        <div className="mskill load flicker"></div>
                        <div className="mskill load flicker"></div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="date load flicker"></div>
                    <div className="img load flicker"></div>
                    <div className="band">
                      <div className="title load flicker"></div>
                      <div className="info">
                        <div className="mskill load flicker"></div>
                        <div className="mskill load flicker"></div>
                      </div>
                    </div>
                  </div>
                </>
              ) : wishlist.length === 0 ? (
                <div className="inner">
                  <div className="img">
                    <img src={oneAss21} alt="Trail welcome" />
                  </div>
                  <p>
                    Your wishlist is empty! Add a resource you would like to go
                    through soon
                  </p>
                  <div
                    className="addResource"
                    onClick={() =>
                      this.setState({
                        showAdd: true,
                        wishList: false,
                        reminder: false,
                      })
                    }
                  >
                    <div className="iconcontainer">
                      <span></span>
                      <span></span>
                    </div>
                    <p>Add a course</p>
                  </div>
                </div>
              ) : (
                wishlist.map((e, i) => (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={e.link}
                    className="card"
                    key={i}
                  >
                    <div className="img">
                      <img
                        src={
                          e.image
                            ? e.image
                            : `https://trailbucket12345.s3.us-east-2.amazonaws.com/content/Content+Images-${setValue(
                                i + 1
                              )}.png`
                        }
                        alt="course-cover"
                      />
                    </div>
                    <div className="band">
                      <div className="title">
                        {trunk(capitalize(e.title), 100)}
                      </div>
                      <div className="info">
                        <div className="mskill">
                          {e.microSkill.length > 0
                            ? trunk(capitalize(e.microSkill[0]), 17)
                            : trunk(capitalize(e.offeredBy), 17)}
                        </div>
                        <div className="mskill">{setDate(e.createdAt)}</div>
                      </div>
                    </div>
                  </a>
                ))
              )}
            </div>
            {wishlist.length > 0 && (
              <div
                className="load-more"
                onClick={() => this.setState({ showMainWish: !showMainWish })}
              >
                {showMainWish ? "Show less" : "Show more"}
              </div>
            )}
          </div>
          <div className={`home-sm ${display === "dahpah" ? "" : "hide"}`}>
            <div className="card-container">
              {!load ? (
                <>
                  <div className="card">
                    <div className="date load flicker"></div>
                    <div className="img load flicker"></div>
                    <div className="band">
                      <div className="title load flicker"></div>
                      <div className="info">
                        <div className="mskill load flicker"></div>
                        <div className="mskill load flicker"></div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="date load flicker"></div>
                    <div className="img load flicker"></div>
                    <div className="band">
                      <div className="title load flicker"></div>
                      <div className="info">
                        <div className="mskill load flicker"></div>
                        <div className="mskill load flicker"></div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="date load flicker"></div>
                    <div className="img load flicker"></div>
                    <div className="band">
                      <div className="title load flicker"></div>
                      <div className="info">
                        <div className="mskill load flicker"></div>
                        <div className="mskill load flicker"></div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="date load flicker"></div>
                    <div className="img load flicker"></div>
                    <div className="band">
                      <div className="title load flicker"></div>
                      <div className="info">
                        <div className="mskill load flicker"></div>
                        <div className="mskill load flicker"></div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="date load flicker"></div>
                    <div className="img load flicker"></div>
                    <div className="band">
                      <div className="title load flicker"></div>
                      <div className="info">
                        <div className="mskill load flicker"></div>
                        <div className="mskill load flicker"></div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="date load flicker"></div>
                    <div className="img load flicker"></div>
                    <div className="band">
                      <div className="title load flicker"></div>
                      <div className="info">
                        <div className="mskill load flicker"></div>
                        <div className="mskill load flicker"></div>
                      </div>
                    </div>
                  </div>
                </>
              ) : allLearning.length === 0 ? (
                <div className="inner">
                  <div className="img">
                    <img src={oneAss19} alt="Trail welcome" />
                  </div>
                  <p>
                    {filter
                      ? "Sorry, we could not find a resource that fits those filters."
                      : "We haven’t picked anything yet. You can add a resource here manually."}
                  </p>
                  <div
                    className="addResource"
                    onClick={() =>
                      this.setState({
                        showAdd: true,
                        wishList: true,
                        reminder: false,
                      })
                    }
                  >
                    <div className="iconcontainer">
                      <span></span>
                      <span></span>
                    </div>
                    <p>Add a course</p>
                  </div>
                </div>
              ) : (
                allLearning.map((e, i) => (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={e.link}
                    className={`card ${
                      e.wishList ? (e.wishList.state ? "light" : "") : "light"
                    }`}
                    key={i}
                  >
                    <div className="img">
                      <img
                        src={
                          e.image
                            ? e.image
                            : `https://trailbucket12345.s3.us-east-2.amazonaws.com/content/Content+Images-${setValue(
                                i + 1
                              )}.png`
                        }
                        alt="course-cover"
                      />
                    </div>
                    <div className="band">
                      <div className="title">
                        {trunk(capitalize(e.title), 100)}
                      </div>
                      <div className="info">
                        <div className="mskill">
                          {e.microSkill.length > 0
                            ? trunk(capitalize(e.microSkill[0]), 17)
                            : trunk(capitalize(e.offeredBy), 17)}
                        </div>
                        <div className="mskill">{setDate(e.createdAt)}</div>
                      </div>
                    </div>
                  </a>
                ))
              )}
            </div>
            {allLearning.length > 0 && (
              <div
                className="load-more"
                onClick={() => this.setState({ showMainRes: !showMainRes })}
              >
                {showMainRes ? "Show less" : "Show more"}
              </div>
            )}
          </div>
          <div className={`one-home ${display === "dahpah" ? "" : "hide"}`}>
            <div className="main">
              <div className="progress">
                <div className="top">
                  <p>Learning History</p>
                  <div
                    className="btn"
                    onClick={() => this.setState({ showMainRes: !showMainRes })}
                  >
                    {showMainRes ? "Hide all" : "View all"}
                  </div>
                </div>
                <div className="card-container">
                  {!load ? (
                    <>
                      <div className="card">
                        <div className="date load flicker"></div>
                        <div className="img load flicker"></div>
                        <div className="band">
                          <div className="title load flicker"></div>
                          <div className="info">
                            <div className="mskill load flicker"></div>
                            <div className="mskill load flicker"></div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="date load flicker"></div>
                        <div className="img load flicker"></div>
                        <div className="band">
                          <div className="title load flicker"></div>
                          <div className="info">
                            <div className="mskill load flicker"></div>
                            <div className="mskill load flicker"></div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="date load flicker"></div>
                        <div className="img load flicker"></div>
                        <div className="band">
                          <div className="title load flicker"></div>
                          <div className="info">
                            <div className="mskill load flicker"></div>
                            <div className="mskill load flicker"></div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="date load flicker"></div>
                        <div className="img load flicker"></div>
                        <div className="band">
                          <div className="title load flicker"></div>
                          <div className="info">
                            <div className="mskill load flicker"></div>
                            <div className="mskill load flicker"></div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="date load flicker"></div>
                        <div className="img load flicker"></div>
                        <div className="band">
                          <div className="title load flicker"></div>
                          <div className="info">
                            <div className="mskill load flicker"></div>
                            <div className="mskill load flicker"></div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="date load flicker"></div>
                        <div className="img load flicker"></div>
                        <div className="band">
                          <div className="title load flicker"></div>
                          <div className="info">
                            <div className="mskill load flicker"></div>
                            <div className="mskill load flicker"></div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : allLearning.length === 0 ? (
                    <div className="inner">
                      <div className="img">
                        <img src={oneAss19} alt="Trail welcome" />
                      </div>
                      <p>
                        {filter
                          ? "Sorry, we could not find a resource that fits those filters."
                          : "We haven’t picked anything yet. You can add a resource here manually."}
                      </p>
                      <div
                        className="addResource"
                        onClick={() =>
                          this.setState({
                            showAdd: true,
                            wishList: true,
                            reminder: false,
                          })
                        }
                      >
                        <div className="iconcontainer">
                          <span></span>
                          <span></span>
                        </div>
                        <p>Add a course</p>
                      </div>
                    </div>
                  ) : (
                    allLearning.map((e, i) => (
                      <div
                        className={`card ${
                          e.wishList
                            ? e.wishList.state
                              ? "light"
                              : ""
                            : "light"
                        }`}
                        key={i}
                      >
                        <div className="img">
                          <img
                            src={
                              e.image
                                ? e.image
                                : `https://trailbucket12345.s3.us-east-2.amazonaws.com/content/Content+Images-${setValue(
                                    i + 1
                                  )}.png`
                            }
                            alt="course-cover"
                          />
                        </div>
                        <div className="band">
                          <div className="title">
                            {trunk(capitalize(e.title), 100)}
                            <div className="space"></div>
                            <a
                              className="btn"
                              target="_blank"
                              rel="noreferrer"
                              href={e.link}
                            >
                              <div className="arrow">
                                <span></span>
                                <span></span>
                                <span></span>
                              </div>
                            </a>
                          </div>
                          <div className="info">
                            <div className="mskill">
                              {e.microSkill.length > 0
                                ? trunk(capitalize(e.microSkill[0]), 17)
                                : trunk(capitalize(e.offeredBy), 17)}
                            </div>
                            <div className="date">{setDate(e.createdAt)}</div>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
              <div className="skill-graph">
                <div className="title">
                  Trail Bank <span>(coming soon)</span>
                </div>
                <div className="container">
                  <div className="img">
                    <img src={oneAss23} alt="Trail Bank" />
                  </div>
                  <div className="content">
                    <h2>Find out where your skills place you!</h2>
                    <p>
                      Trail Bank stores your micro-skills and uses them to
                      determine where you stand in the job market.
                    </p>
                    <div className="btn">Coming soon</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`side ${tutStep === 12 ? ain[tutStep] : ""}`}>
              {tutStep === 12 && (
                <>
                  <div className="highlight twelve"></div>
                  <div className="inner-tut">
                    <div
                      className="back"
                      onClick={(e) => {
                        this.setState({ tutStep: tutStep - 1 });
                        this.handlePages(e, "dahmen");
                      }}
                    >
                      <div className="icon">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                      Go back
                    </div>
                    <h3>Searching for something? Sort through your archive</h3>
                    <p>
                      Use the filter system to quickly access whatever resource
                      it is you are looking for in your learning history.
                    </p>
                    <div className="btn-group">
                      <div className="skip" onClick={this.handleSkip}>
                        Skip all
                      </div>
                      <div
                        className="start"
                        onClick={() => {
                          this.setState({ tutStep: tutStep + 1 });
                        }}
                      >
                        Next
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="filter">
                <div className="title">Filters</div>
                <div className="input">
                  <label htmlFor="keyword">Keyword</label>
                  <input
                    type="text"
                    htmlFor="keyword"
                    placeholder="course title"
                    value={keyword}
                    onChange={(e) => this.setState({ keyword: e.target.value })}
                  />
                </div>
                <div className="input">
                  <label htmlFor="microskill">Micro skill</label>
                  <div className="slic">
                    <input
                      type="search"
                      id="skill-option-fi"
                      list="skill-option-fill"
                      placeholder="Type in a microskill"
                      value={tempSkillFil}
                      onChange={(e) =>
                        this.setState({ tempSkillFil: e.target.value })
                      }
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          this.handleAdd("filter");
                        }
                      }}
                    />
                    <div className="bottom">
                      <AddOutlinedIcon
                        onClick={this.handleAdd.bind(this, "filter")}
                      />
                    </div>

                    <datalist id="skill-option-fill">
                      {microSkill.map((skill, i) => (
                        <option value={skill.name} key={i}>
                          {skill.name}
                        </option>
                      ))}
                    </datalist>
                  </div>
                </div>
                <div className="skills">
                  {chosenSkillFil.map((el, i) => (
                    <div className="item" key={i}>
                      {el}
                      <div
                        class="cross"
                        onClick={this.handleRemoveFil.bind(this, i)}
                      >
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="type">
                  <label>Content Type</label>
                  <div className="check">
                    <div className="tab">
                      <input
                        type="checkbox"
                        name="video"
                        id="video"
                        checked={video}
                        onChange={() => this.setState({ video: !video })}
                      />
                      <p>Video</p>
                    </div>
                    <div className="tab">
                      <input
                        type="checkbox"
                        name="article"
                        id="article"
                        checked={article}
                        onChange={() => this.setState({ article: !article })}
                      />
                      <p>Article</p>
                    </div>
                    <div className="tab">
                      <input
                        type="checkbox"
                        name="course"
                        id="course"
                        checked={courseFil}
                        onChange={() =>
                          this.setState({ courseFil: !courseFil })
                        }
                      />
                      <p>Courses</p>
                    </div>
                    <div className="tab">
                      <input
                        type="checkbox"
                        name="webinar"
                        id="webinar"
                        checked={webinar}
                        onChange={() => this.setState({ webinar: !webinar })}
                      />
                      <p>Webinar</p>
                    </div>
                    <div className="tab">
                      <input
                        type="checkbox"
                        name="podcasts"
                        id="podcasts"
                        checked={podcast}
                        onChange={() => this.setState({ podcast: !podcast })}
                      />
                      <p>Podcasts</p>
                    </div>
                  </div>
                </div>
                <div className="input">
                  <label htmlFor="source">Source</label>
                  <input
                    type="text"
                    htmlFor="source"
                    placeholder="Youtube udemy"
                    value={source}
                    onChange={(e) => this.setState({ source: e.target.value })}
                  />
                </div>
              </div>
              <div className="marketing-card">
                <img src={oneAss06} alt="Reach Trail" />
                <h3>Get Help</h3>
                <p>Having any issues?</p>
                <p>
                  Or just want to chat with us -{" "}
                  <span>That's totally fine.</span>
                </p>
                <a href="mailto:hello@trailng.com?subject=We%20want%20to%20hear%20from%20you!">
                  <div className="btn">Contact us</div>
                </a>
              </div>
            </div>
          </div>
          <div className={`profile-page ${display === "proff" ? "" : "hide"}`}>
            <div className="cover-img">
              <img
                src="https://trailbucket12345.s3.us-east-2.amazonaws.com/content/Content+Images-17.png"
                alt="Trail cover"
              />
              <div className="pencil">
                <EditIcon />
              </div>
            </div>
            <div className="user-info">
              <div className="img-cove">
                <div className="img">
                  <img
                    src={
                      imgStr
                        ? imgStr
                        : "https://pngimg.com/uploads/avatar/avatar_PNG47.png"
                    }
                    alt="Trail user"
                  />
                </div>
                <input type="file" onChange={this.onChange} hidden />
                <div className="edit-btn" onClick={this.onClick}>
                  <span></span>
                  <span></span>
                </div>
              </div>
              <div className="name">
                <h3>
                  {currentUser.user.firstName} {currentUser.user.lastName}
                </h3>
                <p>{currentUser.user.tag}</p>
              </div>
            </div>
            <div className="input">
              <label htmlFor="username">Username</label>
              <input
                type="text"
                placeholder="Clark-Kent"
                value={username}
                name="username"
                onChange={this.onChange}
              />
            </div>
            <hr />
            <div className="input">
              <label htmlFor="username">Bio</label>
              <textarea
                name="bio"
                id=""
                placeholder="A short paragraph about your journey"
                cols="30"
                rows="10"
                value={bio}
                onChange={this.onChange}
              ></textarea>
            </div>
            <hr />
            <div className="input">
              <label htmlFor="username">Job Title</label>
              <input
                type="text"
                placeholder="Short description of you"
                name="tag"
                value={tag}
                onChange={this.onChange}
              />
            </div>
            <hr />
            <div className="input">
              <label htmlFor="username">Email address</label>
              <input
                type="text"
                placeholder="mubarak-lawal"
                value={email}
                readOnly
              />
            </div>
            <div className="btn-group">
              <div
                className={`save ${view ? "btn-load" : ""}`}
                onClick={this.handleSubmit}
              >
                <span className="btn_text">Save Changes</span>
              </div>
              <div className="save out" onClick={this.logout}>
                Log Out
              </div>
            </div>
            <p
              className={`retake ${tutStep === 16 ? "sixteen" : ""}`}
              onClick={(e) => {
                this.setState({ tutorial: true, tutStep: 0 });
                this.handleScroll(0);
                this.handlePages(e, "hom");
              }}
            >
              Take a tour around my dashboard
            </p>
            {tutStep === 16 && (
              <>
                <div className="highlight"></div>
                <div className="inner-tut">
                  <div
                    className="back"
                    onClick={(e) => {
                      this.setState({ tutStep: tutStep - 1 });
                      this.handlePages(e, "proff");
                    }}
                  >
                    <div className="icon">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                    Go back
                  </div>
                  <h3>Did you enjoy the tour? Want a second ride?</h3>
                  <p>
                    If you are ever looking to check around again to see how
                    your learning dashboard works for you, you can come click
                    this button right down here.
                  </p>
                  <div className="btn-group">
                    <div className="skip" onClick={this.handleSkip}>
                      Skip all
                    </div>
                    <div
                      className="start"
                      onClick={() => {
                        this.setState({ tutStep: tutStep + 1 });
                      }}
                    >
                      Next
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    errors: state.errors,
    newTodo: state.todo.new,
    updatedTodo: state.todo.update,
    microSkills: state.learning.microSkills,
    newLearning: state.learning.new,
    updatedLearning: state.learning.updatedLearning,
  };
}

export default connect(mapStateToProps, {
  getUser,
  logout,
  addTodoFn,
  updateTodoFn,
  addLearningFn,
  getMicroSkillsFn,
  updateLearningFn,
  updateUser,
})(NewDashboard);
