import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getMenteeMeetings,
  getMentorMeetings,
  updateMeeting,
} from '../store/actions/meeting';
import Session from './Session';

class History extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      error: '',
      show: '',
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    if (!this.state.query) {
      this.setState({
        error: 'Please type in a comment',
      });
      return;
    }
    if (this.props.currentUser.user.role === 'mentor') {
      var data = {
        mentorQuery: this.state.query,
      };
    } else {
      data = {
        menteeQuery: this.state.query,
      };
    }
    this.props.updateMeeting(e, data).then(() => {
      if (this.props.currentUser.user.role === 'mentor') {
        this.props.getMentorMeetings(this.props.currentUser.user.id);
      } else {
        this.props.getMenteeMeetings(this.props.currentUser.user.id);
      }
      this.setState({
        error: '',
        query: '',
        show: '',
      });
    });
  };

  handleClick = (e) => {
    if (e.target.className.indexOf('ignore') !== -1) {
      return;
    }
    var { id } = e.target;
    if (!id) {
      id = e.target.parentNode.id;
    }
    if (this.state.show !== id) {
      this.setState({
        show: id,
      });
    } else {
      this.setState({
        show: '',
      });
    }
  };

  componentDidMount() {
    if (this.props.currentUser.user.role === 'mentor') {
      this.props.getMentorMeetings(this.props.currentUser.user.id);
    } else {
      this.props.getMenteeMeetings(this.props.currentUser.user.id);
    }
  }

  render() {
    const { meeting, display } = this.props;
    const { query, error, show } = this.state;
    const now = new Date();
    var meets = [];
    if (meeting.mentorMeetings || meeting.menteeMeetings) {
      if (this.props.currentUser.user.role === 'mentor') {
        meets = meeting.mentorMeetings.filter((e) => {
          const meetTime = new Date(e.time);
          if (now > meetTime) {
            return true;
          }
          return false;
        });
      } else if (this.props.currentUser.user.role === 'user') {
        meets = meeting.menteeMeetings.filter((e) => {
          const meetTime = new Date(e.time);
          if (now > meetTime) {
            return true;
          }
          return false;
        });
      }

      meets.sort((a, b) => {
        const at = new Date(a.time);
        const bt = new Date(b.time);
        return bt - at;
      });

      var meetsn = meets.map((e) => {
        if (this.props.currentUser.user.role === 'mentor') {
          if (e.mentee.picture.indexOf('google') !== -1) {
            var imgStr = e.mentee.picture;
          } else if (e.mentee.picture.indexOf('trailbucket12345') !== -1) {
            imgStr =
              'https://dqe80zoqbuyqe.cloudfront.net' +
              e.mentee.picture.substring(51);
          } else {
            imgStr = '';
          }
        } else {
          if (e.mentor.picture.indexOf('google') !== -1) {
            imgStr = e.mentor.picture;
          } else if (e.mentor.picture.indexOf('trailbucket12345') !== -1) {
            imgStr =
              'https://dqe80zoqbuyqe.cloudfront.net' +
              e.mentor.picture.substring(51);
          } else {
            imgStr = '';
          }
        }
        return { ...e, role: this.props.currentUser.user.role, img: imgStr };
      });
    }
    return (
      <div
        className={`history ${
          display === 'hom' ? 'hom' : display === 'his' ? 'show' : ''
        }`}
      >
        <div className="title">History</div>
        <div className="container">
          {(meeting.mentorMeetings || meeting.menteeMeetings) &&
          meetsn.length > 0 ? (
            meetsn.map((e) => (
              <Session
                key={e._id}
                id={e._id}
                name={
                  e.role === 'mentor'
                    ? `${e.mentee.firstName}  ${e.mentee.lastName}`
                    : `${e.mentor.firstName}  ${e.mentor.lastName}`
                }
                time={e.time}
                purpose={e.purpose}
                query={query}
                oldQuery={e.role === 'mentor' ? e.mentorQuery : e.menteeQuery}
                error={error}
                show={show}
                click={this.handleClick.bind(this)}
                change={this.handleChange.bind(this)}
                submit={this.handleSubmit.bind(this, e._id)}
                img={e.img}
              />
            ))
          ) : (
            <div className="nil">You do not have a previous session</div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { errors: state.errors, meeting: state.meeting };
}

export default connect(mapStateToProps, {
  getMenteeMeetings,
  getMentorMeetings,
  updateMeeting,
})(History);
