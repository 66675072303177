import { ADD_TODO, UPDATE_TODO } from '../actionTypes'

const todo = (state = { new: {}, update: {} }, action) => {
  switch (action.type) {
    case ADD_TODO:
      return { new: action.todo }
    case UPDATE_TODO:
      return { update: action.todo }
    default:
      return state
  }
}

export default todo
