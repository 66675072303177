import React, { Component } from 'react';
import moment from 'moment-timezone';
import { timeStruc, dateStruc } from '../services/time';
import Timeslot from './Timeslot';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

class Schedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zone: '',
      zoneAb: '',
      zones: [],
    };
  }

  componentDidMount() {
    const zones = moment.tz.names();
    const zone = moment.tz.guess();
    this.setState({
      zone,
      zones,
      zoneAb: moment.tz(zone).format('z'),
    });
  }

  handleClick = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      zoneAb: moment.tz(e.target.value).format('z'),
    });
  };

  render() {
    const { display, mentor, time, info } = this.props;
    const { zone, zoneAb, zones } = this.state;
    var mentArr = [];
    mentor.freeTime.forEach((m) => {
      if (m.free) {
        mentArr.push(m);
      }
      if (m.freeT) {
        mentArr.push({ ...m, second: true });
      }
    });
    var arr = [];
    var arr1 = [];
    mentArr.forEach((m) => {
      var time = timeStruc(m);
      arr.push({ ...time });
    });
    const arr2 = arr.filter((m) => {
      return m.date;
    });
    arr2.sort((a, b) => {
      return a.date - b.date;
    });
    arr1 = arr2.map((m) => {
      return dateStruc(
        moment.tz(m.date, zone).format(),
        m.id,
        m.today,
        m.tomorrow,
        m.second
      );
    });
    if (arr1) {
      var Timelist = arr1.map((m, i) => (
        <Timeslot
          key={i}
          id={i}
          _id={m.id}
          day={m.day}
          month={m.month}
          date={m.dateNo}
          hour={m.hour}
          year={m.year}
          minute={m.minute}
          zone={zoneAb}
          today={m.today}
          tomorrow={m.tomorrow}
          time={time}
          info={info}
          second={m.second}
        />
      ));
    }

    var price = info.length * 2000;
    price = price.toLocaleString('en-US');
    return (
      <div className={`schedule ${display ? '' : 'hide'}`}>
        {Timelist.length !== 0 && (
          <div>
            <input
              type="text"
              list="timezone"
              name="zone"
              value={zone}
              placeholder="Ex: Africa/Lagos"
              onChange={this.handleClick}
              className="zone-input"
            />
            <datalist id="timezone">
              {zones.map((e, i) => (
                <option key={i} value={e}>
                  {e}
                </option>
              ))}
            </datalist>
            <div className="price">
              <i className="fas fa-shopping-cart"></i>
              <span>N</span>
              {price}
            </div>
            <div className="container">{Timelist}</div>
            {info.length > 0 ? (
              <>
                <div className="prai">
                  <i className="fas fa-shopping-cart"></i>
                  <span>N</span>
                  {price}
                </div>
                <div className="button">
                  <Link
                    to={{
                      pathname: '/mentor/register',
                      state: { info },
                    }}
                  >
                    Pay
                  </Link>
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        )}
        {Timelist.length === 0 && (
          <div>
            This mentor has been fully booked for the next two weeks, check back
            later or{' '}
            <HashLink smooth to="#mentors">
              check out other mentors
            </HashLink>
          </div>
        )}
      </div>
    );
  }
}

export default Schedule;
