import React, { Component } from 'react';
import { connect } from 'react-redux';
import { icon24, icon03, icon20, icon21, icon22, icon14 } from '../Copy/Copy';
import { sectionResources } from '../../store/actions/resources';
import { editAssistant, getUser } from '../../store/actions/users';
import ProgressRing from '../ProgressRing';

class Dashpath extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 0,
      pop: false,
      assistant: '',
      view: false,
      title: '',
      done: 0,
      rem: 0,
      resource: [],
      remTime: '',
      load: false,
      refresh: 0,
    };
  }

  componentDidMount() {
    var done = 0;
    if (!this.props.currentUser.user.role) {
      this.props
        .getUser(this.props.currentUser.user.id, 'user')
        .then((user) => {
          var section = user.section.find((e) => e.done === 'pending');
          if (user.section) {
            var lastSection = user.section[user.section.length - 1];
            user.section.forEach((e) => {
              if (e.done === 'done') {
                done++;
              }
              if (section) {
                this.setState({
                  assistant: user.assistant[0].rate,
                  title: section.title,
                  done,
                  rem: user.section.length - done,
                  remTime: lastSection.due,
                });
              }
            });
          }
          if (section) {
            this.props
              .sectionResources(section.field, {
                pathway: section.title,
              })
              .then(() => {
                var { resource } = this.props;
                resource = resource.filter(
                  (e) => !user.completedResource.includes(e._id)
                );
                resource = resource.map((e) => {
                  if (user.currentResource.includes(e._id)) {
                    return { ...e, pending: true };
                  } else {
                    return { ...e };
                  }
                });
                if (resource.length > 3) {
                  resource.length = 3;
                } else if (resource.length === 2) {
                  resource.push(section);
                }
                this.setState({
                  resource,
                  load: true,
                });
              });
          }
        });
    } else {
      var section = this.props.currentUser.user.section.find(
        (e) => e.done === 'pending'
      );
      if (this.props.currentUser.user.section.length > 0) {
        var lastSection =
          this.props.currentUser.user.section[
            this.props.currentUser.user.section.length - 1
          ];
        this.props.currentUser.user.section.forEach((e) => {
          if (e.done === 'done') {
            done++;
          }
          this.setState({
            assistant: this.props.currentUser.user.assistant[0].rate,
            title: section.title,
            done,
            rem: this.props.currentUser.user.section.length - done,
            remTime: lastSection.due,
          });
        });
      }
      if (section) {
        this.props
          .sectionResources(section.field, {
            pathway: section.title,
          })
          .then(() => {
            var { resource } = this.props;
            resource = resource.filter(
              (e) =>
                !this.props.currentUser.user.completedResource.includes(e._id)
            );
            resource = resource.map((e) => {
              if (this.props.currentUser.user.currentResource.includes(e._id)) {
                return { ...e, pending: true };
              } else {
                return { ...e };
              }
            });
            if (resource.length > 3) {
              resource.length = 3;
            } else if (resource.length <= 3) {
              resource.push(section);
            }
            this.setState({
              resource,
              load: true,
            });
          });
      }
    }
  }

  handleSave = (e) => {
    this.setState({
      view: true,
    });
    if (this.props.currentUser.user.assistant) {
      if (this.props.currentUser.user.assistant[0]) {
        if (
          this.state.assistant === this.props.currentUser.user.assistant[0].rate
        ) {
          this.setState({
            pop: !this.state.pop,
            view: false,
          });
        } else {
          this.props
            .editAssistant(this.props.currentUser.user.assistant[0].field, {
              rate: this.state.assistant,
            })
            .then(() => {
              this.setState({ pop: !this.state.pop, view: false });
            });
        }
      }
      this.setState({
        pop: !this.state.pop,
        view: false,
      });
    } else {
      this.setState({
        pop: !this.state.pop,
        view: false,
      });
    }
  };

  handleAss = (e) => {
    this.setState({
      assistant: e.target.className.split(' ')[0],
    });
  };

  handlePop = (e) => {
    if (e.target.className.indexOf('igno') > -1) {
      return;
    }
    this.setState({
      pop: !this.state.pop,
    });
  };

  handlePath = () => {
    this.props.history.push('/pathway/product-design');
  };

  handleRes = (e) => {
    var anc = document.createElement('a');
    anc.href = e;
    anc.target = '_blank';
    anc.click();
  };

  componentDidUpdate() {
    if (this.state.refresh + 1 === this.props.refScore) {
      var done = 0;
      this.setState({
        load: false,
        refresh: this.state.refresh + 1,
      });
      this.props
        .getUser(this.props.currentUser.user.id, 'user')
        .then((user) => {
          var section = user.section.find((e) => e.done === 'pending');
          if (user.section) {
            var lastSection = user.section[user.section.length - 1];
            user.section.forEach((e) => {
              if (e.done === 'done') {
                done++;
              }
              if (section) {
                this.setState({
                  assistant: user.assistant[0].rate,
                  title: section.title,
                  done,
                  rem: user.section.length - done,
                  remTime: lastSection.due,
                });
              }
            });
          }
          if (section) {
            this.props
              .sectionResources(section.field, {
                pathway: section.title,
              })
              .then(() => {
                var { resource } = this.props;
                resource = resource.filter(
                  (e) => !user.completedResource.includes(e._id)
                );
                resource = resource.map((e) => {
                  if (user.currentResource.includes(e._id)) {
                    return { ...e, pending: true };
                  } else {
                    return { ...e };
                  }
                });
                if (resource.length > 3) {
                  resource.length = 3;
                } else if (resource.length === 2) {
                  resource.push(section);
                }
                this.setState({
                  resource,
                  load: true,
                });
              });
          }
        });
    }
  }

  render() {
    const { progress, display, page } = this.props;
    const { pop, assistant, view, done, title, rem, resource, remTime, load } =
      this.state;

    var date = new Date();
    const remDate = new Date(remTime);
    var weekLeft = Math.ceil((remDate - date) / (1000 * 60 * 60 * 24 * 7));
    date = date.toLocaleDateString('en-us', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    if (!weekLeft) {
      weekLeft = 0;
    }
    let setValue = (val) => (val > 9 ? '' : '0') + val;
    if (resource) {
      var toDo = resource.map((e, i) => (
        <div
          className={`deets icon ${e.resource && 'quiz'}`}
          key={i}
          onClick={e.resource ? page : this.handleRes.bind(this, e.link)}
        >
          <div className={`badg ${e.resource && 'quiz'}`}>
            {e.article ? 'Read Article' : e.done ? 'Assessment' : 'Watch Video'}
          </div>
          <div className={`targ ${e.resource && 'quiz'}`}>{e.title}</div>
          <div className={`achi ${e.resource && 'quiz'}`}>
            {e.resource ? '10 mins' : `${e.duration} mins`}
          </div>
          <div
            className={`stat done ${e.pending ? 'pend' : 'done'} ${
              e.resource && 'quiz'
            }`}
          >
            {e.pending ? 'Pending' : 'New'}
          </div>
          <div className={`rema ${e.resource && 'quiz'}`}>
            <i className="fas fa-arrow-right"></i>
          </div>
        </div>
      ));
    }

    return (
      <div
        className={`dashpath referral ${display === 'dahpah' ? '' : 'hide'}`}
      >
        <div className="info">
          <h1>My Product Design Pathway</h1>
          <div className="time">{date}</div>
        </div>
        <div className="upcoming">
          <div className="session">
            <p className="sm">My learning</p>
            <div className="earn-cover">
              <div className="wrappr">
                {!load ? (
                  <div className="gen info left">
                    <div className="load flicker title"></div>
                    <div className="load flicker img"></div>
                    <p className="load flicker sect"></p>
                    <div className="load flicker btn"></div>
                  </div>
                ) : title ? (
                  <div className="gen info left sect" onClick={page}>
                    <div className="title sect">Current Section</div>
                    <div className="img sect">
                      <img className="sect" src={icon24} alt="Refer Trail" />
                    </div>
                    <p className="sect">{title}</p>
                    <div className="btn sect" onClick={page}>
                      Continue Learning <i className="fas fa-chevron-right"></i>
                    </div>
                  </div>
                ) : (
                  <div className="gen info left " onClick={this.handlePath}>
                    <div className="title ">No current Section</div>
                    <div className="img ">
                      <img className="" src={icon24} alt="Refer Trail" />
                    </div>
                    <p className="">
                      You would have a current section as soon as you start
                      learning
                    </p>
                    <div className="btn " onClick={this.handlePath}>
                      Get Pathway <i className="fas fa-chevron-right"></i>
                    </div>
                  </div>
                )}

                {!load ? (
                  <div className="gen info left">
                    <div className="load flicker title"></div>
                    <div className="load flicker img"></div>
                    <p className="load flicker sect"></p>
                    <div className="load flicker btn"></div>
                  </div>
                ) : title ? (
                  <div className="gen info path" onClick={page}>
                    <div className="title path">Pathway</div>
                    <div className="img path">
                      <img className="path" src={icon03} alt="Refer Trail" />
                    </div>
                    <p className="path">
                      You have a personalized pathway to becoming a world class
                      product designer
                    </p>
                    <div className="btn path" onClick={page}>
                      View full pathway <i className="fas fa-chevron-right"></i>
                    </div>
                  </div>
                ) : (
                  <div className="gen info " onClick={this.handlePath}>
                    <div className="title ">No Pathway</div>
                    <div className="img ">
                      <img className="" src={icon03} alt="Refer Trail" />
                    </div>
                    <p className="">
                      Your pathway would be generated before you start learning
                    </p>
                    <div className="btn " onClick={this.handlePath}>
                      Get Pathway <i className="fas fa-chevron-right"></i>
                    </div>
                  </div>
                )}

                {!load ? (
                  <div className="gen info left">
                    <div className="load flicker title"></div>
                    <div className="load flicker img"></div>
                    <p className="load flicker sect"></p>
                    <div className="load flicker btn"></div>
                  </div>
                ) : (
                  <div className="gen info" onClick={this.handlePop}>
                    <div className="title">Trail assistant</div>
                    <div className="img">
                      <img src={icon20} alt="Refer Trail" />
                    </div>
                    <div className="status">Status</div>
                    <div className="rate">{assistant}</div>
                    <div className="btn" onClick={this.handlePop}>
                      Update Assistant <i className="fas fa-chevron-right"></i>
                    </div>
                    <div className={`popup ${pop ? '' : 'hide'}`}>
                      <div className="inner igno">
                        <div className="cancel" onClick={this.handlePop}>
                          <span></span>
                          <span></span>
                        </div>
                        <div className="bulb igno">
                          <div
                            className={`slow igno ${
                              assistant === 'slow' ? 'active' : ''
                            }`}
                            onClick={this.handleAss}
                          ></div>
                          <div
                            className={`regular igno ${
                              assistant === 'regular' ? 'active' : ''
                            }`}
                            onClick={this.handleAss}
                          ></div>
                          <div
                            className={`intense igno ${
                              assistant === 'intense' ? 'active' : ''
                            }`}
                            onClick={this.handleAss}
                          ></div>
                        </div>
                        <div className="text igno">
                          <p className="igno">Slow</p>
                          <p className="igno">Regular</p>
                          <p className="igno">Intense</p>
                        </div>
                        <div className="btn igno" onClick={this.handleSave}>
                          <span className={`igno ${view ? 'hide' : ''}`}>
                            Save
                          </span>
                          <div className={`igno loader ${view ? '' : 'hide'}`}>
                            <span></span>
                            <span></span>
                            <span></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <p className="sm">Learning Progress</p>
            <div className="sm progre">
              <div className="value">{progress}%</div>
              <div className="pie smm">
                <ProgressRing
                  radius={100}
                  width={180}
                  stroke={25}
                  progress={progress}
                />
                <ProgressRing
                  radius={99}
                  width={180}
                  stroke={24}
                  progress={100}
                  alt
                />
              </div>
              <div className="pie sm">
                <ProgressRing
                  radius={140}
                  width={240}
                  stroke={35}
                  progress={progress}
                />
                <ProgressRing
                  radius={138}
                  width={240}
                  stroke={34}
                  progress={100}
                  alt
                />
              </div>
              <div className="content">
                <div className="stat">
                  <div className="icon">
                    <img src={icon21} alt="Completed Sections" />
                  </div>
                  <div className="text">
                    <div className="no">{setValue(done)}</div>
                    <div className="desc">Sections Completed</div>
                  </div>
                </div>
                <div className="stat">
                  <div className="icon">
                    <img src={icon22} alt="Remaining Sections" />
                  </div>
                  <div className="text">
                    <div className="no">{setValue(rem)}</div>
                    <div className="desc">Sections Remaining</div>
                  </div>
                </div>
                <div className="stat">
                  <div className="icon">
                    <img src={icon14} alt="Remaining Sections" />
                  </div>
                  <div className="text">
                    <div className="no">{setValue(weekLeft)}</div>
                    <div className="desc">
                      {weekLeft > 1 ? 'weeks' : 'week'} left
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p>Top 3 things to do</p>
            <div className="deets">
              <div className="badg">Tasks</div>
              <div className="targ">stage</div>
              <div className="achi">duration</div>
              <div className="stat">Status</div>
              <div className="rema">Actions</div>
            </div>
            {!load ? (
              <>
                <div className="deets icon load flicker"></div>
                <div className="deets icon load flicker"></div>
                <div className="deets icon load flicker"></div>
              </>
            ) : (
              toDo
            )}
          </div>
          <div className="progress">
            <div className="title">Progress</div>
            <div className="stat">
              <div className="icon">
                <img src={icon21} alt="Completed Sections" />
              </div>
              <div className="desc">Completed</div>
              <div className="no">{setValue(done)}</div>
            </div>
            <div className="stat">
              <div className="icon">
                <img src={icon22} alt="Refer pathway" />
              </div>
              <div className="desc">Remaining</div>
              <div className="no">{setValue(rem)}</div>
            </div>
            <div className="monitor">
              <div className="title">Course progress</div>
              <div className="value">{progress}%</div>
              <div className="pie">
                <ProgressRing
                  radius={140}
                  width={240}
                  stroke={35}
                  progress={progress}
                />
                <ProgressRing
                  radius={138}
                  width={240}
                  stroke={34}
                  progress={100}
                  alt
                />
              </div>
              <div className="bar">
                <div className="inner" style={{ width: `${progress}%` }}></div>
              </div>
              <div className="eta">
                Estimated end date for course:{' '}
                <span>
                  {setValue(weekLeft)} {weekLeft > 1 ? 'weeks' : 'week'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { errors: state.errors, resource: state.resource.sectionResources };
}

export default connect(mapStateToProps, {
  editAssistant,
  getUser,
  sectionResources,
})(Dashpath);
