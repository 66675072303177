import { apiCall } from '../../services/api'
import { addError, removeError } from './errors'
import { ADD_TODO, UPDATE_TODO } from '../actionTypes'

export const addTodo = (todo) => ({
  type: ADD_TODO,
  todo,
})

export const updateTodo = (todo) => ({
  type: UPDATE_TODO,
  todo,
})

export const addTodoFn = (todo) => {
  return (dispatch) => {
    return apiCall('post', '/api/v1/todo', todo)
      .then(({ data }) => {
        dispatch(addTodo(data))
        dispatch(removeError())
      })
      .catch((err) => {
        dispatch(addError(err))
      })
  }
}

export const updateTodoFn = (todo, id) => {
  return (dispatch) => {
    return apiCall('put', `/api/v1/todo/${id}`, todo)
      .then(({ data }) => {
        dispatch(updateTodo(data))
        dispatch(removeError())
      })
      .catch((err) => {
        dispatch(addError(err))
      })
  }
}
