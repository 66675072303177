import { apiCall } from '../../services/api';
import {
  GET_MENTOR_HOUR_URL,
  GET_PATHWAY_FULL_URL,
  GET_PATHWAY_SUB_URL,
  GET_BANK,
} from '../actionTypes';
import { addError, removeError } from './errors';

export const getMentorHourUrl = (url) => ({
  type: GET_MENTOR_HOUR_URL,
  url,
});

export const getPathwayFullUrl = (url) => ({
  type: GET_PATHWAY_FULL_URL,
  url,
});

export const getPathwaySubUrl = (url) => ({
  type: GET_PATHWAY_SUB_URL,
  url,
});

export const getBank = (data) => ({
  type: GET_BANK,
  data,
});

export function mentorHourUrl(data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return apiCall('POST', `/api/v1/payments/mentorhour`, data)
        .then(({ data }) => {
          dispatch(getMentorHourUrl(data.data.authorization_url));
          dispatch(removeError());
          resolve();
        })
        .catch((err) => {
          dispatch(addError(err));
          reject();
        });
    });
  };
}

export function pathwayFullUrl(id, data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return apiCall(
        'POST',
        `/api/v1/payments/initializetransaction/${id}`,
        data
      )
        .then(({ data }) => {
          dispatch(getPathwayFullUrl(data.data.authorization_url));
          dispatch(removeError());
          resolve();
        })
        .catch((err) => {
          dispatch(addError(err));
          reject();
        });
    });
  };
}

export function pathwaySubUrl(id, data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return apiCall('POST', `/api/v1/payments/subscription/${id}`, data)
        .then(({ data }) => {
          dispatch(getPathwaySubUrl(data.data.authorization_url));
          dispatch(removeError());
          resolve();
        })
        .catch((err) => {
          dispatch(addError(err));
          reject();
        });
    });
  };
}

export function getBanks() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return apiCall('GET', `/api/v1/payments/getbanks`)
        .then(({ data }) => {
          dispatch(getBank(data));
          dispatch(removeError());
          resolve();
        })
        .catch((err) => {
          dispatch(addError(err));
          reject();
        });
    });
  };
}

export function withdraw(data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return apiCall('POST', `/api/v1/payments/transfer`, data)
        .then(() => {
          dispatch(removeError());
          resolve();
        })
        .catch((err) => {
          dispatch(addError(err));
          reject();
        });
    });
  };
}
