import React, { Component } from 'react';

class Text extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    var { e, change, inc, id, img } = this.props;
    return (
      <div className="container">
        {inc ? (
          <>
            <div className="intro">
              <span>{id + 1}.</span>
              <p>{e.question}</p>
            </div>
            <div className="cover">
              <div className="inner">
                {img.map((e, i) => (
                  <img alt="question" src={e} key={i} />
                ))}
              </div>
            </div>
          </>
        ) : (
          <p>{e.question}</p>
        )}
        <div className="input">
          <input
            type="text"
            onChange={change}
            className="state"
            placeholder="Write your answer here..."
            name={e.question}
          />
        </div>
      </div>
    );
  }
}

export default Text;
