import { Link } from 'react-router-dom'
import WaitNavbar from '../containers/WaitNavbar'
import React, { Component } from 'react'
import { scrollToY } from '../services/scroll'
import { connect } from 'react-redux'
import { AddEmail } from '../store/actions/survey'
import {
  oneFrame1,
  oneFrame2,
  oneFrame3,
  oneFrame4,
  oneFrame5,
  oneFrame6,
  oneFrame7,
  oneLand27,
  oneLand28,
  oneLand29,
  oneLand30,
  oneLand31,
  oneLand32,
  oneLand33,
  oneLand34,
  oneLand36,
  oneLand37,
  oneLand39,
  oneLand40,
  oneLand41,
  oneLand42,
  oneLand43,
  oneLand44,
  oneLand45,
  oneLand46,
} from './Copy/Copy'

class NewLanding extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      emailSub: '',
      error: '',
      errorSub: '',
      view: true,
      viewSub: true,
      step: 0,
      move: 0,
      interval: '',
      intervalStep: '',
      reason: '',
      bank: false,
      subscribe: false,
      frameball: 0,
      frameInterval: '',
      howtoball: 0,
      howtoInterval: '',
    }
  }

  componentDidMount() {
    const interval = setInterval(() => {
      this.setState({
        move: this.state.move + 1,
      })
    }, 1700)
    this.setState({ interval })
    const frameInterval = setInterval(() => {
      const el = document.getElementById('frameball')
      const next = (this.state.frameball + 1) % 3
      el.classList.remove('snap')
      scrollToY(el.offsetWidth * next, 1000, el)
      setTimeout(() => {
        el.classList.add('snap')
      }, 1000)
      this.setState({
        frameball: next,
      })
    }, 5000)
    this.setState({ frameInterval })
    const howtoInterval = setInterval(() => {
      const el = document.getElementById('howtoball')
      const next = (this.state.howtoball + 1) % 5
      el.classList.remove('snap')
      scrollToY(el.offsetWidth * next, 1000, el)
      setTimeout(() => {
        el.classList.add('snap')
      }, 1000)
      this.setState({
        howtoball: next,
      })
    }, 5000)
    this.setState({ howtoInterval })
  }

  handleClick = (no, e) => {
    clearInterval(this.state.frameInterval)
    const el = e.target.parentNode.parentNode.childNodes[0]
    e.target.parentNode.parentNode.childNodes[0].classList.remove('snap')
    scrollToY(el.offsetWidth * no, 1000, el)
    this.setState({
      frameball: no,
    })
    setTimeout(() => {
      e.target.parentNode.childNodes[1].classList.add('snap')
    }, 1000)
    const frameInterval = setInterval(() => {
      const el = e.target.parentNode.parentNode.childNodes[0]
      const next = (this.state.frameball + 1) % 3
      el.classList.remove('snap')
      scrollToY(el.offsetWidth * next, 1000, el)
      setTimeout(() => {
        el.classList.add('snap')
      }, 1000)
      this.setState({
        frameball: next,
      })
    }, 5000)
    this.setState({ frameInterval })
  }

  handlehowTo = (no, e) => {
    clearInterval(this.state.howtoInterval)
    const el = e.target.parentNode.parentNode.childNodes[1]
    e.target.parentNode.parentNode.childNodes[1].classList.remove('snap')
    scrollToY(el.offsetWidth * no, 1000, el)
    this.setState({
      howtoball: no,
    })
    setTimeout(() => {
      e.target.parentNode.parentNode.childNodes[1].classList.add('snap')
    }, 1000)
    const howtoInterval = setInterval(() => {
      const el = e.target.parentNode.parentNode.childNodes[1]
      const next = (this.state.howtoball + 1) % 5
      el.classList.remove('snap')
      scrollToY(el.offsetWidth * next, 1000, el)
      setTimeout(() => {
        el.classList.add('snap')
      }, 1000)
      this.setState({
        howtoball: next,
      })
    }, 5000)
    this.setState({ howtoInterval })
  }

  handleScroll(e) {
    e.preventDefault()
    const schedule = document.querySelector('.fifthland .service-cards')
    schedule.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    })
  }

  handleSubmit = (reason) => {
    const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    var { email, emailSub } = this.state
    if (reason === 'Subscribe') {
      email = emailSub.replace(/\s/g, '')
    } else {
      email = email.replace(/\s/g, '')
    }
    if (re.test(email)) {
      if (reason === 'Subscribe') {
        this.setState({
          viewSub: false,
        })
      } else {
        this.setState({
          view: false,
        })
      }
      this.props
        .AddEmail({ email, reason })
        .then(() => {
          if (this.props.errors.message === null) {
            if (reason === 'Subscribe') {
              this.setState({
                emailSub: '',
                subscribe: true,
                viewSub: true,
              })
            } else {
              this.setState({
                email: '',
                bank: true,
                view: true,
              })
            }
          } else {
            if (reason === 'Subscribe') {
              this.setState({
                errorSub: this.props.errors.message,
                viewSub: true,
              })
            } else {
              this.setState({
                error: this.props.errors.message,
                view: true,
              })
            }
          }
        })
        .catch(() => {
          this.setState({
            view: true,
            viewSub: true,
          })
        })
      if (reason === 'Subscribe') {
        this.setState({
          errorSub: '',
        })
      } else {
        this.setState({
          error: '',
        })
      }
    } else {
      if (reason === 'Subscribe') {
        this.setState({
          errorSub: 'Please Enter a valid email address',
        })
      } else {
        this.setState({
          error: 'Please Enter a valid email address',
        })
      }
    }
  }

  handleMove = (e) => {
    if (e) {
      const interval = setInterval(() => {
        this.setState({
          move: this.state.move + 1,
        })
      }, 1700)
      this.setState({ interval })
    } else {
      clearInterval(this.state.interval)
    }
  }

  handleSteps = (e) => {
    if (!this.state.intervalStep) {
      const intervalStep = setInterval(() => {
        this.setState({
          step: (this.state.step + 1) % 5,
        })
      }, 4000)
      this.setState({ intervalStep })
    }
  }

  handleStepsMove = (e) => {
    clearInterval(this.state.intervalStep)
    this.setState({ step: e, intervalStep: '' })
  }

  handleExit = (e) => {
    clearInterval(this.state.intervalStep)
    clearInterval(this.state.interval)
    clearInterval(this.state.frameInterval)
    clearInterval(this.state.howtoInterval)
  }

  render() {
    const {
      step,
      move,
      email,
      emailSub,
      bank,
      subscribe,
      view,
      viewSub,
      error,
      errorSub,
      frameball,
      howtoball,
    } = this.state
    const ain = ['one', 'two', 'three', 'four', 'five', 'six', 'seven']
    return (
      <div className="home-hero new">
        <WaitNavbar alt exit={this.handleExit} />
        <div className="home-lander">
          <div className="content">
            <div className="hone">
              <div className="line">
                <h1>Get all of your learning</h1>
              </div>
              <div className="line">
                <h1>organized and managed</h1>
              </div>
              <div className="line">
                <h1>in one place.</h1>
              </div>
            </div>
            <div className="line pee">
              <p className="bg">
                In an internet of overwhelming content, one-trail extension
                helps
              </p>
              <p className="sm">
                In an internet of overwhelming content, one-trail
              </p>
            </div>
            <div className="line pee">
              <p className="bg">
                you automatically organise all your learning so you can focus on
              </p>
              <p className="sm">
                extension helps you automatically organise all your
              </p>
            </div>
            <div className="line pee">
              <p className="bg">the most important thing, learning.</p>
              <p className="sm">
                learning so you can focus on the most important thing,
              </p>
            </div>
            <div className="line pee sm">
              <p className="sm">learning.</p>
            </div>
            <div className="btn-group">
              <a
                href="https://chrome.google.com/webstore/detail/one-trail/aafnfdajlgnfgcgopijjcfdffjlmmkjb"
                className="chrome btn"
              >
                <img src={oneLand34} alt="chrome store" />
              </a>
              <Link to="/signup" onClick={this.handleExit} className="sign-up">
                Sign up for free
              </Link>
            </div>
          </div>
          <div className="img">
            <img src={oneLand28} alt="squiggly lines" />
            <img src={oneLand27} alt="Trail landing" className="main" />
            <div className="tile up">
              <div className="blob">
                <img src={oneLand36} alt="Trail up" />
              </div>
              <p>Stay focused and organized!</p>
            </div>
            <div className="tile down">
              <div className="blob">
                <img src={oneLand37} alt="upskilling" />
              </div>
              <p>Accelerated upskilling!</p>
            </div>
          </div>
        </div>
        <div className="partners-tab">
          <h4>
            Automatically integrates all your learning across several world
            leading learning platforms
          </h4>
          <p>*seamlessly do it on other platforms of your choice</p>
          <div className="learning-imgs">
            <img
              className="kha"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f6/Khan_Academy_logo_%282018%29.svg/1200px-Khan_Academy_logo_%282018%29.svg.png"
              alt="Khan academy"
            />
            <img
              className="edx"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/EdX.svg/2560px-EdX.svg.png"
              alt="EDX"
            />
            <img
              className="ski"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c0/Skillshare_logo_2020.svg/1200px-Skillshare_logo_2020.svg.png"
              alt="Skillshare"
            />
            <img
              className="lin"
              src="https://upload.wikimedia.org/wikipedia/commons/5/52/LinkedIn_Learning_logo.png"
              alt="LinkedIn Learning"
            />
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/3/3b/Udacity_logo.png"
              alt="Udacity"
              className="uda"
            />
            <img
              src="https://1000logos.net/wp-content/uploads/2022/06/Coursera-Logo.png"
              alt="Coursera"
              className="cou"
            />
            <img
              src="https://logos-world.net/wp-content/uploads/2021/11/Udemy-Logo-2021-present.png"
              alt="Udemy"
              className="ude"
            />
          </div>
        </div>
        <div className="manage-tab">
          <div className="content">
            <h1>Manage all your learning in one place</h1>
            <p>
              One-trail precisely tracks what you learn online from all the
              websites and digital platforms you learn from. Your Coursera
              specialization, Udemy program, Skillshare course, YouTube videos
              etc are all seamlessly integrated in one place.
            </p>
            <div className="btn-group">
              <a
                href="https://chrome.google.com/webstore/detail/one-trail/aafnfdajlgnfgcgopijjcfdffjlmmkjb"
                className="chrome"
              >
                Get from chrome store
              </a>
              <Link to="/signup" onClick={this.handleExit} className="free">
                Sign up for free
              </Link>
            </div>
          </div>
          <div className="img">
            <h1>Manage all your learning in one place</h1>
            <img src={oneLand29} alt="manage" className="main" />
            <img
              src={oneLand39}
              alt="manage"
              className={`moves ${ain[move % 7]}`}
            />
            <img
              src={oneLand40}
              alt="manage"
              className={`moves ${ain[(move + 1) % 7]}`}
            />
            <img
              src={oneLand41}
              alt="manage"
              className={`moves ${ain[(move + 2) % 7]}`}
            />
            <img
              src={oneLand42}
              alt="manage"
              className={`moves ${ain[(move + 3) % 7]}`}
            />
            <img
              src={oneLand44}
              alt="manage"
              className={`moves ${ain[(move + 4) % 7]}`}
            />
            <img
              src={oneLand46}
              alt="manage"
              className={`moves ${ain[(move + 5) % 7]}`}
            />
            <img
              src={oneLand45}
              alt="manage"
              className="moves lap"
              onMouseOver={this.handleMove.bind(this, false)}
              onMouseLeave={this.handleMove.bind(this, true)}
            />
            <img
              src={oneLand43}
              alt="manage"
              className={`moves ${ain[(move + 6) % 7]}`}
            />
          </div>
        </div>
        <div className="track-tab">
          <div className="img">
            <h1>Stay on track with your learning goals</h1>
            <img src={oneLand30} alt="on track" />
          </div>
          <div className="content">
            <h1>Stay on track with your learning goals</h1>
            <p>
              You can now automatically track your learning while using the
              Internet, and keep up with your learning goals. One-trail also
              comes with a scheduling feature that helps you keep track of your
              learning tasks.
            </p>
            <div className="btn-group">
              <a
                href="https://chrome.google.com/webstore/detail/one-trail/aafnfdajlgnfgcgopijjcfdffjlmmkjb"
                className="chrome"
              >
                Get from chrome store
              </a>
              <Link to="/signup" onClick={this.handleExit} className="free">
                Sign up for free
              </Link>
            </div>
          </div>
        </div>
        <div className="visualise-tab">
          <h1>
            Visualize all the things you learn in real time, from all the
            different learning sources
          </h1>
          <p className="bg">
            A lot of learning goes unmanaged and unnoticed and as a result,
            unrewarded; one-trail prevents this by letting you ‘see’ your
            learning.
          </p>
          <div className="img-group">
            <img src={oneFrame6} alt="Mockups" />
            <img src={oneFrame5} alt="Mockups" />
            <img src={oneFrame7} alt="Mockups" />
          </div>
          <div className="img-groupie">
            <div className="carousel snap" id="frameball">
              <div className="container">
                <img src={oneFrame7} alt="Mockups" />
              </div>
              <div className="container">
                <img src={oneFrame5} alt="Mockups" />
              </div>
              <div className="container">
                <img src={oneFrame6} alt="Mockups" />
              </div>
            </div>
            <div className="balls">
              <span
                className={frameball === 0 ? 'fill' : ''}
                onClick={this.handleClick.bind(this, 0)}
              ></span>
              <span
                className={frameball === 1 ? 'fill' : ''}
                onClick={this.handleClick.bind(this, 1)}
              ></span>
              <span
                className={frameball === 2 ? 'fill' : ''}
                onClick={this.handleClick.bind(this, 2)}
              ></span>
            </div>
          </div>
          <p className="sm">
            A lot of learning goes unmanaged and unnoticed and as a result,
            unrewarded; one-trail prevents this by letting you ‘see’ your
            learning.
          </p>
          <div className="btn-group">
            <a
              href="https://chrome.google.com/webstore/detail/one-trail/aafnfdajlgnfgcgopijjcfdffjlmmkjb"
              className="chrome"
            >
              Get from chrome store
            </a>
            <Link to="/signup" onClick={this.handleExit} className="free">
              Sign up for free
            </Link>
          </div>
        </div>
        <div className="how-to bg" onMouseOver={this.handleSteps}>
          <div className="img">
            <img
              src={oneFrame1}
              className={step === 0 ? '' : 'hide'}
              alt="Chrome store"
            />
            <img
              src={oneFrame2}
              className={step === 1 ? '' : 'hide'}
              alt="Chrome store"
            />
            <img
              src={oneFrame3}
              className={step === 2 ? '' : 'hide'}
              alt="Chrome store"
            />
            <img
              src={oneFrame4}
              className={step === 3 ? '' : 'hide'}
              alt="Chrome store"
            />
            <img
              src={oneFrame5}
              className={step === 4 ? '' : 'hide'}
              alt="Chrome store"
            />
          </div>
          <div className="content">
            <div className="indicator">
              <div className="item">
                <div className={`bulb ${step > -1 ? 'fill' : ''}`}></div>
                <div className={`line ${step > 0 ? 'fill' : ''}`}>
                  <div className="inner"></div>
                </div>
              </div>
              <div className="item">
                <div className={`bulb ${step > 0 ? 'fill' : ''}`}></div>
                <div className={`line ${step > 1 ? 'fill' : ''}`}>
                  <div className="inner"></div>
                </div>
              </div>
              <div className="item">
                <div className={`bulb ${step > 1 ? 'fill' : ''}`}></div>
                <div className={`line ${step > 2 ? 'fill' : ''}`}>
                  <div className="inner"></div>
                </div>
              </div>
              <div className="item">
                <div className={`bulb ${step > 2 ? 'fill' : ''}`}></div>
                <div className={`line ${step > 3 ? 'fill' : ''}`}>
                  <div className="inner"></div>
                </div>
              </div>
              <div className="item">
                <div className={`bulb ${step > 3 ? 'fill' : ''}`}></div>
              </div>
            </div>
            <div className="steps">
              <p
                className={step === 0 ? 'fill' : ''}
                onClick={this.handleStepsMove.bind(this, 0)}
              >
                Visit chrome store and download trail extension
              </p>
              <p
                className={step === 1 ? 'fill' : ''}
                onClick={this.handleStepsMove.bind(this, 1)}
              >
                Sign up to create an account
              </p>
              <p
                className={step === 2 ? 'fill' : ''}
                onClick={this.handleStepsMove.bind(this, 2)}
              >
                Use prompts to add content to resources
              </p>
              <p
                className={step === 3 ? 'fill' : ''}
                onClick={this.handleStepsMove.bind(this, 3)}
              >
                Access your dashboard from mobile/laptop
              </p>
              <p
                className={step === 4 ? 'fill' : ''}
                onClick={this.handleStepsMove.bind(this, 4)}
              >
                Add content manually from the dashboard
              </p>
            </div>
          </div>
        </div>
        <div className="how-to sm">
          <h1>Set up One-Trail in a few clicks</h1>
          <div className="carousel snap" id="howtoball">
            <div className="frame">
              <img src={oneFrame1} alt="chrome store" />
              <p>Visit chrome store and download trail extension</p>
            </div>
            <div className="frame">
              <img src={oneFrame2} alt="chrome store" />
              <p>Sign up to create an account</p>
            </div>
            <div className="frame">
              <img src={oneFrame3} alt="chrome store" />
              <p>Use prompts to add content to resources</p>
            </div>
            <div className="frame">
              <img src={oneFrame4} alt="chrome store" />
              <p>Access your dashboard from mobile/laptop</p>
            </div>
            <div className="frame">
              <img src={oneFrame5} alt="chrome store" />
              <p>Add content manually from the dashboard</p>
            </div>
          </div>
          <div className="balls">
            <span
              className={howtoball === 0 ? 'fill' : ''}
              onClick={this.handlehowTo.bind(this, 0)}
            ></span>
            <span
              className={howtoball === 1 ? 'fill' : ''}
              onClick={this.handlehowTo.bind(this, 1)}
            ></span>
            <span
              className={howtoball === 2 ? 'fill' : ''}
              onClick={this.handlehowTo.bind(this, 2)}
            ></span>
            <span
              className={howtoball === 3 ? 'fill' : ''}
              onClick={this.handlehowTo.bind(this, 3)}
            ></span>
            <span
              className={howtoball === 4 ? 'fill' : ''}
              onClick={this.handlehowTo.bind(this, 4)}
            ></span>
          </div>
        </div>
        <div className="perks-tab">
          <div className="perks">
            <img src={oneLand31} alt="Available" />
            <h1>Available and accessible on your devices</h1>
            <ul>
              <li>Works on your chrome browser as an extension.</li>
              <li>
                Learning dashboard is accessible on mobile {'&'} desktop. (Add
                resouces manually)
              </li>
            </ul>
          </div>
          <div className="perks">
            <img src={oneLand32} alt="privacy" />
            <h1>Your data and privacy is safe</h1>
            <ul>
              <li>Works on your chrome browser as an extension.</li>
              <li>
                Learning dashboard is accessible on mobile {'&'} desktop. (Add
                resouces manually)
              </li>
            </ul>
          </div>
        </div>
        <div className="trail-bank">
          <div className="img">
            <h1>Trailbank is coming soon!</h1>
            <img src={oneLand33} alt="Trail Bank" />
          </div>
          <div className="content">
            <h1>Trailbank is coming soon!</h1>
            <p>
              Trail Bank is a tool that evaluates your learning, determines your
              micro-skills and uses them to determine where you stand in the job
              market. We need to talk people who might need. Want to help out?
            </p>
            {error && <div className="error">{error}</div>}
            <div className="input">
              <input
                type="text"
                id="bank"
                value={email}
                onChange={(e) =>
                  this.setState({ email: e.target.value, bank: false })
                }
              />
              <label
                htmlFor="bank"
                className={view ? '' : 'btn-load'}
                onClick={this.handleSubmit.bind(this, 'Bank')}
              >
                <span className="btn_text">
                  {bank ? 'Completed!' : "I'm up for it"}
                </span>
              </label>
            </div>
          </div>
        </div>
        <div className="footer-tab">
          <div className="information">
            <div className="about-info">
              <div className="brand">
                <img
                  src="https://trail-bucket-074269.s3.us-east-2.amazonaws.com/files/Trail+c-01.png"
                  alt="Trail Home"
                  width="40"
                  height="35"
                />
                <h1>Trail</h1>
              </div>
              <p>
                Seamlessly integrate your learning across several world leading
                platforms
              </p>
            </div>
            <div className="contact">
              <h1>Contact</h1>
              <p>
                Email:{' '}
                <a
                  href="mailto:hello@trailng.com?subject=We%20are%20excited%20to%20hear%20from%20you!"
                  target="_blank"
                  rel="noreferrer"
                >
                  hello@trailng.com
                </a>
              </p>
              <p>
                phone:{' '}
                <a href="tel:+2348146770822" target="_blank" rel="noreferrer">
                  +234 814 677 0822
                </a>
              </p>
            </div>
            <div className="subscribe">
              <h1>SUBSCRIBE TO OUR NEWSLETTER</h1>
              {errorSub && <div className="error">{errorSub}</div>}
              <div className="input">
                <input
                  type="text"
                  id="sub"
                  value={emailSub}
                  onChange={(e) =>
                    this.setState({
                      emailSub: e.target.value,
                      subscribe: false,
                    })
                  }
                />
                <label
                  htmlFor="sub"
                  className={viewSub ? '' : 'btn-load'}
                  onClick={this.handleSubmit.bind(this, 'Subscribe')}
                >
                  <span className="btn_text">
                    {subscribe ? 'Completed!' : 'Subscribe'}
                  </span>
                </label>
              </div>
            </div>
          </div>
          <hr />
          <div className="bottom">
            <p>© 2022 Trail Ltd. All Rights Reserved</p>
            <div className="social">
              <a
                href="https://twitter.com/trail_ng?s=20"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                href="mailto:hello@trailng.com?subject=We%20are%20excited%20to%20hear%20from%20you!"
                target="_blank"
                rel="noreferrer"
              >
                <i className="far fa-envelope"></i>
              </a>
              <a
                href="https://instagram.com/trail.ng"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-instagram"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/trailng"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    errors: state.errors,
  }
}

export default connect(mapStateToProps, { AddEmail })(NewLanding)
