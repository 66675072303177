import React from 'react';
import { Link } from 'react-router-dom';
import WaitSuccess from './WaitSuccess';
import { logo73, logo74, logo76, logo82, logo81, logo83 } from './Copy/Copy';

const Firstpage = ({
  alt,
  alt1,
  img,
  imag,
  rot,
  abo,
  simg,
  text,
  url,
  load,
  info,
  btn,
  err,
  wait,
  email,
  share,
  error,
  cancel,
  cancelBtn,
  view,
  click,
  submit,
  shareBtn,
  copy,
  change,
}) => {
  return (
    <div
      className={`firstpage ${alt ? 'alt' : ''} ${err ? 'err' : ''} ${
        wait ? 'wait' : ''
      }  ${rot ? 'rot' : ''}`}
    >
      <div className="image">
        <img
          alt={`${info ? (load ? 'Trail Home' : '') : 'Trail Home'}`}
          src={img}
          className={`bg ${imag ? 'tin' : ''} ${abo ? 'abo' : ''} ${
            info ? (load ? '' : 'load flicker') : ''
          } `}
        />
        <img
          alt={`${info ? (load ? 'Trail Home' : '') : 'Trail Home'}`}
          src={simg}
          className={`sm ${imag ? 'tin' : ''} ${abo ? 'abo' : ''} ${
            info ? (load ? '' : 'load flicker') : ''
          }`}
        />
        {alt && !alt1 && (
          <div className="lead">
            <p>Mentors from the leading companies in Nigeria</p>
            <div className="img">
              <div className="img-icon">
                <img alt="mentors companies" src={logo73} />
              </div>
              <div className="img-icon">
                <img alt="mentors companies" src={logo83} />
              </div>
              <div className="img-icon">
                <img alt="mentors companies" src={logo76} />
              </div>
              <div className="img-icon">
                <img alt="mentors companies" src={logo82} />
              </div>
              <div className="img-icon">
                <img alt="mentors companies" src={logo81} />
              </div>
              <div className="img-icon">
                <img alt="mentors companies" src={logo74} />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="content">
        <div>
          <h1 className={`${info ? (load ? '' : 'load flicker') : ''}`}>
            {alt && !alt1 && <span className="bluu">{text[2]}</span>} {text[0]}
          </h1>
          <p className={`${info ? (load ? '' : 'load flicker') : ''}`}>
            {text[1]}
          </p>
          {wait ? (
            <>
              <div className="error">{error}</div>
              <div className={`search ${error !== '' ? 'error' : ''} `}>
                <label htmlFor="email" onClick={submit} className="ignore">
                  <div className={`ignore loader ${view ? 'hide' : ''}`}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                  <span className={`ignore ${view ? '' : 'hide'}`}>
                    Get Early Access
                  </span>
                </label>
                <input
                  type="text"
                  id="email"
                  placeholder="Johndoe@example.com"
                  onChange={change}
                  value={email}
                  name="email"
                  className="ignore"
                />
              </div>
              <WaitSuccess
                share={share}
                cancel={cancel}
                shareBtn={shareBtn}
                cancelBtn={cancelBtn}
                title={'Welcome to Trail!'}
                desc={
                  'Together, we can make Nigerian product designers like you become world class.'
                }
                copy={copy}
              />
            </>
          ) : (
            <div className={`links ${info ? 'hide' : ''}`}>
              <Link to={url} className="join">
                {btn}
              </Link>
            </div>
          )}
        </div>
        {alt && !alt1 && (
          <div className="lead">
            <p>Mentors from the leading companies in Nigeria</p>
            <div className="img">
              <div className="img-icon">
                <img alt="mentors companies" src={logo73} />
              </div>
              <div className="img-icon">
                <img alt="mentors companies" src={logo83} />
              </div>
              <div className="img-icon">
                <img alt="mentors companies" src={logo76} />
              </div>
              <div className="img-icon">
                <img alt="mentors companies" src={logo82} />
              </div>
              <div className="img-icon">
                <img alt="mentors companies" src={logo81} />
              </div>
              <div className="img-icon">
                <img alt="mentors companies" src={logo74} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Firstpage;
