import React from 'react';
import {
  logo73,
  logo74,
  logo75,
  logo76,
  logo77,
  logo78,
  logo79,
  logo80,
  logo81,
  logo82,
  logo83,
  logo84,
  logo85,
} from './Copy/Copy';

const Trustedby = ({ landing }) => {
  return (
    <div className="secondpage">
      <div className="title">
        Experts gathered from top companies in Nigeria
      </div>
      <p>
        Our community of industry professionals from different fields of
        specialization, and resources from top companies guarantee reliable
        career guidance.
      </p>
      <div className="company-logo">
        <div className="img">
          <img src={logo73} alt="Mentor Companies" />
        </div>
        <div className="img">
          <img src={logo74} alt="Mentor Companies" />
        </div>
        <div className="img">
          <img src={logo75} alt="Mentor Companies" />
        </div>
        <div className="img">
          <img src={logo76} alt="Mentor Companies" />
        </div>
        <div className="img">
          <img src={logo77} alt="Mentor Companies" />
        </div>
        <div className="img">
          <img src={logo78} alt="Mentor Companies" />
        </div>
        <div className="img">
          <img src={logo79} alt="Mentor Companies" />
        </div>
        <div className="img">
          <img src={logo80} alt="Mentor Companies" />
        </div>
        <div className="img">
          <img src={logo81} alt="Mentor Companies" />
        </div>
        <div className="img">
          <img src={logo82} alt="Mentor Companies" />
        </div>
        <div className="img">
          <img src={logo83} alt="Mentor Companies" />
        </div>
        <div className="img">
          <img src={logo84} alt="Mentor Companies" />
        </div>
        <div className="img">
          <img src={logo85} alt="Mentor Companies" />
        </div>
      </div>
    </div>
  );
};

export default Trustedby;
