import React, { Component } from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import History from '../History';
import { getUser } from '../../store/actions/users';
import { fetchFields } from '../../store/actions/field';
import { getMenteeMeetings, getMentorMeetings } from '../../store/actions/meeting';
import { wicon40, icon13, icon14, icon15, icon18, icon19 } from '../Copy/Copy';

class Dashment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false,
    };
  }

  componentDidMount() {
    this.props.fetchFields();
    if (!this.props.role) {
      this.props.getUser(this.props.user, 'user').then((user) => {
        if (user.role === 'mentor') {
          this.props.getMentorMeetings(user.id).then(() => {
            this.setState({
              load: true,
            });
          });
        } else if (user.role === 'user') {
          this.props.getMenteeMeetings(user.id).then(() => {
            this.setState({
              load: true,
            });
          });
        }
      });
    } else {
      if (this.props.role === 'mentor') {
        this.props.getMentorMeetings(this.props.user).then(() => {
          this.setState({
            load: true,
          });
        });
      } else if (this.props.role === 'user') {
        this.props.getMenteeMeetings(this.props.user).then(() => {
          this.setState({
            load: true,
          });
        });
      }
    }
  }

  render() {
    const { meeting, role, display, fields } = this.props;
    const { load } = this.state;
    var date = new Date();
    date = date.toLocaleDateString('en-us', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });

    const now = new Date();
    var meets = [];
    if (meeting.mentorMeetings || meeting.menteeMeetings) {
      if (role === 'mentor') {
        meets = meeting.mentorMeetings.filter((e) => {
          const meetTime = new Date(e.time);
          if (now < meetTime) {
            return true;
          }
          return false;
        });
      } else if (role === 'user') {
        meets = meeting.menteeMeetings.filter((e) => {
          const meetTime = new Date(e.time);
          if (now < meetTime) {
            return true;
          }
          return false;
        });
      }

      meets.sort((a, b) => {
        const at = new Date(a.time);
        const bt = new Date(b.time);
        return at - bt;
      });

      if (meets.length > 0) {
        meets.length = 1;
        var newTime = new Date(meets[0].time);
        var tag;
        var purpose = meets[0].purpose;
        var username;
        newTime.toDateString();
        var dateTime = newTime.toLocaleDateString('en-us', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });
        dateTime = `${dateTime}`.split(',');
        var day = dateTime[0];
        var redate = dateTime[1];
        newTime = `${newTime}`.split(' ');
        var meetTime = `${newTime[4].split(':')[0]}:${
          newTime[4].split(':')[1]
        }`;
        var zone = newTime[5];
        if (purpose.length > 120) {
          purpose = `${purpose.substring(0, 120)}...`;
        }
        if (role === 'mentor') {
          if (meets[0].mentee.picture.indexOf('google') !== -1) {
            var imgStr = meets[0].mentee.picture;
          } else if (
            meets[0].mentee.picture.indexOf('trailbucket12345') !== -1
          ) {
            imgStr =
              'https://dqe80zoqbuyqe.cloudfront.net' +
              meets[0].mentee.picture.substring(51);
          } else {
            imgStr = '';
          }
          if (meets[0].mentee.tag.length > 120) {
            tag = `${meets[0].mentee.tag.substring(0, 120)}...`;
          } else {
            tag = meets[0].mentee.tag;
          }
          username = meets[0].mentee.username;
        } else {
          if (meets[0].mentor.picture.indexOf('google') !== -1) {
            imgStr = meets[0].mentor.picture;
          } else if (
            meets[0].mentor.picture.indexOf('trailbucket12345') !== -1
          ) {
            imgStr =
              'https://dqe80zoqbuyqe.cloudfront.net' +
              meets[0].mentor.picture.substring(51);
          } else {
            imgStr = '';
          }
          var url = `/${meets[0].mentor.username}`;
          if (meets[0].mentor.tag.length > 120) {
            tag = `${meets[0].mentor.tag.substring(0, 120)}...`;
          } else {
            tag = meets[0].mentor.tag;
          }
          username = meets[0].mentor.username;
        }
      }
    }

    if (fields) {
      var Tracks = fields.map((m) => (
        <Link
          to={{
            pathname: '/mentor/schedule',
            state: { id: m._id, name: m.name },
          }}
          className="info"
          key={m._id}
        >
          <p>{m.name}</p>
          <i className="fas fa-chevron-right"></i>
        </Link>
      ));
    }

    return (
      <div className={`dashment ${display === 'dahmen' ? '' : 'hide'}`}>
        <div className="info">
          <h1>Mentorship Sessions</h1>
          <div className="time">{date}</div>
        </div>
        <div className="upcoming">
          <div className="session">
            <p className="sm">Next Session</p>
            <div className="info">
              {meets.length > 0 ? (
                <div className="meeting">
                  <div className="content">
                    <div className="title">Next session</div>
                    <div className="desc">{purpose}</div>
                    <div className="btn">
                      Update Schedule <i className="fas fa-chevron-right"></i>
                    </div>
                    <div className="deets">
                      <div className="block">
                        <div className="title">Date</div>
                        <img src={icon13} alt="Trail Mentor" />
                        <div className="day">{day}</div>
                        <div className="day">{redate}</div>
                      </div>
                      <div className="block">
                        <div className="title">Time</div>
                        <img src={icon14} alt="Trail Mentor" />
                        <div className="day">{meetTime}</div>
                        <div className="day">{zone}</div>
                      </div>
                      <div className="block">
                        <div className="title">Venue</div>
                        <img src={icon18} alt="Trail Mentor" />
                        <div className="day">Google</div>
                        <div className="day">Meet</div>
                      </div>
                    </div>
                  </div>
                  <div className="img">
                    <div className="up">
                      {imgStr ? (
                        <img src={imgStr} alt="trail user" />
                      ) : (
                        <div className="icon">
                          <i className="fas fa-user"></i>
                        </div>
                      )}
                      <div className="deets">
                        <div className="name">
                          {load && (
                            <>
                              {role === 'mentor' ? (
                                `${meets[0].mentee.firstName} ${meets[0].mentee.lastName}`
                              ) : (
                                <Link to={url}>
                                  {meets[0].mentor.firstName}{' '}
                                  {meets[0].mentor.lastName}
                                </Link>
                              )}
                            </>
                          )}
                        </div>
                        <div className="title">{tag}</div>
                      </div>
                    </div>
                    <Link to={`/${username}`} className="btn">
                      view full profile <i className="fas fa-chevron-right"></i>
                    </Link>
                  </div>
                </div>
              ) : (
                <div className="nil">
                  <div className="title">No Clarity Session yet!</div>
                  <div className="bio">
                    Trail Clarity sessions are designed to help you start and
                    accelerate your career growth through access to 1:1
                    interactions with verified industry experts
                  </div>
                  <HashLink to="/mentor#mentorlink" className={`cir`}>
                    Book a session <i className="fas fa-chevron-right"></i>
                  </HashLink>
                </div>
              )}
              <div className="group">
                <div className="up">
                  <div className="title">Group Sessions</div>
                  <div className="icon">
                    <img src={icon19} alt="Trail Mentor" />
                  </div>
                  <p>
                    Book a mentoring session with your friends who have similar
                    interests.
                  </p>
                </div>
                <HashLink to="/mentor#mentorlink" className="btn">
                  Book a group session <i className="fas fa-chevron-right"></i>
                </HashLink>
              </div>
            </div>
            <div className="disg">
              <div className="group">
                <div className="up">
                  <div className="title">Group Sessions</div>
                  <div className="icon">
                    <img src={icon19} alt="Trail Mentor" />
                  </div>
                  <p>
                    Book a mentoring session with your friends who have similar
                    interests.
                  </p>
                </div>
                <HashLink to="/mentor#mentorlink" className="btn">
                  Book a group session <i className="fas fa-chevron-right"></i>
                </HashLink>
              </div>
              <div className="become">
                <div className="title">Become a mentor</div>
                <div className="icon">
                  <img src={icon15} alt="Trail Mentor" />
                </div>
                <p>
                  Share your experience and help others grow and earn while
                  doing that
                </p>
                <a href="https://forms.gle/LWtnfjCTKyPsYyN99" className="btn">
                  Sign up as a mentor <i className="fas fa-chevron-right"></i>
                </a>
              </div>
            </div>
            <div className="track sm">
              <div className="titl">Mentorship Tracks</div>
              <div className="wrappr">
                <div className="deet">{Tracks}</div>
              </div>
              <div className="query">
                <div className="bulb">
                  <div className="inner">
                    <img src={wicon40} alt="Help Trail" />
                  </div>
                </div>
                <div className="content">
                  <div className="title">
                    Have any creative idea that might help boost our marketing?
                    Even if it's just by a tiny bit? Share away.
                  </div>
                  <a
                    href="mailto:marketing@trailng.com?subject=We%20want%20to%20hear%20from%20you!"
                    className="message"
                  >
                    Send us a message
                  </a>
                </div>
              </div>
            </div>
            <div className="hist">
              <History {...this.props} display={display} />
              <div className="become">
                <div className="title">Become a mentor</div>
                <div className="icon">
                  <img src={icon15} alt="Trail Mentor" />
                </div>
                <p>
                  Share your experience and help others grow and earn while
                  doing that
                </p>
                <a href="https://forms.gle/LWtnfjCTKyPsYyN99" className="btn">
                  Sign up as a mentor <i className="fas fa-chevron-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="track bg">
            <div className="titl">Mentorship Tracks</div>
            <div className="wrappr">
              <div className="deet">{Tracks}</div>
            </div>
            <div className="query">
              <div className="bulb">
                <div className="inner">
                  <img src={wicon40} alt="Help Trail" />
                </div>
              </div>
              <div className="content">
                <div className="title">
                  Have any creative idea that might help boost our marketing?
                  Even if it's just by a tiny bit? Share away.
                </div>
                <a
                  href="mailto:marketing@trailng.com?subject=We%20want%20to%20hear%20from%20you!"
                  className="message"
                >
                  Send us a message
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { meeting: state.meeting, fields: state.field.field };
}

export default connect(mapStateToProps, {
  getMenteeMeetings,
  getMentorMeetings,
  getUser,
  fetchFields,
})(Dashment);
