import { apiCall } from '../../services/api';
import { addError, removeError } from './errors';
import {
  LOAD_MENTOR,
  LOAD_MENTOR_MEETINGS,
  LOAD_MENTEE_MEETINGS,
} from '../actionTypes';

export const loadMentor = (mentor) => ({
  type: LOAD_MENTOR,
  mentor,
});

export const loadMentorMeetings = (mentorMeetings) => ({
  type: LOAD_MENTOR_MEETINGS,
  mentorMeetings,
});

export const loadMenteeMeetings = (menteeMeetings) => ({
  type: LOAD_MENTEE_MEETINGS,
  menteeMeetings,
});

export function getMentor(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return apiCall('get', `/api/v1/meetings/field/${id}`)
        .then(({ data }) => {
          dispatch(loadMentor(data));
          dispatch(removeError());
          resolve();
        })
        .catch((err) => {
          dispatch(addError(err));
          reject();
        });
    });
  };
}

export function getOneMentor(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return apiCall('get', `/api/v1/meetings/singlementor/${id}`)
        .then(({ data }) => {
          dispatch(loadMentor(data));
          dispatch(removeError());
          resolve();
        })
        .catch((err) => {
          dispatch(addError(err));
          reject();
        });
    });
  };
}

export function getMentorMeetings(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return apiCall('get', `/api/v1/meetings/mentor/${id}`)
        .then(({ data }) => {
          dispatch(loadMentorMeetings(data));
          dispatch(removeError());
          resolve();
        })
        .catch((err) => {
          dispatch(addError(err));
          reject();
        });
    });
  };
}

export function getMenteeMeetings(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return apiCall('get', `/api/v1/meetings/mentee/${id}`)
        .then(({ data }) => {
          dispatch(loadMenteeMeetings(data));
          dispatch(removeError());
          resolve();
        })
        .catch((err) => {
          dispatch(addError(err));
          reject();
        });
    });
  };
}

export function updateMeeting(id, data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return apiCall('put', `/api/v1/meetings/${id}`, data)
        .then(() => {
          dispatch(removeError());
          resolve();
        })
        .catch((err) => {
          dispatch(addError(err));
          reject();
        });
    });
  };
}
