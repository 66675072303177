import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { HashLink } from 'react-router-hash-link'
import { logout } from '../store/actions/auth'

class WaitNavbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      close: false,
    }
  }

  handleClick = (e) => {
    this.setState({
      close: !this.state.close,
    })
  }

  handleAltClick = (e) => {
    this.setState({
      close: !this.state.close,
    })
    if (this.props.alt) {
      this.props.exit()
    }
  }

  logout = (e) => {
    e.preventDefault()
    this.props.logout()
  }

  render() {
    var { location, alt, exit } = this.props
    if (!location) {
      location = '/'
    }
    return (
      <div>
        <nav className={`nav ${alt ? 'alt' : ''}`} id="nav">
          <div className="nav-top">
            <div className="navbar-header">
              <Link to="/">
                <img
                  src="https://trail-bucket-074269.s3.us-east-2.amazonaws.com/files/Trail+c-01.png"
                  alt="Trail Home"
                  width="40"
                  height="55"
                />
                <div className="brand">Trail</div>
              </Link>
            </div>
          </div>
          <div className="nav-bottom">
            <Link to="/" className={`nav-link`}>
              <div className="nav-info">
                {this.props.currentUser.isAuthenticated ? 'Dashboard' : 'Home'}
              </div>
            </Link>
            <Link
              to="/mentor"
              className={`nav-link ${
                location.indexOf('mentor') !== -1 ? 'active' : ''
              }`}
            >
              <div className="nav-info hide">Mentor Sessions</div>
            </Link>
            <Link
              to="/pathway/product-design"
              className={`nav-link ${
                location.indexOf('pathway') !== -1 ? 'active' : ''
              }`}
            >
              <div className="nav-info hide">Pathway</div>
            </Link>
            <Link
              to="/about"
              className={`nav-link ${
                location.indexOf('about') !== -1 ? 'active' : ''
              }`}
              onClick={alt ? exit : () => {}}
            >
              <div className="nav-info">About us</div>
            </Link>

            {!this.props.currentUser.isAuthenticated && (
              <div className="nav-auth">
                <div
                  className={`nav-info login ${
                    this.props.navbar === 'signin' ? 'hide' : ''
                  }`}
                >
                  <Link to="/signin" onClick={alt ? exit : () => {}}>
                    Log in
                  </Link>
                </div>
                <div
                  className={`nav-info signup ${
                    this.props.navbar === 'signup' ? 'hide' : ''
                  }`}
                >
                  <Link to="/signup" onClick={alt ? exit : () => {}}>
                    Sign Up
                  </Link>
                </div>
              </div>
            )}
          </div>
        </nav>
        <div
          className={`side-nav bar ${alt ? 'alt' : ''} ${
            this.state.close ? '' : 'open'
          }`}
        >
          <div className="nav-top">
            <Link to="/">
              <div className="nav-info">
                {this.props.currentUser.isAuthenticated ? 'Dashboard' : 'Home'}
              </div>
            </Link>
            <HashLink
              onClick={this.handleClick}
              to="#mentor"
              className={`nav-link ${
                location.indexOf('mentor') !== -1 ? 'active' : ''
              }`}
            >
              <div className="nav-info hide">Mentor Sessions</div>
            </HashLink>
            <Link
              to="/pathway/product-design"
              className={`nav-link ${
                location.indexOf('pathway') !== -1 ? 'active' : ''
              }`}
            >
              <div className="nav-info hide">Product Design Pathway</div>
            </Link>
            <a
              href="https://chrome.google.com/webstore/detail/one-trail/aafnfdajlgnfgcgopijjcfdffjlmmkjb"
              className={`nav-link`}
            >
              <div className="nav-info">Get from Chrome store</div>
            </a>
            <Link
              onClick={this.handleAltClick}
              to="/about"
              className={`nav-link ${
                location.indexOf('about') !== -1 ? 'active' : ''
              }`}
            >
              <div className="nav-info">About us</div>
            </Link>
          </div>
          <div className="nav-bottom">
            {this.props.currentUser.isAuthenticated ? (
              <div></div>
            ) : (
              <div className="nav-auth">
                <div
                  className={`nav-info login ${
                    this.props.navbar === 'signin' ? 'hide' : ''
                  }`}
                >
                  <Link to="/signin" onClick={alt ? exit : () => {}}>
                    Log in
                  </Link>
                </div>
                <div
                  className={`nav-info signup ${
                    this.props.navbar === 'signup' ? 'hide' : ''
                  }`}
                >
                  <Link to="/signup" onClick={alt ? exit : () => {}}>
                    Sign Up
                  </Link>
                </div>
              </div>
            )}
            <div
              className={`hamburger ${this.state.close ? 'open' : ''}`}
              onClick={this.handleClick}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
  }
}

export default connect(mapStateToProps, { logout })(WaitNavbar)
